import React, { ReactNode } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Box, CircularProgress } from '@mui/material';

/***
 * Display a consistent card title, with an optional loading spinner and child(ren) on the right
 */

interface TitleProps extends Pick<TypographyProps, 'sx'> {
  title?: ReactNode;
  loading?: boolean;
  rightChild?: ReactNode;
}

export default function ({ title, loading, rightChild, ...props }: TitleProps) {
  return (
    <Typography
      component="h2"
      variant="h6"
      color="primary"
      gutterBottom
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...(props.sx || {}),
      }}
    >
      {title}
      {loading && (
        <CircularProgress size="16px" thickness={5} sx={{ marginLeft: 2 }} />
      )}
      <Box sx={{ flexGrow: 1 }} />
      {rightChild}
    </Typography>
  );
}

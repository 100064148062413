import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import useApiForm from '../../Hooks/useApiForm';
import PalletService from '../../Services/PalletService';
import CartonService, {
  ORPHAN_PALLET_STATUS_REASONS,
} from '../../Services/CartonService';
import MobilePage from '../../Components/MobilePage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Pallet } from '../../Models/Pallet';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { toast, toastError } from '../../Components/Toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  CartonStatus,
  digitsDisplayedForCartonBarcode,
  getShortenBarcode,
} from '../../Models/Carton';
import { confirmModalDialog } from '../../Components/ModalDialog';
import BarcodeScannerFormInput from '../../Components/Forms/BarcodeScannerFormInput';
import theme from '../../theme';
import CartonsWIthStatusFilter from '../../Components/Carton/CartonsWIthStatusFilter';

export default function () {
  const [pallet, setPallet] = useState<Pallet | null>(null);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState<CartonStatus | null>(null);

  const resetPalletForm = () => {
    getPalletForm.setData('code', '');
    setPallet(null);
    resetCartonForm();
  };

  const resetCartonForm = () => {
    addCartonToPalletByBarcodeForm.setData('cartonBarcode', '');
  };

  const getPalletForm = useApiForm(
    PalletService.getByCode,
    {
      code: '',
      withCartons: true,
    },
    {
      onError: (message) => {
        getPalletForm.setErrors({ code: message });
      },
      onSuccess: (result) => {
        setPallet(result);
      },
      useSearchParams: true,
    }
  );

  const markAsVerifiedForm = useApiForm(
    CartonService.markAsVerified,
    {
      cartonId: 0,
    },
    {
      onSuccess: () => {
        resetCartonForm();
        // refresh the list of cartons
        getPalletForm.submit();
      },
    }
  );

  const addCartonToPalletByBarcodeForm = useApiForm(
    CartonService.addCartonToPalletByBarcode,
    {
      cartonBarcode: '',
      palletId: 0,
      process: ORPHAN_PALLET_STATUS_REASONS.VERIFY_A_PALLET,
      newPalletBarcode: '',
    },
    {
      onSuccess: () => {
        resetCartonForm();
        getPalletForm.submit();
      },
    }
  );

  // submit the form if the url contains a query parameter code
  useEffect(() => {
    if (searchParams.get('code')) {
      getPalletForm.submit();
    }
    // should happen only when loading the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPallet = (e: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    getPalletForm.submit();
    return false;
  };

  const fetchCarton = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    const cartons = pallet?.cartons?.filter((carton) =>
      carton.cartonBarcode.startsWith(
        addCartonToPalletByBarcodeForm.data.cartonBarcode
      )
    );

    if (cartons && cartons.length > 0) {
      if (cartons.length > 1) {
        toastError('Multiple cartons were returned.');
        resetCartonForm();
      } else {
        markAsVerifiedForm.setData('cartonId', cartons[0].id);
        markAsVerifiedForm.submit();
        // when copying a barcode, its row gets selected, now that the carton has been verified we can remove the row from the selection
        setRowSelectionModel([]);
      }
    } else {
      const confirmAddCartonToPallet = await confirmModalDialog({
        title: 'Unexpected Carton. Add Carton to Pallet?',
        content: '',
      });
      if (confirmAddCartonToPallet) {
        addCartonToPalletByBarcodeForm.setData('palletId', pallet!.id);
        addCartonToPalletByBarcodeForm.submit();
      } else {
        resetCartonForm();
      }
    }
    return false;
  };

  const columnDefs = useMemo<GridColDef[]>(
    () => [
      {
        field: 'cartonBarcode',
        renderHeader: () => (
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 450,
              fontSize: '14px',
            }}
          >
            Barcode
            <ContentCopyIcon sx={{ marginLeft: 0.5 }} />
          </Typography>
        ),
        flex: 1,
        renderCell: (params) => (
          <Button
            variant="text"
            onClick={() => {
              navigator.clipboard
                .writeText(params.row.cartonBarcode)
                .then(() => toast('Barcode copied'));
            }}
          >
            {params.row.cartonBarcode.length > digitsDisplayedForCartonBarcode
              ? '...'
              : ''}
            {getShortenBarcode(
              params.row.cartonBarcode,
              digitsDisplayedForCartonBarcode
            )}
          </Button>
        ),
      },
      {
        field: 'productCode',
        headerName: 'Pdt Code',
        flex: 1,
      },
      {
        field: 'qty',
        headerName: 'QTY',
        flex: 0.6,
        renderCell: (params) => params.row.qty,
      },
    ],
    []
  );

  const completeScanningForm = useApiForm(
    PalletService.completeVerifying,
    {
      id: 0,
    },
    {
      onSuccess: () => {
        toast('Scanning completed');
        resetPalletForm();
      },
    }
  );

  const onClickOnStatus = (status: CartonStatus) => {
    setStatusFilter(statusFilter === status ? null : status);
  };

  const onCompleteScanning = async () => {
    const nbCartonsLost =
      pallet?.cartons?.filter((c) => c.status === CartonStatus.expected)
        .length ?? 0;
    const nbCartonsAdded =
      pallet?.cartons?.filter((c) => c.status === CartonStatus.new).length ?? 0;
    const nbCartonsVerified =
      pallet?.cartons?.filter((c) => c.status === CartonStatus.verified)
        .length ?? 0;

    const confirmCompleteScanning = await confirmModalDialog({
      title: 'Confirm scanning completed',
      acceptButtonCountdown: nbCartonsLost > 0 ? 3 : 0,
      declineButtonCountdown: 0,
      content: (
        <ul>
          {nbCartonsVerified > 0 && (
            <li>
              <span data-testid="nb-cartons-verified">{nbCartonsVerified}</span>{' '}
              carton(s) scanned
            </li>
          )}
          {nbCartonsAdded > 0 && (
            <li>
              <span data-testid="nb-cartons-added">{nbCartonsAdded}</span> new
              carton(s) added
            </li>
          )}
          {nbCartonsLost > 0 && (
            <li style={{ color: theme.palette.error.main, fontWeight: 'bold' }}>
              <span data-testid="nb-cartons-to-be-lost">{nbCartonsLost}</span>{' '}
              carton(s) will be marked as lost
            </li>
          )}
        </ul>
      ),
    });
    if (!confirmCompleteScanning) {
      return;
    }
    completeScanningForm.setData('id', pallet!.id);
    completeScanningForm.submit();
  };

  const onPalletScan = () => {
    // if scanning and the current barcode field has focus we want it to lose focus for the second scan
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    getPalletForm.submit();
  };

  const onCartonScan = () => {
    fetchCarton();
  };

  return (
    <MobilePage
      showOnDesktop
      onBack={() => {
        if (pallet !== null) {
          getPalletForm.setData('code', '');
          setPallet(null);
        } else {
          navigate(-1);
        }
      }}
    >
      <Stack spacing={1}>
        {pallet == null && (
          <Box component="form" onSubmit={fetchPallet} noValidate>
            <Typography variant="subtitle2" textAlign="center">
              Scan pallet:
            </Typography>
            <BarcodeScannerFormInput
              label="Barcode"
              id="code"
              form={getPalletForm}
              onBarcode={onPalletScan}
              onClear={() => resetPalletForm()}
            />
          </Box>
        )}
        {pallet && (
          <>
            <Box component="form" onSubmit={fetchCarton} noValidate>
              <Typography variant="subtitle2" textAlign="center">
                Scan carton:
              </Typography>
              <BarcodeScannerFormInput
                label="Carton Barcode"
                id="cartonBarcode"
                form={addCartonToPalletByBarcodeForm}
                onBarcode={onCartonScan}
              />
            </Box>
            <CartonsWIthStatusFilter
              cartons={pallet.cartons}
              status={statusFilter}
              onStatusClick={onClickOnStatus}
              cartonColumns={columnDefs}
              onCompleteScanning={onCompleteScanning}
              rowSelectionModel={rowSelectionModel}
              onRowSelectionChange={(newRowSelectionModel) =>
                setRowSelectionModel(newRowSelectionModel)
              }
            />
          </>
        )}
      </Stack>
    </MobilePage>
  );
}

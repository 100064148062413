import React, { useState } from 'react';
import SettingService from '../../Services/SettingService';
import EditSetting from './EditSetting';
import { toast } from '../../Components/Toast';
import useApiGet from '../../Hooks/useApiGet';
import { Setting } from '../../Models/Setting';
import MobilePage from '../../Components/MobilePage';
import CardTitle from '../../Components/CardTitle';
import { Box } from '@mui/material';
import StandardDataGrid from '../../Components/StandardDataGrid';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium';

/***
 * This is the list of Settings
 */
export default function Settings() {
  const { data, loading, refresh } = useApiGet(SettingService.getSettings);
  const [editSetting, setEditSetting] = useState<Setting | null>(null);
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
    setEditSetting(null);
  };

  const onSuccess = () => {
    toast('Setting updated!');
    closeModal();
    refresh();
  };

  const renderActions = (params: GridRowParams) => {
    const actions = [];

    const editBtn = (
      <GridActionsCellItem
        testId="edit-setting"
        label="Edit"
        onClick={() => {
          setEditSetting(params.row as Setting);
          setOpen(true);
        }}
        showInMenu
      />
    );
    actions.push(editBtn);

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      valueGetter: (params: GridRenderCellParams<Setting>) => params.row.name,
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      valueGetter: (params: GridRenderCellParams<Setting>) => params.row.value,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      headerName: 'Actions',
      getActions: renderActions,
    },
  ];

  let rowCount = 0;
  const settingRows = data?.map((setting) => {
    return {
      id: rowCount++,
      name: setting.name,
      value: setting.value,
    };
  });

  return (
    <MobilePage sx={{ position: 'relative' }}>
      <Box sx={{ height: '82vh' }}>
        <CardTitle title="Settings" />
        <StandardDataGrid
          testId="data-grid-settings-table"
          loading={loading}
          rows={settingRows || []}
          columns={columns}
          getRowId={(row) => row.id}
          disableRowSelectionOnClick={true}
        />
      </Box>
      {open && (
        <EditSetting
          setting={editSetting}
          onClose={closeModal}
          onSuccess={onSuccess}
        />
      )}
    </MobilePage>
  );
}

import { faker } from '@faker-js/faker';
import { Factory } from 'fishery';
import { RoleName } from './Role';
import { Warehouse } from './Warehouse';
import { SelectItem } from '../Components/Forms/FormField';

export interface UserNameDto {
  id: number;
  name: string;
  surname: string;
  fullName: string;
  userName: string;
}

export interface User extends UserNameDto {
  emailAddress: string;
  roleNames: RoleName[];
  creationTime?: string;
  isActive: boolean;
  lastLoginTime?: string;
  hasBarcode?: boolean;
  currentWarehouseId?: number;
  currentWarehouse?: Warehouse;
  warehouseIds?: number[];
  warehouses?: Warehouse[];
  customerIds?: number[];
}

export const userFactory = Factory.define<User>(() => {
  const firstName = faker.name.firstName();
  const lastName = faker.name.lastName();
  return {
    id: faker.datatype.number(),
    name: firstName,
    userName: firstName + lastName,
    surname: lastName,
    fullName: firstName + ' ' + lastName,
    emailAddress: faker.internet.email(),
    isActive: true,
    roleNames: [],
    warehouseIds: [],
    customerIds: [],
  };
});

/**
 *  Helper functions
 */

export function userSelectItems(users?: UserNameDto[]) {
  return (users || []).map<SelectItem>((user) => ({
    id: user.id,
    label: user.fullName,
  }));
}

import Api, { ServicePostMethod } from './Api';

/**
 * The PdfGeneratorService provides api endpoints for an Admin to operate on other Users.
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const generatePdf: ServicePostMethod<object, string> = (data, options) =>
  Api.put('api/services/app/PdfGenerator/GenerateStaticPdf', data, options);

const generateTablePdf: ServicePostMethod<TablePdfRequest, string> = (
  data,
  options
) => Api.put('api/services/app/PdfGenerator/GenerateTable', data, options);

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  generatePdf,
  generateTablePdf,
};

/**
 *  Models used in this service
 */
export interface TablePdfRequest {
  columns: string[];
  rows: { [key: string]: string }[] | undefined;
  reportHeading: string;
  reportFooter: string;
}

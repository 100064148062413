import React, { useState } from 'react';
import { BillOfLadingWithCounts } from '../../Models/BillOfLading';
import { BOLContainer } from '../../Models/BOLContainer';
import useApiForm from '../../Hooks/useApiForm';
import { Button } from '@mui/material';
import { UserDefinedCode } from '../../Models/UserDefinedCode';
import usePermissions from '../../Hooks/usePermissions';
import BOLContainerService from '../../Services/BolContainerService';
import { confirmModalDialog } from '../../Components/ModalDialog';
import EntityBilling from '../../Components/Billing/EntityBilling';

export interface BolContainerBillingProps {
  bol: BillOfLadingWithCounts;
  container: BOLContainer;
  refresh: () => void;
}

export const billingCodeIsAuto = (code: UserDefinedCode) =>
  code.customValues?.some(
    (cv) => cv.name == 'Unit' && ['Container', 'Pallet'].includes(cv.value)
  );

export default function ({
  bol,
  container,
  refresh: refreshContainer,
}: BolContainerBillingProps) {
  const [canEditBilling] = usePermissions(['BOL.Billing.Edit']);
  const [canEditFullBilling] = usePermissions(['BOL.Billing.FullEdit']);

  const [isBillingComplete, setIsBillingComplete] = useState(
    !!container.billingCompletedDate
  );

  const completeBillingForm = useApiForm(
    BOLContainerService.setCompletedDates,
    {
      id: container.id,
      billingComplete: true,
    },
    {
      onSuccess: () => {
        setIsBillingComplete(true);
        refreshContainer();
      },
    }
  );

  const closeOffBilling = async () => {
    const confirmed = await confirmModalDialog({
      title: 'Complete Billing',
      content: `Are you sure you want to close off billing for this container? ${
        canEditBilling ? 'You will be unable to make further changes.' : ''
      }`,
    });
    if (confirmed) {
      completeBillingForm.submit();
    }
  };

  return (
    <>
      <EntityBilling
        entity={container}
        customerId={parseInt(bol.customerId || '0')}
        onBillingClosed={() => setIsBillingComplete(true)}
        canEditBilling={(canEditBilling || false) && !isBillingComplete}
        canEditFullBilling={canEditFullBilling || false}
      />
      <Button
        variant="outlined"
        data-testid={'billing-complete-button'}
        onClick={() => closeOffBilling()}
        // if they have the 'BOL.Billing.FullEdit' permission and the billing is not yet complete
        // if they have the 'BOL.Billing.Edit' permission and the billing is not yet complete
        // otherwise, if no permissions, disable the button
        disabled={isBillingComplete || !(canEditBilling || canEditFullBilling)}
      >
        {container.billingCompletedDate || isBillingComplete
          ? 'Billing Completed'
          : 'Complete Billing'}
      </Button>
    </>
  );
}

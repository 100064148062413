import Api, { ServiceGetMethod } from './Api';
import { Checklist } from '../Models/Checklist';

const getByEntity: ServiceGetMethod<
  {
    entityType: string;
    entityId: number;
    parentEntityType?: string;
    parentEntityId?: number;
  },
  Checklist
> = (options) => Api.get('api/services/app/Checklist/GetByEntity', options);

export default {
  getByEntity,
};

import { Box, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import useApiForm from '../../Hooks/useApiForm';
import PalletService from '../../Services/PalletService';
import PalletDetails from '../../Components/PalletDetails';
import { useSearchParams } from 'react-router-dom';
import BarcodeScannerFormInput from '../../Components/Forms/BarcodeScannerFormInput';
import { Pallet } from '../../Models/Pallet';

export interface PalletSearchProps {
  barcode: string;
  onGotPalletEnquiryInfo?: (pallet: Pallet) => void;
}

export default function ({
  barcode,
  onGotPalletEnquiryInfo,
}: PalletSearchProps) {
  const [palletId, setPalletId] = useState(0);
  const [searchParams] = useSearchParams();
  const onGotPalletInfo = useCallback(
    (pallet: Pallet) => {
      if (onGotPalletEnquiryInfo != undefined) {
        onGotPalletEnquiryInfo!(pallet);
      }
    },
    [onGotPalletEnquiryInfo]
  );

  const resetForms = () => {
    getPalletForm.setData('code', '');
    setPalletId(0);
  };

  const getPalletForm = useApiForm(
    PalletService.getByCode,
    {
      code: '',
      syncFromBasis: false,
      performPalletOnHandCheck: false,
    },
    {
      onError: (message) => {
        getPalletForm.setErrors({ code: message });
      },
      onSuccess: (result) => {
        setPalletId(result.id);
      },
      useSearchParams: true,
    }
  );

  // submit the form if the url contains a query parameter code
  useEffect(() => {
    if (searchParams.get('code')) {
      getPalletForm.submit();
    }
    if (barcode != '') {
      getPalletForm.setData('code', barcode);
      getPalletForm.submit();
    }
    // should happen only when loading the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPallet = (e: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    getPalletForm.submit();
    return false;
  };

  const onPalletScan = () => {
    // if scanning and the current barcode field has focus we want it to lose focus for the second scan
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    getPalletForm.submit();
    resetForms();
  };

  return (
    <Box component="form" onSubmit={fetchPallet} noValidate>
      <Typography variant="subtitle2" textAlign="center">
        Scan pallet/carton:
      </Typography>
      <BarcodeScannerFormInput
        label="Barcode"
        id="code"
        form={getPalletForm}
        onBarcode={onPalletScan}
        onClear={() => resetForms()}
      />
      {palletId !== 0 && (
        <Stack>
          <PalletDetails
            palletId={palletId}
            onGotPalletInfo={onGotPalletInfo}
          />
        </Stack>
      )}
    </Box>
  );
}

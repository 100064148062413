import { Role } from '../Models/Role';
import Api, {
  PagedAndSortedRequest,
  PagedResult,
  ServiceGetMethod,
} from './Api';

/**
 * The RoleService provides api endpoints for interacting with Roles.
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const getRoles: ServiceGetMethod<PagedAndSortedRequest, PagedResult<Role>> = (
  options
) => Api.get('api/services/app/Role/GetAll', options);

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  getRoles,
};

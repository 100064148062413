import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { XmlTag } from '../../../Lib/xmlUtils';
import ECertBlockInfo from './ECertBlockInfo';

export default function ECertTransport({
  eCertXmlContent,
  tagNamePrefix = 'ed:',
}: {
  eCertXmlContent: XmlTag;
  tagNamePrefix?: string;
}) {
  const fieldNames = [
    { label: 'Loading Port ID', keyName: 'LoadingPortID' },
    { label: 'Transport Mode', keyName: 'TransportMode' },
    { label: 'Final Destination', keyName: 'FinalDestination' },
    { label: 'Local Carrier', keyName: 'LocalCarrier' },
    { label: 'Carrier Name', keyName: 'CarrierName' },
    { label: 'Conveyance Reference', keyName: 'ConveyanceReference' },
  ];
  return (
    <Box sx={{ marginY: '16px' }}>
      <Typography
        sx={{ fontSize: '20px', fontWeight: 500, marginBottom: '16px' }}
      >
        Transport
      </Typography>
      <Grid container spacing={2}>
        {fieldNames.map((field, index) => {
          let displayValue =
            eCertXmlContent.getElementsByTagName?.(
              field.keyName,
              tagNamePrefix
            )[0]?.value || '';
          if (field.keyName === 'TransportMode') {
            switch (displayValue) {
              case '1':
                displayValue = 'Maritime';
                break;
              case '2':
                displayValue = 'Rail';
                break;
              case '3':
              default:
                displayValue = 'Road';
                break;
              case '4':
                displayValue = 'Air';
                break;
            }
          }
          return (
            <ECertBlockInfo
              key={`transport-block-info-${index}`}
              xs={6}
              md={6}
              label={field.label}
              displayText={displayValue}
            />
          );
        })}
      </Grid>
    </Box>
  );
}

import { Box } from '@mui/material';
import React from 'react';
import BarcodeScannerFormInput, {
  BarcodeResult,
} from '../../../Components/Forms/BarcodeScannerFormInput';
import useApiForm, { UseApiFormResult } from '../../../Hooks/useApiForm';
import PalletService, {
  SplitPalletRequest,
} from '../../../Services/PalletService';
import FormField from '../../../Components/Forms/FormField';

interface Props {
  splitPalletForm: UseApiFormResult<SplitPalletRequest, void>;
  onSuccess?: (code: string) => void;
}

export default function TargetPalletForm({
  splitPalletForm,
  onSuccess,
}: Props) {
  const getPalletForm = useApiForm(PalletService.getByCode, {
    code: '',
    withCartons: true,
  });

  const handleOnBarcode = (result: BarcodeResult) => {
    // if scanning and the current barcode field has focus we want it to lose focus for the second scan
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    onSuccess?.(result.code);
  };

  return (
    <Box component="form" noValidate>
      <BarcodeScannerFormInput
        label="Scan target pallet"
        id="code"
        form={getPalletForm}
        onBarcode={handleOnBarcode}
        onClear={() => getPalletForm.setData('code', '')}
      />
      <FormField
        type="checkbox"
        form={splitPalletForm}
        id="isDamaged"
        label="Target pallet is damaged?"
        sx={{ mt: 0 }}
      />
    </Box>
  );
}

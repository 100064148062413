import { Store } from '../Models/Store';
import { StoreCapacity } from '../Models/StoreCapacity';
import Api, { PagedResult, ServiceGetMethod } from './Api';

/**
 * The CustomerService provides api endpoints for an Admin to operate on Customers.
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const getStoreCapacity: ServiceGetMethod<
  GetStoreCapacityRequest,
  StoreCapacity[]
> = (options) => Api.get('api/services/app/Store/GetStoreCapacity', options);

const getStores: ServiceGetMethod<GetStoresRequest, PagedResult<Store>> = (
  options
) => Api.get('api/services/app/Store/GetAll', options);

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  getStoreCapacity,
  getStores,
};

/**
 *  Models used in this service
 */

export interface GetStoresRequest {
  warehouseId?: number | null;
}

export interface GetStoreCapacityRequest {
  storeId?: number;
  productId?: string;
}

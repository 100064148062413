import { SelectItem } from '../Components/Forms/FormField';

export type RoleName =
  | 'ADMIN MANAGER'
  | 'OFFICE ADMIN'
  | 'OPERATOR'
  | 'SUPER ADMIN'
  | 'CUSTOMER USER';

export type Permission =
  // Views
  | 'Audit.View'
  | 'Billing.View'
  // ASN
  | 'ASN.Arrivals'
  | 'ASN.Billing.Edit'
  | 'ASN.Billing.FullEdit'
  | 'ASN.Checklist.Complete'
  | 'ASN.Checklist.Update'
  | 'ASN.Update'
  | 'ASN.View'
  // Bill of Lading related
  | 'BOL.Billing.Confirm'
  | 'BOL.Billing.Edit'
  | 'BOL.Billing.FullEdit'
  | 'BOL.Billing.View'
  | 'BOL.Carton.ClearAllAssignedCartons'
  | 'BOL.Checklist.Complete'
  | 'BOL.Checklist.Update'
  | 'BOL.Create'
  | 'BOL.Fulfil'
  | 'BOL.LinkBarcode'
  | 'BOL.Load'
  | 'BOL.MarkAsReady'
  | 'BOL.Pallet.Search'
  | 'BOL.Pallet.Split'
  | 'BOL.Pallet.Unload'
  | 'BOL.Pallet.UpdateLocation'
  | 'BOL.Pallet.VerifyDetails'
  // Pallet
  | 'API.Pallet.Create'
  // Container
  | 'Container.Reviewer'
  | 'Container.CartersNote'
  | 'Container.ProofOfDelivery'
  | 'Container.Status.Update'
  // Carton
  | 'Carton.UpdateHoldStatus'
  // Customer
  | 'Customer.Admin'
  // Pages
  | 'Pages.AdminBolLoadList'
  | 'Pages.AdminDashboard'
  | 'Pages.LoadPlan'
  | 'Pages.Codes'
  | 'Pages.Customers'
  | 'Pages.Cartons'
  | 'Pages.Lots'
  | 'Pages.Roles'
  | 'Pages.StockOnHand'
  | 'Pages.Tenants'
  | 'Pages.Users'
  | 'Pages.Users.Activation'
  // Store
  | 'Store.Capacity.View'
  // User perms
  | 'User.Create'
  | 'User.Delete'
  | 'User.LoginTimeout'
  | 'User.View'
  | 'User.Update'
  // Settings
  | 'Admin.Settings.Update'
  | 'Admin.Settings.View'
  // External Customer User
  | 'Customer.User'
  // All Internal Users
  | 'Internal.User'
  | 'Stocktake.View'
  | 'Stocktake.Create'
  | 'StocktakePallet.View'
  | 'StocktakePallet.Create';

export interface Role {
  id: number;
  name: string;
  displayName: string;
  normalizedName: RoleName;
  description: string | null;
  grantedPermissions: Permission[];
}

// these are just for unit tests - in real life they come from the API...
export const FAKE_ROLES: Role[] = [
  {
    id: 1,
    name: 'Super Admin',
    displayName: 'Super Admin',
    normalizedName: 'SUPER ADMIN',
    description: null,
    grantedPermissions: [
      'Admin.Settings.Update',
      'Admin.Settings.View',
      'ASN.View',
      'ASN.Update',
      'ASN.Checklist.Complete',
      'ASN.Checklist.Update',
      'Audit.View',
      'Billing.View',
      'BOL.Checklist.Update',
      'BOL.MarkAsReady',
      'BOL.Billing.FullEdit',
      'BOL.Billing.Confirm',
      'BOL.Billing.View',
      'BOL.LinkBarcode',
      'BOL.Pallet.UpdateLocation',
      'BOL.Pallet.Search',
      'BOL.Pallet.VerifyDetails',
      'BOL.Pallet.Split',
      'BOL.Pallet.Unload',
      'BOL.Carton.ClearAllAssignedCartons',
      'BOL.Create',
      'BOL.Fulfil',
      'Carton.UpdateHoldStatus',
      'Container.CartersNote',
      'Container.ProofOfDelivery',
      'Container.Status.Update',
      'Pages.AdminBolLoadList',
      'Pages.AdminDashboard',
      'Pages.Cartons',
      'Pages.Codes',
      'Pages.Customers',
      'Pages.LoadPlan',
      'Pages.Roles',
      'Pages.StockOnHand',
      'Pages.Tenants',
      'Pages.Users',
      'Pages.Users.Activation',
      'Pages.Lots',
      'Stocktake.View',
      'Stocktake.Create',
      'Store.Capacity.View',
      'User.Create',
      'User.Delete',
      'User.Update',
      'User.View',
    ],
  },
  {
    id: 2,
    name: 'Operator',
    displayName: 'Operator',
    normalizedName: 'OPERATOR',
    description: null,
    grantedPermissions: [
      'BOL.Billing.Edit',
      'BOL.Billing.View',
      'BOL.Checklist.Complete',
      'BOL.Create',
      'BOL.Fulfil',
      'BOL.LinkBarcode',
      'BOL.Load',
      'BOL.MarkAsReady',
      'BOL.Pallet.Search',
      'BOL.Pallet.Split',
      'BOL.Pallet.VerifyDetails',
      'BOL.Pallet.UpdateLocation',
      'BOL.Pallet.Unload',
      'Container.Status.Update',
      'User.LoginTimeout',
      'StocktakePallet.View',
      'StocktakePallet.Create',
      'ASN.Arrivals',
      'ASN.Update',
      'Pages.LoadPlan',
    ],
  },
  {
    id: 3,
    name: 'Office Admin',
    displayName: 'Office Admin',
    normalizedName: 'OFFICE ADMIN',
    description: null,
    grantedPermissions: [
      'Audit.View',
      'Billing.View',
      'BOL.Billing.View',
      'BOL.Checklist.Update',
      'BOL.Carton.ClearAllAssignedCartons',
      'BOL.Create',
      'BOL.Fulfil',
      'BOL.LinkBarcode',
      'BOL.MarkAsReady',
      'Container.Reviewer',
      'Container.CartersNote',
      'Container.ProofOfDelivery',
      'Container.Status.Update',
      'Pages.AdminDashboard',
      'Pages.AdminBolLoadList',
      'Pages.Customers',
      'Pages.StockOnHand',
      'Store.Capacity.View',
      'Pages.Cartons',
      'User.View',
      'ASN.View',
      'ASN.Update',
      'Pages.LoadPlan',
    ],
  },
  {
    id: 4,
    name: 'Admin Manager',
    displayName: 'Admin Manager',
    normalizedName: 'ADMIN MANAGER',
    description: null,
    grantedPermissions: [
      'Audit.View',
      'Billing.View',
      'BOL.Billing.FullEdit',
      'BOL.Create',
      'BOL.LinkBarcode',
      'BOL.MarkAsReady',
      'Container.CartersNote',
      'Container.ProofOfDelivery',
      'Pages.AdminDashboard',
      'Pages.AdminBolLoadList',
      'Pages.Customers',
      'Pages.StockOnHand',
      'Store.Capacity.View',
      'User.View',
      'Stocktake.View',
      'Stocktake.Create',
      'Pages.LoadPlan',
    ],
  },
  // Add CUSTOMER USER role with empty grantedPermissions to fix warning from MUI in Users.spec.tsx about
  // "You have provided an out-of-range value `CUSTOMER USER` for the select (name="roleNames") component."
  {
    id: 5,
    name: 'Customer User',
    displayName: 'Customer User',
    normalizedName: 'CUSTOMER USER',
    description: null,
    grantedPermissions: [],
  },
];

/**
 *  Helper functions
 */

export function roleSelectItems(roles?: Role[]) {
  return (roles || []).map<SelectItem>((role) => ({
    id: role.normalizedName,
    label: role.displayName,
  }));
}

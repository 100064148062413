import { Snackbar, Alert, AlertProps, SnackbarProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

/**
 * export a handy function to trigger a "Toast" event so we can create toasts from anywhere
 * This event is handled within the Toast component and displayed in the UI.
 */

export interface Toast {
  message: string;
  severity?: AlertProps['severity'];
  autoHideDuration?: SnackbarProps['autoHideDuration'];
}

const TOAST_EVENT = 'customToast';

export function toast(toast: Toast | string) {
  const actualToast = typeof toast == 'string' ? { message: toast } : toast;
  window.dispatchEvent(
    new CustomEvent<Toast>(TOAST_EVENT, { detail: actualToast })
  );
}

export function toastError(message: string) {
  const toast: Toast = {
    message,
    severity: 'error',
  };
  window.dispatchEvent(new CustomEvent<Toast>(TOAST_EVENT, { detail: toast }));
}

export default function () {
  const [toast, setToast] = useState<Toast | null>(null);

  useEffect(() => {
    const handler = (data: Event) => {
      const newToast = (data as CustomEvent<Toast>).detail;
      setToast(newToast);
    };
    window.addEventListener(TOAST_EVENT, handler);

    return () => window.removeEventListener(TOAST_EVENT, handler);
  }, [setToast]);

  return (
    <Snackbar
      open={!!toast}
      autoHideDuration={
        typeof toast?.autoHideDuration == 'undefined'
          ? 6000
          : toast.autoHideDuration
      }
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      onClose={() => setToast(null)}
    >
      <Alert onClose={() => setToast(null)} severity={toast?.severity}>
        {toast?.message}
      </Alert>
    </Snackbar>
  );
}

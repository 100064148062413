import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { XmlTag } from '../../../Lib/xmlUtils';
import { Stack } from '@mui/system';

export default function ECertProductContainers({
  product,
  tagNamePrefix = 'ed:',
}: {
  product: XmlTag;
  tagNamePrefix?: string;
}) {
  const [expandedAccordion, setExpandedAccordion] = useState('');
  const handleAccordionChanged =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : '');
    };
  const containers =
    product.getElementsByTagName?.('Container', tagNamePrefix) || [];

  return (
    <Accordion
      expanded={expandedAccordion === 'panelProductContainers'}
      onChange={handleAccordionChanged('panelProductContainers')}
      sx={{ padding: '8px' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="panelProductContainers-header"
      >
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          Containers
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              Container ID
            </Typography>
            <Stack direction={'column'} spacing={4} sx={{ marginTop: '16px' }}>
              {containers.map((container, index) => {
                const containerId =
                  container.getElementsByTagName?.('ID', tagNamePrefix)[0]
                    ?.value || '';
                return (
                  <Typography
                    sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                    key={`ecert-product-container-id-${index}`}
                  >
                    {containerId}
                  </Typography>
                );
              })}
            </Stack>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              Seal ID
            </Typography>
            <Stack direction={'column'} spacing={4} sx={{ marginTop: '16px' }}>
              {containers.map((container, index) => {
                const sealTag =
                  container.getElementsByTagName?.('Seals', tagNamePrefix) ||
                  [];
                return sealTag.length > 0 ? (
                  <Box
                    sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                    key={`ecert-product-container-seal-id-${index}`}
                  >
                    {sealTag[0].children?.map((sealId, idx) => {
                      return (
                        <Typography
                          key={`ecert-product-seal-id-${index}-${idx}`}
                        >
                          {sealId.getElementsByTagName?.('ID', tagNamePrefix)[0]
                            ?.value || ''}
                        </Typography>
                      );
                    })}
                  </Box>
                ) : null;
              })}
            </Stack>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { useMemo } from 'react';
import useApiForm from '../../../Hooks/useApiForm';
import FormField, { SelectItem } from '../../../Components/Forms/FormField';
import LotService from '../../../Services/LotService';
import { Lot } from '../../../Models/Lot';
import { requiredTempStates } from '../../../Models/RequiredTempStates';
import { defaultProductCodeLabel } from '../LotCartonLinking';

interface Props {
  lot: Lot | null;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

function EditLot({ lot, open, onClose, onSuccess }: Props) {
  const form = useApiForm(
    LotService.editLot,
    {
      id: lot?.id || 0,
      productCode:
        (lot?.productCode && lot?.productCode === defaultProductCodeLabel
          ? ''
          : lot?.productCode) || '',
      productionBatch: lot?.productionBatch || '',
      requiredTempState: lot?.requiredTempState || '',
    },
    { onSuccess }
  );

  const requiredTempStateOptions = useMemo(() => {
    return requiredTempStates.map<SelectItem>((type) => ({
      id: type.value,
      label: type.label,
    }));
  }, []);

  return (
    <Dialog open={open}>
      <Box
        component="form"
        onSubmit={form.submit}
        data-testid="edit-edit-form"
        noValidate
      >
        <DialogTitle>Edit Lot</DialogTitle>
        <DialogContent>
          <FormField
            form={form}
            id="productCode"
            type="text"
            label="Product Code"
            required
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="productionBatch"
            type="text"
            label="Batch"
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="requiredTempState"
            type="select"
            options={requiredTempStateOptions}
            label="Required Temp State"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onClose()}
            disabled={form.processing}
            tabIndex={2}
          >
            Cancel
          </Button>
          <LoadingButton type="submit" loading={form.processing} tabIndex={1}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default EditLot;

import { useEffect } from 'react';

// This hook is used to detect inactivity on the page.
// It takes a timeout in milliseconds, and a callback function to call when the timeout is reached.
export type InactivityTimeoutCallback = () => void;

// Keep lastActivity as a global variable so it won't get re-created on every render
let lastActivity = Date.now();

// This hook uses the useEffect hook to set up event listeners for keydown, scroll, and click events.
const useInactivityTimeout = (
  timeout: number,
  onTimeout: InactivityTimeoutCallback,
  canUseTimeout: boolean | undefined
): InactivityTimeoutCallback => {
  const resetActivity = (): void => {
    lastActivity = Date.now();
  };

  useEffect(() => {
    //If the permission is not granted, return, permission is defined by the property of the consumer in this hook
    if (!canUseTimeout) {
      return;
    }

    resetActivity();

    const events = ['keydown', 'scroll', 'click'];

    const activityHandler = () => {
      resetActivity();
    };

    events.forEach((event) => {
      window.addEventListener(event, activityHandler);
    });

    // check every second if the last activity was more than the timeout
    const interval = setInterval(() => {
      if (Date.now() > lastActivity + timeout) {
        onTimeout();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      events.forEach((event) => {
        window.removeEventListener(event, activityHandler);
      });
    };
  }, [onTimeout, timeout, canUseTimeout]);

  return resetActivity;
};

export default useInactivityTimeout;

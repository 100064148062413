import { Box } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import React from 'react';
import { Link } from 'react-router-dom';
import AuditLog from '../../Components/AuditLog/AuditLog';
import StandardDataGrid from '../../Components/StandardDataGrid';
import usePermissions from '../../Hooks/usePermissions';
import { tryFormatDateStr } from '../../Lib/utils';
import {
  BOLContainerStatus,
  CONTAINER_LABELS,
  CONTAINER_ENTITY_NAME,
  CONTAINER_ENTITY_AUDIT_NAME,
} from '../../Models/BOLContainer';
import {
  ASNStatus,
  ASN_ENTITY_NAME,
  ASN_ENTITY_AUDIT_NAME,
  ASN_STATUS_LABELS,
} from '../../Models/ASN';

type DataGridBillingProps = {
  data?: ContainerWithCharges[];
  loading: boolean;
  onRowUpdate?: (
    updatedRow: ContainerWithCharges,
    originalRow: ContainerWithCharges
  ) => Promise<ContainerWithCharges>;
  getRowId?: (row: ContainerWithCharges) => number;
  changeBOLContainerStatus: (
    rowData: ContainerWithCharges,
    status: BOLContainerStatus
  ) => void;
  changeASNStatus: (rowData: ContainerWithCharges, status: ASNStatus) => void;
};

export interface ContainerWithCharges {
  rowId: number;
  userDefinedCodeId: number;
  userDefinedCodeName?: string;
  quantity: number;
  id: number;
  code: string;
  status: BOLContainerStatus;
  statusName: string;
  entityType: string;
  entityTypeId?: number;
  entityTypeNumber?: number;
  billingCycle?: string;
  loadingCompletedDate?: string;
  customerName?: string;
  customerOrderNo?: string;
  warehouseId?: number;
  warehouseName?: string;
  netsuiteSalesOrderNumber?: string;
}

// only allow numbers for the quantity field
const regEx = new RegExp('^[0-9]*$');

const DataGridBillingTable = ({
  data,
  loading,
  onRowUpdate,
  changeBOLContainerStatus,
  changeASNStatus,
}: DataGridBillingProps) => {
  const [canViewAudit, canReview, canConfirm, canEditBilling] = usePermissions([
    'Audit.View',
    'Billing.View',
    'BOL.Billing.Confirm',
    'BOL.Billing.FullEdit',
  ]);

  const initialBillingTableFilterModel: GridFilterModel = {
    items: [
      {
        id: 1,
        field: 'statusName',
        operator: 'isAnyOf',
        value: [
          CONTAINER_LABELS[BOLContainerStatus.managerReview],
          CONTAINER_LABELS[BOLContainerStatus.pendingInvoice],
        ], // containers in admin review, manager review, and pending invoice.
      },
    ],
  };

  const renderActions = (params: GridRowParams) => {
    const isBOLContainerRow = params.row.entityType === CONTAINER_ENTITY_NAME;
    const isASNRow = params.row.entityType === ASN_ENTITY_NAME;

    const actions = [];
    if (canViewAudit && isBOLContainerRow) {
      actions.push(
        <AuditLog
          entityType={CONTAINER_ENTITY_AUDIT_NAME}
          entityId={params.row.id}
          valueMappings={{ Status: CONTAINER_LABELS }}
        />
      );
    }

    if (canViewAudit && isASNRow) {
      actions.push(
        <AuditLog
          entityType={ASN_ENTITY_AUDIT_NAME}
          entityId={params.row.entityTypeId}
          valueMappings={{ Status: CONTAINER_LABELS }}
        />
      );
    }

    // If BOL Container status is manager review, can mark as pending invoice
    if (
      canReview &&
      params.row.status == BOLContainerStatus.managerReview &&
      isBOLContainerRow
    ) {
      actions.push(
        <GridActionsCellItem
          label={`Mark as ${
            CONTAINER_LABELS[BOLContainerStatus.pendingInvoice]
          }`}
          onClick={() =>
            changeBOLContainerStatus(
              params.row,
              BOLContainerStatus.pendingInvoice
            )
          }
          showInMenu
          data-testid={`actioncellitem-billingreviewed-${params.row.rowId}`}
        />
      );
    }

    // If BOL Container status is pending invoice, can mark as invoiced
    if (
      canConfirm &&
      params.row.status == BOLContainerStatus.pendingInvoice &&
      isBOLContainerRow
    ) {
      actions.push(
        <GridActionsCellItem
          label={`Mark as ${CONTAINER_LABELS[BOLContainerStatus.invoiced]}`}
          onClick={() =>
            changeBOLContainerStatus(params.row, BOLContainerStatus.invoiced)
          }
          showInMenu
          data-testid={`actioncellitem-markasinvoiced-${params.row.rowId}`}
        />
      );
    }

    // If ASN status is manager review, can mark as pending invoice
    if (canReview && params.row.status == ASNStatus.managerReview && isASNRow) {
      actions.push(
        <GridActionsCellItem
          label={`Mark as ${ASN_STATUS_LABELS[ASNStatus.pendingInvoice]}`}
          onClick={() => changeASNStatus(params.row, ASNStatus.pendingInvoice)}
          showInMenu
          data-testid={`actioncellitem-billingreviewed-${params.row.rowId}`}
        />
      );
    }

    // If ASN status is pending invoice, can mark as invoiced
    if (
      canConfirm &&
      params.row.status == ASNStatus.pendingInvoice &&
      isASNRow
    ) {
      actions.push(
        <GridActionsCellItem
          label={`Mark as ${ASN_STATUS_LABELS[ASNStatus.invoiced]}`}
          onClick={() => changeASNStatus(params.row, ASNStatus.invoiced)}
          showInMenu
          data-testid={`actioncellitem-markasinvoiced-${params.row.rowId}`}
        />
      );
    }

    // TODO: as the status is pending billing, can mark as billing as complete or exported
    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'warehouseName',
      headerName: 'Warehouse',
      minWidth: 100,
      maxWidth: 200,
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      minWidth: 200,
      maxWidth: 500,
    },
    {
      field: 'entityTypeNumber',
      headerName: 'ASN / BOL',
      minWidth: 50,
      maxWidth: 100,
      renderCell: (params: GridRenderCellParams) =>
        params.row.entityType === ASN_ENTITY_NAME ? (
          <Link to={`/asn/${params.row.entityTypeNumber}`}>
            {params.row.entityTypeNumber}
          </Link>
        ) : (
          <Link to={`/bol/${params.row.entityTypeNumber}`}>
            {params.row.entityTypeNumber}
          </Link>
        ),
    },
    {
      field: 'customerOrderNo',
      headerName: 'Order Number',
      minWidth: 110,
      maxWidth: 200,
    },
    {
      field: 'code',
      headerName: 'Container',
      minWidth: 100,
      maxWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Link to={`/bol/${params.row.entityTypeNumber}/${params.row.code}`}>
          {params.row.code}
        </Link>
      ),
    },
    {
      field: 'netsuiteSalesOrderNumber',
      headerName: 'Netsuite',
      minWidth: 170,
      maxWidth: 250,
    },
    {
      field: 'loadingCompletedDate',
      headerName: 'Load / Received Date',
      minWidth: 150,
      maxWidth: 250,
      renderCell: (params: GridRenderCellParams) => (
        <div>{tryFormatDateStr(params.row.loadingCompletedDate)}</div>
      ),
    },
    {
      field: 'userDefinedCodeName',
      headerName: 'Billing Code',
      minWidth: 120,
      maxWidth: 250,
    },
    {
      field: 'billingCycle',
      headerName: 'Billing Cycle',
      minWidth: 100,
      maxWidth: 250,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      minWidth: 40,
      maxWidth: 100,
      editable: canEditBilling,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = !regEx.test(params.props.value);
        return { ...params.props, error: hasError };
      },
    },
    {
      field: 'statusName',
      headerName: 'Status',
      minWidth: 200,
      maxWidth: 300,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: renderActions,
      align: 'left',
    },
  ];

  return (
    <Box sx={{ height: '82vh' }}>
      <StandardDataGrid
        loading={loading}
        columns={columns}
        rows={data || []}
        disableRowSelectionOnClick={true}
        getRowId={(row) => row.rowId}
        initialFilterModel={initialBillingTableFilterModel}
        onRowUpdate={onRowUpdate}
        testId="data-grid-billing-table"
      />
    </Box>
  );
};

export default DataGridBillingTable;

import React, { useRef, useState, useEffect } from 'react';
import useApiForm from '../Hooks/useApiForm';
import PalletService from '../Services/PalletService';
import LocationService, {
  GetLocationDetailsRequest,
} from '../Services/LocationService';
import {
  Typography,
  Stack,
  Box,
  CircularProgress,
  Divider,
  IconButton,
} from '@mui/material';
import { PalletDetails } from '../Models/Pallet';
import useApiGet from '../Hooks/useApiGet';
import { formatNumber } from '../Lib/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import { defaultAuthState } from '../Providers/AuthProvider';
import { alertModalDialog, confirmModalDialog } from './ModalDialog';
import BarcodeScannerFormInput from './Forms/BarcodeScannerFormInput';
import { DetailRow } from './PalletDetails';

export const MovePallets = ({
  scannedPallet,
  onAfterScan,
}: {
  scannedPallet?: PalletDetails;
  onAfterScan?: (res?: { err: string }) => void;
}) => {
  const maxSecondsBetweenScans =
    defaultAuthState.application?.maxTimeBetweenMovingPalletsSecs || 15;
  const [scannedPallets, setScannedPallets] = useState<PalletDetails[]>([]);
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [pendingPallet, setPendingPallet] = useState<PalletDetails | null>(
    null
  );
  const timeExpired = useRef(false);
  const lastTimePalletScanned = useRef(0);

  const handleAlertDialogOpen = (title: string, message: string) => {
    setModalActive(true);
    if (onAfterScan) {
      onAfterScan({ err: message });
    }
    alertModalDialog({
      title: title,
      content: message,
      severity: 'error',
      isClosableFromBackdrop: false,
      onClose: () => {
        setPendingPallet(null);
        setModalActive(false);
      },
    });
  };

  // Delay so that the operator can visually see the successful movement
  const AFTER_MOVE_PALLETS_DELAY = 3500;

  const {
    data: lastPalletMovement,
    loading,
    refresh,
  } = useApiGet(PalletService.getLastPalletMovement);
  const movePalletsForm = useApiForm(
    PalletService.movePallets,
    {
      palletIds: [] as number[],
      location: '',
    },
    {
      onSuccess: () => {
        getPalletForm.reset();
        movePalletsForm.reset();
        setScannedPallets([]);
        refresh();
        if (pendingPallet) {
          setPendingPallet(null);
          setScannedPallets([pendingPallet]);
          movePalletsForm.setData('palletIds', [pendingPallet.id]);
        }
        // Only call with setTimeout when submitting successfully
        // NOTE: delay is so the operator can visually see the successful movement of a pallet
        if (onAfterScan) {
          setTimeout(onAfterScan, AFTER_MOVE_PALLETS_DELAY);
        }
      },
      onError: (message: string) => {
        // Invoke the dialog
        handleAlertDialogOpen('Error', message);
      },
      suppressError: true,
    }
  );

  const resetPalletForm = () => {
    getPalletForm.setData('code', '');
  };

  const addPalletToMovePallets = (palletDetails: PalletDetails) => {
    setScannedPallets([...scannedPallets, palletDetails]);
    movePalletsForm.setData('palletIds', [
      ...movePalletsForm.data.palletIds,
      palletDetails.id,
    ]);
  };

  const getPalletForm = useApiForm(
    PalletService.getByCodeDetailed,
    {
      code: '',
      detailed: true,
      withCartons: true,
    },
    {
      onError: (message: string) => {
        // Invoke the dialog
        handleAlertDialogOpen('Error', message);
      },
      suppressError: true, //Don't show the error message in the toast automatically
      onSuccess: (palletDetails: PalletDetails) =>
        handlePalletScan(palletDetails),
      enableFormUpdatesWhenFocussed: true,
    }
  );

  const handlePalletScan = async (palletDetails: PalletDetails) => {
    resetPalletForm();

    // If the pallet has already been loaded out, we don't allow it to be loaded out again
    if (palletDetails.loadedDateTime || palletDetails.dateOut) {
      handleAlertDialogOpen('Error', 'Pallet has already been loaded out.');
      return;
    }
    // If the pallet has any carton which doesn't have a dateIn, display an error
    if (palletDetails.cartons?.some((c) => !c.dateIn || c.dateIn.length == 0)) {
      handleAlertDialogOpen('Error', 'Carton does not have a DateIn.');
      return;
    }

    if (timeExpired.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setPendingPallet(palletDetails);
      timeExpired.current = false;
      setModalActive(true);

      await confirmModalDialog({
        onAccept: () => {
          addPalletToMovePallets(palletDetails);
          setPendingPallet(null);
          setModalActive(false);
        },
        onDecline: () => {
          setPendingPallet(null);
          setModalActive(false);
        },
        title: 'No location scanned',
        content:
          'No location scanned for previous pallet, are you sure you want to add a 2nd pallet for movement?',
      });
    } else {
      addPalletToMovePallets(palletDetails);
    }
  };

  const onScanPalletOrLocation = async (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    const scannedBarcode = getPalletForm.data.code;

    const getLocationDetailsRequestParams: GetLocationDetailsRequest = {
      locationBarcode: scannedBarcode,
    };

    const locationDetails = await LocationService.getLocationDetails({
      params: getLocationDetailsRequestParams,
    });

    // if the operator scans a location, we submit the form if a pallet has already been scanned
    if (locationDetails[0]?.isValidLocation) {
      movePalletsForm.setData('location', scannedBarcode);

      if (movePalletsForm.data.palletIds.length === 0) {
        handleAlertDialogOpen('Error', 'Please scan a pallet first.');
        resetPalletForm();
        return false;
      }

      movePalletsForm.submit();
    } else if (
      locationDetails[0]?.isValidLocation === false &&
      movePalletsForm.data.palletIds.length === 2
    ) {
      handleAlertDialogOpen('Error', 'Invalid location scanned.');
      resetPalletForm();
      return false;
    } else {
      // the operator scanned a pallet code
      handleScannedPallets(scannedBarcode);
    }
  };

  function handleScannedPallets(barcode = '') {
    // check that the operator is not loading the same pallet twice
    if (
      scannedPallets.some(
        (p) =>
          p.barcode === barcode ||
          p.code === barcode ||
          p.linkedBarcode === barcode
      )
    ) {
      //toastError('Pallets already scanned.');
      handleAlertDialogOpen('Error', 'Pallets already scanned.');
      resetPalletForm();
      return false;
    }

    const nbPalletsScanned = scannedPallets.length;
    // start the timer when loading the 1st pallet
    if (nbPalletsScanned === 0) {
      lastTimePalletScanned.current = Date.now();
    }

    // An operator can only scan two pallets in a row
    if (nbPalletsScanned === 2) {
      handleAlertDialogOpen(
        'No location scanned',
        'No location scanned for current pallets, please scan a location.'
      );
      resetPalletForm();
      return false;
    }

    // An operator can scan two pallets in a row only within 15 seconds
    if (
      nbPalletsScanned === 1 &&
      Date.now() > lastTimePalletScanned.current + maxSecondsBetweenScans * 1000
    ) {
      timeExpired.current = true;
    }

    // the operator scanned a pallet code in less than 15s, let's fetch the pallet details
    getPalletForm.setData('code', barcode);
    getPalletForm.submit();
  }

  useEffect(() => {
    if (!scannedPallet) return;

    const getLocationDetailsRequestParams: GetLocationDetailsRequest = {
      locationBarcode: scannedPallet.barcode,
    };

    LocationService.getLocationDetails({
      params: getLocationDetailsRequestParams,
    }).then((res) => {
      if (!res[0]?.isValidLocation) {
        setScannedPallets([scannedPallet]);
        addPalletToMovePallets(scannedPallet);
      } else {
        onAfterScan?.({ err: 'Not a valid pallet barcode' }); //use this to close
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedPallet]);

  const formatBolNumber = (palletDetails: PalletDetails) => {
    if (palletDetails.bolNumber != null) return palletDetails.bolNumber;
    else if (palletDetails.plannedBolNumber != null)
      return palletDetails.plannedBolNumber;
    else return '';
  };

  const formatContainerCode = (palletDetails: PalletDetails) => {
    if (palletDetails.containerCode != null) return palletDetails.containerCode;
    else if (palletDetails.plannedContainerCode != null)
      return palletDetails.plannedContainerCode;
    else return '';
  };

  const PalletDetailsRow = ({
    palletDetails,
    canDelete = false,
    onDelete,
  }: {
    palletDetails: PalletDetails;
    canDelete?: boolean;
    onDelete?: () => void;
  }) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        data-testid={`pallet-details-${palletDetails.id}`}
      >
        <Stack>
          <DetailRow label="Pallet:" testId="pallet-details-code">
            {palletDetails.code && <b>{palletDetails.code}</b>}
          </DetailRow>
          <DetailRow label="Move to:" testId="pallet-details-move-to">
            {palletDetails.moveToLocation && (
              <b>{palletDetails.moveToLocation}</b>
            )}
          </DetailRow>
          <DetailRow label="BOL:" testId="pallet-details-bol-number">
            {formatBolNumber(palletDetails)}
          </DetailRow>
          <DetailRow label="Container:" testId="pallet-details-container-code">
            {formatContainerCode(palletDetails)}
          </DetailRow>
          <DetailRow
            label="Notes:"
            testId="pallet-details-container-notes"
            wrapContent={true}
          >
            {palletDetails.containerNotes}
          </DetailRow>
          <DetailRow label="Current Loc:" testId="pallet-details-location">
            {palletDetails.location}
          </DetailRow>
          <DetailRow label="Customer:" testId="pallet-details-customer">
            {palletDetails.customer?.name}
          </DetailRow>
          <DetailRow label="QTY:" testId="pallet-details-quantity">
            {palletDetails.contents?.reduce((sum, b) => sum + b.totalQty, 0) ??
              0}
          </DetailRow>
          <DetailRow label="Weight:" testId="pallet-details-weight">
            {formatNumber(
              palletDetails.contents?.reduce((sum, b) => sum + b.weight, 0) ?? 0
            )}{' '}
            kg
          </DetailRow>
        </Stack>
        {canDelete && (
          <Box>
            <IconButton
              onClick={onDelete}
              color="primary"
              data-testid="delete-pallet-btn"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Stack>
    );
  };

  return (
    <>
      <Stack spacing={1} data-testid="move-pallets-page">
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={onScanPalletOrLocation}
        >
          <Typography variant="subtitle2" textAlign="center">
            Scan pallet/location:
          </Typography>
          <BarcodeScannerFormInput
            label="Barcode"
            id="code"
            form={getPalletForm}
            required
            onClear={() => resetPalletForm()}
            onBarcode={() => onScanPalletOrLocation()}
            disabledBackgroundInput={modalActive}
          />
        </Box>
      </Stack>

      <Typography variant="h6" mt={1}>
        Current Pallet(s)
      </Typography>

      <Box data-testid="pallets-scanned">
        {scannedPallets.map((pallet, index) => (
          <React.Fragment key={pallet.id}>
            {index !== 0 && <Divider sx={{ mt: 1, mb: 1 }} />}
            <PalletDetailsRow
              palletDetails={pallet}
              canDelete
              onDelete={() => {
                movePalletsForm.setData(
                  'palletIds',
                  movePalletsForm.data.palletIds.filter(
                    (id) => id !== pallet.id
                  )
                );
                setScannedPallets(
                  scannedPallets.filter((p) => p.id !== pallet.id)
                );
              }}
            />
          </React.Fragment>
        ))}
      </Box>

      {pendingPallet && (
        <>
          <Divider />
          <PalletDetailsRow
            palletDetails={pendingPallet}
            canDelete
            onDelete={() => setPendingPallet(null)}
          />
        </>
      )}

      <Typography variant="h6" mt={1}>
        Last Pallet Movement
      </Typography>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        lastPalletMovement &&
        lastPalletMovement.length > 0 && (
          <>
            <Box data-testid="last-pallet-movement">
              {lastPalletMovement.map((palletDetails, index) => (
                <React.Fragment key={palletDetails.id}>
                  <PalletDetailsRow palletDetails={palletDetails} />
                  {index === 0 && <Divider sx={{ mt: 1, mb: 1 }} />}
                </React.Fragment>
              ))}
            </Box>
          </>
        )
      )}
    </>
  );
};

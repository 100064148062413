import {
  ChevronLeft,
  ChevronRight,
  Close,
  Download,
  FilePresentTwoTone,
} from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { saveAs } from 'file-saver';
import React, { useMemo, useState } from 'react';
import { WMSFile } from '../../Models/WMSFile';
import FileService from '../../Services/FileService';
import { isUploadedFile } from './MediaLibrary';

/**
 * This component provides a light box for viewing and cycling through images,
 * including a download button.
 * CSS tricks are used to make the image auto-scale to the window, but a native invisible <img> element also allows right-clicking the image
 */

const iconSx = {
  color: 'white',
  // apply a black drop-shadow to the icons in case the image behind them is very light
  filter: 'drop-shadow(2px 2px 2px black)',
};

export interface LightboxProps {
  files: WMSFile[];
  file: WMSFile;
  onClose: () => void;
  selectedIds?: number[];
  setSelectedIds?: (ids: number[]) => void;
}

export default function ({
  files,
  file,
  onClose,
  selectedIds,
  setSelectedIds,
}: LightboxProps) {
  const [index, setIndex] = useState(files.indexOf(file));
  const url = useMemo(
    () => FileService.makeProxyUrl(files[index]),
    [files, index]
  );

  const increment = (sign: 1 | -1) => {
    // display the previous or next image, looping round if necessary
    setIndex((index + sign + files.length) % files.length);
  };

  const visibleId = files[index].id;
  const isImage = files[index].mimeType.startsWith('image/');

  return (
    <Dialog
      open
      onClose={onClose}
      fullScreen
      sx={{
        '.MuiDialog-paper': {
          background: 'rgba(0, 0, 0, 0.7)',
        },
      }}
    >
      <DialogTitle display="flex">
        <Box flexGrow={1}>
          {selectedIds && setSelectedIds && isUploadedFile(files[index]) && (
            <FormControlLabel
              label="Select for Download"
              sx={iconSx}
              control={
                <Checkbox
                  checked={selectedIds.includes(visibleId)}
                  color="success"
                  data-testid="lightbox-checkbox"
                  onChange={() => {
                    if (selectedIds.includes(visibleId)) {
                      setSelectedIds(
                        selectedIds.filter((id) => id != visibleId)
                      );
                    } else {
                      setSelectedIds([...selectedIds, visibleId]);
                    }
                  }}
                  sx={iconSx}
                />
              }
            />
          )}
        </Box>
        <IconButton href={url + '?download=true'} sx={iconSx}>
          <Download />
        </IconButton>
        <IconButton onClick={onClose} sx={iconSx}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        data-testid="lightbox-content"
        sx={{
          // actually display the image as a background image so we can use css background-size to make it fit nicely without javascript...
          background: isImage
            ? `url('${url}') no-repeat center center`
            : undefined,
          backgroundSize: 'contain',
          position: 'relative',
        }}
      >
        {isImage ? (
          <img
            src={url}
            data-testid="lightbox-dummy-img"
            style={{
              // ...but also display an invisible <img> element on top so the user can still right-click to "Open in new tab" or "Save as"
              position: 'absolute', // requires position: relative on the parent
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              opacity: 0,
            }}
          />
        ) : (
          <div
            onClick={() => saveAs(url, files[index].name)}
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              position: 'absolute',
              cursor: 'pointer',
            }}
          >
            <FilePresentTwoTone
              fontSize="large"
              sx={{
                height: '100px',
                width: '100px',
              }}
            />
            <Typography variant="body2" display="flex">
              {files[index].name}
            </Typography>
          </div>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: '100%' }}
        >
          <IconButton size="large" sx={iconSx} onClick={() => increment(-1)}>
            <ChevronLeft fontSize="large" />
          </IconButton>
          <IconButton size="large" sx={iconSx} onClick={() => increment(1)}>
            <ChevronRight fontSize="large" />
          </IconButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

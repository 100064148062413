import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import useApiForm from '../../Hooks/useApiForm';
import MobilePage from '../../Components/MobilePage';
import BarcodeScannerFormInput, {
  BarcodeResult,
} from '../../Components/Forms/BarcodeScannerFormInput';
import StocktakeService from '../../Services/StocktakeService';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import PalletsWIthStatusFilter from './Pallet/PalletsWIthStatusFilter';
import {
  StocktakePallet,
  StocktakePalletStatus,
  digitsDisplayedForCartonBarcode,
  getShortenBarcode,
} from '../../Models/StocktakePallet';
import { GridColDef } from '@mui/x-data-grid-pro';
import { toast } from '../../Components/Toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PalletService from '../../Services/PalletService';
import { PalletDetails } from '../../Models/Pallet';
import { MovePallets } from '../../Components/MovePallets';
import CloseIcon from '@mui/icons-material/Close';
import { confirmModalDialog } from '../../Components/ModalDialog';

export default function () {
  const navigate = useNavigate();
  const { stocktakeId: stocktakeIdPram } = useParams<{ stocktakeId: string }>();
  const { location: locationPram } = useParams<{ location: string }>();
  const [verifiedCount, setVerifiedCount] = useState<number>(0);
  const [newCount, setNewCount] = useState<number>(0);
  const [lostCount, setLostCount] = useState<number>(0);
  const [stocktakePalletDetails, setStocktakePalletDetails] = useState<
    StocktakePallet[] | undefined
  >([]);
  const [statusFilter, setStatusFilter] =
    useState<StocktakePalletStatus | null>(null);
  const [location, setLocation] = useState<string>('');
  const getLocationEnquiryForm = useApiForm(
    StocktakeService.scanStocktakePalletLocation,
    {
      stocktakeId: stocktakeIdPram,
      location: '',
    },
    {
      onSuccess: (stocktakePalletDetails) => {
        setStocktakePalletDetails(stocktakePalletDetails);
        const verifiedCount = stocktakePalletDetails.filter(
          (sp) => sp.status == StocktakePalletStatus.verified
        ).length;
        const newCount = stocktakePalletDetails.filter(
          (sp) => sp.status == StocktakePalletStatus.new
        ).length;
        const lostCount = stocktakePalletDetails.filter(
          (sp) => sp.status == StocktakePalletStatus.expected
        ).length;
        setVerifiedCount(verifiedCount);
        setNewCount(newCount);
        setLostCount(lostCount);
      },
      onError: (message) => {
        getLocationEnquiryForm.setErrors({ location: message });
      },
    }
  );

  const onLocationEnquiryScan = (event: BarcodeResult) => {
    getLocationEnquiryForm.setData('location', event.code);
    setLocation(event.code.toLocaleUpperCase());
    // if scanning and the current barcode field has focus we want it to lose focus for the second scan
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    getLocationEnquiryForm.submit();
  };

  function resetLocationEnquiryForm() {
    getLocationEnquiryForm.setData('location', '');
  }

  const columnDefs = useMemo<GridColDef[]>(
    () => [
      {
        field: 'palletBarcode',
        renderHeader: () => (
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 450,
              fontSize: '14px',
            }}
          >
            Barcode
            <ContentCopyIcon sx={{ marginLeft: 0.5 }} />
          </Typography>
        ),
        flex: 1,
        renderCell: (params) => (
          <Button
            variant="text"
            onClick={() => {
              navigator.clipboard
                .writeText(params.row.palletBarcode)
                .then(() => toast('Barcode copied'));
            }}
          >
            {params.row.palletBarcode.length > digitsDisplayedForCartonBarcode
              ? '...'
              : ''}
            {getShortenBarcode(
              params.row.palletBarcode,
              digitsDisplayedForCartonBarcode
            )}
          </Button>
        ),
      },
      {
        field: 'product',
        headerName: 'Product',
        flex: 0.6,
      },
      {
        field: 'depth',
        headerName: 'Depth',
        flex: 1,
        renderCell: (params) => params.row.qty,
      },
    ],
    []
  );

  const onCompleteScanning = () => {
    return confirmModalDialog({
      title: 'Confirm scanning completed',
      acceptButtonLabel: 'OK',
      declineButtonLabel: 'Cancel',
      acceptButtonCountdown: lostCount > 0 ? 5 : 0,
      declineButtonCountdown: 0,
      content: (
        <Box textAlign={'center'}>
          {verifiedCount! > 0 && (
            <h4>{verifiedCount} pallet(s) have been verified</h4>
          )}

          {newCount! > 0 && (
            <h4>{newCount} pallet(s) have been marked as new</h4>
          )}
          {lostCount! > 0 && (
            <h4 style={{ color: 'red' }}>
              {lostCount} pallet(s) will be mark as lost
            </h4>
          )}
        </Box>
      ),
      onAccept: () => {
        StocktakeService.onScanCompleted({ stocktakeId: stocktakeIdPram }).then(
          () => {
            resetForms();
            navigate(`/StocktakeListOperator`);
          }
        );
      },
    });
  };

  const onClickOnStatus = (status: StocktakePalletStatus) => {
    setStatusFilter(statusFilter === status ? null : status);
  };
  // pallet movement
  const [scannedPallet, setScannedPallet] = useState<null | PalletDetails>(
    null
  );
  const [movePalletsActive, setMovePalletsActive] = useState<boolean>(false);

  const resetForms = () => {
    getPalletForm.setData('code', '');
    setScannedPallet(null);
  };

  const getPalletForm = useApiForm(
    PalletService.getByCodeDetailed,
    {
      code: '',
      detailed: true,
    },
    {
      onError: (message) => {
        getPalletForm.setErrors({ code: message });
      },
      onSuccess: (result: PalletDetails) => {
        if (result) setScannedPallet(result);
      },
      useSearchParams: true,
    }
  );

  const markStocktakePalletStatusForm = useApiForm(
    StocktakeService.markStocktakePalletStatus,
    {
      stocktakeId: stocktakeIdPram,
      stocktakePalletStatus: StocktakePalletStatus.expected,
      code: '',
    },
    {
      onSuccess: () => {
        getLocationEnquiryForm.submit();
      },
    }
  );

  const onPalletScan = () => {
    // if scanning and the current barcode field has focus we want it to lose focus for the second scan
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    getPalletForm.submit();
    markStocktakePalletStatusForm.setData(
      'stocktakePalletStatus',
      StocktakePalletStatus.verified
    );
    markStocktakePalletStatusForm.setData('code', getPalletForm.data.code);
    markStocktakePalletStatusForm.submit();

    resetForms();
  };
  const checkAfterScanResponse = (res: { err: string } | undefined) => {
    if (res && res.err) return;
    setScannedPallet(null);
    setMovePalletsActive(false);
  };

  const movePalletDialogue = useMemo(() => {
    if (!scannedPallet) return null;

    setMovePalletsActive(true);

    return (
      <Dialog
        open={true}
        fullScreen
        data-testid="modal-dialog"
        scroll="paper"
        sx={{ margin: 0, padding: 0 }}
      >
        <DialogContent
          data-testid="MovePalletDialogue"
          dividers
          sx={{ margin: 0, padding: 0 }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setScannedPallet(null);
              setMovePalletsActive(false);
            }}
            aria-label="close"
            sx={{ position: 'absolute', left: '5px', top: '3px' }}
          >
            <CloseIcon />
          </IconButton>
          <MovePallets
            scannedPallet={scannedPallet}
            onAfterScan={(res) => checkAfterScanResponse(res)}
          />
        </DialogContent>
      </Dialog>
    );
  }, [scannedPallet]);

  //end pallet movement

  return (
    <MobilePage showOnDesktop showTitle title="Stocktake">
      <Stack spacing={1}>
        {stocktakePalletDetails!.length == 0 && (
          <Box component="form" noValidate>
            <Typography variant="subtitle2" textAlign="center">
              Stocktake:
            </Typography>
            <Typography variant="subtitle2" textAlign="center">
              Stocktake Location:{locationPram}
            </Typography>
            <BarcodeScannerFormInput
              label="Store Location Barcode"
              id="location"
              form={getLocationEnquiryForm}
              onBarcode={onLocationEnquiryScan}
              onClear={() => resetLocationEnquiryForm()}
            />
          </Box>
        )}
        {stocktakePalletDetails!.length > 0 && (
          <>
            <Box component="form" noValidate>
              <Typography variant="subtitle2" textAlign="center">
                Scan Pallet(s):
              </Typography>
              <Typography variant="subtitle2" textAlign="center">
                Stocktake Location : {location}
              </Typography>
              <BarcodeScannerFormInput
                label="Pallet Barcode"
                id="code"
                form={getPalletForm}
                onBarcode={onPalletScan}
                onClear={() => resetForms()}
                disabledBackgroundInput={movePalletsActive}
              />
              {movePalletDialogue}
            </Box>
            <PalletsWIthStatusFilter
              stocktakePallets={stocktakePalletDetails}
              status={statusFilter}
              onStatusClick={onClickOnStatus}
              stocktakePalletColumns={columnDefs}
              onCompleteScanning={onCompleteScanning}
            />
          </>
        )}
      </Stack>
    </MobilePage>
  );
}

import {
  BillOfLadingWithCounts,
  BillOfLading,
  BOLOrderType,
  BolStatus,
  BillOfLadingWithCountsAndPalletDetails,
  BillOfLadingWithCountsForCustomer,
  BillOfLadingForCustomerWithContainerAndProducts,
} from '../Models/BillOfLading';
import Api, {
  PagedAndSortedSearchRequest,
  PagedResult,
  ServiceGetMethod,
  ServicePostMethod,
} from './Api';

/**
 * This Service provides api endpoints for interacting with its related entity via the Api Service
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const getBillsOfLading: ServiceGetMethod<
  PagedAndSortedSearchRequest,
  PagedResult<BillOfLadingWithCounts>
> = (options) =>
  Api.get('api/services/app/BillOfLading/GetAllWithCounts', options);

const getBillsOfLadingByDate: ServiceGetMethod<
  PagedAndSortedSearchRequest,
  PagedResult<BillOfLadingWithCountsForCustomer>
> = (options) =>
  Api.get('api/services/app/BillOfLadingExternal/GetAllByShipDate', options);

const getFullByBolNumber: ServiceGetMethod<
  GetFullBolRequest,
  BillOfLadingWithCountsAndPalletDetails
> = (options) =>
  Api.get('api/services/app/BillOfLading/GetFullWithCounts', options);

const getBillOfLadingByIdForCustomer: ServiceGetMethod<
  { bolNumber: string },
  BillOfLadingForCustomerWithContainerAndProducts
> = (options) =>
  Api.get(
    'api/services/app/BillOfLadingExternal/GetBillOfLadingDetails',
    options
  );

const getByRemoteId: ServicePostMethod<{ bolNumber: string }, BillOfLading> = (
  { bolNumber },
  options
) =>
  Api.get(
    'api/services/app/BillOfLading/GetByRemoteId?orderNumber=' + bolNumber,
    options
  );

const getBOLOrderTypes: ServiceGetMethod<void, BOLOrderType[]> = (options) =>
  Api.get('api/services/app/BillOfLading/GetAllOrderTypes', options);

const markAsReady: ServicePostMethod<{ bolId: number }, void> = (
  { bolId },
  options
) =>
  Api.put('api/services/app/BillOfLading/MarkAsReady?bolId=' + bolId, options);

const markAsReadyToPick: ServicePostMethod<
  { bolId: number; warehouseId: number | null },
  void
> = (data, options) =>
  Api.put('api/services/app/BillOfLading/MarkAsReadyToPick', data, options);

const generatePdf: ServicePostMethod<TestBolPdfRequest, string> = (
  data,
  options
) => Api.put('api/services/app/BillOfLading/GenerateBOLPdf', data, options);

const getAreAllBOLContainersBeyondCompletedStatus: ServicePostMethod<
  { billOfLadingId: number },
  void
> = ({ billOfLadingId }, options) =>
  Api.get(
    'api/services/app/BillOfLading/AreAllBOLContainersBeyondCompletedStatus?billOfLadingId=' +
      billOfLadingId,
    options
  );

const markAsLoaded: ServicePostMethod<{ billOfLadingId: number }, boolean> = (
  { billOfLadingId },
  options
) =>
  Api.put(
    'api/services/app/BillOfLading/MarkAsLoaded?billOfLadingId=' +
      billOfLadingId,
    options
  );

const toleranceCheckForBol: ServiceGetMethod<
  { billOfLadingId: number },
  BillOfLading
> = (options) =>
  Api.get('api/services/app/BillOfLading/ToleranceCheckForBol', options);

const runToleranceCheck: ServicePostMethod<
  { warehouseId?: number },
  boolean
> = ({ warehouseId }, options) =>
  Api.post(
    'api/services/app/BillOfLading/RunToleranceCheck?warehouseId=' +
      warehouseId,
    options
  );

const countToleranceFailBOLs: ServiceGetMethod<
  { warehouseId?: number },
  number
> = (options) =>
  Api.get('api/services/app/BillOfLading/CountToleranceFailBols', options);

const unassignAllForBol: ServicePostMethod<{ bolId: number }, BillOfLading> = (
  { bolId },
  options
) =>
  Api.post(
    `api/services/app/BillOfLading/UnassignAllForBol?bolId=${bolId}`,
    options
  );

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  getBillsOfLading,
  getBillsOfLadingByDate,
  getFullByBolNumber,
  getBillOfLadingByIdForCustomer,
  getByRemoteId,
  getBOLOrderTypes,
  markAsReady,
  generatePdf,
  markAsReadyToPick,
  getAreAllBOLContainersBeyondCompletedStatus,
  markAsLoaded,
  toleranceCheckForBol,
  runToleranceCheck,
  countToleranceFailBOLs,
  unassignAllForBol,
};

/**
 * Models used in this service
 */

export interface GetFullBolRequest {
  bolNumber: number | string;
  statuses?: BolStatus[];
  warehouseId?: number | null;
}

export interface TestBolPdfRequest {
  bolNumber: number;
}

import { Setting } from '../Models/Setting';
import Api, { ServiceGetMethod, ServicePostMethod } from './Api';

/**
 * The SettingService provides api endpoints for an Admin to operate on other Settings.
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

// eslint-disable-next-line @typescript-eslint/ban-types
const getSettings: ServiceGetMethod<{}, Setting[]> = (options) =>
  Api.get('api/services/app/Settings/GetAll', options);

const updateSetting: ServicePostMethod<UpdateSettingRequest, Setting> = (
  data,
  options
) => Api.put('api/services/app/Settings/Update', data, options);

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  getSettings,
  updateSetting,
};

/**
 *  Models used in this service
 */

export interface UpdateSettingRequest {
  name: string;
  value: string;
}

import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * ResponsiveProvider provides the current ui state (mobileView, width, sm, etc)
 * to any component which consumes it with useContext(AuthContext)
 *
 * It also provides:
 *  * overrideDesktop: a flag to indicate when the app is currently displaying the desktop version
 *  * setOverrideDesktop(boolean): a method to change the overrideDesktop flag
 */

// https://mui.com/material-ui/customization/breakpoints/
export const breakpoints = {
  sm: 600, // inclusive
  md: 900,
  lg: 992,
} as const;

let mockedInnerWidth: number | null = null;
export const setMockedInnerWidth = (width: number | null) => {
  mockedInnerWidth = width;
  defaultValue.mobileView = (width || 10000) <= breakpoints.md;
  defaultValue.sm = (width || 10000) <= breakpoints.sm;
};

// make the default widescreen so all existing tests pass
const defaultValue = {
  width: 10000,
  height: 10000,
  sm: false,
  md: false,
  lg: true,
  mdDown: false,
  mdUp: false,
  mobileView: window.innerWidth <= breakpoints.md,
  overrideDesktop: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setOverrideDesktop: /* istanbul ignore next */ (override: boolean) => {},
};

export type ResponsiveContextType = typeof defaultValue;

export const ResponsiveContext = createContext(defaultValue);

export default function ({ children }: { children: ReactNode }) {
  const [windowSize, setWindowSize] = useState(defaultValue);
  const [overrideDesktop, setOverrideDesktop] = useState(false);
  const location = useLocation();

  // listener for window resizing and scroll
  useEffect(() => {
    function handleResize() {
      let width = mockedInnerWidth || window.innerWidth;
      let height = window.innerHeight;

      if (overrideDesktop) {
        width = 10000;
        height = 10000;
      }

      setWindowSize({
        width,
        height,
        sm: width <= breakpoints.sm,
        md: width > breakpoints.sm && width <= breakpoints.md,
        lg: width > breakpoints.md,
        mdDown: width <= breakpoints.md,
        mdUp: width > breakpoints.sm,
        mobileView: width <= breakpoints.md,
        overrideDesktop,
        setOverrideDesktop,
      });
    }

    // add event listener and then call handler right away so state gets updated with initial window size
    window.addEventListener('resize', handleResize);
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [setOverrideDesktop, overrideDesktop]); // Empty array ensures that effect is only run on mount

  // listener for scrolling to top on navigation https://stackoverflow.com/a/54343182
  useEffect(() => {
    document.querySelector('main')?.scrollTo(0, 0);
  }, [location]);

  return (
    <ResponsiveContext.Provider value={windowSize}>
      {children}
    </ResponsiveContext.Provider>
  );
}

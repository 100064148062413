import { Stack } from '@mui/material';
import React, { useMemo } from 'react';
import {
  StocktakePallet,
  StocktakePalletStatus,
  StocktakePalletStatusChip,
} from '../../../Models/StocktakePallet';

interface PalletStatusLegendProps {
  pallets?: StocktakePallet[]; // to display the number of pallets in a badge
  status?: StocktakePalletStatus | null;
  onClick?: (cartonStatus: StocktakePalletStatus) => void;
}

const statuses: StocktakePalletStatus[] = [
  StocktakePalletStatus.expected,
  StocktakePalletStatus.verified,
  StocktakePalletStatus.new,
  StocktakePalletStatus.lost,
];

export default function ({
  pallets,
  status,
  onClick,
}: PalletStatusLegendProps) {
  const chips = useMemo(() => {
    return statuses.map((status) => ({
      status,
      count: pallets?.filter((pallet) => pallet.status === status).length || 0,
    }));
  }, [pallets]);

  return (
    <Stack direction="row" spacing={0.65}>
      {chips.map((chip) => (
        <StocktakePalletStatusChip
          status={chip.status}
          key={chip.status}
          data-testid={'pallet-status-chip-' + chip.status}
          active={chip.status === status}
          badgeContent={chip.count}
          onClick={onClick ? () => onClick(chip.status) : undefined}
        />
      ))}
    </Stack>
  );
}

import React from 'react';
import { BillOfLadingWithCounts } from '../../Models/BillOfLading';
import { Typography } from '@mui/material';
import DOMPurify from 'dompurify';

export interface BolContainerInstructionsProps {
  bol: BillOfLadingWithCounts;
}

export default function ({ bol }: BolContainerInstructionsProps) {
  return (
    <>
      <Typography variant="h6" data-testid={'auto-charge-header'}>
        Load Instructions
      </Typography>
      {bol.instructions ? (
        <div
          // NOTE: we can alter the sanitize config, but the default is quite secure
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              bol.instructions.replace(/\n/g, '<br />')
            ),
          }}
        ></div>
      ) : (
        <Typography variant="subtitle1" mt={1}>
          None
        </Typography>
      )}
    </>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import useApiForm from '../../Hooks/useApiForm';
import { base64toBlob, printFile, saveFile } from '../../Lib/utils';
import { LoadingButton } from '@mui/lab';
import { ServicePostMethod } from '../../Services/Api';
import { Download } from '@mui/icons-material';

export interface DownloadPdfButtonProps<TRequest> {
  request: TRequest;
  filename?: string; // if provided will download instead of print
  title?: string;
  serviceMethod: ServicePostMethod<TRequest, string>;
  showIcon?: boolean;
}

export default function <TRequest extends Record<string, any>>({
  request,
  filename,
  title,
  serviceMethod,
  showIcon = false,
}: DownloadPdfButtonProps<TRequest>) {
  const generatePdfForm = useApiForm(serviceMethod, request, {
    onSuccess: (data: string) => {
      const mimeType = 'application/pdf';
      const blob = base64toBlob(data, mimeType);
      filename ? saveFile(blob, filename, mimeType) : printFile(blob, mimeType);
    },
  });
  const onGeneratePdf = () => generatePdfForm.submit();

  return (
    <LoadingButton
      variant="contained"
      onClick={onGeneratePdf}
      loading={generatePdfForm.processing}
      sx={{ whiteSpace: 'nowrap' }}
    >
      {showIcon ? <Download /> : title}
    </LoadingButton>
  );
}

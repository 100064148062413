import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material';
import React, {
  ChangeEvent,
  KeyboardEvent as ReactKeyboardEvent,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from '@mui/lab';
import { Search } from '@mui/icons-material';
import { UseApiFormResult } from '../../Hooks/useApiForm';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';

/**
 * Helper barcode function to emulate barcode scanner input, use from console:
 * `scanBarcode('019940104092526311230418310200114821141700263107');`
 */
declare global {
  function scanBarcode(code: string): Promise<void>;
}

globalThis.scanBarcode = async (code: string) => {
  const keys = [startKeys[0].key, ...code.split(''), endKeys[0].key];
  for (const key of keys) {
    const event = { key };
    window.dispatchEvent(new KeyboardEvent('keydown', event));
    await new Promise((resolve) => setTimeout(resolve, 10));
  }
};

export type BarcodeEntryMethod = 'scanner' | 'manual';

export interface BarcodeResult {
  code: string;
  isValid: boolean;
  date: Date;
  entryMethod: BarcodeEntryMethod;
}

interface Buffer {
  current: Array<string>;
}

interface Timeout {
  current: ReturnType<typeof setTimeout> | undefined;
}

interface ExpectedKeyCharacter {
  code?: string;
  key?: string;
  altKey?: boolean;
  shiftKey?: boolean;
  ctrlKey?: boolean;
  metaKey?: boolean;
}

export const isValidBarcode = (barcode: string) => barcode.length > 3;

export type BarcodeScannerFormInputProps<T, R> = Omit<
  TextFieldProps,
  'id' | 'value'
> & {
  id: Extract<keyof T, string>;
  form: UseApiFormResult<T, R>;
  label?: string;
  /**
   * If `true`, the background input listeners for detecting keyboard wedge scanning are disabled
   * @default false
   */
  disabledBackgroundInput?: boolean;
  hideSearchButton?: boolean;
  hideClearButton?: boolean;
  onBarcode?: (result: BarcodeResult) => void;
  onClear?: () => void;
};

const startKeys: Array<ExpectedKeyCharacter> = [{ key: '~' }];
const endKeys: Array<ExpectedKeyCharacter> = [{ key: 'Enter' }];
const maxMsToEvaluate = 500;

const isExpectedKeyEqualToEventKey = (
  expectedKey: ExpectedKeyCharacter,
  keyEvent: ReactKeyboardEvent
): boolean => {
  return Object.keys(expectedKey).every(
    (expectedKeyProperty) =>
      keyEvent[expectedKeyProperty as keyof ReactKeyboardEvent] ===
      expectedKey[expectedKeyProperty as keyof ExpectedKeyCharacter]
  );
};

export default function <T, R>({
  id,
  form,
  label,
  disabledBackgroundInput,
  hideSearchButton,
  hideClearButton,
  onBarcode,
  onClear,
  ...props
}: BarcodeScannerFormInputProps<T, R>) {
  const newValue = form.data[id] as string;
  const [barcodeValue, internalSetBarcodeValue] = useState<string>(newValue);
  props.name = props.name || id;
  props.error = props.error || !!form.errors[id];

  const hasInputFocus = useRef(false);
  const isBufferStarted = useRef(false);
  const entryMethod = useRef<BarcodeEntryMethod>('manual');
  const timeout: Timeout = useRef(undefined);
  const buffer: Buffer = useRef([]);
  const { mobileView } = useContext(ResponsiveContext);

  const setBarcodeValue = (
    barcode: string,
    shouldClearFieldErrors?: boolean
  ) => {
    internalSetBarcodeValue(barcode);
    form.setData(id, barcode as T[Extract<keyof T, string>]);
    if (shouldClearFieldErrors) {
      form.setErrors({ ...form.errors, [id]: '' });
    }
  };

  const resetBarcode = () => {
    clearBuffer();
    setBarcodeValue('', true);
    onClear?.();
  };

  const clearBuffer = () => {
    buffer.current = [];
    isBufferStarted.current = false;
  };

  const evaluateBuffer = (): string => {
    clearTimeout(timeout.current);

    const code = buffer.current.reduce((previousKeys, key) => {
      let outputKey = '';

      if (key.length == 1) {
        outputKey = key;
      }

      if (key == 'Spacebar') {
        outputKey = ' ';
      }

      if (key == 'Tab') {
        outputKey = '\u0009'; // unicode tab character
      }

      return previousKeys + outputKey;
    }, '');

    clearBuffer();

    return code;
  };

  const addToBuffer = (key: string) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(clearBuffer, maxMsToEvaluate);
    buffer.current.push(key);
  };

  // detects if a barcode scanner key event and returns a boolean if the key has been handled
  const handleBarcodeKeyEvent = (e: ReactKeyboardEvent<Element>): boolean => {
    // check if this is the start barcode character if buffer not started, if so start the buffer ready for the next key event
    if (
      !isBufferStarted.current &&
      startKeys.some((startKey) => isExpectedKeyEqualToEventKey(startKey, e))
    ) {
      e.preventDefault();
      e.stopPropagation();

      isBufferStarted.current = true;

      return true;
    }

    // check that we're just typing characters into the void, but not a command (eg paste, or reload)
    const focusableInputs = ['INPUT', 'SELECT', 'TEXTAREA'];
    if (
      !isBufferStarted.current &&
      !disabledBackgroundInput &&
      !focusableInputs.some((a) => a === document.activeElement?.tagName) &&
      e.key.length === 1 &&
      !e.altKey &&
      !e.metaKey &&
      !e.ctrlKey
    ) {
      e.preventDefault();
      e.stopPropagation();

      isBufferStarted.current = true;

      addToBuffer(e.key);

      return true;
    }

    // check if this is the end barcode character if buffer started, if so evaluate the buffer
    if (
      isBufferStarted.current &&
      endKeys.some((endKey) => isExpectedKeyEqualToEventKey(endKey, e))
    ) {
      e.preventDefault();
      e.stopPropagation();

      entryMethod.current = 'scanner';
      const barcode = evaluateBuffer();
      setBarcodeValue(barcode, true);
      outputBarcode(barcode);

      return true;
    }

    // check the buffer has started and record the key
    if (isBufferStarted.current) {
      e.preventDefault();
      e.stopPropagation();

      addToBuffer(e.key);

      return true;
    }

    return false;
  };

  // for detecting non focused background keyboard wedge scanning
  const onBackgroundKey: KeyboardEventHandler = (e) => {
    if (props.disabled || hasInputFocus.current) {
      return;
    }

    // for testing detect the specific string `Reset for Testing` and clear the barcode scanner buffer
    if (e.key == 'Reset for Testing') {
      clearBuffer();
      return;
    }

    handleBarcodeKeyEvent(e);
  };

  // for detecting both keyboard wedge scanning and manual input directly into the focused input
  const onInputKey: KeyboardEventHandler = (e) => {
    const isHandled = handleBarcodeKeyEvent(e);

    if (isHandled || props.disabled) {
      return;
    }

    if (e.key != 'Enter') {
      entryMethod.current = 'manual';
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    if (isValidBarcode(barcodeValue)) {
      outputBarcode(barcodeValue);
      return;
    }
  };

  // for manual input entry not using keyboard wedge scanning
  // Android IME will swallow onkeydown events (they come through as 'Unidentified' with code 229)
  //  if using the barcode scanner while the input is focused.
  // Reprocesses the oninput/onchange as a keydown event for the barcode scanner to process
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Check if we're starting the barcode
    if (
      !isBufferStarted.current &&
      startKeys.some((startKey, i) => e.target.value[i] == startKey.key)
    ) {
      e.target.value = '';
      isBufferStarted.current = true;
      return;
    }

    if (!isBufferStarted.current) {
      setBarcodeValue(e.target.value, true);
    } else {
      addToBuffer(e.target.value);
    }
  };

  const outputBarcode = (barcode: string) => {
    onBarcode?.({
      code: barcode,
      isValid: isValidBarcode(barcode),
      date: new Date(),
      entryMethod: entryMethod.current,
    });
  };

  useEffect(() => {
    if (!disabledBackgroundInput) {
      window.addEventListener(
        'keydown',
        onBackgroundKey as unknown as EventListener,
        { capture: true }
      );
    }
    return () =>
      window.removeEventListener(
        'keydown',
        onBackgroundKey as unknown as EventListener,
        { capture: true }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledBackgroundInput, form]);

  useEffect(() => {
    internalSetBarcodeValue(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newValue, form]);

  const inputProps = {
    endAdornment: !hideClearButton && (
      <InputAdornment position="end">
        <IconButton
          sx={
            !props.disabled && barcodeValue !== ''
              ? undefined
              : {
                  // use visibility:hidden instead of display:none so the input's width doesn't change
                  visibility: 'hidden',
                  // but need to let user events pass through the invisible button
                  userEvent: 'none',
                }
          }
          onClick={resetBarcode}
          edge="end"
        >
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    ),
  };

  // a custom TextField is used over a FormField:
  // the FormField component is rerendered every `onChange` event for each key press and is slow when scanning directly into the input field on low spec devices
  // the barcode start/ends characters need to be handled outside of the input and evaluated as a whole
  return (
    <Stack direction="column" alignItems="stretch">
      <Stack direction="row" gap={1} alignItems="flex-start">
        <TextField
          margin="normal"
          fullWidth
          data-testid={`input-barcode-${id}`}
          required
          value={barcodeValue}
          label={label ?? 'Barcode'}
          onFocus={() => (hasInputFocus.current = true)}
          onBlur={() => (hasInputFocus.current = false)}
          autoComplete="off"
          autoFocus={!mobileView}
          type="text"
          error={!!form.errors[id]}
          helperText={form.errors[id]}
          onKeyDown={onInputKey}
          onChange={onInputChange}
          {...props}
          name={props.name + '-LastPass-ignore-hack'}
          InputProps={inputProps}
        />
        {!hideSearchButton && (
          <LoadingButton
            loading={form.processing}
            variant="contained"
            disabled={props.disabled || !isValidBarcode(barcodeValue)}
            size="large"
            data-testid="search-barcode-button"
            aria-label="Search"
            sx={{ mt: 3 }}
            onClick={() => outputBarcode(barcodeValue)}
          >
            <Search />
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
}

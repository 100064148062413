import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import React, { useContext } from 'react';
import { AuthContext } from '../Providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

export default function ({ appBar }: { appBar?: boolean }) {
  const { user, setCurrentCustomerId, refreshToken } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <>
      {(user?.customers || []).length > 1 && (
        <FormControl
          fullWidth={!appBar}
          size={appBar ? 'small' : undefined}
          margin="normal"
          variant="outlined"
          sx={
            appBar
              ? {
                  margin: '0 0 0 12px !important',
                  '&, svg, label, .MuiSelect-select': {
                    color: 'white !important',
                  },
                  fieldset: {
                    borderColor: 'white !important',
                  },
                  '.MuiSelect-select': {
                    minWidth: '10em',
                  },
                }
              : undefined
          }
        >
          <InputLabel
            id="customer-user-selector-label"
            sx={{ color: appBar ? 'white' : undefined }}
          >
            Select Customer
          </InputLabel>
          <Select
            value={user?.currentCustomerId || ''}
            data-testid="customer-user-selector"
            labelId="customer-user-selector-label"
            input={<OutlinedInput label="Select Customer" />}
            onChange={async (e) => {
              await setCurrentCustomerId(
                +e.target.value > 0 ? +e.target.value : null
              );
              await refreshToken();
              navigate('/');
              // blur the Customer User Switcher input element
              setTimeout(() => (document.activeElement as HTMLElement)?.blur());
            }}
          >
            {user?.customers?.map((customer) => (
              <MenuItem key={customer.id} value={customer.id}>
                {customer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ASNStatus, ASNWithCounts } from '../../Models/ASN';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import SortedTable, {
  SortedTableColumn,
  SortedTableSortOpts,
} from '../../Components/SortedTable';
import Link from '@mui/material/Link';
import { Toast, toast, toastError } from '../../Components/Toast';
import {
  CheckBox,
  CheckBoxOutlineBlankTwoTone,
  FileCopy,
} from '@mui/icons-material';
import { tryFormatDateStr } from '../../Lib/utils';
import { useNavigate } from 'react-router-dom';
import usePermissions from '../../Hooks/usePermissions';
import { Pallet, PalletDetails } from '../../Models/Pallet';
import {
  alertModalDialog,
  confirmModalDialog,
} from '../../Components/ModalDialog';
import BarcodeScannerFormInput from '../../Components/Forms/BarcodeScannerFormInput';
import useApiForm from '../../Hooks/useApiForm';
import AsnService from '../../Services/AsnService';
import { CartonStatus } from '../../Models/Carton';

interface ASNReceiveProps {
  asn?: ASNWithCounts;
  asnRefresh: (options?: { clear: boolean }) => void;
}
export default function ASNReceive({ asn, asnRefresh }: ASNReceiveProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { mobileView } = useContext(ResponsiveContext);
  const [canUpdateAsn] = usePermissions(['ASN.Update']);
  const [canSearchPallet] = usePermissions(['BOL.Pallet.Search']);
  const [redirectASNNumber, setRedirectASNNumber] = useState<string | null>(
    null
  );
  const canNavigateToSearchPallet = !mobileView && canSearchPallet;
  const defaultSort: SortedTableSortOpts = {
    id: mobileView ? 'checkbox' : 'verifiedDateTime',
    dir: 'asc',
  };

  const isPalletVerified = useCallback(
    (pallet: PalletDetails | Pallet) => {
      const cartons = pallet.cartons?.filter((c) => c.asnId == asn?.id) ?? [];
      if (cartons.length === 0) return false;
      // Check any carton has Pending status
      const pendingCartons = cartons.filter(
        (c) => c.status === CartonStatus.pending
      );
      return pendingCartons.length == 0;
    },
    [asn]
  );

  useEffect(() => {
    if (redirectASNNumber && redirectASNNumber.length > 0) {
      navigate(`/asn/${redirectASNNumber}#receive`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectASNNumber]);

  const processScannedPallet = async (pallet: Pallet) => {
    // If this pallet is verified, just show message.
    if (isPalletVerified(pallet)) {
      toastError('Pallet is already verified');
      return 'warning';
    }
    // If this pallet is not expected to this ASN, show confirmation to switch to other ASN
    const asnCartons =
      pallet.cartons?.filter((c) => asn && c.asnId === asn.id) ?? [];
    verifyPalletForASNForm.setData('palletId', pallet.id);
    if (asnCartons.length === 0) {
      // Check other ASNs for this pallet
      const [asnListByPallet] = await AsnService.getASNListByPallet({
        params: { palletId: pallet.id },
      });
      const otherAsn = asnListByPallet?.find((a) => a.id !== asn?.id);
      if (otherAsn && otherAsn.id) {
        const switchASNConfirmation = await confirmModalDialog({
          title: '',
          acceptButtonCountdown: 3,
          content: (
            <Box textAlign={'center'}>
              <h3>Pallet is related to a different ASN. Switch to this ASN?</h3>
            </Box>
          ),
        });
        if (switchASNConfirmation) {
          // Verify Pallet with new ASN
          verifyPalletForASNForm.setData('asnId', otherAsn.id);
          verifyPalletForASNForm.submit();
          setRedirectASNNumber(otherAsn.asnNumber.toString());
          return 'submitted';
        }
        return 'cancel';
      } else {
        alertModalDialog({
          title: '',
          content: (
            <Box textAlign={'center'}>
              <h3>Pallet is related to a different ASN</h3>
            </Box>
          ),
        });
        return 'warning';
      }
    } else {
      setRedirectASNNumber(null);
      // Process to verify pallet for this ASN
      verifyPalletForASNForm.setData('asnId', asn?.id ?? 0);
      verifyPalletForASNForm.submit();
      return 'submitted';
    }
  };

  const form = useApiForm(
    AsnService.findPalletInASNByCode,
    {
      code: '',
    },
    {
      onError: (message) => {
        toastError(message);
      },
      onSuccess: async (pallet) => {
        const res = await processScannedPallet(pallet);
        if (res === 'submitted') {
          form.setData('code', '');
          // Cannot refresh immediately after submitting the verification,
          // Need to wait 500ms before refresh.
          setTimeout(() => asnRefresh({ clear: true }), 500);
        }
      },
      enableFormUpdatesWhenFocussed: true,
      suppressError: true,
    }
  );
  const verificationCompleteForm = useApiForm(
    AsnService.verificationCompleteASN,
    {
      asnId: asn?.id ?? 0,
    },
    {
      onSuccess: () => {
        // Redirect to ASN list
        navigate('/asn');
      },
    }
  );
  const verifyPalletForASNForm = useApiForm(
    AsnService.verifyPalletForASN,
    {
      asnId: 0,
      palletId: 0,
    },
    {
      onSuccess: () => {
        const successToast: Toast = {
          message: 'Pallet verified',
          severity: 'success',
        };
        toast(successToast);
        verifyPalletForASNForm.reset();
      },
    }
  );

  const columnDefs = useMemo<SortedTableColumn<PalletDetails>[]>(
    () => [
      {
        id: 'code',
        label: 'Pallet',
        sortable: true,
        cellProps: {
          className: 'pallet-table--pallet-code',
        },
        cellRender: (palletDetails) => {
          const barcode = palletDetails.prefix + palletDetails.code;
          return (
            <>
              <Link
                sx={{
                  cursor: 'pointer',
                  color: theme.palette.primary.main,
                }}
                onClick={() =>
                  canNavigateToSearchPallet
                    ? navigate(`/barcode-search?code=${barcode}`)
                    : undefined
                }
              >
                {barcode}
              </Link>
              <IconButton
                onClick={() => {
                  navigator.clipboard
                    .writeText(barcode)
                    .then(() => toast('Barcode copied'));
                }}
              >
                <FileCopy sx={{ fontSize: '12px' }} />
              </IconButton>
            </>
          );
        },
      },
      {
        id: 'location',
        label: 'Location',
        show: !mobileView,
        sortable: true,
        value: (palletDetails) => palletDetails.location,
      },
      {
        id: 'quantity',
        label: 'Quantity',
        sortable: true,
        numeric: true,
        value: (palletDetails) =>
          palletDetails.contents.reduce((sum, row) => sum + row.totalQty, 0),
      },
      {
        id: 'weight',
        label: 'Weight',
        numeric: true,
        sortable: true,
        show: !mobileView,
        value: (palletDetails) =>
          palletDetails.contents.reduce((sum, row) => sum + row.weight, 0),
      },
      {
        id: 'productCode',
        label: 'Product Code',
        sortable: true,
        show: !mobileView,
        value: (palletDetails) =>
          Array.from(
            new Set(palletDetails.contents.map((c) => c.productCode))
          ).join(', '),
      },
      {
        id: 'productDescription',
        label: 'Product Description',
        sortable: true,
        show: !mobileView,
        value: (palletDetails) =>
          Array.from(
            new Set(palletDetails.contents.map((c) => c.productDescription))
          ).join(','),
      },
      {
        id: 'verifiedUsername',
        label: 'Verified By',
        sortable: true,
        show: !mobileView,
        value: (palletDetails) => palletDetails.verifiedUsername,
      },
      {
        id: 'verifiedDateTime',
        label: 'Verified Date',
        sortable: true,
        show: !mobileView,
        value: (palletDetails) =>
          tryFormatDateStr(palletDetails.verifiedDateTime),
        sortValue: (palletDetails) => palletDetails.verifiedDateTime,
      },
      {
        id: 'checkbox',
        exportable: false,
        sortable: true,
        label: 'Verified',
        cellProps: { width: '11em', align: 'center' },
        sortValue: (palletDetails) => palletDetails.verifiedDateTime,
        cellRender: (palletDetails) => (
          <>
            {isPalletVerified(palletDetails) ||
            palletDetails.verifiedDateTime ? (
              <CheckBox color="primary" />
            ) : (
              <CheckBoxOutlineBlankTwoTone />
            )}
          </>
        ),
      },
    ],
    [mobileView, canNavigateToSearchPallet, theme, navigate, isPalletVerified]
  );

  const verificationCompleteHandler = async () => {
    const notVerifiedPallets =
      asn?.pallets?.filter((p) => !isPalletVerified(p)) ?? [];
    const confirmed = await confirmModalDialog({
      title: '',
      acceptButtonCountdown: 3,
      content: (
        <Box textAlign={'center'}>
          <h3>Are you sure you want to close this ASN?</h3>
          {notVerifiedPallets.length > 0 && (
            <h4>{`${notVerifiedPallets.length} Pallets will be marked as 'Not Arrived'`}</h4>
          )}
        </Box>
      ),
    });
    if (confirmed) {
      verificationCompleteForm.setData('asnId', asn?.id ?? 0);
      verificationCompleteForm.submit();
    }
  };
  const onScanPallet = (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    form.submit();
  };

  return (
    <>
      {asn ? (
        <Box width="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" data-testid="asn-number">
              {asn.asnNumber}
            </Typography>

            {canUpdateAsn && (
              <Button
                data-testid={'close-asn-btn'}
                sx={{ height: '42px', whiteSpace: 'nowrap' }}
                variant="contained"
                disabled={asn.status !== ASNStatus.receiving}
                onClick={() => verificationCompleteHandler()}
              >
                Close ASN
              </Button>
            )}
          </Stack>
          <Typography variant="subtitle1" data-testid="asn-customer-name">
            {asn.customer?.name}
          </Typography>
          <Stack gap={0}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Pallets Expected</Typography>
              <Typography
                variant="subtitle1"
                data-testid="expected-pallets-counts"
              >
                {asn.totalPallets}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Pallets Received</Typography>
              <Typography
                variant="subtitle1"
                data-testid="pallet-verified-counts"
              >
                {asn.verifiedPallets}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Pallets Not Received</Typography>
              <Typography
                variant="subtitle1"
                data-testid="not-received-pallet-counts"
              >
                {asn.notReceivedPallets}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Pallets New</Typography>
              <Typography variant="subtitle1" data-testid="new-pallets-counts">
                {asn.newPallets}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Pallets Put Away</Typography>
              <Typography
                variant="subtitle1"
                data-testid="pallet-put-away-counts"
              >
                {asn.putAwayPallets} /{' '}
                {(asn.verifiedPallets ?? 0) + (asn.newPallets ?? 0)}
              </Typography>
            </Stack>
          </Stack>
          {canUpdateAsn && (
            <Box
              component="form"
              onSubmit={onScanPallet}
              noValidate
              sx={{ mt: 1 }}
            >
              <Typography variant="subtitle2" textAlign="center">
                Scan to load pallet or fill in the details below:
              </Typography>

              <BarcodeScannerFormInput
                label="Pallet code"
                id="code"
                form={form}
                required
                onBarcode={() => {
                  onScanPallet();
                }}
              />
            </Box>
          )}
          <Box
            sx={{
              width: '100%',
              paddingTop: !mobileView ? '2em' : undefined,
              '& th': {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
              },
              '& th .MuiTableSortLabel-root, .MuiTableSortLabel-icon': {
                color: theme.palette.primary.contrastText + ' !important',
              },
            }}
          >
            <SortedTable
              testId="pallets-grid"
              columnDefs={columnDefs}
              data={asn.pallets}
              exportable={!mobileView}
              exportFileName={() => `${asn?.asnNumber}`}
              sortOpts={defaultSort}
              smallFont
              smallGap
              rowKey="id"
            />
          </Box>
        </Box>
      ) : null}
    </>
  );
}

import { Factory } from 'fishery';
import { SelectItem } from '../Components/Forms/FormField';
import { faker } from '@faker-js/faker';

export interface Product {
  id: number;
  code: string;
  description: string;
  supportedCustomerIDs?: number[];
}

export const productFactory = Factory.define<Product>(() => ({
  id: faker.datatype.number(),
  code: faker.datatype.string(),
  description: faker.datatype.string(),
}));

/**
 *  Helper functions
 */

export function productSelectItems(products: Product[]) {
  return products
    .map<SelectItem>((product) => ({
      id: product.id,
      label: `${product.code || ''} - ${product.description || ''}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

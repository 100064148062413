import { Product } from '../Models/Product';
import Api, { ServiceGetMethod } from './Api';

/**
 * The CustomerService provides api endpoints for an Admin to operate on Customers.
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const getAllProductNames: ServiceGetMethod<
  { includeSupportedCustomers?: boolean },
  Product[]
> = (options) =>
  Api.get('api/services/app/Product/GetAllProductNames', options);

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  getAllProductNames,
};

/**
 *  Models used in this service
 */

interface RequiredTempState {
  value: string;
  label: string;
}

export const requiredTempStates: RequiredTempState[] = [
  { value: 'AMB', label: 'AMB - Ambient' },
  { value: 'BLAST', label: 'BLAST - Blast' },
  { value: 'CHD', label: 'CHD - Chilled' },
  { value: 'FZN', label: 'FZN - Frozen' },
];

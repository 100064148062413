import { SvgIcon, SvgIconProps, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';

const icon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="m12 12.443 5.4-3.06v3.42c.63 0 1.26.09 1.8.36v-5.31c0-.36-.18-.63-.45-.81l-7.11-3.96c-.18-.09-.36-.18-.54-.18-.18 0-.36.09-.54.18l-7.11 3.96c-.27.18-.45.45-.45.81v8.1c0 .36.18.63.45.81l7.11 3.96c.18.09.36.18.54.18.18 0 .36-.09.54-.18l.81-.45c-.27-.54-.36-1.17-.45-1.8m-.9-13.59 5.4 2.97-1.8.99-5.31-3.06 1.71-.9zm-.9 13.59-5.4-3.06v-6.03l5.4 3.06v6.03zm.9-7.65-5.4-2.97 1.8-1.08 5.4 3.15-1.8.9z"
        fill="#2273AB"
      />
      <path
        d="M17.506 17.28c-.334.474-.905.793-1.209 1.334-.237.43-.185.927-.192 1.23h1.126c0-.578.037-.696.134-.874.244-.445.689-.652 1.156-1.342.422-.622.267-1.46-.008-1.905-.318-.519-.948-.934-1.86-.934-1.09 0-1.838.712-2.12 1.542l1.016.422c.133-.415.46-.911 1.111-.911 1.001 0 1.201.934.846 1.438zM16.66 20.652a.767.767 0 0 0-.778.771c0 .43.349.778.778.778.445 0 .771-.348.771-.778a.756.756 0 0 0-.77-.77z"
        fill="#2273AB"
      />
    </SvgIcon>
  );
};

export default icon as OverridableComponent<SvgIconTypeMap> & {
  muiName: string;
};

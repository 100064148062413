import { faker } from '@faker-js/faker';
import { Factory } from 'fishery';

export const CODE_GROUPS = {
  // this should match the list in back end UserDefinedCodeGroup.cs
  BILLING_CODES: 'Billing Codes',
  COUNTRY_CODES: 'Country Codes',
  WAREHOUSE_CODES: 'Location Codes',
  ECERT_INTENDED_USE_CODES: 'ECert Intended Use Codes',
  ECERT_TEMPERATURE_CODES: 'ECert Temperature Codes',
  ECERT_PACKAGE_TYPE_CODES: 'ECert Package Type Codes',
  PRINTER_CODES: 'Printers',
} as const;
export const RELEVANT_TYPES = {
  ASN: 'ASN',
  BOL: 'BOL',
  BOTH: 'Both',
};

export interface UserDefinedCodeGroup {
  id: number;
  name: string;
  userDefinedCodes?: UserDefinedCode[];
  // schema?: string | null, - this has been de-serialised for us into:
  customFields?: CodeGroupCustomField[];
}

export interface UserDefinedCode {
  id: number;
  name: string; // this can be changed by the user and could be duplicated
  code: string; // this can be hard coded and is unique for automated stuff
  description: string;
  value: string;
  order: number;
  active: boolean;
  userDefinedCodeGroupId: number;
  // custom?: string, // this has been de-serialised for us into:
  customValues?: CodeCustomValue[];
}

export interface CodeGroupCustomField {
  name: string;
  type: 'select' | 'parent' | 'number';
  options?: string[];
  maxLevels?: number; // for 'parent'
}

export interface CodeCustomValue {
  name: string;
  value: string;
}

export interface CodeEntityQuantity {
  userDefinedCodeId: number;
  userDefinedCodeName?: string;
  quantity: number;
}

export const userDefinedCodeGroupFactory = Factory.define<UserDefinedCodeGroup>(
  () => {
    const userDefinedCodes = userDefinedCodeFactory.buildList(2);
    const customFields = codeGroupCustomFieldFactory.buildList(1);
    userDefinedCodes[0].customValues!.push(codeCustomValueFactory.build());
    return {
      id: faker.datatype.number(),
      name: faker.datatype.string(),
      userDefinedCodes,
      customFields,
    };
  }
);

export const userDefinedCodeFactory = Factory.define<UserDefinedCode>(() => {
  const name = faker.datatype.string();
  return {
    id: faker.datatype.number(),
    name,
    code: name,
    description: faker.datatype.string(),
    value: faker.datatype.string(),
    order: faker.datatype.number(),
    active: true,
    customValues: [],
    userDefinedCodeGroupId: faker.datatype.number(),
  };
});

export const codeGroupCustomFieldFactory = Factory.define<CodeGroupCustomField>(
  () => ({
    name: 'Unit',
    type: 'select',
    options: ['', 'Hours', 'Order'],
  })
);

export const codeCustomValueFactory = Factory.define<CodeCustomValue>(() => ({
  name: 'Unit',
  value: 'Hours',
}));

import React, { useState } from 'react';
import { XmlTag } from '../../../Lib/xmlUtils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ECertBlockInfo from './ECertBlockInfo';

export default function ECertProductOverview({
  product,
  tagNamePrefix = 'ed:',
}: {
  product: XmlTag;
  tagNamePrefix?: string;
}) {
  const [expandedAccordion, setExpandedAccordion] = useState(
    'panelProductOverview'
  );
  const handleAccordionChanged =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : '');
    };
  const findChild = (tagName: string) => {
    return product.getElementsByTagName?.(tagName, tagNamePrefix)[0];
  };
  const fieldNames = [
    { label: 'Origin', keyName: 'Origin' },
    { label: 'Description', keyName: 'Description' },
    { label: 'Species Common Name', keyName: 'CommonName' },
    { label: 'Eligibility Countries', keyName: 'EligibilityCountryID' },
    { label: 'Production Batch', keyName: 'ProductionBatch' },
    { label: 'First Expiry Date', keyName: 'FirstExpiryDate' },
    { label: 'Last Expiry Date', keyName: 'LastExpiryDate' },
    { label: 'Intended Use', keyName: 'IntendedUse' },
    { label: 'Net Weight', keyName: 'NetWeight' },
  ];
  const sourceDocuments =
    product.getElementsByTagName?.('SourceDocument', tagNamePrefix) || [];

  return (
    <Accordion
      expanded={expandedAccordion === 'panelProductOverview'}
      onChange={handleAccordionChanged('panelProductOverview')}
      sx={{ padding: '8px' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="panelProductOverview-header"
      >
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          Overview
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {fieldNames.map((field, index) => {
            let displayValue = findChild(field.keyName)?.value || '';
            let displayLabel = field.label;
            if (
              field.keyName === 'EligibilityCountryID' ||
              field.keyName === 'CommonName'
            ) {
              // Need to join all values of EligibilityCountries or CommonName
              const tags =
                product.getElementsByTagName?.(field.keyName, tagNamePrefix) ||
                [];
              const arrValues = tags.map((tag) => tag.value);
              displayValue = arrValues.join(', ');
            }
            if (field.keyName === 'NetWeight') {
              // Need to concat unitCode to label
              const netWeightTag = findChild(field.keyName);
              if (netWeightTag) {
                displayLabel = `${field.label} (${
                  netWeightTag.attributes?.unitCode || 'kg'
                })`;
              }
            }
            return (
              <ECertBlockInfo
                key={`overview-block-info-${index}`}
                label={displayLabel}
                displayText={displayValue}
                xs={6}
                md={6}
              />
            );
          })}
          <Grid item xs={12}>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              Source Documents
            </Typography>
            {sourceDocuments.length > 0 && (
              <Grid container spacing={2}>
                {sourceDocuments.map((srcDocument, index) => {
                  const documentId = srcDocument.getElementsByTagName?.(
                    'ID',
                    tagNamePrefix
                  )[0];
                  const reference = srcDocument.getElementsByTagName?.(
                    'ProductItemReference',
                    tagNamePrefix
                  )[0];
                  return (
                    <Grid item xs={5} md={4} key={`source-document-${index}`}>
                      <Typography sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                        {documentId?.value || ''} &nbsp;&nbsp;&nbsp; ref#{' '}
                        {reference?.value || ''}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

import Api, { ServiceGetMethod } from './Api';
import { AdminReviewEntity } from '../Models/AdminReviewEntity';

const getAdminReviewData: ServiceGetMethod<
  { warehouseId?: number | null },
  AdminReviewEntity[]
> = (options) =>
  Api.get('api/services/app/Dashboard/GetAdminReviewData', options);

export default {
  getAdminReviewData,
};

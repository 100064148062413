import { User } from '../../Models/User';
import React from 'react';
import UserService from '../../Services/UserService';
import useApiForm from '../../Hooks/useApiForm';
import { toast } from '../Toast';
import LoadingButton from '@mui/lab/LoadingButton';
import { confirmModalDialog } from '../ModalDialog';

interface UserActivationFormProps {
  user: User;
  onSuccess: () => void;
}

export default function UserActivationForm({
  user,
  onSuccess,
}: UserActivationFormProps) {
  const onSuccessApiResponse = (toastString: string) => {
    toast(toastString);
    onSuccess();
  };

  const activateUserForm = useApiForm(
    UserService.activateUser,
    {
      id: user.id,
    },
    {
      onSuccess: () => onSuccessApiResponse('User activated!'),
    }
  );

  const archiveUserForm = useApiForm(
    UserService.deActivateUser,
    {
      id: user.id,
    },
    {
      onSuccess: () => onSuccessApiResponse('User archived!'),
    }
  );

  const onClickArchiveOrActivate = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const yes = await confirmModalDialog({
      title: ` ${user.isActive ? 'Archive' : 'Activate'} User`,
      content: `Are you sure you want to ${
        user.isActive ? 'archive' : 'activate'
      } this user?`,
    });
    if (yes) {
      user.isActive ? archiveUserForm.submit() : activateUserForm.submit();
    }
  };

  return (
    <>
      {user.isActive ? (
        <LoadingButton
          size="small"
          color="warning"
          onClick={onClickArchiveOrActivate}
          loading={archiveUserForm.processing}
        >
          Archive User
        </LoadingButton>
      ) : (
        <LoadingButton
          size="small"
          onClick={onClickArchiveOrActivate}
          loading={activateUserForm.processing}
        >
          Activate User
        </LoadingButton>
      )}
    </>
  );
}

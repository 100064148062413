import React from 'react';
import MobilePage from '../../Components/MobilePage';
import { Stack } from '@mui/material';
import DownloadPdfButton from '../../Components/PdfGenerator/DownloadPdfButton';
import PdfGeneratorService from '../../Services/PdfGeneratorService';
import BillOfLadingService from '../../Services/BillOfLadingService';
import BolContainerService from '../../Services/BolContainerService';

export default function () {
  return (
    <MobilePage maxWidth={false}>
      <Stack
        direction="row"
        spacing={2}
        flexWrap="wrap"
        justifyContent="space-around"
      >
        <DownloadPdfButton
          filename={`report_${new Date().getTime()}_test.pdf`}
          request={{}}
          title="Download PDF - static"
          serviceMethod={PdfGeneratorService.generatePdf}
        />
        <DownloadPdfButton
          request={{}}
          title="Print PDF - static"
          serviceMethod={PdfGeneratorService.generatePdf}
        />
        <DownloadPdfButton
          filename={`report_${new Date().getTime()}_test.pdf`}
          request={{ bolNumber: 98745 }}
          title="Download BOL 98745"
          serviceMethod={BillOfLadingService.generatePdf}
        />
        <DownloadPdfButton
          filename={`cartersnote_${new Date().getTime()}_test.pdf`}
          request={{ bolContainerId: 1006 }}
          title="Download BOL Container 1006"
          serviceMethod={BolContainerService.generateCartersNotePdf}
        />
      </Stack>
    </MobilePage>
  );
}

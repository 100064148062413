import { useEffect, useMemo } from 'react';
import dohSound from '../Sounds/homer_doh.mp3';

const useAlertSound = () => {
  const alertSound = useMemo(() => new Audio(dohSound), []);

  useEffect(() => {
    return () => {
      alertSound.pause();
      alertSound.currentTime = 0;
    };
  }, [alertSound]);

  return { alertSound };
};

export default useAlertSound;

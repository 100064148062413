import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import React, { useEffect, useState, MouseEvent } from 'react';
import { Pallet, PalletLoadedType } from '../../Models/Pallet';
import LoadingButton from '@mui/lab/LoadingButton';
import useApiForm from '../../Hooks/useApiForm';
import PalletService from '../../Services/PalletService';
import PalletDetails from '../../Components/PalletDetails';
import useAlertSound from '../../Hooks/useAlertSound';

const errorBoxStyle = {
  backgroundColor: 'red',
  margin: '10px',
};

export interface PalletDetailsProps {
  onClose: (opts?: { shouldRefresh?: boolean }) => void;
  pallet: Pallet;
  currentBolId: number;
  currentContainerId?: number;

  // this is only here so it gets logged in AbpAuditLogs
  usedScanner: boolean;
}

export default function ({
  onClose,
  pallet,
  currentBolId: currentBolId,
  currentContainerId: currentContainerId,
  usedScanner,
}: PalletDetailsProps) {
  const [palletDetailsErrors, setPalletDetailsErrors] = useState({
    hasError: false,
    errorMessage: '',
  });

  const { alertSound } = useAlertSound();

  useEffect(() => {
    const validatePalletDetails = () => {
      // Check all cartons for Planned BOL Id or Container Id matching
      // and also that there are cartons in the pallet
      const cartonPlannedBOLMatch =
        (pallet.cartons?.every(
          (carton) => carton.plannedBillOfLadingId === currentBolId
        ) ||
          false) &&
        (pallet.cartons?.length > 0 || false);
      const cartonPlannedBOLContainerMatch =
        (pallet.cartons?.every(
          (carton) => carton.plannedBOLContainerId === currentContainerId
        ) ||
          false) &&
        (pallet.cartons?.length > 0 || false);
      const cartonHasECertPending =
        pallet.cartons?.some((carton) => carton.eCertPending === true) || false;

      // Check if the current BOL Id doesn't match the hard assigned BOL Id or Planned BOL Id
      if (pallet.billOfLadingId !== currentBolId && !cartonPlannedBOLMatch) {
        // BOL Id doesn't match (hard or soft assigned value)
        alertSound.play().then(() => {
          setPalletDetailsErrors({
            hasError: true,
            errorMessage: `Pallet belongs to different BOL number - ${
              pallet.bolNumber || 'No BOL Number'
            }`,
          });
        });
      }
      // Check if the current Container Id doesn't match the Hard assigned or Planned Container I
      else if (
        pallet.bolContainerId !== currentContainerId &&
        !cartonPlannedBOLContainerMatch
      ) {
        // Container Id doesn't match (hard or soft assigned value)
        alertSound.play().then(() => {
          setPalletDetailsErrors({
            hasError: true,
            errorMessage: `Pallet belongs to different container - ${
              pallet.containerCode || 'No Container Code'
            }`,
          });
        });
      }

      // Check if the pallet customer is not hard assigned and the pallet doesn't have a BOL Container Id
      if (
        pallet.customer != null &&
        !pallet.customer.hardAssign &&
        pallet.bolContainerId === null
      ) {
        alertSound.play().then(() => {
          setPalletDetailsErrors({
            hasError: true,
            errorMessage: `Stock for ${pallet.customer.name} must be assigned through BASIS`,
          });
        });
      }

      // Check if the pallet has cartons with ECertPending
      if (cartonHasECertPending) {
        alertSound.play().then(() => {
          setPalletDetailsErrors({
            hasError: true,
            errorMessage: `Unable to load Carton(s) that doesn’t have inbound ECert data`,
          });
        });
      }
    };

    validatePalletDetails();
  }, [
    pallet.bolNumber,
    pallet.billOfLadingId,
    currentBolId,
    pallet.containerCode,
    pallet.bolContainerId,
    currentContainerId,
    pallet.cartons,
    pallet.customer,
    alertSound,
  ]);

  const markAsLoadedForm = useApiForm(
    PalletService.markAsLoaded,
    {
      palletId: pallet.id,
      loadedType: PalletLoadedType.LOADED,

      // these are only here so they get logged in AbpAuditLogs
      palletCode: pallet.prefix + pallet.barcode,
      linkedBarcode: pallet.linkedBarcode || '',
      containerCode: pallet.containerCode,
      bolNumber: pallet.bolNumber ?? pallet.plannedBolNumber,
      usedScanner,
    },
    {
      onSuccess: () => {
        onClose({ shouldRefresh: true });
      },
      onError: (message) => {
        markAsLoadedForm.setErrors({ palletId: message });
      },
    }
  );

  const onMarkAsLoaded = (
    e: MouseEvent<HTMLButtonElement>,
    loadedType: PalletLoadedType
  ) => {
    e.stopPropagation();
    markAsLoadedForm.setData('loadedType', loadedType);
    markAsLoadedForm.submit();
  };

  const isDisabled = () =>
    pallet.loadedDateTime !== null ||
    palletDetailsErrors.hasError ||
    markAsLoadedForm.processing;

  return (
    <Dialog
      open
      onClose={() => onClose()}
      fullWidth
      maxWidth="xs"
      data-testid="pallet-details-dialog"
      PaperProps={{
        style: { border: palletDetailsErrors.hasError ? '4px solid red' : '' },
      }}
      scroll="paper"
    >
      <DialogTitle
        sx={{ backgroundColor: palletDetailsErrors.hasError ? 'red' : '' }}
      >
        Pallet #
        <span data-testid="pallet-code">{pallet.prefix + pallet.code}</span>
      </DialogTitle>
      <DialogContent>
        {palletDetailsErrors.hasError && (
          <Alert variant="filled" severity="error" sx={errorBoxStyle}>
            {palletDetailsErrors.errorMessage}
          </Alert>
        )}
        <PalletDetails palletId={pallet.id} backgroundColor="grey.100" />
      </DialogContent>
      <DialogActions>
        <Stack direction={'column'} spacing={2} width="100%">
          <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
            <Button fullWidth onClick={() => onClose()}>
              Cancel
            </Button>
            <LoadingButton
              fullWidth
              disabled={isDisabled()}
              variant="contained"
              data-testid="mark-loaded-btn"
              loading={
                markAsLoadedForm.processing &&
                markAsLoadedForm.data.loadedType === PalletLoadedType.LOADED
              }
              onClick={(e) => onMarkAsLoaded(e, PalletLoadedType.LOADED)}
            >
              Mark as loaded
            </LoadingButton>
          </Stack>
          <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
            <LoadingButton
              fullWidth
              disabled={isDisabled()}
              variant="contained"
              data-testid="hand-stack-btn"
              loading={
                markAsLoadedForm.processing &&
                markAsLoadedForm.data.loadedType === PalletLoadedType.HAND_STACK
              }
              onClick={(e) => onMarkAsLoaded(e, PalletLoadedType.HAND_STACK)}
            >
              Mark as hand stack
            </LoadingButton>
            <LoadingButton
              fullWidth
              disabled={isDisabled()}
              variant="contained"
              data-testid="top-up-btn"
              loading={
                markAsLoadedForm.processing &&
                markAsLoadedForm.data.loadedType === PalletLoadedType.TOP_UP
              }
              onClick={(e) => onMarkAsLoaded(e, PalletLoadedType.TOP_UP)}
            >
              Mark as top up
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

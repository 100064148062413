import React, { useMemo, useState } from 'react';
import { pagedParams } from '../../Services/Api';
import UserService from '../../Services/UserService';
import AddIcon from '@mui/icons-material/Add';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import EditUser from './EditUser';
import { toast } from '../../Components/Toast';
import RoleService from '../../Services/RoleService';
import { selectLabelsById } from '../../Components/Forms/FormField';
import useApiGet from '../../Hooks/useApiGet';
import { roleSelectItems } from '../../Models/Role';
import { User } from '../../Models/User';
import MobilePage from '../../Components/MobilePage';
import { Box, Button } from '@mui/material';
import CardTitle from '../../Components/CardTitle';
import Skeletons from '../../Components/Skeletons';
import SortedTable, {
  SortedTableColumn,
  SortedTableSwitchFilter,
} from '../../Components/SortedTable';

/***
 * This is the Admin's list of users
 */
export default function () {
  const { data, loading, refresh } = useApiGet(UserService.getUsers, {
    params: pagedParams,
  });
  const { data: roleData } = useApiGet(RoleService.getRoles, {
    params: pagedParams,
  });
  const [editUser, setEditUser] = useState<User | null>(null);
  const [open, setOpen] = useState(false);

  const roles = useMemo(() => roleSelectItems(roleData?.items), [roleData]);

  const roleNamesById = useMemo(() => selectLabelsById(roles), [roles]);

  const switchFilters: SortedTableSwitchFilter<User>[] = [
    {
      id: 'isActive',
      label: 'Active',
      default: true,
    },
  ];

  const onUserEditClick = (user: User) => {
    setEditUser(user);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setEditUser(null);
  };

  const onSuccess = () => {
    toast(editUser ? 'User updated!' : 'User created!');
    closeModal();
    refresh();
  };

  const onToggleUserIsActive = () => {
    closeModal();
    refresh();
  };

  const columnDefs = useMemo<SortedTableColumn<User>[]>(
    () => [
      {
        id: 'userName',
        label: 'Username',
        sortable: true,
      },
      {
        id: 'fullName',
        label: 'Name',
        sortable: true,
      },
      {
        id: 'emailAddress',
        label: 'Email',
        sortable: true,
      },
      {
        id: 'roleNames',
        label: 'Roles',
        sortable: true,
        value: (user) =>
          user.roleNames.map((id) => roleNamesById[id]).join(', '),
      },
      {
        id: 'warehouseIds',
        label: 'Warehouses',
        sortable: true,
        headerProps: { align: 'center' },
        cellProps: { align: 'center' },
        value: (user) => user.warehouseIds?.length || '',
      },
      {
        id: 'isActive',
        label: 'Active',
        sortable: true,
        cellProps: { sx: { minWidth: 40, maxWidth: 50 } },
        cellRender: (user) => <div>{user.isActive ? 'True' : 'False'}</div>,
      },
      {
        id: 'hasBarcode',
        cellProps: { sx: { paddingBottom: '6px' }, width: 40 },
        cellRender: (user) =>
          user.hasBarcode && <QrCode2Icon color="success" />,
      },
    ],
    [roleNamesById]
  );

  return (
    <MobilePage sx={{ position: 'relative' }}>
      <CardTitle
        title="Users"
        rightChild={
          <Box>
            <Button
              data-testid="create-user-button"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpen(true)}
            >
              Add User
            </Button>
          </Box>
        }
      />
      {loading ? (
        <Skeletons count={3} />
      ) : (
        <SortedTable
          columnDefs={columnDefs}
          data={data?.items}
          rowKey="id"
          sortOpts={{ id: 'fullName', dir: 'asc' }}
          title="Users"
          loading={loading}
          onRowClick={onUserEditClick}
          localStorageKey="Users.sortOpts"
          searchable
          switchFilters={switchFilters}
        />
      )}
      {open && (
        <EditUser
          user={editUser}
          roles={roles}
          onClose={closeModal}
          onSuccess={onSuccess}
          onToggleUserIsActive={onToggleUserIsActive}
        />
      )}
    </MobilePage>
  );
}

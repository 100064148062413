import React, { useEffect, useMemo, useState } from 'react';
import { StockOnHandCarton } from '../../Models/Carton';
import { Box, Tooltip } from '@mui/material';
import { tryFormatDateStr, validateQueryDate } from '../../Lib/utils';
import { PalletStatus } from '../../Models/PalletStatus';
import {
  DataGridPremium,
  GridAggregationModel,
  GridColDef,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import useApiGet from '../../Hooks/useApiGet';
import CartonService from '../../Services/CartonService';
import CardTitle from '../../Components/CardTitle';
import {
  standardDataGridStyles,
  toolbarProps,
} from '../../Components/StandardDataGrid';
import { parseISO } from 'date-fns';
import {
  GridCellParams,
  gridExpandedSortedRowIdsSelector,
} from '@mui/x-data-grid-pro';

const DEFAULT_OPACITY_IF_SAME_PREVIOUS = 0.2;
const stockOnHandCartonDataGridStyle = {
  ...standardDataGridStyles,
  fontSize: '13px',
  '& .MuiDataGrid-aggregationColumnHeaderLabel': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-cell--opacityIfSamePrevious': {
    opacity: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
  },
};

export interface StockOnHandCartonDataGridProps {
  warehouseId: number | null | undefined;
  productId: number | null;
  customerId: number | null;
  batchNumber: string | null;
  palletId: number | null;
  plantCodeId: string | null;
  palletStatusId: number | null;
  dateIn: string;
  lowDate: string;
  highDate: string;
  palletStatuses: PalletStatus[];
  onGotCartons: (cartons: StockOnHandCarton[]) => void;
  packDateFrom: string;
  packDateTo: string;
  eligibility: string;
  cypher: string;
}

export default function ({
  warehouseId,
  productId,
  customerId,
  batchNumber,
  palletId,
  plantCodeId,
  palletStatusId,
  dateIn,
  lowDate,
  highDate,
  palletStatuses,
  onGotCartons,
  packDateFrom,
  packDateTo,
  eligibility,
  cypher,
}: StockOnHandCartonDataGridProps) {
  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [filteredCartonsTable, setFilteredCartonsTable] = useState<
    StockOnHandCarton[] | undefined
  >();
  const [aggregationModel, setAggregationModel] =
    useState<GridAggregationModel>({
      qty: 'sum',
      weight: 'sum',
    });

  const cartonsTable = useApiGet(CartonService.getStockOnHand, {
    params: {
      warehouseId,
      productId: productId || 0,
      customerId: customerId || 0,
      dateIn: validateQueryDate(dateIn),
      // don't include any other parameters in this query - the rest are filtered client-side
    },
    onSuccess: (data) => onGotCartons(data),
  });

  useEffect(
    () => {
      const lowDateStr = lowDate
        ? tryFormatDateStr(lowDate, 'yyyy-MM-dd') + 'T00:00:00'
        : '';
      const highDateStr = highDate
        ? tryFormatDateStr(highDate, 'yyyy-MM-dd') + 'T00:00:00'
        : '';
      const packDateFromStr = packDateFrom
        ? tryFormatDateStr(packDateFrom, 'yyyy-MM-dd') + 'T00:00:00'
        : '';
      const packDateToStr = packDateTo
        ? tryFormatDateStr(packDateTo, 'yyyy-MM-dd') + 'T00:00:00'
        : '';
      // Eligibility is a bunch of space-separated values, so we need to match the whole word
      const eligibilityRegex = new RegExp(
        '\\b' + eligibility?.replace(/[^- a-zA-Z0-9]/g, '') + '\\b'
      );
      const cypherLower = cypher?.toLocaleLowerCase();

      setPaginationModel({ pageSize: paginationModel.pageSize, page: 0 });

      // prettier-ignore
      setFilteredCartonsTable(cartonsTable.data?.filter((row) =>
        (!palletId || row.palletId === palletId)
        && (!plantCodeId || row.plantCode === plantCodeId)
        && (!palletStatusId || row.palletStatusId === palletStatusId)
        && (!batchNumber || row.batchNumber === batchNumber)
        && (!lowDateStr || (row.lowDate && row.lowDate >= lowDateStr))
        && (!highDateStr || (row.highDate && row.highDate <= highDateStr))
        && (!packDateFromStr || (row.packDate && row.packDate >= packDateFromStr))
        && (!packDateToStr || (row.packDate && row.packDate <= packDateToStr))
        && (!eligibility || row.eligibility?.match(eligibilityRegex))
        && (!cypher || row.cypher?.toLocaleLowerCase().includes(cypherLower))
      ))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setFilteredCartonsTable,
      cartonsTable.data,
      palletId,
      plantCodeId,
      palletStatusId,
      batchNumber,
      lowDate,
      highDate,
      packDateFrom,
      packDateTo,
      eligibility,
      cypher,
    ]
  );

  const columnDefs = useMemo<GridColDef[]>(() => {
    const palletStatusByCode = Object.fromEntries(
      palletStatuses.map((ps) => [ps.code, ps.description])
    );
    return [
      {
        field: 'palletCode',
        headerName: 'Pallet',
        sortable: true,
        valueGetter: (params) =>
          [params.row.palletPrefix, params.row.palletCode]
            .filter((a) => a)
            .join(' - '),
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'location',
        headerName: 'Location',
        sortable: true,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
        minWidth: 80,
        flex: 1,
      },
      {
        field: 'palletDepth',
        headerName: 'Pallet Depth',
        sortable: true,
        type: 'number',
        minWidth: 80,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
      },
      {
        field: 'cartonBarcode',
        headerName: 'Barcode',
        sortable: true,
        minWidth: 380,
        flex: 1,
      },
      {
        field: 'batchNumber',
        headerName: 'Batch No',
        sortable: true,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
      },
      {
        field: 'plantCode',
        headerName: 'Plant Code',
        sortable: true,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
      },
      {
        field: 'palletStatusCode',
        headerName: 'Status Code',
        sortable: true,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
        renderCell: (params) => {
          const status =
            params.row.eCertPending ||
            (params.row.palletStatusCode &&
              palletStatusByCode[params.row.palletStatusCode]);
          return status ? (
            <Tooltip title={status}>
              <div>
                {params.row.eCertPending
                  ? 'ECert'
                  : params.row.palletStatusCode}
              </div>
            </Tooltip>
          ) : (
            params.row.palletStatusCode
          );
        },
      },
      {
        field: 'expDate',
        headerName: 'Expiry Date',
        sortable: true,
        width: 85,
        type: 'date',
        renderCell: (params) =>
          tryFormatDateStr(params.row.expDate, 'dd/MM/yyyy'),
        valueGetter: (params) =>
          params.row.expDate ? parseISO(params.row.expDate) : null,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
      },
      {
        field: 'dateIn',
        headerName: 'Date In',
        sortable: true,
        width: 85,
        type: 'date',
        renderCell: (params) =>
          tryFormatDateStr(params.row.dateIn, 'dd/MM/yyyy'),
        valueGetter: (params) =>
          params.row.dateIn ? parseISO(params.row.dateIn) : null,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
      },
      {
        field: 'lowDate',
        headerName: 'Low Date',
        sortable: true,
        width: 85,
        type: 'date',
        renderCell: (params) =>
          tryFormatDateStr(params.row.lowDate, 'dd/MM/yyyy'),
        valueGetter: (params) =>
          params.row.lowDate ? parseISO(params.row.lowDate) : null,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
      },
      {
        field: 'highDate',
        headerName: 'High Date',
        sortable: true,
        width: 85,
        type: 'date',
        renderCell: (params) =>
          tryFormatDateStr(params.row.highDate, 'dd/MM/yyyy'),
        valueGetter: (params) =>
          params.row.highDate ? parseISO(params.row.highDate) : null,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
      },
      {
        field: 'packDate',
        headerName: 'Pack Date',
        sortable: true,
        width: 85,
        type: 'date',
        renderCell: (params) =>
          tryFormatDateStr(params.row.packDate, 'dd/MM/yyyy'),
        valueGetter: (params) =>
          params.row.packDate ? parseISO(params.row.packDate) : null,
      },
      {
        field: 'halalStatus',
        headerName: 'Halal Status',
        sortable: true,
        minWidth: 80,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
      },
      {
        field: 'qty',
        headerName: 'Qty',
        sortable: true,
        type: 'number',
        minWidth: 80,
        cellClassName: 'font-tabular-nums',
      },
      {
        field: 'weight',
        headerName: 'Weight',
        sortable: true,
        type: 'number',
        minWidth: 120,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
        cellClassName: 'font-tabular-nums',
      },
      {
        field: 'asn',
        headerName: 'ASN Number',
        sortable: true,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
        type: 'number',
        minWidth: 120,
        align: 'center',
      },
      {
        field: 'sourceEdNumber',
        headerName: 'Source ED #',
        sortable: true,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
        renderCell: (params) =>
          params.row.sourceEdNumber?.split(',').join('\n'),
      },
      {
        field: 'cypher',
        headerName: 'Cypher',
        sortable: true,
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
      },
      {
        field: 'declarations',
        headerName: 'Declarations',
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
      },
      {
        field: 'eligibility',
        headerName: 'Eligibility',
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
      },
      {
        field: 'isLostCarton',
        headerName: 'Hold Status',
        opacityIfSameAsPrevious: DEFAULT_OPACITY_IF_SAME_PREVIOUS,
        renderCell: (data) => (data.row.isLostCarton ? 'Lost Carton' : ''),
      },
    ];
  }, [palletStatuses]);
  const isColWithOpacityIfSameAsPrevious = (colField: string) => {
    const currentColDef = columnDefs.find((c) => c.field === colField);
    return (
      currentColDef != null &&
      (currentColDef['opacityIfSameAsPrevious' as keyof GridColDef] as number) >
        0
    );
  };
  const getCellClassName = (params: GridCellParams<StockOnHandCarton>) => {
    const needToRenderOpacity = isColWithOpacityIfSameAsPrevious(
      params.colDef.field
    );
    if (!needToRenderOpacity) return '';

    const visibleRowIds = gridExpandedSortedRowIdsSelector(apiRef);
    const currentIndex = apiRef.current.getRowIndexRelativeToVisibleRows(
      params.row.id
    );
    if (!currentIndex) return '';

    const prevIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
    const prevRowId = visibleRowIds[prevIndex];
    const prevRow = apiRef.current.getRow(prevRowId) as StockOnHandCarton;
    if (!prevRow) return '';
    let prevValue =
      prevRow[params.field as keyof StockOnHandCarton]?.toLocaleString();
    let currentValue = params.value?.toLocaleString();
    if (
      params.value &&
      params.colDef.type &&
      (params.colDef.type == 'date' || params.colDef.type == 'dateTime')
    ) {
      prevValue = tryFormatDateStr(prevValue);
      currentValue = tryFormatDateStr(params.value.toString());
    }

    return prevValue && currentValue && currentValue == prevValue
      ? 'MuiDataGrid-cell--opacityIfSamePrevious'
      : '';
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '& .font-tabular-nums': {
          fontVariantNumeric: 'tabular-nums',
        },
        backgroundColor: 'white',
        '& .stock-on-hand--hold_status--true': {
          backgroundColor: '#cd1ddc',
          '&:hover': {
            backgroundColor: 'rgba(170,25,182)',
          },
        },
      }}
    >
      <CardTitle
        title="Stock on Hand - Cartons"
        loading={cartonsTable.loading}
      />
      <DataGridPremium
        data-testid={'stock-on-hand-cartons-data-grid'}
        rows={filteredCartonsTable ?? []}
        apiRef={apiRef}
        columns={columnDefs}
        loading={cartonsTable.loading}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        aggregationModel={aggregationModel}
        onAggregationModelChange={(newModel) => setAggregationModel(newModel)}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: toolbarProps,
        }}
        sx={stockOnHandCartonDataGridStyle}
        getCellClassName={getCellClassName}
        getRowClassName={(params) =>
          `stock-on-hand--hold_status--${params.row.isLostCarton}`
        }
      />
    </Box>
  );
}

import React, { useState } from 'react';
import CardTitle from '../../../Components/CardTitle';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { XmlTag } from '../../../Lib/xmlUtils';
import ECertProductOverview from './ECertProductOverview';
import ECertProductClassifications from './ECertProductClassifications';
import ECertProductContainers from './ECertProductContainers';
import ECertProductPackaging from './ECertProductPackaging';
import ECertProductProcessingHistory from './ECertProductProcessingHistory';

interface ECertProductsProps {
  products: XmlTag[];
  tagNamePrefix?: string;
}
export default function ECertProducts({
  products,
  tagNamePrefix = 'ed:',
}: ECertProductsProps) {
  const [expandedAccordion, setExpandedAccordion] = useState('');
  const handleAccordionChanged =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : '');
    };

  return (
    <Paper
      variant="elevation"
      elevation={1}
      sx={{ marginY: '24px' }}
      data-testid="ecert-products-section"
    >
      <CardTitle title={'Products'} sx={{ marginBottom: '24px' }}></CardTitle>
      {products.map((product, index) => {
        const productItem =
          product.getElementsByTagName?.('ProductItem', tagNamePrefix)[0]
            ?.value || null;
        const description =
          product.getElementsByTagName?.('Description', tagNamePrefix)[0]
            ?.value || 'ERROR NO DESCRIPTION';

        return (
          <Accordion
            key={`ecert-product-${index}`}
            expanded={expandedAccordion === `panelProduct${index}`}
            onChange={handleAccordionChanged(`panelProduct${index}`)}
            sx={{ padding: '16px' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id={`panelProduct${index}-header`}
            >
              <Stack
                spacing={{ xs: 2, sm: 4 }}
                direction="row"
                alignItems="center"
              >
                <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
                  {productItem || index + 1}
                </Typography>
                <Typography
                  sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  style={{ width: '100%' }}
                >
                  {description}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <ECertProductOverview
                product={product}
                tagNamePrefix={tagNamePrefix}
              />
              <ECertProductClassifications
                product={product}
                tagNamePrefix={tagNamePrefix}
              />
              <ECertProductContainers
                product={product}
                tagNamePrefix={tagNamePrefix}
              />
              <ECertProductPackaging
                product={product}
                tagNamePrefix={tagNamePrefix}
              />
              <ECertProductProcessingHistory
                product={product}
                tagNamePrefix={tagNamePrefix}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Paper>
  );
}

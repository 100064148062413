import { BillingCycle } from '../Models/Customer';
import Api, { ServicePostMethod } from './Api';

const exportSalesOrdersToNetsuite: ServicePostMethod<
  { billingCycle: BillingCycle | null | undefined },
  null
> = (data, options) => {
  const opt = options ?? {};
  opt.headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  return Api.post(
    'api/services/app/Netsuite/ExportSalesOrdersToNetsuite',
    data,
    opt
  );
};

export { exportSalesOrdersToNetsuite };

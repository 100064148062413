import { useCallback } from 'react';
import { format } from 'date-fns';

/**
 * This hook provides easy access to the date-fns format() method,
 * and also memoizes the formatted dates for quick re-rendering
 */

// store the memoized formatted dates in a structure like [formatStr][dateStr] = formattedStr;
const formattedDates: Record<string, Record<string, string>> = {};

// you could use this exported method directly...
export function getOrCacheFormattedDate(
  dateStr: string | number,
  formatStr = 'dd/MM/yyyy'
) {
  let resultsByDate = formattedDates[formatStr];
  if (!resultsByDate) {
    resultsByDate = formattedDates[formatStr] = {};
  }
  if (resultsByDate[dateStr]) {
    return resultsByDate[dateStr];
  }
  return (resultsByDate[dateStr] = format(new Date(dateStr), formatStr));
}

// ...or for consistent custom formatting you can use this Hook
export default function (formatStr = 'dd/MM/yyyy') {
  const formatDate = useCallback(
    (dateStr: string | number) => getOrCacheFormattedDate(dateStr, formatStr),
    [formatStr]
  );

  return formatDate;
}

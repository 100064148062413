import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { XmlTag } from '../../../Lib/xmlUtils';

interface ECertPartiesProps {
  consignorId: string;
  consigneeId: string;
  thirdParties: XmlTag[];
}
export default function ECertParties({
  consignorId,
  consigneeId,
  thirdParties,
}: ECertPartiesProps) {
  return (
    <Box sx={{ marginY: '16px' }}>
      <Typography
        sx={{ fontSize: '20px', fontWeight: 500, marginBottom: '16px' }}
      >
        Parties
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
            Consignor ID
          </Typography>
          <Typography
            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
            data-testid="consignor-id-text"
          >
            {consignorId}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
            Consignee ID
          </Typography>
          <Typography
            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
            data-testid="consignee-id-text"
          >
            {consigneeId}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
            Third Parties
          </Typography>
          <Typography
            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
            data-testid="third-parties-text"
          >
            {thirdParties.map((p) => p.value).join(', ')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

import { InstallDesktop, InstallMobile } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { ResponsiveContext } from '../Providers/ResponsiveProvider';

/**
 * usePwaButton is a custom React hook to capture beforeinstallprompt events
 *
 * It provides:
 *  * show: true if the event has been captured and the button should be displayed
 *  * Icon: the correct icon to use (mobile or desktop install icon)
 *  * onClick(): install the PWA using the captured event
 */

// https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
interface BeforeInstallPromptEvent {
  preventDefault: () => void;
  prompt: () => void;
}

let cachedInstall: null | BeforeInstallPromptEvent = null;

export default function usePwaButton() {
  const [deferredInstall, setDeferredInstall] =
    useState<null | BeforeInstallPromptEvent>(cachedInstall);
  const { mobileView } = useContext(ResponsiveContext);

  // catch the install prompt event on Chrome
  useEffect(() => {
    const listener = (e: Event) => {
      e.preventDefault();
      cachedInstall = e as unknown as BeforeInstallPromptEvent;
      setDeferredInstall(e as unknown as BeforeInstallPromptEvent);
    };
    window.addEventListener('beforeinstallprompt', listener);
    return () => window.removeEventListener('beforeinstallprompt', listener);
  }, []);

  const onClick = () => {
    deferredInstall?.prompt();
    cachedInstall = null;
    setDeferredInstall(null);
  };

  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Icon: mobileView ? InstallMobile : InstallDesktop,
    show: !!deferredInstall,
    onClick,
  };
}

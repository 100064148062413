import React from 'react';
import { Box, Typography } from '@mui/material';
import DOMPurify from 'dompurify';

export interface ASNInstructionsProps {
  title?: string;
  instructionContent: string;
}

export default function ({
  title = 'ASN Instructions',
  instructionContent,
}: ASNInstructionsProps) {
  return (
    <Box padding={4} width={'100%'}>
      <Typography variant="h6" data-testid={'instruction-title-header'}>
        {title}
      </Typography>
      {instructionContent.length > 0 ? (
        <div
          // NOTE: we can alter the sanitize config, but the default is quite secure
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              instructionContent.replace(/\n/g, '<br />')
            ),
          }}
        ></div>
      ) : (
        <Typography variant="subtitle1" mt={1}>
          None
        </Typography>
      )}
    </Box>
  );
}

import React, { useMemo } from 'react';
import { BOLContainer, CONTAINER_ENTITY_NAME } from '../../Models/BOLContainer';
import { ASN, ASNWithCounts } from '../../Models/ASN';
import {
  CODE_GROUPS,
  RELEVANT_TYPES,
  UserDefinedCode,
} from '../../Models/UserDefinedCode';
import useApiGet from '../../Hooks/useApiGet';
import UserDefinedCodeService from '../../Services/UserDefinedCodeService';
import { CUSTOMER_ENTITY_NAME } from '../../Models/Customer';
import { ASN_ENTITY_NAME } from '../../Pages/ASNs/ASNDetail';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import BillingRow from './BillingRow';

interface EntityBillingProps {
  entity: BOLContainer | ASN | ASNWithCounts;
  customerId: number;
  canEditBilling?: boolean;
  canEditFullBilling?: boolean;
  onBillingClosed?: () => void;
}

export const billingCodeIsAuto = (code: UserDefinedCode) =>
  code.customValues?.some(
    (cv) => cv.name == 'Unit' && ['Container', 'Pallet'].includes(cv.value)
  );

export default function ({
  entity,
  customerId,
  canEditBilling,
  canEditFullBilling,
  onBillingClosed,
}: EntityBillingProps) {
  const isASNEntity = Object.prototype.hasOwnProperty.call(entity, 'asnNumber');
  const relevantTypes = isASNEntity
    ? [RELEVANT_TYPES['ASN'], RELEVANT_TYPES['BOTH']]
    : [RELEVANT_TYPES['BOL'], RELEVANT_TYPES['BOTH']];
  const entityType = isASNEntity ? ASN_ENTITY_NAME : CONTAINER_ENTITY_NAME;
  const billingCodeGroupName = CODE_GROUPS.BILLING_CODES;
  const { data: billingCodeGroup } = useApiGet(
    UserDefinedCodeService.getGroupDetails,
    {
      params: {
        name: billingCodeGroupName,
        relevantTypes: relevantTypes,
      },
      paramsSerializer: {
        indexes: null, // no brackets at all
      },
    }
  );
  // load which Billing Codes are assigned ot this Customer
  const { data: customerCodes } = useApiGet(
    UserDefinedCodeService.getCodeQuantitiesForEntity,
    {
      params: {
        entityType: CUSTOMER_ENTITY_NAME,
        entityId: customerId,
        groupId: billingCodeGroup?.id || 0, // with re-fetch when this Id is available
      },
      noAutoFetch: true,
    }
  );

  const { data: entityCharges, refresh: refreshCodeQuantities } = useApiGet(
    UserDefinedCodeService.getCodeQuantitiesForEntity,
    {
      params: {
        entityType: entityType,
        entityId: entity.id,
        groupId: billingCodeGroup?.id || 0, // with re-fetch when this Id is available
      },
      noAutoFetch: true,
      autoRefreshTrueOrSecs: 20,
    }
  );
  // display only the Codes assigned to the Customer, but in the order they appear in the Billing Code Group
  const sortedManualBillingCodes = useMemo<UserDefinedCode[]>(() => {
    if (!billingCodeGroup || !customerCodes) {
      return [];
    }
    return (billingCodeGroup.userDefinedCodes || []).filter((code) =>
      customerCodes.some(
        (cc) => cc.userDefinedCodeId == code.id && !billingCodeIsAuto(code)
      )
    );
  }, [billingCodeGroup, customerCodes]);

  const sortedAutomaticBillingCodes = useMemo<UserDefinedCode[]>(() => {
    if (!billingCodeGroup || !customerCodes) {
      return [];
    }
    return (billingCodeGroup.userDefinedCodes || []).filter((code) =>
      customerCodes.some(
        (cc) => cc.userDefinedCodeId == code.id && billingCodeIsAuto(code)
      )
    );
  }, [billingCodeGroup, customerCodes]);

  // display a loading spinner while we wait for everything...
  if (!billingCodeGroup || !entityCharges || !customerCodes) {
    return (
      <Box textAlign="center" data-testid="bol-container-billing-loading">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Stack spacing={1}>
        {sortedManualBillingCodes.map((code) => (
          <BillingRow
            key={code.code}
            entityType={entityType}
            entityId={entity.id}
            code={code}
            entityQuantities={entityCharges}
            refresh={refreshCodeQuantities}
            // only allow editing if the user has the 'Billing.FullEdit' permission
            // if they have the 'Billing.Edit' permission and the billing is not yet complete
            // otherwise, if no permissions, disable the button
            disabled={!canEditBilling && !canEditFullBilling}
            onBillingClosed={() => {
              if (onBillingClosed) onBillingClosed();
            }}
          />
        ))}
      </Stack>
      {sortedAutomaticBillingCodes && (
        <>
          <hr />
          <Typography variant="h6" data-testid={'auto-charge-header'}>
            Automatic Charges
          </Typography>
          <Stack spacing={1}>
            {sortedAutomaticBillingCodes.map((code) => (
              <BillingRow
                key={code.code}
                entityType={entityType}
                entityId={entity.id}
                code={code}
                entityQuantities={entityCharges}
                refresh={refreshCodeQuantities}
                // only allow editing if the user has the 'Billing.FullEdit' permission
                disabled={!canEditFullBilling}
                onBillingClosed={() => {
                  if (onBillingClosed) onBillingClosed();
                }}
              />
            ))}
          </Stack>
        </>
      )}
    </>
  );
}

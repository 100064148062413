import { Factory } from 'fishery';
import StatusChip, {
  StatusChipColors,
  StatusChipProps,
} from '../Components/StatusChip';
import React from 'react';
import { faker } from '@faker-js/faker';

export interface StocktakePallet {
  id: number;
  stocktakeId: number | null;
  locatedLocation: string;
  status: StocktakePalletStatus;
  palletBarcode: string;
  product: string;
  depth: number;
}

export enum StocktakePalletStatus {
  expected,
  verified,
  new,
  lost,
}

export const STOCKTAKE_PALLET_STATUS_LABELS = [
  'Expected',
  'Verified',
  'New',
  'Lost',
] as const;

export type StocktakePalletStatusLabel =
  typeof STOCKTAKE_PALLET_STATUS_LABELS[number];

export const STOCKTAKE_PALLET_STATUS_COLORS: StatusChipColors<StocktakePalletStatusLabel> =
  {
    Expected: { bg: '#FFFFFF' },
    Verified: { bg: '#C0D8C1' }, // lighten(theme.palette.success.main, 0.7)
    New: { bg: '#F9D2B3' }, // lighten(theme.palette.warning.main, 0.7)
    Lost: { bg: '#F08E8D' },
  };

// renders the last nbChars digits of a barcode
export const getShortenBarcode = (barcode: string, nbChars?: number) => {
  const limitDigits = nbChars || digitsDisplayedForCartonBarcode;
  // for some reason .replaceAll() doesn't exists when running in tests...
  return barcode.replace(/ /g, '').slice(-limitDigits);
};

export const digitsDisplayedForCartonBarcode = 10;

export const StocktakePalletStatusChip = ({
  status,
  ...props
}: { status: StocktakePalletStatus } & Omit<
  StatusChipProps<number>,
  'label' | 'colors'
>) => {
  return (
    <StatusChip
      {...props}
      label={STOCKTAKE_PALLET_STATUS_LABELS[status]}
      colors={STOCKTAKE_PALLET_STATUS_COLORS}
      width="6em"
      sx={{
        p: '2px 4px',
        paddingY: '4px',
      }}
    />
  );
};

export interface MarkStocktakePalletStatusRequestDto {
  stocktakeId: string | undefined;
  code: string;
  stocktakePalletStatus: StocktakePalletStatus;
}

export const stocktakePalletFactory = Factory.define<StocktakePallet>(() => ({
  id: faker.datatype.number(),
  stocktakeId: faker.datatype.number(),
  locatedLocation: faker.datatype.string(),
  status: StocktakePalletStatus.expected,
  palletBarcode: faker.datatype.string(),
  product: faker.datatype.string(),
  depth: faker.datatype.number(),
}));

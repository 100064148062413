import React from 'react';
import { ASN, ASNStatus } from '../../Models/ASN';
import Checklist from '../../Components/Checklist/Checklist';
import useApiForm from '../../Hooks/useApiForm';
import usePermissions from '../../Hooks/usePermissions';
import AsnService from '../../Services/AsnService';

export interface AsnChecklistProps {
  asn: ASN;
  refresh: () => void;
}

export default function ({ asn: asn, refresh }: AsnChecklistProps) {
  const [canCompleteChecklist] = usePermissions(['ASN.Checklist.Complete']);
  const [canUpdateChecklist] = usePermissions(['ASN.Checklist.Update']);

  // If the user's permission is to complete the checklist, then they can submit the form if the checklist is not completed
  // If the user's permission is to update the checklist, then they can submit the form at any time
  const canSubmitCompleteForm =
    canUpdateChecklist || (canCompleteChecklist && !asn.checklistCompletedDate);

  const completeChecklistForm = useApiForm(
    AsnService.updateASNChecklistCompleted,
    {
      asnId: asn.id,
      checklistCompleted: true,
    },
    {
      onSuccess: () => {
        refresh();
      },
    }
  );

  // When the checklist gets completed, we update the checklistCompletedDate on the ASN
  const onComplete = (isComplete: boolean) => {
    if (
      asn.status != ASNStatus.managerReview &&
      asn.status != ASNStatus.pendingInvoice &&
      asn.status != ASNStatus.invoiced &&
      (isComplete ? canSubmitCompleteForm : canUpdateChecklist)
    ) {
      completeChecklistForm.setData('checklistCompleted', isComplete);
      completeChecklistForm.submit();
    }
  };

  // refresh the bol when a media has been uploaded
  const onMediaUploaded = () => {
    refresh();
  };

  return (
    <Checklist
      entityType="ASNs.ASN"
      entityId={asn.id}
      onComplete={onComplete}
      canCompleteChecklist={canSubmitCompleteForm}
      onMediaUploaded={onMediaUploaded}
    />
  );
}

import { Factory } from 'fishery';
import { faker } from '@faker-js/faker';

export interface Checklist {
  id: number;
  entityType: string;
  checklistItemGroups: ChecklistItemGroup[];
}

export interface ChecklistItemGroup {
  id: number;
  name: string;
  order: number;
  checklistItems: ChecklistItem[];
}

export enum ChecklistItemType {
  INT,
  DATE,
  TIME,
  DECIMAL,
  STRING,
  OPTIONS,
}

export interface ChecklistItem {
  id: number;
  text: string;
  order: number;
  required: boolean;
  type: ChecklistItemType;
  units: string;
  actions: string[];
  options: string[];
  validationRegEx: string;
  validationMessage: string;
  uniqueRef: string;
}

export const checklistItemFactory = Factory.define<ChecklistItem>(() => ({
  id: faker.datatype.number(),
  text: faker.datatype.string(),
  order: faker.datatype.number(),
  required: faker.datatype.boolean(),
  type: faker.datatype.number({ min: 0, max: 5 }),
  units: '',
  actions: [],
  options: [],
  validationRegEx: '',
  validationMessage: '',
  uniqueRef: faker.datatype.string(),
}));

export const checklistItemGroupFactory = Factory.define<ChecklistItemGroup>(
  () => ({
    id: faker.datatype.number(),
    name: faker.datatype.string(),
    order: faker.datatype.number(),
    checklistItems: checklistItemFactory.buildList(2),
  })
);

export const checklistFactory = Factory.define<Checklist>(() => ({
  id: faker.datatype.number(),
  entityType: faker.datatype.string(),
  checklistItemGroups: checklistItemGroupFactory.buildList(2),
}));

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Close, FlashOff, FlashOn, ZoomIn, ZoomOut } from '@mui/icons-material';
import { toastError } from '../Toast';
import { FileMetaData } from '../../Models/WMSFile';
import { AuthContext } from '../../Providers/AuthProvider';
import { scaleDimensions } from '../../Lib/utils';
import useLocalStorage from '../../Hooks/useLocalStorage';
import DOMPurify from 'dompurify';

/**
 * This renders a full-screen modal which connects a <video> element's source to the camera
 * so that the user can take a photo without lots of extra mouse-clicks
 *
 * After taking the photo the user is prompted to enter some meta data before saving the photo
 */

// TIP: To enable the camera on an Android device (or emulator) for testing:
//   * run this from IP address (see .env.development and README.md)
//   * visit chrome://flags in Chrome on the device and enable the "Insecure origins treated as secure"
//   * add http://your.ip.address:3000 to the whitelist

interface ZoomSettings {
  min: number;
  max: number;
}

export type ModernMediaTrackCapabilities = MediaTrackCapabilities & {
  zoom?: ZoomSettings;
  torch?: boolean;
};

export type MetaQuestionType =
  | {
      type: 'select';
      label: string;
      required?: boolean;
      options: string[];
    }
  | {
      type: 'string';
      label: string;
      required?: boolean;
    };

const aspectRatios = ['full', 'square', '4:3'] as const;

export interface PhotoTakerProps {
  metaData?: FileMetaData[];
  questions?: MetaQuestionType[];
  onPhoto: (dataUrl: string, metaData: FileMetaData[]) => void;
  onClose: () => void;
}

export default function ({
  metaData,
  questions,
  onPhoto,
  onClose,
}: PhotoTakerProps) {
  const videoElement = useRef<HTMLVideoElement>(null);
  const canvasElement = useRef<HTMLCanvasElement>(null);

  const { application } = useContext(AuthContext);
  const mediaStream = useRef<MediaStream | null>(null);
  const [intervalHandle, setIntervalHandle] = useState<number | null>(null);
  const [videoReady, setVideoReady] = useState(false);
  const [haveImg, setHaveImg] = useState(false);
  const [flashAvailable, setFlashAvailable] = useState(false);
  const [flashOn, setFlashOn] = useState(false);
  const [zoomParams, setZoomParams] = useState<ZoomSettings | null>(null);
  const [zoom, setZoom] = useState(0);
  const [aspectRatio, setAspectRatio] = useLocalStorage<
    typeof aspectRatios[number]
  >('PhotoTaker.aspectRatio', '4:3');

  const stopVideo = () => {
    if (flashOn) {
      toggleFlash();
    }
    if (zoom > 0) {
      handleSetZoom(0);
    }
    mediaStream.current?.getVideoTracks().forEach((track) => track.stop());
    mediaStream.current = null;

    // stop the timer which is looking for the video ready event
    if (intervalHandle) {
      clearInterval(intervalHandle);
      setIntervalHandle(null);
    }
    setVideoReady(false);
  };

  const startVideo = async () => {
    const video = videoElement.current;
    if (video && !mediaStream.current) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: application!.photoWidth,
            height: application!.photoWidth,
            // use the back camera (which is facing away from the user)
            facingMode: 'environment',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            zoom: true,
          },
          audio: false,
        });

        const [track] = stream.getVideoTracks();
        const capabilities =
          track.getCapabilities() as ModernMediaTrackCapabilities;
        video.srcObject = stream;
        mediaStream.current = stream;

        setZoomParams(capabilities.zoom || null);
        setFlashAvailable(capabilities.torch == true);
        handleSetAspectRatio(aspectRatio);

        // wait for the video to be ready before hiding the progress spinner...
        const handle = setInterval(
          () => {
            if (video.readyState == 4 || stream.id == 'Mocked Stream') {
              setVideoReady(true);
              clearInterval(handle);
              setIntervalHandle(null);
            }
          },
          50,
          // we can test for this string in the tests...
          'check video ready interval'
        );
        setIntervalHandle(handle);
      } catch (e) {
        console.error(e);
        const err: any = e;
        toastError("Couldn't access camera: " + err.toString());
      }
    }
  };

  useEffect(() => {
    // start capturing video after the component is first mounted
    setTimeout(startVideo);

    // stop streaming video when the component is dismounted
    return () => stopVideo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleFlash = () => {
    const torch = !flashOn;
    setFlashOn(torch);
    const [track] = mediaStream.current!.getVideoTracks();
    track.applyConstraints({ advanced: [{ torch } as any] });
  };

  const handleSetZoom = (z: number) => {
    const newZoom = Math.min(Math.max(z, 0), 100); // constrain between 0-100
    setZoom(newZoom);

    // the default slider has a range of 0-100 which is fine, but we need to convert that to the actual zoom range
    const actualZoom =
      zoomParams!.min + ((zoomParams!.max - zoomParams!.min) * newZoom) / 100;
    // https://googlechrome.github.io/samples/image-capture/update-camera-zoom.html
    const [track] = mediaStream.current!.getVideoTracks();
    track.applyConstraints({ advanced: [{ zoom: actualZoom } as any] });
  };

  const handleSetAspectRatio = (ratio: typeof aspectRatio) => {
    setAspectRatio(ratio);
    const [track] = mediaStream.current!.getVideoTracks();
    const capabilities =
      track.getCapabilities() as ModernMediaTrackCapabilities;
    const maxWidth = capabilities.width?.max || 0;
    const maxHeight = capabilities.height?.max || 0;
    if (ratio == 'full') {
      // force the camera to use its full ratio instead of 4:3 which seems to be the default
      // but scale it to the maximum resolution we want so we don't get the white screen
      // bug on the Coolpak guns as per CPK-199
      const { width, height } = scaleDimensions(
        maxWidth,
        maxHeight,
        application!.photoWidth
      );
      if (width && height) {
        track.applyConstraints({ width, height });
      }
    } else if (ratio == 'square') {
      track.applyConstraints({
        width: application!.photoWidth,
        height: application!.photoWidth,
      });
    } else {
      // 4:3
      // if the phone is portrait then we actually need to invert the ratio
      const isPortrait = (screen.orientation.type || '').includes('portrait');
      track.applyConstraints({
        width: application!.photoWidth * (isPortrait ? 3 / 4 : 1),
        height: application!.photoWidth * (isPortrait ? 1 : 3 / 4),
      });
    }
  };

  // if we're in 4:3 mode then we can get a bigger picture by resetting the constraints
  useEffect(() => {
    const handler = () => {
      if (mediaStream.current && aspectRatio == '4:3') {
        handleSetAspectRatio(aspectRatio);
      }
    };
    window.addEventListener('orientationchange', handler);
    return () => window.removeEventListener('orientationchange', handler);
  }, [aspectRatio, setAspectRatio]); // eslint-disable-line react-hooks/exhaustive-deps

  const takePhoto = () => {
    // we can't get the canvas context in tests, so skip this bit if the stream is our "Mocked Stream"
    if (mediaStream.current!.id != 'Mocked Stream') {
      // resize the canvas to match the video, including current screen rotation (from video dimensions)
      const video = videoElement.current!;
      const canvas = canvasElement.current!;
      const maxSize = application!.photoWidth;
      const { width, height } = scaleDimensions(
        video.videoWidth,
        video.videoHeight,
        maxSize
      );
      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext('2d')!;
      context.drawImage(
        videoElement.current!,
        0, // x origin
        0, // y origin
        canvas.width,
        canvas.height
      );
    }
    setHaveImg(true);

    // don't stop the video - to make it quicker to start the video again if the user clicks 'RETAKE'
  };

  const reTake = () => {
    setHaveImg(false);
    startVideo();
  };

  const save = async () => {
    let dataUrl = 'mockDataUrl';
    if (mediaStream.current!.id != 'Mocked Stream') {
      const canvas = canvasElement.current!;
      // the canvas has already been scaled and rotated correctly
      dataUrl = canvas.toDataURL('image/jpeg', application!.photoQuality);
    }

    // combine the question answers with the meta data
    let meta = [...(metaData || [])];
    Object.keys(formData).forEach((key) =>
      meta.push({ label: key, value: formData[key] })
    );

    // remove empty meta data
    meta = meta.filter((m) => m.value);

    // send the photo to the parent component and then close
    onPhoto(dataUrl, meta);
    close();
  };

  const close = () => {
    stopVideo();
    onClose();
  };

  // create blank form data to be used for the questions
  const form = useMemo(() => {
    const val: Record<string, string> = {};
    (questions || []).forEach((q) => (val[q.label] = ''));
    return val;
  }, [questions]);

  const [formData, setFormData] = useState(form);

  const onFormInput = (key: string, value: string) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const formValid = useMemo(() => {
    return (questions || [])
      .filter((q) => q.required)
      .every((q) => formData[q.label] != '');
  }, [formData, questions]);

  return (
    <Dialog open onClose={close} fullScreen>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Box>Camera</Box>
          <IconButton color="primary" onClick={close}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          p: haveImg ? undefined : 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: haveImg ? 'flex-start' : 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        {!videoReady && !haveImg && (
          <Stack gap={2} alignItems="center" width="100%">
            <Typography variant="body1">Accessing Camera...</Typography>
            <CircularProgress />
          </Stack>
        )}
        <video
          ref={videoElement}
          autoPlay
          width="100%"
          style={{
            display: videoReady && !haveImg ? 'block' : 'none',
            maxHeight: '100%',
          }}
        />
        {videoReady && !haveImg && (
          <Stack
            direction="row"
            sx={{
              mb: 1,
              // position the ratio options top-left
              // and apply a bit of white drop shadow just in case the they is over a dark part of the image
              position: 'absolute',
              top: 0,
              left: 0,
              filter: 'drop-shadow(2px 2px 2px rgba(255,255,255,0.7))',
            }}
          >
            {aspectRatios.map((ratio) => (
              <Button
                key={ratio}
                color="success"
                onClick={() => handleSetAspectRatio(ratio)}
                sx={{ color: ratio == aspectRatio ? undefined : 'grey' }}
              >
                {ratio}
              </Button>
            ))}
          </Stack>
        )}
        {videoReady && !haveImg && zoomParams && (
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{
              mb: 1,
              width: '100%',
              // position the zoom slider at the bottom of the container, possibly on top of the image
              // and apply a bit of white drop shadow just in case the slider is over a dark part of the image
              position: 'absolute',
              bottom: 0,
              filter: 'drop-shadow(2px 2px 2px rgba(255,255,255,0.7))',
            }}
          >
            <IconButton
              onClick={() => handleSetZoom(zoom - 10)}
              disabled={zoom == 0}
              data-testid="zoom-out-btn"
            >
              <ZoomOut />
            </IconButton>
            <Slider
              value={zoom}
              data-testid="zoom-slider"
              onChange={(e, z) => handleSetZoom(z as number)}
            />
            <IconButton
              onClick={() => handleSetZoom(zoom + 10)}
              disabled={zoom == 100}
              data-testid="zoom-in-btn"
            >
              <ZoomIn />
            </IconButton>
          </Stack>
        )}
        {videoReady && (
          <canvas
            ref={canvasElement}
            width="1000"
            height="1000"
            style={{
              display: haveImg ? 'block' : 'none',
              maxWidth: '100%',
              maxHeight: '60%',
            }}
          />
        )}
        {haveImg && (metaData?.length || questions?.length) && (
          <table border={0}>
            <tbody>
              {(metaData || []).map((meta) => (
                <tr key={meta.label}>
                  <th
                    style={{
                      width: '1%',
                      textAlign: 'left',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {/* NOTE: we can alter the sanitize config, but the default is quite secure */}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(meta.label),
                      }}
                    />
                    :
                  </th>
                  <td style={{ width: '100%' }}>{meta.value}</td>
                </tr>
              ))}
              {(questions || []).map((question) => (
                <tr key={question.label}>
                  <th
                    style={{
                      width: '1%',
                      textAlign: 'left',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {question.label}:
                  </th>
                  {question.type == 'string' ? (
                    <td>
                      <TextField
                        value={formData[question.label]}
                        onChange={(e) =>
                          onFormInput(question.label, e.target.value)
                        }
                        error={question.required && !formData[question.label]}
                        size="small"
                        data-testid={'question-' + question.label}
                        fullWidth
                      />
                    </td>
                  ) : (
                    <td>
                      <Select
                        value={formData[question.label]}
                        onChange={(e) =>
                          onFormInput(question.label, e.target.value as string)
                        }
                        error={question.required && !formData[question.label]}
                        size="small"
                        data-testid={'question-' + question.label}
                        fullWidth
                      >
                        {question.options.map((option) => (
                          <MenuItem
                            value={option}
                            key={option}
                            data-testid={'select-' + option}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        {!haveImg && flashAvailable && (
          <IconButton
            color={flashOn ? 'success' : 'default'}
            disabled={!videoReady}
            onClick={toggleFlash}
            data-testid="toggle-flash-btn"
          >
            {flashOn ? <FlashOn /> : <FlashOff />}
          </IconButton>
        )}
        {!haveImg && (
          <IconButton
            color="primary"
            onClick={takePhoto}
            disabled={!videoReady}
            data-testid="take-photo-btn"
          >
            <CameraAltIcon />
          </IconButton>
        )}
        {haveImg && (
          <Button variant="outlined" onClick={reTake}>
            Retake
          </Button>
        )}
        {haveImg && (
          <Button variant="contained" onClick={save} disabled={!formValid}>
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

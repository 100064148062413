import React, { useContext, useMemo, useState } from 'react';
import BillOfLadingService from '../../../Services/BillOfLadingService';
import { BillOfLadingLine } from '../../../Models/BillOfLadingLine';
import { BillOfLading, BolStatus } from '../../../Models/BillOfLading';
import { LoadingButton } from '@mui/lab';
import { AuthContext } from '../../../Providers/AuthProvider';

const PickPalletsButton = ({ bol }: { bol: BillOfLading }) => {
  const [loadingId, setLoadingId] = useState(0);
  const { user } = useContext(AuthContext);

  const setToPickPalletsStatus = async () => {
    setLoadingId(bol.id);
    await BillOfLadingService.markAsReadyToPick({
      bolId: bol.id,
      warehouseId: user?.currentWarehouseId ?? null,
    });
    setLoadingId(0);
  };

  const bolLinesComplete = useMemo(() => {
    if (bol.status != BolStatus.notStarted) return false;
    if (!bol.lines) return false;

    return bol.lines.some((line: BillOfLadingLine) => {
      return line.selectedQty > 0;
    });
  }, [bol?.lines, bol?.status]);

  return (
    <LoadingButton
      data-testid={'PickPalletsButton'}
      onClick={() => setToPickPalletsStatus()}
      variant="contained"
      disabled={!bolLinesComplete}
      loading={loadingId == bol.id}
    >
      Pick Pallets
    </LoadingButton>
  );
};

export default PickPalletsButton;

import { QuestionMark } from '@mui/icons-material';
import { useContext } from 'react';
import { AuthContext } from '../Providers/AuthProvider';
import routes, { MenuGroup, MenuRoute } from '../routes';

/**
 * Filter the full list of routes to only make routes available if the logged-in user is authorised to see them.
 * Also return a filtered list of authorised routes which also include a menu icon - to show in the menu.
 */

export default function useAuthorizedRoutes() {
  const { user } = useContext(AuthContext);
  const userPermissions = user?.permissions || [];
  const authorizedRoutes = routes.filter(
    (r) =>
      !r.permissions || r.permissions.some((p) => userPermissions.includes(p))
  );

  let routeMenuItems = authorizedRoutes.filter((r) => r.Icon) as MenuRoute[];

  // get unique list of group names
  const groupNames = new Set(
    routeMenuItems.map((r) => r.group).filter((g) => g)
  );
  const groups = Array.from(groupNames).map<MenuGroup>((name) => {
    const items = routeMenuItems.filter((r) => r.group == name);
    return {
      name: name!,
      items,
      Icon: items.find((g) => g.GroupIcon)?.GroupIcon || QuestionMark,
      open: items.find((g) => g.open)?.open ?? false,
    };
  });

  // remove the grouped items from the main list
  routeMenuItems = routeMenuItems.filter((r) => !r.group);

  return {
    authorizedRoutes,
    routeMenuItems,
    groups,
  };
}

import { History } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import useApiGet from '../../Hooks/useApiGet';
import AuditLogService from '../../Services/AuditLogService';
import AuditLogItem from './AuditLogItem';
import usePermissions from '../../Hooks/usePermissions';
import { ValueMappings, formattedChangeValues } from '../../Models/AuditLog';

export interface AuditLogProps {
  entityType: string;
  entityId: number | string;
  tabIndex?: number;
  valueMappings?: ValueMappings;
}

export default function ({
  entityType,
  entityId,
  tabIndex,
  valueMappings,
}: AuditLogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const {
    data: items,
    loading,
    refresh,
  } = useApiGet(AuditLogService.getByEntity, {
    params: {
      entityType: entityType,
      entityId: entityId.toString(),
    },
    noAutoFetch: true,
  });

  const onOpen = () => {
    setIsOpen(true);
    refresh();
  };

  const formattedItems = useMemo(() => {
    return items?.map((item) => ({
      ...item,
      changes: item.changes.map((change) => formattedChangeValues(change)),
    }));
  }, [items]);

  const filteredItems = useMemo(() => {
    if (!formattedItems || !searchText) {
      return formattedItems;
    }

    // index each item for faster searching
    if (!formattedItems[0].changes[0]._index) {
      for (const item of formattedItems) {
        for (const change of item.changes) {
          change._index = [change.name, change.oldValue, change.newValue]
            .map((v) => (v || '').toLocaleLowerCase())
            .join('~~');
        }
      }
    }
    const searchLower = searchText.toLocaleLowerCase();
    const filtered = formattedItems.map((item) => ({
      ...item,
      changes: item.changes.filter((change) =>
        change._index!.includes(searchLower)
      ),
    }));
    return filtered.filter((item) => item.changes.length);
  }, [formattedItems, searchText]);

  const [canViewAudit] = usePermissions(['Audit.View']);
  if (!canViewAudit) {
    return null;
  }

  return (
    <>
      <IconButton
        color="primary"
        data-testid="audit-log-open-btn"
        onClick={() => onOpen()}
        tabIndex={tabIndex}
      >
        <History />
      </IconButton>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullWidth
        maxWidth={false}
      >
        <DialogTitle display="flex" justifyContent="space-between">
          <Box>Audit Log</Box>
          <TextField
            label="Search"
            margin="none"
            size="small"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : !filteredItems?.length ? (
            <Box>No {searchText ? 'matching ' : ''}changes!</Box>
          ) : (
            <>
              {filteredItems.map((item) => (
                <AuditLogItem
                  item={item}
                  key={item.date}
                  valueMappings={valueMappings}
                />
              ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

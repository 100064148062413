/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import AuthService, {
  AuthenticateResponse,
  CurrentLoginInformation,
} from '../Services/AuthService';
import { setAccessToken, HAS_COOKIE_LOCALSTORAGE_KEY } from '../Services/Api';
import useApiGet from '../Hooks/useApiGet';
import useLocalStorage from '../Hooks/useLocalStorage';
import CurrentUserService from '../Services/CurrentUserService';
import useInactivityTimeout from '../Hooks/useInactivityTimeout';

/**
 * AuthProvider provides the current authState (user, tenant, application)
 * to any component which consumes it with useContext(AuthContext)
 *
 * It also provides:
 *  * loggingIn: a flag to indicate when the app is first checking the auth state
 *  * logout(): a method any component can call to logout
 *  * setToken(token): a method to respond to a successful login attempt to store the token and refresh the authState
 */

export const defaultAuthState: CurrentLoginInformation = {
  application: null,
  tenant: null,
  user: null,
};

export interface AuthContext extends CurrentLoginInformation {
  loggingIn: boolean;
  setToken: (token: AuthenticateResponse) => void;
  logout: () => void;
  refresh: () => void;
  refreshToken: () => void;
  setCurrentWarehouseId: (id: number | null) => Promise<void>;
  setCurrentCustomerId: (id: number | null) => Promise<void>;
}

// as well as providing the authState, also provide a 'loggingIn' flag and helper functions to out and handle a log in
export const defaultAuthContextValue: AuthContext = {
  ...defaultAuthState,
  loggingIn: false,
  setToken: /* istanbul ignore next */ () => {},
  logout: /* istanbul ignore next */ () => {},
  refresh: /* istanbul ignore next */ () => {},
  refreshToken: /* istanbul ignore next */ () => {},
  setCurrentWarehouseId: /* istanbul ignore next */ async () => {},
  setCurrentCustomerId: /* istanbul ignore next */ async () => {},
};

export const LAST_VERSION_LOCALSTORAGE_KEY = '_lastVersion';
export const LAST_USER_LOCALSTORAGE_KEY = '_lastUser';

export const AuthContext = createContext(defaultAuthContextValue);

export default function ({ children }: { children: ReactNode }) {
  const [authState, setAuthState] = useState(defaultAuthState);
  const [getUserName, setUserName] = useLocalStorage(
    LAST_USER_LOCALSTORAGE_KEY,
    ''
  );
  const { loading: loggingIn, refresh } = useApiGet(
    AuthService.getCurrentLoginInformations,
    {
      suppressError: true,
      onSuccess: (data) => setAuthState(data?.user ? data : defaultAuthState),
    }
  );
  useEffect(() => {
    const currentServerVersion = authState.application?.version;

    // if the logged in user is not the same as the user who logged in last time
    // we want to redirect to the default route, otherwise the user will just end up on the same route as before
    if (
      authState.user &&
      getUserName &&
      authState.user.userName != getUserName
    ) {
      //Redirect to the default route
      window.location.href = '/';
    }

    // when the user changes, update the username in local storage
    // this is used to check if the user has changed when logging in
    // and if so, redirect to the default route
    if (authState.user) {
      setUserName(authState.user.userName);

      if (currentServerVersion) {
        const lastKnownVersion = localStorage.getItem(
          LAST_VERSION_LOCALSTORAGE_KEY
        );
        // if the server version differs from the last version we remember,
        // reload the page so that the front-end gets updated.
        if (lastKnownVersion != currentServerVersion) {
          localStorage.setItem(
            LAST_VERSION_LOCALSTORAGE_KEY,
            currentServerVersion
          );
          // only reload if we did have a lastKnown version
          // - if we haven't, but this code exists, then we already have the new version.
          if (lastKnownVersion) {
            window.location.reload();
          }
        }
      }
    }
  }, [
    authState.application?.version,
    authState.user,
    setUserName,
    getUserName,
  ]);

  const setToken = useCallback(
    (token: AuthenticateResponse) => {
      refresh();
      setAccessToken(token);
    },
    [refresh]
  );

  // provide a logout function so any component can call this function to logout
  const logout = useCallback(() => {
    setAuthState(defaultAuthState);
    setAccessToken();
  }, [setAuthState]);

  // if another tab logs in/out, also log this tab in/out
  useEffect(() => {
    const storageListener = (e: StorageEvent) => {
      // if the key is null, assumption is the storage has been cleared
      // if the key is HAS_COOKIE_LOCALSTORAGE_KEY and the old value is true, assumption is the user has logged out
      // refresh on all other occasions if there are events from different tabs
      if (
        e.key == null ||
        (e.key == HAS_COOKIE_LOCALSTORAGE_KEY && e.oldValue === 'true')
      ) {
        logout();
      } else {
        refresh();
      }
    };
    window.addEventListener('storage', storageListener);
    return () => window.removeEventListener('storage', storageListener);
  }, [refresh, logout]);

  const setCurrentWarehouseId = useCallback(
    async (warehouseId: number | null) => {
      // use "Optimistic UI" - assume this is going to work and update the AuthState and rest of the UI immediately
      // if there is an error revert the AuthState
      // that's why it's handy to call CurrentUserService.setCurrentWarehouse from within the AuthProvider
      const prevWarehouseId = authState.user!.currentWarehouseId;
      authState.user!.currentWarehouseId = warehouseId;
      setAuthState({ ...authState });
      const [, error] = await CurrentUserService.setCurrentWarehouse(
        warehouseId
      );
      if (error) {
        authState.user!.currentWarehouseId = prevWarehouseId;
        setAuthState({ ...authState });
      }
    },
    [authState, setAuthState]
  );

  const refreshToken = useCallback(async () => {
    // if the user is not logged in, don't try to refresh the token
    if (!authState.user) {
      return;
    }

    const [token, error] = await AuthService.refreshToken();

    if (!error) {
      setToken(token!);
    } else {
      // if the refresh token fails, log the user out as they won't be able to continue
      logout();
    }
  }, [authState.user, setToken, logout]);

  const setCurrentCustomerId = useCallback(
    async (customerId: number | null) => {
      const prevCustomerId = authState.user!.currentCustomerId;
      authState.user!.currentCustomerId = customerId;
      setAuthState({ ...authState });
      const [, error] = await CurrentUserService.setCurrentCustomer(customerId);
      if (error) {
        authState.user!.currentCustomerId = prevCustomerId;
        setAuthState({ ...authState });
      }
    },
    [authState, setAuthState]
  );

  // if the inactivity timeout mins value does not exist, default to 15 minutes
  // this only applies if the user has the timeout permission
  const canUseTimeout =
    authState.user?.permissions?.includes('User.LoginTimeout');

  useInactivityTimeout(
    (authState.application?.inactivityTimeoutMins || 15) * 60 * 1000,
    logout,
    canUseTimeout
  );

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        loggingIn,
        setToken,
        logout,
        refresh,
        refreshToken,
        setCurrentWarehouseId,
        setCurrentCustomerId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

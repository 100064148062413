import React, { useContext, useEffect } from 'react';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';
import { AuthContext } from '../../Providers/AuthProvider';
import Login from '../../Pages/Auth/Login';
import { Toolbar, Box } from '@mui/material';
import { Routes, Route, useLocation } from 'react-router-dom';
import AppDrawer, { drawerWidth } from './AppDrawer';
import AppBar from './AppBar';
import useAuthorizedRoutes from '../../Hooks/useAuthorizedRoutes';
import MobilePage from '../../Components/MobilePage';
import { setTopLevelPath } from '../../Hooks/useTopLevelNavCache';
import CustomerLogin from '../../Pages/Auth/CustomerLogin';

export default function () {
  const { user } = useContext(AuthContext);
  const { mobileView } = useContext(ResponsiveContext);
  const { authorizedRoutes } = useAuthorizedRoutes();
  const location = useLocation();
  const isCustomerViewEnabled =
    process.env.REACT_APP_CUSTOMER_VIEW_ENABLED === 'true' ?? false;

  // tell the useTopLevelNavCache hook that the route has changed
  useEffect(() => setTopLevelPath(location.pathname), [location.pathname]);

  if (!user) {
    if (isCustomerViewEnabled) {
      return <CustomerLogin />;
    }
    return <Login />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {!mobileView && <AppBar />}
      {!mobileView && <AppDrawer />}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          minHeight: '100vh',
          maxWidth: mobileView ? '100vw' : `calc(100vw - ${drawerWidth}px)`,
        }}
      >
        {!mobileView && <Toolbar />}
        <Box
          sx={{
            mt: 2,
            // if the BottomNav is present we need more padding at the bottom to go under the BottomNav
            mb: mobileView ? 9 : 2,
          }}
        >
          <Routes>
            {authorizedRoutes.map((route, i) => (
              <Route key={i} path={route.path} element={<route.Component />} />
            ))}
            <Route path="*" element={<MobilePage>No route</MobilePage>} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}

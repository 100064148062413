import CardTitle from '../../Components/CardTitle';
import React, { useContext, useEffect } from 'react';
import MobilePage from '../../Components/MobilePage';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import LocationSelector from '../../Components/LocationSelector';
import useApiGet from '../../Hooks/useApiGet';
import AsnService from '../../Services/AsnService';
import { pagedParams } from '../../Services/Api';
import { ASNStatus, ASNWithCounts } from '../../Models/ASN';
import { AuthContext } from '../../Providers/AuthProvider';
import ASNStatusChip from './ASNStatusChip';
import { ArrowForwardIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function () {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { data, loading, refresh } = useApiGet(AsnService.getAllWithCounts, {
    params: {
      ...pagedParams,
      warehouseId: user?.currentWarehouseId,
      statuses: [ASNStatus.arrived, ASNStatus.receiving],
    },
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => refresh({ clear: true }), [user?.currentWarehouseId]);
  const openASN = (asn: ASNWithCounts) => {
    navigate(`/asn/${asn.asnNumber}`);
  };

  return (
    <MobilePage showTitle={false}>
      <CardTitle title="ASN Arrivals" />
      <LocationSelector />
      <Stack sx={{ mt: 1 }}>
        {loading && (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )}
        {(data || []).length > 0 && (
          <Stack gap={1}>
            {data?.map((asn) => {
              return (
                <Card
                  key={asn.asnNumber}
                  onClick={() => openASN(asn)}
                  data-testid={'asn-card-' + asn.asnNumber}
                >
                  <CardContent>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Stack gap={1} flexGrow={1}>
                        <Stack direction="row" alignItems="center">
                          <CardTitle
                            title={asn.asnNumber}
                            sx={{ flexGrow: 1 }}
                          />
                          <ASNStatusChip status={asn.status} />
                        </Stack>
                        <Typography>
                          {asn.customer?.name.toLocaleUpperCase()}
                        </Typography>
                      </Stack>
                      <IconButton size="large" sx={{ p: 0 }}>
                        <ArrowForwardIos />
                      </IconButton>
                    </Stack>
                  </CardContent>
                </Card>
              );
            })}
          </Stack>
        )}
        {data?.length === 0 && (
          <Typography align="center">No ASN found in this Warehouse</Typography>
        )}
      </Stack>
    </MobilePage>
  );
}

import { faker } from '@faker-js/faker';
import { Factory } from 'fishery';
import { Store, storeFactory } from './Store';
import { Customer } from './Customer';
import { Carton, cartonFactory } from './Carton';
import { PalletStatus } from './PalletStatus';

export enum PalletLoadedType {
  LOADED,
  HAND_STACK,
  TOP_UP,
}

export interface Pallet {
  id: number;
  prefix: string;
  code: string;
  barcode: string;
  containerCode: string;
  containerNotes?: string;
  plannedContainerCode: string;
  moveToLocation: string;
  bolNumber: number;
  plannedBolNumber: number;
  billOfLadingId: number;
  dateOut: string | null;
  loadedDateTime: string | null;
  loadedUsername: string | null;
  loadedType: PalletLoadedType | null;
  xCoord: string;
  yCoord: string;
  zCoord: string;
  locatedDateTime: string | null;
  linkedBarcode?: string;
  cartons?: Carton[];
  depth: number;
  location: string;
  storeCode: string;
  store: Store | null;
  customer: Customer;
  status: PalletStatus;
  bolContainerId?: number;
  verifiedUsername: string | null;
  verifiedDateTime: string | null;
  lost: string;
}

export interface PalletForStaging extends Pallet {
  batch: string | null;
  productCode: string | null;
  priority: number | null;
}

export interface PalletDetails extends Pallet {
  contents: CartonContent[];
}

export const PALLET_LOADED_TYPE_LABELS = [
  'Loaded',
  'Hand Stack',
  'Top Up',
] as const;

export interface CartonContent {
  totalQty: number;
  productCode: string;
  productDescription: string;
  batchNumber: string;
  highDate: string;
  lowDate: string;
  expDate: string;
  plantCode: string;
  halalStatus: string;
  lot: number;
  weight: number;
  qty: number;
  packDate: string;
  dateIn: string;
  dateOut: string;
  asn: number;
  requiredTempState: string;
  eCertPending: boolean;
}

let uniqueId = 1;

export const palletFactory = Factory.define<Pallet>(() => ({
  id: uniqueId++,
  prefix: '',
  code: faker.datatype.string(),
  barcode: faker.datatype.string(),
  containerCode: faker.datatype.string(),
  plannedContainerCode: faker.datatype.string(),
  moveToLocation: faker.datatype.string(),
  bolNumber: faker.datatype.number(),
  plannedBolNumber: faker.datatype.number(),
  billOfLadingId: faker.datatype.number(),
  dateOut: null,
  loadedDateTime: null,
  loadedType: null,
  loadedUsername: faker.datatype.string(),
  xCoord: faker.datatype.string(),
  yCoord: faker.datatype.string(),
  zCoord: faker.datatype.string(),
  locatedDateTime: faker.datatype.datetime().toISOString(),
  location: faker.datatype.string(),
  depth: faker.datatype.number(),
  statusCode: faker.datatype.string(),
  storeCode: faker.datatype.number().toString(),
  store: storeFactory.build(),
  lost: faker.datatype.string(),
  customer: {
    id: faker.datatype.number(),
    code: faker.datatype.number(),
    name: faker.datatype.string(),
    netsuiteId: faker.datatype.string(),
    hardAssign: faker.datatype.boolean(),
  },
  status: {
    id: faker.datatype.number(),
    code: faker.datatype.string(),
    description: faker.datatype.string(),
    allowLoadIn: faker.datatype.boolean(),
    allowLoadOut: faker.datatype.boolean(),
    allowTransfer: faker.datatype.boolean(),
  },
  isOnHand: faker.datatype.boolean(),
  verifiedUsername: null,
  verifiedDateTime: null,
}));

export const palletForStagingFactory = Factory.define<PalletForStaging>(() => ({
  ...palletFactory.build(),
  batch: faker.datatype.string(),
  productCode: faker.datatype.string(),
  priority: faker.datatype.number(),
}));

export const contentFactory = Factory.define<CartonContent>(() => ({
  totalQty: faker.datatype.number(),
  productCode: faker.datatype.string(),
  productDescription: faker.datatype.string(),
  batchNumber: faker.datatype.string(),
  lowDate: '',
  highDate: '',
  expDate: '',
  plantCode: '',
  halalStatus: '',
  lot: faker.datatype.number(),
  weight: faker.datatype.number(),
  qty: faker.datatype.number(),
  packDate: '',
  dateIn: '',
  dateOut: '',
  asn: faker.datatype.number(),
  requiredTempState: '',
  eCertPending: faker.datatype.boolean(),
}));

export const palletDetailsFactory = Factory.define<PalletDetails>(() => ({
  ...palletFactory.build(),
  productCode: faker.datatype.string(),
  contents: contentFactory.buildList(2),
  cartons: cartonFactory.buildList(2),
}));

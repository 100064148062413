import React, { useMemo } from 'react';
import MobilePage from '../../../Components/MobilePage';
import { Box } from '@mui/material';
import useApiGet from '../../../Hooks/useApiGet';
import StoreService from '../../../Services/StoreService';
import { StoreCapacityRow } from '../../../Models/StoreCapacity';
import CardTitle from '../../../Components/CardTitle';
import StoreCapacityDataGrid from './Components/StoreCapacityDataGrid';

export default function StoreCapacity() {
  // fetch store capacity
  const { data: storeCapacity, loading } = useApiGet(
    StoreService.getStoreCapacity
  );

  // combine the containers and charges into a single array that is flatten to support the data grid
  const rows: StoreCapacityRow[] = useMemo(() => {
    if (!storeCapacity) return [];

    let rowId = 0;

    return storeCapacity.map((storeCapacity) => {
      rowId++;
      return {
        ...storeCapacity,
        rowId,
      };
    });
  }, [storeCapacity]);

  return (
    <MobilePage showOnDesktop maxWidth={false}>
      <Box sx={{ height: '80vh' }}>
        <CardTitle title="Store Capacity" />
        <StoreCapacityDataGrid loading={loading} rows={rows} />
      </Box>
    </MobilePage>
  );
}

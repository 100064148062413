import React, { ReactNode } from 'react';
import {
  Box,
  BoxProps,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import CardTitle from '../../Components/CardTitle';
import useApiGet from '../../Hooks/useApiGet';
import { BOL_LABELS } from '../../Models/BillOfLading';
import BillOfLadingService from '../../Services/BillOfLadingService';
import StandardDataGrid from '../../Components/StandardDataGrid';
import { GridColDef } from '@mui/x-data-grid-premium';
import { friendlyDate } from '../../Lib/utils';
import MobilePage from '../../Components/MobilePage';
import { useParams } from 'react-router-dom';

/**
 * This page shows a list of BoL to the Operator so they can select a BoL to start loading
 */

export const DetailRow = ({
  label,
  testId,
  children,
  wrapContent = false,
  ...props
}: {
  label: ReactNode;
  children: ReactNode;
  testId?: string;
  wrapContent?: boolean;
} & BoxProps) => (
  <Grid container sx={{ ...props.sx }}>
    <Grid item xs={2}>
      <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
        {label}
      </Typography>
    </Grid>
    <Grid
      item
      xs={10}
      sx={{
        textOverflow: wrapContent ? 'initial' : 'ellipsis',
        overflow: wrapContent ? 'initial' : 'hidden',
        whiteSpace: wrapContent ? 'normal' : 'nowrap',
        wordBreak: wrapContent ? 'break-word' : 'normal',
      }}
    >
      <Typography data-testid={testId} sx={{ fontSize: '14px' }}>
        {children}
      </Typography>
    </Grid>
  </Grid>
);

export default function () {
  const { bolNumber } = useParams<{ bolNumber: string }>();

  const { data } = useApiGet(
    BillOfLadingService.getBillOfLadingByIdForCustomer,
    {
      params: {
        bolNumber: bolNumber || '',
      },
    }
  );

  const cartonColumns: GridColDef[] = [
    {
      field: 'productCode',
      headerName: 'Product Code',
      minWidth: 50,
      maxWidth: 150,
    },
    {
      field: 'productDescription',
      headerName: 'Product Description',
      minWidth: 250,
      maxWidth: 350,
    },
    {
      field: 'cartonsCount',
      headerName: 'Units',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'cartonsWeight',
      headerName: 'Weight',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'palletsCount',
      headerName: 'Pallets',
      minWidth: 50,
      maxWidth: 100,
    },
  ];

  const containerColumns: GridColDef[] = [
    {
      field: 'containerCode',
      headerName: 'Container Code',
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: 'palletsCount',
      headerName: 'Pallets',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'cartonsCount',
      headerName: 'Units',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'cartonsWeight',
      headerName: 'Weight',
      minWidth: 50,
      maxWidth: 100,
    },
  ];

  return (
    <MobilePage
      showTitle={false}
      maxWidth={false}
      showOnDesktop
      title={`Outbound Orders for ${data?.bolNumber}`}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {!data && (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}

          {data && (
            <Grid container spacing={1}>
              <Grid item xs={12} lg={8} md={12} sm={12} xl={9}>
                <Card variant="outlined" sx={{ height: '100%' }}>
                  <CardContent
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <CardTitle title="Order Details" />

                    <DetailRow label="Deliver To:" testId={`consignee-name`}>
                      {data?.consigneeName}
                    </DetailRow>

                    <DetailRow label="Address:" testId={`consignee-address`}>
                      {data?.consigneeAddress}
                    </DetailRow>

                    <DetailRow
                      label="Order Number:"
                      testId={`customer-order-no`}
                    >
                      {data?.customerOrderNo}
                    </DetailRow>

                    <DetailRow label="Bill Of Lading:" testId={`bol-number`}>
                      {data?.bolNumber}
                    </DetailRow>

                    <DetailRow label="Ship Date:" testId={`ship-date`}>
                      {friendlyDate(data?.shipDate ?? '')}
                    </DetailRow>

                    <DetailRow label="Status:" testId={`bol-status`}>
                      {BOL_LABELS[data?.status]}
                    </DetailRow>

                    <DetailRow label="Consignor:" testId={`consignor`}>
                      {data?.consignorName}
                    </DetailRow>

                    <DetailRow label="Carrier:" testId={`carrier`}>
                      {data?.carrier}
                    </DetailRow>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} lg={8} md={12} sm={12} xl={9}>
                <Card variant="outlined" sx={{ height: '100%' }}>
                  <CardContent
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <CardTitle title="Products" />

                    <Box sx={{ minHeight: '20vh' }}>
                      <StandardDataGrid
                        testId="data-grid-productsbol-table"
                        rows={data?.cartonsByProduct || []}
                        columns={cartonColumns}
                        getRowId={(row) => row.productId}
                        disableRowSelectionOnClick={true}
                        noRowsMessage="No products found"
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} lg={8} md={12} sm={12} xl={9}>
                <Card variant="outlined" sx={{ height: '100%' }}>
                  <CardContent
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <CardTitle title="Containers" />

                    <Box sx={{ minHeight: '20vh' }}>
                      <StandardDataGrid
                        testId="data-grid-containers-table"
                        rows={data?.containers || []}
                        columns={containerColumns}
                        getRowId={(row) => row.containerId}
                        disableRowSelectionOnClick={true}
                        noRowsMessage="No containers found"
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </MobilePage>
  );
}

import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../Providers/AuthProvider';
import {
  ApiOptions,
  getHttpErrorMessage,
  getHttpErrorCode,
  HttpError,
  ServiceGetMethod,
} from '../Services/Api';

/**
 * useApiGet is a custom React hook to fetch data from a standardised serviceMethod
 *
 * * The returned objects are strongly typed based on the inferred type of the serviceMethod
 * * The options parameter extends the axios parameters so can take additional url parameters, http headers, etc.
 * * Errors are automatically displayed in a Toast, unless options.suppressError = true
 */

export interface UseApiGetOptions<TRequest, TResponse>
  extends ApiOptions<TRequest> {
  noAutoFetch?: boolean;
  noFetchOnParameterUpdate?: boolean;
  // auto refresh the data at the same frequency as the server refreshes from Basis, or a custom interval
  autoRefreshTrueOrSecs?: true | number;
  cache?: boolean;
  onError?: (
    message: string,
    e: HttpError,
    errorCode: number | undefined
  ) => void;
  onSuccess?: (r: TResponse, req: TRequest) => void;
}

export interface UseApiGetResult<TResponse> {
  data: TResponse | undefined;
  loading: boolean;
  error: HttpError | undefined;
  refresh: (options?: { clear: boolean }) => void;
}

export default function <TRequest, TResponse>(
  serviceMethod: ServiceGetMethod<TRequest, TResponse>,
  // paramsOrFn?: ApiParameters | (() => ApiParameters),
  options?: UseApiGetOptions<TRequest, TResponse>
): UseApiGetResult<TResponse> {
  const { application } = useContext(AuthContext);
  const [loading, setLoading] = useState(!options?.noAutoFetch);
  const [data, setData] = useState<TResponse | undefined>(undefined);
  const [error, setError] = useState<HttpError | undefined>(undefined);
  const [autoRefreshIntervalHandle, setAutoRefreshIntervalHandle] =
    useState<NodeJS.Timer | null>(null);
  const abortController = useRef<AbortController>();

  const [refreshCounter, setRefreshCounter] = useState(
    options?.noAutoFetch ? 0 : 1
  );

  useEffect(() => {
    if (!refreshCounter) {
      return;
    }
    const asyncFn = async () => {
      setLoading(true);
      abortController.current?.abort();
      abortController.current = new AbortController();
      const [response, error] = await serviceMethod({
        signal: abortController.current.signal,
        ...(options || {}),
      });
      setLoading(false);
      setData(response);
      setError(error);
      if (error) {
        options?.onError?.(
          getHttpErrorMessage(error),
          error,
          getHttpErrorCode(error)
        );
      } else {
        options?.onSuccess?.(response as TResponse, options.params!);
      }
    };
    asyncFn();
    // don't add options has a dependency because it will likely be a fresh object literal on each re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceMethod, setLoading, refreshCounter]);

  // if authRefreshTrueOrSecs is set, set a timeout to increment the refreshCounter
  useEffect(() => {
    if (options?.autoRefreshTrueOrSecs) {
      // if autoRefreshTrueOrSecs === true, use the same sync interval as the server
      const autoRefreshMs =
        options.autoRefreshTrueOrSecs === true
          ? // if BasisSync has been disabled on the server, default to 10 min refresh instead of infinite loop!
            (application!.basisSyncPeriodMins || 10) * 60 * 1000
          : options.autoRefreshTrueOrSecs * 1000;
      const handle = setTimeout(
        () => setRefreshCounter(refreshCounter + 1),
        autoRefreshMs
      );
      setAutoRefreshIntervalHandle(handle);
    }
    // tear down the old timeout when refreshCounter changes or the component dismounts
    return () => {
      if (autoRefreshIntervalHandle) {
        clearInterval(autoRefreshIntervalHandle);
        setAutoRefreshIntervalHandle(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.autoRefreshTrueOrSecs, refreshCounter]);

  const refresh = useCallback(
    (options?: { clear?: boolean }) => {
      // if options.clear is true, clear the current data so the parent can show
      // the Skeleton/progress bar instead of stale data, if they want to
      if (options?.clear) {
        setData(undefined);
      }
      setRefreshCounter(refreshCounter + 1);
    },
    [setData, refreshCounter, setRefreshCounter]
  );

  // clear and refresh if props change
  if (!options?.noFetchOnParameterUpdate) {
    const newParamJson = JSON.stringify(options?.params || {});
    const [paramJson, setParamJson] = useState<string>(newParamJson);
    if (newParamJson != paramJson) {
      setParamJson(newParamJson);
      refresh({ clear: true });
    }
  }

  return {
    loading,
    data,
    error,
    refresh,
  };
}

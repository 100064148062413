import Api, {
  ServicePostMethod,
  ServiceDeleteMethod,
  ServiceGetMethod,
} from './Api';
import { ECert } from '../Models/BOLContainer';

const createECertForContainers: ServicePostMethod<
  { containerIds: number[] },
  ECert
> = (data, options) =>
  Api.post(`api/services/app/ECert/CreateECertForContainers`, data, options);

const deleteECert: ServiceDeleteMethod = (id, options) =>
  Api.deleteById(`api/services/app/ECert/DeleteECert?eCertId=${id}`, options);

const createOrUpdateRemark: ServicePostMethod<
  CreateOrUpdateRemarkRequest,
  ECert
> = (data, options) =>
  Api.post(`api/services/app/ECert/CreateOrUpdateRemark`, data, options);

const deleteRemark: ServiceDeleteMethod = (id, options) =>
  Api.deleteById(`api/services/app/ECert/DeleteRemark?RemarkId=${id}`, options);

const getECertsForBol: ServiceGetMethod<
  { bolNumber: string | number },
  ECert[]
> = (options) => Api.get('api/services/app/ECert/GetECertsForBol', options);
const getECertsDetails: ServiceGetMethod<
  { certificatePublicId: string },
  ECert
> = (options) => Api.get('api/services/app/ECert/GetECertDetails', options);

const submitECert: ServicePostMethod<{ certificatePublicId: string }, ECert> = (
  data,
  options
) => Api.post(`api/services/app/ECert/SubmitECert`, data, options);

export default {
  createECertForContainers,
  deleteECert,
  createOrUpdateRemark,
  deleteRemark,
  getECertsForBol,
  getECertsDetails,
  submitECert,
};

export interface CreateOrUpdateRemarkRequest {
  eCertPublicId: string;
  RemarkId?: number | null;
  RemarkValue: string;
  RemarkType: string;
}

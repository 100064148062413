import { faker } from '@faker-js/faker';
import { Factory } from 'fishery';
import { UserNameDto } from './User';

export interface AdminReviewEntity {
  rowKey: string;
  entityId: number;
  entityNumber: string; // ASN Number or BolNumber
  entityType: AdminReviewEntityType;
  code?: string; // Container code
  customerName?: string;
  consigneeName?: string;
  customerOrderNumber?: string; // ASN.CustomerOrderNumber or container.customerOrderNo
  admin?: UserNameDto; // Customer.Admin
  completedDate?: string; // most recent dateIn from cartons related to the ASN or loadingCompletedDate of Container
}

export enum AdminReviewEntityType {
  ASN = 'asn',
  CONTAINER = 'container',
}

export const adminReviewEntityFactory = Factory.define<AdminReviewEntity>(
  () => {
    const randomIndex = Math.floor(Math.random() * 2);
    const entityType =
      randomIndex > 0
        ? AdminReviewEntityType.CONTAINER
        : AdminReviewEntityType.ASN;
    const entityId = faker.datatype.number();
    const rowKey = `${entityType}-${entityId}`;
    return {
      rowKey: rowKey,
      entityId: entityId,
      entityType: entityType,
      entityNumber: faker.random.alphaNumeric(10),
      code:
        entityType === AdminReviewEntityType.CONTAINER
          ? faker.datatype.hexadecimal({ length: 10 })
          : '',
      customerName: faker.company.name(),
      customerOrderNumber:
        entityType === AdminReviewEntityType.CONTAINER
          ? faker.datatype.string()
          : '',
      consigneeName: faker.company.name(),
      adminName: faker.name.fullName(),
      loadingCompletedDate: faker.datatype.datetime().toISOString(),
    };
  }
);

export const adminReviewEntityASNFactory = Factory.define<AdminReviewEntity>(
  () => {
    return {
      ...adminReviewEntityFactory.build(),
      entityType: AdminReviewEntityType.ASN,
      code: '',
      customerOrderNumber: '',
    };
  }
);

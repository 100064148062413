import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import useApiForm from '../../Hooks/useApiForm';
import PalletService from '../../Services/PalletService';
import MobilePage from '../../Components/MobilePage';
import BarcodeScannerFormInput, {
  BarcodeResult,
} from '../../Components/Forms/BarcodeScannerFormInput';
import SortedTable, { SortedTableColumn } from '../../Components/SortedTable';
import { tryFormatDateStr } from '../../Lib/utils';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';
import { LocationEnquiry } from '../../Models/LocationEnquiry';
import { useNavigate, useParams } from 'react-router-dom';
import usePermissions from '../../Hooks/usePermissions';

const nbDigitsDisplayedForPalletBarCode = 10;

// renders the last nbChars digits of a barcode
export const getShortenBarcode = (barcode: string, nbChars: number) => {
  return barcode.replace(/ /g, '').slice(-nbChars);
};

export default function () {
  const { location: locationParam } = useParams<{ location: string }>();

  const [locationEnquiries, setLocationEnquiry] = useState<
    LocationEnquiry[] | null
  >(null);
  const [location, setLocation] = useState('');
  const { mobileView } = useContext(ResponsiveContext);
  const navigate = useNavigate();
  const getLocationEnquiryForm = useApiForm(
    PalletService.locationEnquiry,
    {
      location: '',
    },
    {
      onError: (message) => {
        getLocationEnquiryForm.setErrors({ location: message });
        setLocationEnquiry(null);
      },
      onSuccess: (result) => {
        setLocationEnquiry(result);
        resetLocationEnquiryForm();
      },
      useSearchParams: true,
    }
  );
  useEffect(() => {
    if (locationParam != undefined) {
      getLocationEnquiryForm.setData('location', locationParam);
      setLocation(locationParam);
      getLocationEnquiryForm.submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationParam]);

  const onLocationEnquiryScan = (event: BarcodeResult) => {
    setLocation(event.code);
    // if scanning and the current barcode field has focus we want it to lose focus for the second scan
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    getLocationEnquiryForm.submit();
  };

  function resetLocationEnquiryForm() {
    getLocationEnquiryForm.setData('location', '');
  }

  const [canSearchPallet] = usePermissions(['BOL.Load', 'BOL.Pallet.Search']);
  const canNavigateToSearchPallet = !mobileView && canSearchPallet;

  const columnDefs: SortedTableColumn<LocationEnquiry>[] = [
    {
      id: 'code',
      label: 'Pallet',
      sortable: true,
      cellRender: (li) => (
        <Button href={`/barcode-search?code=${li.code}`}>
          {!mobileView
            ? li.code
            : li.code.length > nbDigitsDisplayedForPalletBarCode
            ? '...' +
              getShortenBarcode(li.code, nbDigitsDisplayedForPalletBarCode)
            : li.code}
        </Button>
      ),
    },
    {
      id: 'productCode',
      label: !mobileView ? 'Product Code' : 'Product',
      sortable: true,
      value: (li) =>
        li.productCode == 'Mixed Product' && mobileView
          ? 'Mixed'
          : li.productCode,
    },
    {
      id: 'batch',
      label: 'Batch',
      sortable: true,
    },
    {
      id: 'quantity',
      label: 'Quantity',
      sortable: true,
      show: !mobileView,
    },
    {
      id: 'depth',
      label: 'Depth',
      sortable: true,
    },
    {
      id: 'locatedDateTime',
      label: 'Located DateTime',
      sortable: true,
      value: (li) => tryFormatDateStr(li.locatedDateTime?.toString()),
      show: !mobileView,
    },
  ];

  return (
    <MobilePage showOnDesktop>
      <Stack spacing={1}>
        <Box component="form" noValidate>
          <Typography variant="subtitle2" textAlign="center">
            Scan Store Location:
          </Typography>
          <BarcodeScannerFormInput
            label="Store Location Barcode"
            id="location"
            form={getLocationEnquiryForm}
            onBarcode={onLocationEnquiryScan}
            onClear={() => resetLocationEnquiryForm()}
          />
        </Box>
        {locationEnquiries && (
          <Box component="form" noValidate>
            <SortedTable
              columnDefs={columnDefs}
              data={locationEnquiries}
              rowKey="code"
              title={`Location Scanned (${location.toUpperCase()})`}
              onRowClick={(li) =>
                canNavigateToSearchPallet
                  ? navigate(`/barcode-search?code=${li.prefix + li.code}`)
                  : undefined
              }
            />
          </Box>
        )}
      </Stack>
    </MobilePage>
  );
}

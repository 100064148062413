import { Button, ButtonProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface CountdownButtonProps extends ButtonProps {
  countdownSecs?: number;
  title?: string;
}

export const CountdownButton: React.FC<CountdownButtonProps> = ({
  countdownSecs = 0,
  title = '',
  ...props
}) => {
  const [countdown, setCountdown] = useState<number>(countdownSecs);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Button {...props} disabled={props.disabled || countdown > 0}>
      {title}
      {countdown > 0 ? ` (${countdown})` : ''}
    </Button>
  );
};

import { Factory } from 'fishery';
import { faker } from '@faker-js/faker';
import { ChecklistItem, ChecklistItemGroup } from './Checklist';

export interface ChecklistItemResponse {
  id: number;
  response: string;
  entityType: string;
  entityId: number;
  comment: string;
  checklistItemId: number;
}

export const getFileMetadata = (
  response: ChecklistItemResponse,
  checklistItem: ChecklistItem,
  checklistItemGroup: ChecklistItemGroup
) => {
  // use the name of the group when the group as a single item (ie: Exterior / Interior check)
  const label =
    checklistItemGroup.checklistItems.length === 1
      ? checklistItemGroup.name
      : checklistItem.text;

  return {
    label,
    value: response.response,
  };
};

export const checklistItemResponseFactory =
  Factory.define<ChecklistItemResponse>(() => ({
    id: faker.datatype.number(),
    response: faker.datatype.string(),
    entityType: faker.datatype.string(),
    entityId: faker.datatype.number(),
    comment: faker.datatype.string(),
    checklistItemId: faker.datatype.number(),
  }));

import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  BOLContainer,
  BOLContainerStatus,
  isContainerClosed,
} from '../../Models/BOLContainer';
import {
  BillOfLadingWithCountsAndPalletDetails,
  BolStatus,
  BolStatusChip,
} from '../../Models/BillOfLading';
import {
  CheckBox,
  CheckBoxOutlineBlankTwoTone,
  FileCopy,
} from '@mui/icons-material';
import { formatNumber, tryFormatDateStr } from '../../Lib/utils';
import useApiForm from '../../Hooks/useApiForm';
import PalletService from '../../Services/PalletService';
import {
  Pallet,
  PALLET_LOADED_TYPE_LABELS,
  PalletDetails,
} from '../../Models/Pallet';
import usePermissions from '../../Hooks/usePermissions';
import PalletDetailsDialog from './PalletDetailsDialog';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';
import { useNavigate } from 'react-router-dom';
import {
  alertModalDialog,
  confirmModalDialog,
} from '../../Components/ModalDialog';
import SortedTable, {
  SortedTableColumn,
  SortedTableSortOpts,
} from '../../Components/SortedTable';
import BarcodeScannerFormInput, {
  BarcodeResult,
} from '../../Components/Forms/BarcodeScannerFormInput';
import useUnloadSelectedPallets from './Hooks/useUnloadSelectedPallets';
import { toast } from '../../Components/Toast';
import BolContainerService from '../../Services/BolContainerService';
import BillOfLadingService from '../../Services/BillOfLadingService';

/**
 * This is the page where an Operator can see the details of a Container and start scanning Pallets
 */

export interface BolOperatorLoadContainerProps {
  bol: BillOfLadingWithCountsAndPalletDetails;
  container: BOLContainer;
  refresh: () => void;
}

export default function ({
  bol,
  container,
  refresh,
}: BolOperatorLoadContainerProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { mobileView } = useContext(ResponsiveContext);
  const [canUnloadPallet] = usePermissions(['BOL.Pallet.Unload']);

  const [palletScanned, setPalletScanned] = useState<Pallet | null>(null);
  const [haveJustLoadedPallet, setHaveJustLoadedPallet] = useState(false);
  const [usedScanner, setUsedScanner] = useState(false);

  const {
    selectedPalletIdsRef,
    onUnloadBtnClickHandler,
    onUnloadPalletCheckboxChangeHandler,
  } = useUnloadSelectedPallets(container.id, refresh);

  const onCloseModal = ({ shouldRefresh = false } = {}) => {
    setPalletScanned(null);
    if (shouldRefresh) {
      setHaveJustLoadedPallet(true);
      refresh();
    }
  };
  const [canLoadBol, canSearchPallet, canUpdateContainerStatus] =
    usePermissions([
      'BOL.Load',
      'BOL.Pallet.Search',
      'Container.Status.Update',
    ]);
  const canNavigateToSearchPallet = !mobileView && canSearchPallet;

  const containerPallets = useMemo(() => {
    return (bol.pallets || []).filter(
      (p) =>
        p.containerCode == container.code ||
        p.plannedContainerCode == container.code
    );
  }, [container, bol]);

  const hardAssignPalletIds = useMemo(() => {
    return (bol.pallets || [])
      .filter((p) => {
        const filterCartons = p.cartons?.filter(
          (c) => c.bolContainerId == container.id
        );
        // Check all cartons have BOLContainerId matched to current container
        return (
          p.bolContainerId == container.id ||
          (filterCartons &&
            filterCartons.length > 0 &&
            filterCartons.length == (p.cartons?.length ?? 0))
        );
      })
      .map((p) => p.id);
  }, [container, bol]);

  const isAllPalletsLoaded =
    containerPallets.length > 0 &&
    containerPallets.filter((c) => c.loadedDateTime != null).length ===
      containerPallets.length;

  const form = useApiForm(
    PalletService.getByCode,
    {
      code: '',
      containerCode: container.code,
      billOfLadingId: bol.id,
      syncFromBasis: true,
      performPalletOnHandCheck: false,
    },
    {
      onError: (message) => {
        form.setErrors({ code: message });
      },
      onSuccess: (pallet) => {
        setPalletScanned(pallet);
        form.setData('code', '');
        refresh();
      },
      suppressError: true,
      enableFormUpdatesWhenFocussed: true,
    }
  );

  // If the screen is in mobileView, default sorting is checkbox and asc
  // otherwise default sorting is loadedDateTime and asc
  const defaultSort: SortedTableSortOpts = {
    id: mobileView ? 'checkbox' : 'loadedDateTime',
    dir: 'asc',
  };

  const fetchPallet = (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    // look for the pallet in containerPallets and fetch it from the server if not found
    const pallet = containerPallets.find(
      (pallet) =>
        pallet.code === form.data.code ||
        pallet.barcode === form.data.code ||
        pallet.linkedBarcode === form.data.code
    );
    if (pallet) {
      setPalletScanned(pallet);
      form.setData('code', '');
    } else {
      form.submit();
    }
  };

  // if we've just loaded a Pallet and now all the Pallets are loaded, notify the Operator
  useEffect(() => {
    if (
      container.loadedPallets === container.totalPallets &&
      haveJustLoadedPallet
    ) {
      setHaveJustLoadedPallet(false);
      confirmModalDialog({
        title: 'Pallets loaded',
        content: `${container.loadedPallets} / ${container.totalPallets} pallets have been loaded`,
      });
    }
  }, [
    setHaveJustLoadedPallet,
    haveJustLoadedPallet,
    container.loadedPallets,
    container.totalPallets,
  ]);

  const columnDefs = useMemo<SortedTableColumn<PalletDetails>[]>(
    () => [
      {
        id: 'code',
        label: 'Pallet',
        sortable: true,
        cellProps: {
          className: 'pallet-table--pallet-code',
        },
        cellRender: (palletDetails) => {
          const barcode = palletDetails.prefix + palletDetails.code;
          return (
            <>
              <Link
                sx={{
                  cursor: 'pointer',
                  color: theme.palette.primary.main,
                }}
                onClick={() =>
                  canNavigateToSearchPallet
                    ? navigate(`/barcode-search?code=${barcode}`)
                    : undefined
                }
              >
                {barcode}
              </Link>
              <IconButton
                onClick={() => {
                  navigator.clipboard
                    .writeText(barcode)
                    .then(() => toast('Barcode copied'));
                }}
              >
                <FileCopy sx={{ fontSize: '12px' }} />
              </IconButton>
            </>
          );
        },
      },
      {
        id: 'location',
        label: 'Location',
        sortable: true,
        value: (palletDetails) => palletDetails.location,
      },
      {
        id: 'quantity',
        label: 'Quantity',
        sortable: true,
        show: !mobileView,
        numeric: true,
        value: (palletDetails) =>
          palletDetails.contents.reduce((sum, row) => sum + row.totalQty, 0),
      },
      {
        id: 'weight',
        label: 'Weight',
        numeric: true,
        sortable: true,
        show: !mobileView,
        value: (palletDetails) =>
          palletDetails.contents.reduce((sum, row) => sum + row.weight, 0),
      },
      {
        id: 'productCode',
        label: 'Product Code',
        sortable: true,
        show: !mobileView,
        value: (palletDetails) =>
          Array.from(
            new Set(palletDetails.contents.map((c) => c.productCode))
          ).join(','),
      },
      {
        id: 'productDescription',
        label: 'Product Description',
        sortable: true,
        show: !mobileView,
        value: (palletDetails) =>
          Array.from(
            new Set(palletDetails.contents.map((c) => c.productDescription))
          ).join(','),
      },
      {
        id: 'batchNumber',
        label: 'Batch',
        sortable: true,
        show: !mobileView,
        value: (palletDetails) =>
          Array.from(
            new Set(palletDetails.contents.map((c) => c.batchNumber))
          ).join(', '),
      },
      {
        id: 'loadedUsername',
        label: 'Loaded By',
        sortable: true,
        show: !mobileView,
        value: (palletDetails) => palletDetails.loadedUsername,
      },
      {
        id: 'loadedType',
        label: 'Loaded Type',
        sortable: true,
        show: !mobileView,
        value: (palletDetails) =>
          palletDetails.loadedType != null
            ? PALLET_LOADED_TYPE_LABELS[palletDetails.loadedType]
            : '',
      },
      {
        id: 'loadedDateTime',
        label: 'Loaded Date',
        sortable: true,
        show: !mobileView,
        value: (palletDetails) =>
          tryFormatDateStr(palletDetails.loadedDateTime),
        sortValue: (palletDetails) => palletDetails.loadedDateTime,
      },
      {
        id: 'checkbox',
        exportable: false,
        sortable: true,
        label: 'Loaded',
        cellProps: { width: '11em', align: 'center' },
        sortValue: (palletDetails) => palletDetails.loadedDateTime,
        cellRender: (palletDetails) => (
          <>
            {palletDetails.loadedDateTime ? (
              <CheckBox color="primary" />
            ) : (
              <CheckBoxOutlineBlankTwoTone />
            )}
          </>
        ),
      },
      {
        id: 'checkbox-unload',
        exportable: false,
        sortable: true,
        show: !mobileView && canUnloadPallet,
        label: 'Unload',
        cellProps: { width: '11em', align: 'center' },
        sortValue: (palletDetails) => palletDetails.loadedDateTime,
        cellRender: (palletDetails) => (
          <Checkbox
            data-testid={'unload-checkbox-' + palletDetails.id}
            checked={selectedPalletIdsRef.current.includes(palletDetails.id)}
            aria-checked={selectedPalletIdsRef.current.includes(
              palletDetails.id
            )}
            disabled={container.status === BOLContainerStatus.invoiced}
            onChange={() =>
              onUnloadPalletCheckboxChangeHandler(palletDetails.id)
            }
          />
        ),
      },
    ],
    [
      mobileView,
      canNavigateToSearchPallet,
      theme,
      selectedPalletIdsRef,
      navigate,
      onUnloadPalletCheckboxChangeHandler,
      container.status,
      canUnloadPallet,
    ]
  );
  const rowClassName = (data: PalletDetails) => {
    // if any pallet id is not in softAssignedIds, just highlight the row to gray
    return hardAssignPalletIds.indexOf(data.id) < 0
      ? 'pallet-table--soft-assign'
      : '';
  };
  const unloadPalletsHandler = () => {
    return confirmModalDialog({
      title: '',
      acceptButtonLabel: 'OK',
      declineButtonLabel: 'Cancel',
      acceptButtonCountdown: 3,
      declineButtonCountdown: 0,
      content: (
        <Box textAlign={'center'}>
          <h3>{`Are you sure you want to unload ${selectedPalletIdsRef.current.length} pallets from BOL: ${bol.bolNumber}/${container.code}?`}</h3>
        </Box>
      ),
      onAccept: () => {
        onUnloadBtnClickHandler();
      },
    });
  };
  const processCloseContainer = async () => {
    const [checkedBol, error] = await BillOfLadingService.toleranceCheckForBol({
      params: {
        billOfLadingId: bol.id,
      },
    });
    if (!error && checkedBol) {
      if (checkedBol.toleranceFail) {
        // Check if there is any Open container
        const containers = bol.containers ?? [];
        const openContainers = containers.filter(
          (c) => c.id != container.id && !isContainerClosed(c.status)
        );
        if (openContainers.length > 0) {
          const confirmed = await confirmModalDialog({
            title: 'Warning',
            acceptButtonLabel: 'Yes',
            severity: 'warning',
            content: (
              <Box textAlign={'center'}>
                <h3>
                  There is not enough stock for this BOL. Are you sure you want
                  to close this container?
                </h3>
              </Box>
            ),
          });
          if (confirmed) {
            await closeContainer();
          }
        } else {
          // Do not allow to close container
          alertModalDialog({
            severity: 'error',
            title: 'Error',
            content:
              'Cannot close this container. There is not enough stock for this BOL.',
          });
          return;
        }
      } else {
        await closeContainer();
      }
    }
  };
  const closeContainer = async () => {
    const [bolContainer, error] = await BolContainerService.closeContainer({
      bolContainerId: container.id,
    });
    if (!error && bolContainer) {
      refresh();
    }
  };

  useEffect(() => {
    /*
     * In mobile view, when user lands on Load tab,
     * blur the active element (tab) so scanner can fill in the input field.
     * */
    if (mobileView) {
      document.activeElement instanceof HTMLElement &&
        document.activeElement.blur();
    }
  }, [mobileView]);

  return (
    <Stack spacing={1} width={'100%'}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" data-testid="bol-number">
          {bol.bolNumber}
        </Typography>
        {!mobileView && bol.status === BolStatus.loaded && (
          <Box data-testid={'bol-status'}>
            <BolStatusChip status={bol.status} sx={{ margin: 'inherit' }} />
          </Box>
        )}
        {mobileView && canUpdateContainerStatus && (
          <Button
            data-testid={'close-container-btn'}
            sx={{ height: '42px', whiteSpace: 'nowrap' }}
            variant="contained"
            disabled={
              !isAllPalletsLoaded ||
              container.status !== BOLContainerStatus.loading ||
              (container.loadingCompletedDate ?? '').length > 0
            }
            onClick={() => processCloseContainer()}
          >
            Close Container
          </Button>
        )}
      </Stack>
      <Typography variant="subtitle1">{bol.customerName}</Typography>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="h6">Container/Truck:</Typography>
        <Typography
          variant="h6"
          flexGrow={1}
          textAlign="center"
          color="info.light"
          data-testid="container-code"
          sx={{
            border: '1px solid ' + theme.palette.info.light,
            borderRadius: '4px',
            p: '4px',
          }}
        >
          {container.code}
        </Typography>
        {!mobileView && canUpdateContainerStatus && (
          <Button
            data-testid={'close-container-btn'}
            sx={{ height: '42px' }}
            variant="contained"
            disabled={
              !isAllPalletsLoaded ||
              container.status !== BOLContainerStatus.loading ||
              (container.loadingCompletedDate ?? '').length > 0
            }
            onClick={() => processCloseContainer()}
          >
            Close Container
          </Button>
        )}
      </Stack>

      <Stack gap={0} px={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Picking</Typography>
          <Typography variant="subtitle1" data-testid="picking-progress-counts">
            {container.palletsInPickedLocation} / {container.totalPallets}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Pallets</Typography>
          <Typography variant="subtitle1" data-testid="pallet-counts">
            {container.loadedPallets} / {container.totalPallets}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Cartons</Typography>
          <Typography variant="subtitle1" data-testid="carton-counts">
            {container.loadedCartons} / {container.totalCartons}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Weight</Typography>
          <Typography variant="subtitle1" data-testid="weights">
            {formatNumber(container.loadedWeight || 0, 2)} /{' '}
            {formatNumber(container.totalWeight || 0, 2)} kg
          </Typography>
        </Stack>
      </Stack>

      {canLoadBol && container.loadingCompletedDate == null && (
        <Box component="form" onSubmit={fetchPallet} noValidate sx={{ mt: 1 }}>
          <Typography variant="subtitle2" textAlign="center">
            Scan to load pallet or fill in the details below:
          </Typography>

          <BarcodeScannerFormInput
            label="Pallet code"
            id="code"
            form={form}
            required
            onBarcode={(barcodeResult: BarcodeResult) => {
              setUsedScanner(barcodeResult.entryMethod == 'scanner');
              fetchPallet();
            }}
          />
        </Box>
      )}

      <Box
        sx={{
          width: '100%',
          paddingTop: !mobileView ? '2em' : undefined,
          '& th': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
          },
          '& th .MuiTableSortLabel-root, .MuiTableSortLabel-icon': {
            color: theme.palette.primary.contrastText + ' !important',
          },
          '& .pallet-table--pallet-code': {
            textDecoration: 'underline',
            maxWidth: mobileView ? '120px !important' : '100%',
            wordWrap: 'break-word',
          },
          '& .pallet-table--soft-assign': {
            backgroundColor: '#d3d3d3',
          },
        }}
      >
        <SortedTable
          testId="pallets-grid"
          columnDefs={columnDefs}
          data={containerPallets}
          exportable={!mobileView}
          exportFileName={() => `${bol.bolNumber}_${container.code}`}
          sortOpts={defaultSort}
          smallFont
          smallGap
          rowKey="id"
          actionBtnTitle={canUnloadPallet ? 'Unload' : undefined}
          actionBtnIsDisabled={
            selectedPalletIdsRef.current.length === 0 ||
            container.status === BOLContainerStatus.invoiced
          }
          onActionBtnClick={unloadPalletsHandler}
          getRowClassName={rowClassName}
        />
      </Box>
      {palletScanned && (
        <PalletDetailsDialog
          pallet={palletScanned}
          currentBolId={bol.id}
          currentContainerId={container.id}
          onClose={onCloseModal}
          usedScanner={usedScanner}
        />
      )}
    </Stack>
  );
}

import Api, { ServicePostMethod } from './Api';

const printAddressLabels: ServicePostMethod<PrintAddressLabelsRquest, void> = (
  data,
  options
) =>
  Api.post(`api/services/app/LabelPrinting/PrintAddressLabels`, data, options);

interface PrintAddressLabelsRquest {
  bolId: number;
  printerCode: string;
  qty: number;
}

export default {
  printAddressLabels,
};

import { Box, CircularProgress } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useApiGet from '../../Hooks/useApiGet';
import BillOfLadingService from '../../Services/BillOfLadingService';
import MobilePage from '../../Components/MobilePage';
import BolLoad from './BolLoad';
import { AuthContext } from '../../Providers/AuthProvider';
import { BolStatus } from '../../Models/BillOfLading';
import usePermissions from '../../Hooks/usePermissions';

export default function () {
  const { bolNumber } = useParams<{ bolNumber: string }>();
  const [canLoadBol] = usePermissions(['BOL.Load']);
  const { user } = useContext(AuthContext);
  const {
    data: bol,
    loading,
    refresh,
  } = useApiGet(BillOfLadingService.getFullByBolNumber, {
    params: {
      bolNumber: bolNumber!,
      // operators can only view BoL in their current warehouse - Admins can see all
      statuses: canLoadBol
        ? [BolStatus.readyToLoad, BolStatus.loading, BolStatus.loaded]
        : [],
      warehouseId: user?.currentWarehouseId,
    },
    noAutoFetch: true,
    autoRefreshTrueOrSecs: true,
  });

  // reload the BOL when the route changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refresh, [bolNumber]);

  return loading && !bol ? (
    <MobilePage>
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    </MobilePage>
  ) : !bol ? (
    <MobilePage>
      <Box>
        Cannot find Bill of Lading in this Warehouse or with the correct Status
      </Box>
    </MobilePage>
  ) : (
    <BolLoad bol={bol} refresh={refresh} />
  );
}

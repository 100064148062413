import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { getAppBarColour } from './Lib/utils';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      // main: '#556cd6', // MUI default
      main: '#2374ab', // Coolpak homepage buttons
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },

  components: {
    // https://stackoverflow.com/questions/54236623/cant-remove-padding-bottom-from-card-content-in-material-ui
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        div: {
          '& .data-grid-opacity-half': {
            opacity: 0.5,
          },
          '& .data-grid-opacity-full': {
            opacity: 1,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: getAppBarColour(),
        },
      },
    },
  },
});

export default theme;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import MobilePage from '../../Components/MobilePage';
import usePermissions from '../../Hooks/usePermissions';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  ListItem,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import useApiForm from '../../Hooks/useApiForm';
import useApiGet from '../../Hooks/useApiGet';
import CustomerService from '../../Services/CustomerService';
import {
  CustomerSelectOption,
  customerSelectItems,
} from '../../Models/Customer';
import FormField, { SelectItem } from '../../Components/Forms/FormField';
import PalletStatusService from '../../Services/PalletStatusService';
import {
  PalletStatus,
  palletStatusSelectItems,
} from '../../Models/PalletStatus';
import { StockOnHandCarton } from '../../Models/Carton';
import { tryFormatDateStr } from '../../Lib/utils';
import CartonService from '../../Services/CartonService';
import { AuthContext } from '../../Providers/AuthProvider';
import { confirmModalDialog } from '../../Components/ModalDialog';
import ProductService from '../../Services/ProductService';
import { Product, productSelectItems } from '../../Models/Product';
import List from '@mui/material/List';
import StandardDataGrid from '../../Components/StandardDataGrid';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-premium';
import CardTitle from '../../Components/CardTitle';
import AuditLog from '../../Components/AuditLog/AuditLog';
import useWarehouses from '../../Hooks/useWarehouses';

const ConfirmationListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'list-item',
  paddingLeft: 0,
}));

export default function () {
  const { user } = useContext(AuthContext);
  const { getWarehouseName } = useWarehouses();
  const [canUpdateCartonInfo] = usePermissions(['Carton.UpdateHoldStatus']);
  const [loading, setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<SelectItem | null>(
    null
  );
  const [cartons, setCartons] = useState<StockOnHandCarton[] | undefined>(
    undefined
  );
  const [selectedCartonIds, setSelectedCartonIds] = useState<number[]>([]);
  const [forceRefreshCartons, setForceRefreshCartons] = useState(false);

  const [clearCartonCheckboxRowSelection, setClearCartonCheckboxRowSelection] =
    useState(false);

  const inputForm = useApiForm(null, {
    ownerId: null as number | null,
    productId: null as number | null,
    holdStatusId: null as number | null,
    holdStatusCode: null as string | null,
    batchNumber: null as string | null,
  });
  const changeCartonInfoForm = useApiForm(
    CartonService.changeCartonsInfo,
    {
      cartonIds: [] as number[],
      batchNumber: inputForm.data.batchNumber || '',
      holdStatusId: inputForm.data.holdStatusId || 0,
      ownerId: inputForm.data.ownerId || 0,
      productId: inputForm.data.productId || 0,
    },
    {
      onSuccess: () => {
        setSelectedCartonIds([]);
        inputForm.reset();
        changeCartonInfoForm.reset();
        setForceRefreshCartons(true);
        setClearCartonCheckboxRowSelection(true);
      },
    }
  );

  // The count of Cartons per Pallet
  const cartonsByPalletId = useMemo(() => {
    const countByPalletId: Record<number, number> = {};
    if (!cartons) {
      return countByPalletId;
    }

    cartons.forEach((carton) => {
      if (countByPalletId[carton.palletId]) {
        countByPalletId[carton.palletId]++;
      } else {
        countByPalletId[carton.palletId] = 1;
      }
    });

    return countByPalletId;
  }, [cartons]);

  // The count of selected Cartons per Pallet
  const selectedCartonsByPalletId = useMemo(() => {
    const countByPalletId: Record<number, number> = {};
    if (!cartons) {
      return countByPalletId;
    }

    cartons
      .filter((carton) => selectedCartonIds.includes(carton.id))
      .forEach((carton) => {
        if (countByPalletId[carton.palletId]) {
          countByPalletId[carton.palletId]++;
        } else {
          countByPalletId[carton.palletId] = 1;
        }
      });

    return countByPalletId;
  }, [cartons, selectedCartonIds]);

  // Check if all Cartons per Pallet are selected
  const allPalletCartonsSelected = useMemo(() => {
    for (const palletId in selectedCartonsByPalletId) {
      if (cartonsByPalletId[palletId] !== selectedCartonsByPalletId[palletId]) {
        return false;
      }
    }
    return true;
  }, [cartonsByPalletId, selectedCartonsByPalletId]);

  /**
   * Populate data for dropdown boxes: Customers, Products, Hold Status and Batch
   */
  const { data: products, loading: loadingProducts } = useApiGet(
    ProductService.getAllProductNames
  );
  const productItems = useMemo(
    () => productSelectItems(products || []),
    [products]
  );

  const { data: customers, loading: loadingCustomers } = useApiGet(
    CustomerService.getAllCustomerNames
  );
  const customerItems = useMemo(
    () => customerSelectItems(customers || []),
    [customers]
  );
  const { data: palletStatuses, loading: loadingPalletStatuses } = useApiGet(
    PalletStatusService.getAll,
    {
      params: {
        maxResultCount: 9999,
      },
    }
  );
  const palletStatusItems = useMemo(
    () => palletStatusSelectItems(palletStatuses?.items || []),
    [palletStatuses]
  );
  // End populate data for Dropdown boxes

  const onCartonCheckboxChangeHandler = (
    rowSelectionModel: GridRowSelectionModel
  ) => {
    setSelectedCartonIds(rowSelectionModel as number[]);
  };
  const onConfirmChangesClickHandler = () => {
    const holdStatusCode = inputForm.data.holdStatusId
      ? palletStatusItems?.find((i) => i.id == inputForm.data.holdStatusId)
          ?.label
      : '';
    const ownerName = inputForm.data.ownerId
      ? customerItems?.find((i) => i.id == inputForm.data.ownerId)?.label
      : '';
    const productLabel = inputForm.data.productId
      ? productItems?.find((i) => i.id == inputForm.data.productId)?.label
      : '';
    const productCode = productLabel?.length
      ? productLabel.split(' - ')[0]
      : '';

    return confirmModalDialog({
      title: `Confirm Changes to ${selectedCartonIds.length} Cartons`,
      acceptButtonLabel: 'OK',
      declineButtonLabel: 'Cancel',
      acceptButtonCountdown: 0,
      declineButtonCountdown: 0,
      content: (
        <Box textAlign={'left'} data-testid={'confirm-changes-content'}>
          <List sx={{ listStyleType: 'disc', padding: 2 }}>
            {inputForm.data.holdStatusId && (
              <ConfirmationListItem data-testid="confirm-content-hold-code">
                <strong>Hold Code</strong> changed to {holdStatusCode}
              </ConfirmationListItem>
            )}
            {inputForm.data.ownerId && (
              <ConfirmationListItem data-testid="confirm-content-owner">
                <strong>Owner</strong> changed to {ownerName}
              </ConfirmationListItem>
            )}
            {inputForm.data.productId && (
              <ConfirmationListItem data-testid="confirm-content-product">
                <strong>Product Code</strong> changed to {productCode}
              </ConfirmationListItem>
            )}
            {inputForm.data.batchNumber?.length && (
              <ConfirmationListItem data-testid="confirm-content-batch">
                <strong>Batch</strong> changed to {inputForm.data.batchNumber}
              </ConfirmationListItem>
            )}
          </List>
        </Box>
      ),
      onAccept: () => {
        changeCartonInfoForm.setData('cartonIds', selectedCartonIds);
        changeCartonInfoForm.setData(
          'productId',
          inputForm.data.productId ?? 0
        );
        changeCartonInfoForm.setData('ownerId', inputForm.data.ownerId ?? 0);
        changeCartonInfoForm.setData(
          'holdStatusId',
          inputForm.data.holdStatusId ?? 0
        );
        changeCartonInfoForm.setData(
          'batchNumber',
          inputForm.data.batchNumber ?? ''
        );
        changeCartonInfoForm.submit();
      },
    });
  };

  const renderActions = (params: GridRowParams) => {
    const actions = [];
    actions.push(
      <AuditLog
        entityType="Coolpak.WMS.Cartons.Carton"
        entityId={params.row.id}
        valueMappings={{
          HoldStatusId:
            palletStatuses?.items.reduce((acc: string[], cur: PalletStatus) => {
              acc[cur.id] = cur.code;
              return acc;
            }, []) ?? [],
          ProductId:
            products?.reduce((acc: string[], cur: Product) => {
              acc[cur.id] = cur.description;
              return acc;
            }, []) ?? [],
          CustomerId:
            customers?.reduce((acc: string[], cur: CustomerSelectOption) => {
              acc[cur.id] = cur.name;
              return acc;
            }, []) ?? [],
        }}
      />
    );
    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'palletPrefixCode',
      headerName: 'Pallet',
      sortable: true,
      type: 'string',
      valueGetter: (params: GridRenderCellParams<StockOnHandCarton>) =>
        [params.row.palletPrefix, params.row.palletCode]
          .filter((a) => a)
          .join(' - '),
      minWidth: 100,
    },
    {
      field: 'cartonBarcode',
      headerName: 'Carton Barcode',
      sortable: true,
    },
    ...(user?.currentWarehouseId
      ? []
      : [
          {
            field: 'warehouseId',
            headerName: 'Warehouse',
            minWidth: 100,
            maxWidth: 300,
            renderCell: (params: GridRenderCellParams<StockOnHandCarton>) =>
              getWarehouseName(params.row.warehouseId),
            valueGetter: (params: GridRenderCellParams<StockOnHandCarton>) =>
              getWarehouseName(params.row.warehouseId),
          },
        ]),
    {
      field: 'customerName',
      headerName: 'Customer',
      renderCell: () => selectedCustomer?.label,
    },
    {
      field: 'productCode',
      headerName: 'Product Code',
      sortable: true,
    },
    {
      field: 'batchNumber',
      headerName: 'Batch',
      sortable: true,
    },
    {
      field: 'qty',
      headerName: 'Qty',
      sortable: true,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      sortable: true,
    },
    {
      field: 'holdStatusCode',
      headerName: 'Hold Status',
      sortable: true,
    },
    {
      field: 'asn',
      headerName: 'ASN',
      sortable: true,
    },
    {
      field: 'bolNumber',
      headerName: 'BOL',
      sortable: true,
    },
    {
      field: 'plannedBolNumber',
      headerName: 'Planned BOL',
      sortable: true,
    },
    {
      field: 'containerCode',
      headerName: 'Container',
      sortable: true,
    },
    {
      field: 'plannedContainerCode',
      headerName: 'Planned Container',
      sortable: true,
    },
    {
      field: 'plantCode',
      headerName: 'Plant Code',
      sortable: true,
    },
    {
      field: 'requiredTempState',
      headerName: 'Temp State',
      sortable: true,
    },
    {
      field: 'packDate',
      headerName: 'Pack Date',
      sortable: true,
      renderCell: (data) => tryFormatDateStr(data.row.packDate, 'dd/MM/yyyy'),
    },
    {
      field: 'lowDate',
      headerName: 'Low Date',
      sortable: true,
      renderCell: (data) => tryFormatDateStr(data.row.lowDate, 'dd/MM/yyyy'),
    },
    {
      field: 'highDate',
      headerName: 'High Date',
      sortable: true,
      renderCell: (data) => tryFormatDateStr(data.row.highDate, 'dd/MM/yyyy'),
    },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      align: 'left',
      getActions: renderActions,
    },
  ];

  useEffect(() => {
    if (selectedCustomer || forceRefreshCartons) {
      setLoading(true);
      CartonService.getStockOnHand({
        params: {
          warehouseId: user?.currentWarehouseId,
          productId: 0,
          customerId: (selectedCustomer?.id as number) || 0,
        },
      }).then((response) => {
        const [data] = response;
        setCartons(data ?? []);
        setLoading(false);
        setForceRefreshCartons(false);
      });
    } else {
      setCartons(undefined);
      setForceRefreshCartons(false);
    }
  }, [selectedCustomer, user, user?.currentWarehouseId, forceRefreshCartons]);
  const shouldDisableConfirmChangesBtn = useMemo(() => {
    return (
      selectedCartonIds.length == 0 ||
      (!inputForm.data.ownerId &&
        !inputForm.data.holdStatusId &&
        !inputForm.data.productId &&
        (!inputForm.data.batchNumber ||
          inputForm.data.batchNumber.length == 0)) ||
      !allPalletCartonsSelected
    );
  }, [selectedCartonIds, inputForm, allPalletCartonsSelected]);

  const handleClearCartonCheckboxRowSelection = () => {
    setClearCartonCheckboxRowSelection(false);
  };

  return (
    <MobilePage showOnDesktop maxWidth={false}>
      <Stack spacing={1}>
        <Stack
          direction="row"
          spacing={1}
          sx={{ py: 1, maxWidth: { md: '50%', sm: '100%' } }}
        >
          <Autocomplete
            data-testid={'customer-selector'}
            id="customerId"
            value={selectedCustomer}
            options={customerItems}
            loading={loadingCustomers}
            fullWidth
            size={'small'}
            noOptionsText={'No customer found'}
            renderInput={(params) => <TextField {...params} label="Customer" />}
            onChange={(event, newValue) => {
              setSelectedCustomer(newValue);
              inputForm.reset();
            }}
          />
        </Stack>
        <Box sx={{ height: '62vh' }}>
          <CardTitle title="Carton Management" />
          <StandardDataGrid
            loading={loading}
            columns={columns}
            rows={cartons || []}
            allowCheckboxSelection={true}
            clearCheckboxSelection={clearCartonCheckboxRowSelection}
            onClearCheckboxSelection={handleClearCartonCheckboxRowSelection}
            getRowId={(row) => row.id}
            testId="data-grid-cartons-table"
            noRowsMessage={
              selectedCustomer
                ? 'No rows found'
                : 'Please select a customer to view cartons'
            }
            onRowSelectionModelChange={(rowSelectionModel) =>
              onCartonCheckboxChangeHandler(rowSelectionModel)
            }
          />
        </Box>
        {canUpdateCartonInfo && (
          <Stack
            direction="row"
            data-testid="change-info-block"
            sx={{ marginTop: '16px !important' }}
          >
            <Grid
              container
              columnSpacing={{ xs: 2, sm: 3, md: 4 }}
              width={'100%'}
              marginTop={4}
            >
              <Grid item container xs={8} columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <FormField
                    data-testid="hold-status-selector"
                    form={inputForm}
                    id="holdStatusId"
                    label="Set Hold To..."
                    size="small"
                    type="autocomplete"
                    options={palletStatusItems}
                    loading={loadingPalletStatuses}
                    noOptionsText={'No hold status found'}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField
                    data-testid="owner-selector"
                    form={inputForm}
                    id="ownerId"
                    label="Set Owner To..."
                    size="small"
                    type="autocomplete"
                    options={customerItems}
                    loading={loadingCustomers}
                    noOptionsText={'No owner found'}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField
                    data-testid="product-selector"
                    form={inputForm}
                    id="productId"
                    label="Set Product To..."
                    size="small"
                    type="autocomplete"
                    options={productItems}
                    loading={loadingProducts}
                    noOptionsText="No product found"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField
                    inputProps={{ 'data-testid': 'batch-input' }}
                    form={inputForm}
                    id="batchNumber"
                    label={'Set Batch To...'}
                    size="small"
                    type={'text'}
                    clearable
                    sx={{ marginTop: 0 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4} textAlign={'right'} rowSpacing={2}>
                <Grid item>
                  <Button
                    data-testid={'confirm-changes-btn'}
                    variant="contained"
                    onClick={onConfirmChangesClickHandler}
                    disabled={shouldDisableConfirmChangesBtn}
                  >
                    Confirm Changes
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant="caption" sx={{ color: 'red' }}>
                    {!allPalletCartonsSelected && inputForm.data.ownerId
                      ? 'Unable to Change Owner for part of a pallet'
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        )}
      </Stack>
    </MobilePage>
  );
}

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
} from '@mui/material';
import React, { FormEventHandler, useEffect, useMemo, useState } from 'react';
import useApiForm from '../../Hooks/useApiForm';
import {
  CodeGroupCustomField,
  UserDefinedCode,
  UserDefinedCodeGroup,
} from '../../Models/UserDefinedCode';
import UserDefinedCodeService from '../../Services/UserDefinedCodeService';
import { SortedTableColumn } from '../../Components/SortedTable';
import DragTable from '../../Components/DragTable';
import EditCode from './EditCode';
import { Add } from '@mui/icons-material';
import { jsonClone } from '../../Lib/utils';

/***
 * This modal allows the Admin to edit or create a Group of Custom Codes
 */

export interface EditUserDefinedCodeGroupProps {
  group: UserDefinedCodeGroup;
  onClose: () => void;
  onSuccess: () => void;
  onRefresh: () => void;
}

export default function ({
  group,
  onClose,
  onSuccess,
  onRefresh,
}: EditUserDefinedCodeGroupProps) {
  const form = useApiForm(
    UserDefinedCodeService.updateGroupOrderAndActive,
    {
      id: group.id,
      // make a deep copy so we don't update the parent if the user cancels this modal
      userDefinedCodes: jsonClone(group.userDefinedCodes || []) || [],
    },
    {
      // pass the success to the parent event handler which will close the modal and refresh its data
      onSuccess,
    }
  );

  // make the codes easier to access
  const codes = form.data.userDefinedCodes;
  const setCodes = (codes: UserDefinedCode[]) =>
    form.setData('userDefinedCodes', [...codes]);

  // track if the codes or order has changed, so we can enable the 'Save' button
  const [originalJson, setOriginalJson] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    const newJson = JSON.stringify(codes);
    if (!originalJson) {
      setOriginalJson(newJson);
    } else {
      setIsChanged(newJson != originalJson);
    }
  }, [codes, originalJson, setOriginalJson, setIsChanged]);

  const getCustomValue = (
    code: UserDefinedCode,
    field: CodeGroupCustomField
  ) => {
    const value =
      code.customValues?.find((v) => v.name == field.name)?.value || '';
    return field.type == 'parent'
      ? codes.find((c) => c.code == value)?.name || ''
      : value;
  };

  const [editCode, setEditCode] = useState<UserDefinedCode | null>(null);
  const [open, setOpen] = useState(false);

  const onRowClick = (code?: UserDefinedCode) => {
    setEditCode(code || null);
    setOpen(true);
  };

  const closeModal = (code?: UserDefinedCode) => {
    if (code) {
      onRefresh();
      // the modal successfully saved a code
      if (editCode) {
        // replace the existing code with the new one
        Object.assign(editCode, code);
        setCodes(codes);
      } else {
        // add the new code to the end of the list of codes
        setCodes([...codes, code]);
      }
      // tell the parent list to refresh
    }
    setOpen(false);
    setEditCode(null);
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setCodes(codes);
    form.submit();
  };

  const columnDefs = useMemo<SortedTableColumn<UserDefinedCode>[]>(
    () => [
      {
        id: 'name',
        label: 'Name',
      },
      ...(group.customFields || []).map<SortedTableColumn<UserDefinedCode>>(
        (field) => ({
          id: 'code',
          label: field.name,
          cellRender: (code) => getCustomValue(code, field),
        })
      ),
      {
        id: 'value',
        label: 'Value',
        cellProps: { sx: { minWidth: '10em' } },
        show: codes.some((c) => c.value),
      },
      {
        id: 'description',
        label: 'Description',
        cellProps: { sx: { minWidth: '10em' } },
        show: codes.some((c) => c.description),
      },
      {
        id: 'active',
        label: 'Active',
        headerProps: { sx: { width: '92px' } },
        cellRender: (code) => (
          <Checkbox
            checked={code.active}
            onChange={() => {
              code.active = !code.active;
              setCodes([...codes]);
            }}
            onClick={(e) => e.stopPropagation()}
            size="small"
            // don't let the checkbox make the rows higher
            sx={{ py: 0, my: '-2px' }}
          />
        ),
      },
    ],
    [group, codes] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Dialog open onClose={onClose} fullWidth maxWidth={false} scroll="paper">
        <Box component="form" onSubmit={onSubmit} noValidate>
          <DialogTitle display="flex">
            <Box flexGrow={1}>Update Group: {group.name}</Box>
            <Fab color="primary" size="small" onClick={() => onRowClick()}>
              <Add />
            </Fab>
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <DragTable
              columnDefs={columnDefs}
              data={codes}
              rowKey="id"
              smallFont
              smallGap
              onUpdate={setCodes}
              onRowClick={onRowClick}
              // searchable
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} disabled={form.processing}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              loading={form.processing}
              disabled={!isChanged}
              data-testid="save-group-btn"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      {open && (
        <EditCode
          group={group}
          code={editCode}
          onClose={() => closeModal()}
          onSuccess={(code) => closeModal(code)}
        />
      )}
    </>
  );
}

import React from 'react';
import { BillOfLadingWithCounts } from '../../Models/BillOfLading';
import { BOLContainer, BOLContainerStatus } from '../../Models/BOLContainer';
import Checklist from '../../Components/Checklist/Checklist';
import BolContainerService from '../../Services/BolContainerService';
import useApiForm from '../../Hooks/useApiForm';
import usePermissions from '../../Hooks/usePermissions';

export interface BolContainerChecklistProps {
  bol: BillOfLadingWithCounts;
  container: BOLContainer;
  refresh: () => void;
}

export default function ({
  bol,
  container,
  refresh,
}: BolContainerChecklistProps) {
  const [canCompleteChecklist] = usePermissions(['BOL.Checklist.Complete']);
  const [canUpdateChecklist] = usePermissions(['BOL.Checklist.Update']);

  // If the user's permission is to complete the checklist, then they can submit the form if the checklist is not completed
  // If the user's permission is to update the checklist, then they can submit the form at any time
  const canSubmitCompleteForm =
    canUpdateChecklist ||
    (canCompleteChecklist && !container.checklistCompletedDate);

  const completeChecklistForm = useApiForm(
    BolContainerService.setCompletedDates,
    {
      id: container.id,
      checklistComplete: true,
    },
    {
      onSuccess: () => {
        refresh();
      },
    }
  );

  // When the checklist gets completed, we update the checklistComplete attribute on the container
  const onComplete = (isComplete: boolean) => {
    if (
      container.status != BOLContainerStatus.managerReview &&
      container.status != BOLContainerStatus.pendingInvoice &&
      container.status != BOLContainerStatus.invoiced &&
      (isComplete ? canSubmitCompleteForm : canUpdateChecklist)
    ) {
      completeChecklistForm.setData('checklistComplete', isComplete);
      completeChecklistForm.submit();
    }
  };

  // refresh the bol when a media has been uploaded
  const onMediaUploaded = () => {
    refresh();
  };

  return (
    <Checklist
      entityType="BOLContainers.BOLContainer"
      entityId={container.id}
      parentEntityType="BillOfLadings.BillOfLading"
      parentEntityId={bol.id}
      autoFill={{ entityCode: container.code }}
      onComplete={onComplete}
      canCompleteChecklist={canSubmitCompleteForm}
      onMediaUploaded={onMediaUploaded}
    />
  );
}

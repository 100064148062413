import React, { useMemo, useContext } from 'react';
import SortedTable, { SortedTableColumn } from '../../Components/SortedTable';
import { Carton } from '../../Models/Carton';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';
import { Pallet } from '../../Models/Pallet';
import { Tooltip } from '@mui/material';

export interface CartonTableProps {
  pallet: Pallet | null;
}

export default function ({ pallet }: CartonTableProps) {
  const { mobileView } = useContext(ResponsiveContext);

  const columnDefs = useMemo<SortedTableColumn<Carton>[]>(() => {
    return [
      {
        id: 'palletCode',
        label: 'Pallet Code',
        sortable: true,
      },
      {
        id: 'plantCode',
        label: 'Plant Code',
        sortable: true,
      },
      {
        id: 'productCode',
        label: 'Product Code',
        sortable: true,
      },
      {
        id: 'batchNumber',
        label: 'Batch No',
        sortable: true,
      },
      {
        id: 'qty',
        label: 'Qty',
        sortable: true,
        numeric: true,
        showTotal: true,
      },
      {
        id: 'weight',
        label: 'Weight',
        sortable: true,
        numeric: true,
        showTotal: true,
      },
      {
        id: 'asn',
        label: 'ASN',
        sortable: true,
        show: !mobileView,
      },
      {
        id: 'declarations',
        label: 'Declarations',
        sortable: true,
        show: !mobileView,
      },
      {
        id: 'sourceEdNumber',
        label: 'Source ED #',
        sortable: true,

        cellRender: (data) => data.sourceEdNumber?.split(',').join('\n'),
        show: !mobileView,
      },
      {
        id: 'eligibility',
        label: 'Eligibility',
        show: !mobileView,
        cellRender: (params) => (
          <Tooltip
            title={params.eligibility}
            data-testid={'eligibility-Span-' + params.id}
          >
            <span>{params.eligibility?.slice(0, 30)}</span>
          </Tooltip>
        ),
      },
      {
        id: 'requiredTempState',
        label: 'Temp State',
        sortable: true,
        show: !mobileView,
      },
      {
        id: 'cartonBarcode',
        label: 'Barcode',
        sortable: true,
        show: !mobileView,
      },
      {
        id: 'holdStatusDescription',
        label: 'Hold Status',
        sortable: true,
        show: !mobileView,
      },
      {
        id: 'notes',
        label: 'Notes',
        sortable: true,
        show: !mobileView,
      },
    ];
  }, [mobileView]);

  return (
    <SortedTable
      columnDefs={columnDefs}
      data={pallet?.cartons || []}
      title="Stock on Hand - Cartons"
      rowKey="id"
      smallFont
      smallGap
      searchable
      pageSize="auto"
      exportable
    />
  );
}

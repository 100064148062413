import { SelectItem } from '../Components/Forms/FormField';

export interface PalletStatus {
  id: number;
  code: string;
  description: string;
  allowLoadIn: boolean;
  allowLoadOut: boolean;
  allowTransfer: boolean;
}

export function palletStatusSelectItems(statuses: PalletStatus[]) {
  return statuses
    .sort((a, b) => a.code.localeCompare(b.code))
    .map<SelectItem>((status) => ({
      id: status.id,
      label: status.code + ' - ' + status.description,
    }));
}

import { Box, Fab, SxProps } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import React, { ReactNode, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import MarkAsReadyButton from '../../../../Components/BillOfLading/PickList/MarkAsReadyButton';
import StandardDataGrid from '../../../../Components/StandardDataGrid';
import useBOLOrderTypes from '../../../../Hooks/useBOLOrderTypes';
import useWarehouses from '../../../../Hooks/useWarehouses';
import { formatNumber } from '../../../../Lib/utils';
import {
  ALLOW_EXPORT_LABELS,
  BillOfLadingWithCounts,
  BOL_LABELS,
  BolStatus,
  BolStatusChip,
} from '../../../../Models/BillOfLading';
import { AuthContext } from '../../../../Providers/AuthProvider';

interface BolDataGridProps {
  loading: boolean;
  rows: BillOfLadingWithCounts[];
  checkContainersStatuses: (bolId: number) => void;
  refreshRows?: () => void;
  fabIcon?: ReactNode;
  onFabClick?: () => void;
  fabStyle?: SxProps;
}

const BolDataGrid = ({
  loading,
  rows,
  checkContainersStatuses,
  refreshRows,
  fabIcon,
  fabStyle,
  onFabClick,
}: BolDataGridProps) => {
  const { user } = useContext(AuthContext);
  const { getWarehouseName } = useWarehouses();
  const { getBolOrderTypeName } = useBOLOrderTypes();

  const renderActions = useMemo(
    () => (params: GridRowParams<BillOfLadingWithCounts>) => {
      const actions = [];

      if (params.row.status == BolStatus.loading) {
        const markAsLoadedBtn = (
          <GridActionsCellItem
            label="Mark as Loaded"
            onClick={() => checkContainersStatuses(params.row.id)}
            showInMenu
          />
        );
        actions.push(markAsLoadedBtn);
      }
      if (params.row.status == BolStatus.notStarted) {
        const markAsReadyBtn = (
          <MarkAsReadyButton
            billOfLadingId={params.row.id}
            textContent="Mark as Ready to Load"
            displayAs="GridAction"
            onSuccess={refreshRows}
            showInMenu={true}
          />
        );
        actions.push(markAsReadyBtn);
      }

      return actions;
    },
    [checkContainersStatuses, refreshRows]
  );
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'bolNumber',
        headerName: 'BOL No.',
        type: 'number',
        width: 100,
        minWidth: 80,
        maxWidth: 200,
        renderCell: (params: GridRenderCellParams<BillOfLadingWithCounts>) => (
          <Link to={`/bol/${params.row.bolNumber}`}>
            {params.row.bolNumber}
          </Link>
        ),
      },
      {
        field: 'childBolNumbers',
        headerName: 'Child BOL(s)',
        minWidth: 80,
        maxWidth: 200,
        renderCell: (params: GridRenderCellParams<BillOfLadingWithCounts>) => (
          <div>
            {params.row.childBolNumbers?.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        ),
      },
      {
        field: 'shipDate',
        headerName: 'Ship Date',
        minWidth: 80,
        maxWidth: 300,
        type: 'date',
        valueGetter: (params: GridRenderCellParams<BillOfLadingWithCounts>) =>
          new Date(params.row.shipDate),
      },
      ...(user?.currentWarehouseId
        ? []
        : [
            {
              field: 'warehouseId',
              headerName: 'Warehouse',
              minWidth: 80,
              maxWidth: 300,
              renderCell: (
                params: GridRenderCellParams<BillOfLadingWithCounts>
              ) => getWarehouseName(params.row.warehouseId),
              valueGetter: (
                params: GridRenderCellParams<BillOfLadingWithCounts>
              ) => getWarehouseName(params.row.warehouseId),
            },
          ]),
      {
        field: 'customerName',
        headerName: 'Customer',
        minWidth: 160,
        maxWidth: 300,
      },
      {
        field: 'consigneeName',
        headerName: 'Deliver To Customer',
        minWidth: 160,
        maxWidth: 300,
      },
      {
        field: 'customerOrderNo',
        headerName: 'Customer Order #',
        minWidth: 140,
        maxWidth: 300,
      },
      {
        field: 'childBolOrderNumbers',
        headerName: 'Child Customer Order #',
        minWidth: 80,
        maxWidth: 300,
        renderCell: (params: GridRenderCellParams<BillOfLadingWithCounts>) => (
          <div>
            {params.row.childBolOrderNumbers?.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        ),
      },
      {
        field: 'orderType',
        headerName: 'Type',
        width: 80,
        minWidth: 60,
        maxWidth: 300,
        renderCell: (params: GridRenderCellParams<BillOfLadingWithCounts>) =>
          getBolOrderTypeName(params.row.orderType),
        valueGetter: (params: GridRenderCellParams<BillOfLadingWithCounts>) =>
          getBolOrderTypeName(params.row.orderType),
      },
      {
        field: 'palletsPickedCount',
        headerName: 'Picked',
        width: 90,
        minWidth: 80,
        maxWidth: 300,
        align: 'right',
        renderCell: (params: GridRenderCellParams<BillOfLadingWithCounts>) => (
          <>
            {formatNumber(params.row.palletsPickedCount)} /{' '}
            {formatNumber(params.row.palletsCount)}
          </>
        ),
      },
      {
        field: 'palletsLoadedCount',
        headerName: 'Loaded',
        width: 90,
        minWidth: 80,
        maxWidth: 300,
        align: 'right',
        renderCell: (params: GridRenderCellParams<BillOfLadingWithCounts>) => (
          <>
            {formatNumber(params.row.palletsLoadedCount)} /{' '}
            {formatNumber(params.row.palletsCount)}
          </>
        ),
      },
      {
        field: 'cartonsLoadedCount',
        headerName: 'CTN Loaded',
        width: 90,
        minWidth: 80,
        maxWidth: 300,
        align: 'right',
        renderCell: (params: GridRenderCellParams<BillOfLadingWithCounts>) => (
          <>
            {formatNumber(params.row.cartonsLoadedCount)} /{' '}
            {formatNumber(params.row.cartonsCount)}
          </>
        ),
      },
      {
        field: 'cartonsWeight',
        headerName: 'Weight',
        minWidth: 140,
        maxWidth: 300,
        align: 'right',
        renderCell: (params: GridRenderCellParams<BillOfLadingWithCounts>) => (
          <>
            {formatNumber(params.row.cartonsLoadedWeight, 2)} /{' '}
            {formatNumber(params.row.cartonsWeight, 2)}
          </>
        ),
      },
      {
        field: 'export',
        headerName: 'Export?',
        valueGetter: (params: GridRenderCellParams<BillOfLadingWithCounts>) => {
          const isAllowToExport = !!params.row.canExportBol;
          return ALLOW_EXPORT_LABELS[isAllowToExport ? 1 : 0];
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 130,
        maxWidth: 300,
        renderCell: (params: GridRenderCellParams<BillOfLadingWithCounts>) => (
          <BolStatusChip status={params.row.status} />
        ),
        valueGetter: (params: GridRenderCellParams<BillOfLadingWithCounts>) =>
          BOL_LABELS[params.row.status],
      },
      {
        field: 'actions',
        type: 'actions',
        width: 70,
        minWidth: 50,
        maxWidth: 300,
        headerName: 'Actions',
        getActions: renderActions,
      },
    ],
    [
      getBolOrderTypeName,
      getWarehouseName,
      user?.currentWarehouseId,
      renderActions,
    ]
  );

  const initialSortModel: GridSortModel = [
    {
      field: 'bolNumber',
      sort: 'desc',
    },
  ];

  return (
    <>
      {fabIcon && (
        <Fab
          color="primary"
          size="medium"
          data-testid="Bol-datagrid-fab-button"
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            ...fabStyle,
          }}
          onClick={onFabClick}
        >
          {fabIcon}
        </Fab>
      )}
      <Box sx={{ height: '65vh' }}>
        <StandardDataGrid
          testId="bol-data-grid"
          loading={loading}
          columns={columns}
          rows={rows}
          getRowId={(row) => row.id}
          initialSortModel={initialSortModel}
          disableRowSelectionOnClick={true}
        />
      </Box>
    </>
  );
};

export default BolDataGrid;

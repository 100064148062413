import { StockOnHandCarton, StockOnHandCustomerCount } from '../Models/Carton';
import { StockOnHandPalletToFulfilBol } from '../Models/StockOnHand';
import Api, { ServiceGetMethod, ServicePostMethod } from './Api';

/**
 * This Service provides api endpoints for interacting with its related entity via the Api Service
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const getStockOnHandCounts: ServiceGetMethod<
  GetStockOnHandCountsRequest,
  StockOnHandCustomerCount[]
> = (options) =>
  Api.get('api/services/app/Carton/GetStockOnHandCounts', options);

const getStockOnHand: ServiceGetMethod<
  GetStockOnHandRequestDto,
  StockOnHandCarton[]
> = (options) => Api.get('api/services/app/Carton/GetStockOnHand', options);

const getStockOnHandForBol: ServiceGetMethod<
  GetStockOnHandForBolRequestDto,
  StockOnHandPalletToFulfilBol[]
> = (options) =>
  Api.get('api/services/app/Carton/GetStockOnHandForBol', options);

const markAsVerified: ServicePostMethod<{ cartonId: number }, boolean> = (
  { cartonId },
  options
) =>
  Api.put(
    `api/services/app/Carton/MarkAsVerified?cartonId=${cartonId}`,
    options
  );

const addCartonToPalletByBarcode: ServicePostMethod<
  AddCartonToPalletRequest,
  boolean
> = (data, options) =>
  Api.put('api/services/app/Carton/AddCartonToPalletByBarcode', data, options);

const unassignCartons: ServicePostMethod<UnassignCartonsRequest, boolean> = (
  data,
  options
) => Api.put('api/services/app/Carton/UnassignCartons', data, options);

const changeCartonsInfo: ServicePostMethod<
  ChangeCartonsInfoRequest,
  boolean
> = (data, options) =>
  Api.put('api/services/app/Carton/ChangeCartonsInfo', data, options);

// export a default object so we can use FooService.method in our code - which makes it clear what we're getting
export default {
  getStockOnHandCounts,
  getStockOnHand,
  getStockOnHandForBol,
  markAsVerified,
  addCartonToPalletByBarcode,
  unassignCartons,
  changeCartonsInfo,
};

export interface GetStockOnHandCountsRequest {
  warehouseId: number | null | undefined;
  batchNumber: string;
  customerId: number | null | undefined;
  productId: number | null | undefined;
}

export interface GetStockOnHandRequestDto {
  warehouseId: number | null | undefined;
  productId: number;
  customerId: number;
  dateIn?: string;
}

export interface GetStockOnHandForBolRequestDto {
  warehouseId: number | null | undefined;
  bolId: number;
}

export interface AddCartonToPalletRequest {
  palletId: number;
  cartonBarcode: string;
  allowNewProduct?: boolean;
  process: string;
  newPalletBarcode: string;
}

export interface UnassignCartonsRequest {
  PalletId: number;
  ProductCode: string;
  BatchNumber: string;
}

export interface ChangeCartonsInfoRequest {
  cartonIds: number[];
  ownerId?: number | null;
  batchNumber?: string;
  productId?: number | null;
  holdStatusId?: number | null;
}

export const ORPHAN_PALLET_STATUS_REASONS = {
  VERIFY_A_PALLET: 'verify',
  SPLIT_A_PALLET: 'split',
} as const;

export const CARTON_ERROR_CODES = {
  CUSTOMER_MISMATCH_PALLET: 1000, // Unable to add. Carton relates to a different customer than the Pallets content
  PRODUCT_NOT_EXISTS_ON_PALLET: 1001, // Unable to add. Product does not exist on this pallet
} as const;

import React, { useMemo, useState } from 'react';
import ForkliftIcon from '../../Icons/Forklift';
import DollarIcon from '@mui/icons-material/AttachMoney';
import InfoIcon from '@mui/icons-material/Info';
import MobilePage, { MobilePageTab } from '../../Components/MobilePage';
import { TabPanel } from '@mui/lab';
import { BillOfLadingWithCountsAndPalletDetails } from '../../Models/BillOfLading';
import { BOLContainer, CONTAINER_LABELS } from '../../Models/BOLContainer';
import BolLoadContainer from './BolLoadContainer';
import { FactCheckOutlined } from '@mui/icons-material';
import BolContainerBilling from './BolContainerBilling';
import BolContainerUnload from './BolContainerUnload';
import BolContainerChecklist from './BolContainerChecklist';
import { formatNumber } from '../../Lib/utils';
import MediaLibrary from '../../Components/MediaLibrary/MediaLibrary';
import usePermissions from '../../Hooks/usePermissions';
import { MetaQuestionType } from '../../Components/MediaLibrary/PhotoTaker';
import AuditLog from '../../Components/AuditLog/AuditLog';
import BolContainerInstructions from './BolContainerInstructions';
import { useLocation, useNavigate } from 'react-router-dom';
import { FileEntityType } from '../../Models/WMSFile';

/**
 * This is the container view for an Operator
 */

const tabLists = ['instructions', 'load', 'unload', 'billing', 'checklist'];
type Tab = typeof tabLists[number];

// These are questions to pass to the PhotoTaker component to add custom Meta data to new photos
const questions: MetaQuestionType[] = [
  {
    type: 'select',
    label: '% Loaded',
    options: ['Empty', '25%', '50%', '75%', '100%'],
    // required: true, // TODO re-enable when we go live?
  },
  {
    type: 'string',
    label: 'Comments',
  },
];

export interface BolContainerProps {
  container: BOLContainer;
  bol: BillOfLadingWithCountsAndPalletDetails;
  refresh: () => void;
}

export default function ({ container, bol, refresh }: BolContainerProps) {
  const [canLoadBol, canViewAudit] = usePermissions(['BOL.Load', 'Audit.View']);
  const location = useLocation();
  const navigate = useNavigate();
  // remove the hash key
  const hash = location.hash.substring(1);

  // if the url contains a #tab we set the default tab to it
  const [tab, setTab] = useState<Tab>(
    tabLists.includes(hash) ? hash : bol.instructions ? 'instructions' : 'load'
  );

  const onTabChange = (tab: Tab) => {
    // add the hash to the url so we can redirect to the tab if needed
    navigate(`#${tab}`, { replace: true });
    setTab(tab);
  };

  const tabs: MobilePageTab<Tab>[] = [
    {
      id: 'instructions',
      icon: InfoIcon,
      complete: false,
    },
    {
      id: 'load',
      icon: ForkliftIcon,
      complete: !!container.loadingCompletedDate,
    },
    {
      id: 'billing',
      icon: DollarIcon,
      complete: !!container.billingCompletedDate,
    },
    {
      id: 'checklist',
      icon: FactCheckOutlined,
      complete: !!container.checklistCompletedDate,
    },
  ];

  const right = useMemo(() => {
    if (tab == 'load') {
      const photoMetaData = [
        {
          label: 'Pallets',
          value: bol.palletsCount
            ? `${formatNumber(
                (100 * bol.palletsLoadedCount) / bol.palletsCount
              )}% (${bol.palletsLoadedCount} / ${bol.palletsCount})`
            : '',
        },
        {
          label: 'Cartons',
          value: bol.cartonsCount
            ? `${formatNumber(
                (100 * bol.cartonsLoadedCount) / bol.cartonsCount
              )}% (${bol.cartonsLoadedCount} / ${bol.cartonsCount})`
            : '',
        },
      ];
      return (
        <MediaLibrary
          entityTypes={[
            'BOLContainers.BOLContainer',
            ...container.checklistResponseIds.map<FileEntityType>(
              () => 'ChecklistItemResponses.ChecklistItemResponse'
            ),
          ]}
          labels={[
            `Container ${container.code}`,
            ...container.checklistResponseIds.map(
              () => `Checklist ${container.code}`
            ),
          ]}
          combineGroups
          entityIds={[container.id, ...container.checklistResponseIds]}
          metaData={photoMetaData}
          questions={questions}
          newFilePrefix={`bol-${bol.bolNumber}-cont-${container.code}`}
          zipName={`bol-${bol.customerName}-${bol.bolNumber}-cont-${container.code}-photos.zip`}
          allowUpload
          allowDelete
          autoOpenCamera={canLoadBol}
        />
      );
    } else if (tab == 'billing') {
      return (
        canViewAudit && (
          <AuditLog
            entityType="Coolpak.WMS.BOLContainers.BOLContainer"
            entityId={container.id}
            valueMappings={{ Status: CONTAINER_LABELS }}
          />
        )
      );
    }
    return null;
  }, [tab, bol, container, canLoadBol, canViewAudit]);

  return (
    <MobilePage
      tabs={tabs}
      tab={tab}
      right={right}
      onTabChange={onTabChange}
      maxWidth={false}
    >
      <TabPanel value="instructions" sx={{ padding: 0 }}>
        <BolContainerInstructions bol={bol} />
      </TabPanel>
      <TabPanel value="load" sx={{ padding: 0 }}>
        <BolLoadContainer bol={bol} container={container} refresh={refresh} />
      </TabPanel>
      <TabPanel value="unload" sx={{ padding: 0 }}>
        <BolContainerUnload bol={bol} container={container} refresh={refresh} />
      </TabPanel>
      <TabPanel value="billing" sx={{ padding: 0 }}>
        <BolContainerBilling
          container={container}
          bol={bol}
          refresh={refresh}
        />
      </TabPanel>
      <TabPanel value="checklist" sx={{ padding: 0 }}>
        <BolContainerChecklist
          bol={bol}
          container={container}
          refresh={refresh}
        />
      </TabPanel>
    </MobilePage>
  );
}

import { Lot } from '../Models/Lot';
import Api, { ServiceGetMethod, ServicePostMethod } from './Api';

const getAllLots: ServiceGetMethod<void, Lot[]> = (options) =>
  Api.get(`api/services/app/Lots/GetAll`, options);

const editLot: ServicePostMethod<EditLotRequest, Lot> = (data, options) =>
  Api.put(`api/services/app/Lots/Update`, data, options);

const linkCartonsToLot: ServicePostMethod<LinkCartonsToLotRequest, void> = (
  data,
  options
) => Api.post(`api/services/app/Lots/LinkCartonsToLot`, data, options);

interface EditLotRequest {
  id: number;
  productCode: string;
  productionBatch: string;
  requiredTempState: string;
}

interface LinkCartonsToLotRequest {
  lotId: number;
  cartonIds: number[];
}

export default { getAllLots, editLot, linkCartonsToLot };

import React, { useContext, useState } from 'react';
import useApiGet from '../../Hooks/useApiGet';
import StocktakeService from '../../Services/StocktakeService';
import {
  Stocktake,
  STOCKTAKE_STATUS_COLORS,
  STOCKTAKE_STATUS_LABELS,
} from '../../Models/Stocktake';
import { tryFormatDateStr } from '../../Lib/utils';
import StatusChip from '../../Components/StatusChip';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { AuthContext } from '../../Providers/AuthProvider';
import StoreService from '../../Services/StoreService';
import useApiForm from '../../Hooks/useApiForm';
import MobilePage from '../../Components/MobilePage';
import CardTitle from '../../Components/CardTitle';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { LoadingButton } from '@mui/lab';
import StandardDataGrid from '../../Components/StandardDataGrid';

export default function () {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const [stocktakeDetails, setStocktakeDetails] = useState<
    Stocktake[] | undefined
  >([]);
  const { refresh: refreshStocktakes, loading: loadingStocktakes } = useApiGet(
    StocktakeService.getAllStocktakes,
    {
      onSuccess: (stocktakes) => {
        setStocktakeDetails(stocktakes);
      },
    }
  );
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Stocktake Id',
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: 'creationTime',
      headerName: 'Created Time',
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => (
        <div>{tryFormatDateStr(params.row.creationTime.toString())}</div>
      ),
      flex: 1,
    },
    {
      field: 'location',
      headerName: 'Stocktake Location',
      minWidth: 150,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
    },
    {
      field: 'lastScannedTime',
      headerName: 'Last Scanned Time',
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => (
        <div>{tryFormatDateStr(params.row.lastScannedTime?.toString())}</div>
      ),
      flex: 1,
    },
    {
      field: 'expected',
      headerName: 'Expected',
    },
    {
      field: 'verified',
      headerName: 'Verified',
    },
    {
      field: 'lost',
      headerName: 'Lost',
    },
    {
      field: 'new',
      headerName: 'New',
    },
    {
      field: 'stocktakeStatus',
      headerName: 'Status',
      renderCell: (params: GridRenderCellParams) => (
        <StatusChip
          label={STOCKTAKE_STATUS_LABELS[params.row.stocktakeStatus]}
          colors={STOCKTAKE_STATUS_COLORS}
          width="7em"
          sx={{
            p: '2px 4px',
            paddingY: '4px',
          }}
        />
      ),
    },
  ];

  const [storeId, setStoreId] = useState<number>(-1);
  const { data: locationWithPalletsCount, loading } = useApiGet(
    StocktakeService.getLocationWithPalletsCounts,
    {
      params: {
        currentWarehouseId: user?.currentWarehouseId,
        storeId: storeId,
      },
    }
  );

  const { data: storeData } = useApiGet(StoreService.getStores, {
    params: { warehouseId: user?.currentWarehouseId },
  });

  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const toggle = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((i) => i != id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };
  function handleCreateStocktakeClick() {
    setSelectedIds([]);
    setIsOpen(true);
  }

  const createStocktakeForm = useApiForm(
    StocktakeService.createStocktake,
    {
      locationIds: selectedIds,
    },
    {
      onSuccess: () => {
        setStoreId(-1);
        setIsOpen(false);
        refreshStocktakes();
      },
    }
  );

  function CreateStocktakes() {
    createStocktakeForm.setData('locationIds', selectedIds);
    setSelectedIds([]);
    createStocktakeForm.submit();
  }
  const createStockTakesButton = () => (
    <LoadingButton
      variant="contained"
      data-testid="create-stocktake-button-on-the-list"
      onClick={() => handleCreateStocktakeClick()}
      loading={loadingStocktakes}
    >
      Create Stocktakes
    </LoadingButton>
  );

  const columnLocationWithPalletsCount: GridColDef[] = [
    {
      field: '',
      headerName: '',
      renderCell: (params: GridRenderCellParams) => (
        <FormControlLabel
          control={
            <Checkbox
              data-testid={'checkBoxName' + params.row.locationId}
              checked={selectedIds.includes(params.row.locationId as number)}
              onClick={() => toggle(params.row.locationId as number)}
            />
          }
          label={undefined}
        />
      ),
      flex: 0.3,
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 1,
    },
    {
      field: 'palletCount',
      headerName: 'Pallet(s) in Row',
      flex: 1,
    },
  ];

  return (
    <MobilePage maxWidth={false}>
      <Box sx={{ height: '84vh' }}>
        <CardTitle title="Stocktake" rightChild={createStockTakesButton()} />
        <StandardDataGrid
          testId="data-grid-stocktake-table"
          rows={stocktakeDetails || []}
          columns={columns}
          getRowId={(row) => row.id}
          disableRowSelectionOnClick={true}
        />
      </Box>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <div style={{ justifyContent: 'left' }}>
            {' '}
            <Select
              fullWidth
              onChange={(e) => setStoreId(parseInt(e.target.value))}
              defaultValue={'-1'}
            >
              <MenuItem value="-1">Store Location</MenuItem>
              {storeData?.items.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              data-testid="data-button-createstocktake"
              variant="contained"
              style={{ margin: 5 }}
              onClick={() => CreateStocktakes()}
            >
              CREATE STOCKTAKE
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          {loading && (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}
          <StandardDataGrid
            columns={columnLocationWithPalletsCount}
            rows={locationWithPalletsCount || []}
            disableRowSelectionOnClick={true}
            getRowId={(row) => row.locationId}
            testId="data-grid-LocationWithPalletCount-table"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </MobilePage>
  );
}

import React from 'react';

import StatusChip, { StatusChipColors } from '../../Components/StatusChip';
import { ASNStatus } from '../../Models/ASN';
import { PaperProps } from '@mui/material';

export const ASN_STATUS_LABELS = [
  'Pending',
  'Arrived',
  'Receiving',
  'Admin Review',
  'Manager Review',
  'Pending Invoice',
  'Invoiced',
  'Completed',
] as const;

export type ASNStatusLabel = typeof ASN_STATUS_LABELS[number];

export const ASN_STATUS_COLORS: StatusChipColors<ASNStatusLabel> = {
  Pending: { bg: '#808080', fg: '#ffffff' }, // grey/300
  Arrived: { bg: '#ff2905', fg: '#ffffff' }, // blue/200
  Receiving: { bg: '#fefd32', fg: '#1a1a1a' }, // green/500
  'Admin Review': { bg: '#7ffcd4', fg: '#1a1a1a' }, // green/A100
  'Pending Invoice': { bg: '#7ffcd4', fg: '#1a1a1a' }, // green/A100
  'Manager Review': { bg: '#7ffcd4', fg: '#1a1a1a' }, // green/A100
  Invoiced: { bg: '#1a5e20', fg: '#ffffff' },
  Completed: { bg: '#2fcb40', fg: '#1a1a1a' },
};
export default function ({
  status,
  ...props
}: { status: ASNStatus } & PaperProps) {
  return (
    <StatusChip
      {...props}
      label={ASN_STATUS_LABELS[status]}
      colors={ASN_STATUS_COLORS}
      width="11em"
    />
  );
}

import { useCallback, useMemo } from 'react';
import BillOfLadingService from '../Services/BillOfLadingService';
import useApiGet from './useApiGet';

/***
 * A convenient hook to consume the cached Bill Of Lading Order Types in various formats
 */

export default function () {
  // fetch cached Order Types
  const { data } = useApiGet(BillOfLadingService.getBOLOrderTypes, {
    cache: true,
  });

  // convert to Record<id, name>
  const bolOrderTypesByCode = useMemo(() => {
    const result: Record<string, string> = {};
    (data || []).forEach((type) => (result[type.code] = type.name));
    return result;
  }, [data]);

  const bolOrderTypesById = useMemo(() => {
    const result: Record<number, string> = {};
    (data || []).forEach((type) => (result[type.id] = type.name));
    return result;
  }, [data]);

  const getBolOrderTypeName = useCallback(
    (code: string | null | undefined) =>
      /* istanbul ignore next */
      code ? bolOrderTypesByCode[code] : '',
    [bolOrderTypesByCode]
  );
  const getBolOrderTypeNameById = useCallback(
    (id: number | null | undefined) =>
      /* istanbul ignore next */
      id ? bolOrderTypesById[id] : '',
    [bolOrderTypesById]
  );

  return {
    bolOrderTypesByCode,
    getBolOrderTypeName,
    getBolOrderTypeNameById,
  };
}

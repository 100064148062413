import React, { useContext, useEffect, useState } from 'react';
import { ChecklistItem, ChecklistItemType } from '../../Models/Checklist';
import FormField from '../Forms/FormField';
import { UseApiFormResult } from '../../Hooks/useApiForm';
import { FormLabel, Stack } from '@mui/material';
import DOMPurify from 'dompurify';

import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControl,
} from '@mui/material';
import {
  CreateOrUpdateChecklistItemResponseRequest,
  CreateOrUpdateChecklistItemResponseResponse,
} from '../../Services/ChecklistItemResponseService';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';
import ConfirmBooleanAnswer from './ConfirmOptionAnswer';

export interface ChecklistItemProps {
  checklistItem: ChecklistItem;
  form: UseApiFormResult<
    CreateOrUpdateChecklistItemResponseRequest,
    CreateOrUpdateChecklistItemResponseResponse
  >;
  onValueUpdated: ({
    value,
    comment,
  }: {
    value: string;
    comment?: string;
  }) => void;
  disabled?: boolean;
}

export default function ({
  checklistItem,
  form,
  onValueUpdated,
  disabled = false,
}: ChecklistItemProps) {
  const id = 'response';
  const label = checklistItem.text;
  const { mobileView } = useContext(ResponsiveContext);
  const formControlStyle = mobileView
    ? {}
    : {
        justifyContent: 'space-between',
        flexDirection: 'row',
      };

  // don't let the option value be changed from the server if the comment is focussed
  const oldOptionValue = form.data.response;
  const [booleanValue, setBooleanValue] = useState(oldOptionValue);
  const [booleanCommentFocussed, setBooleanCommentFocussed] = useState(false);
  useEffect(() => {
    if (!booleanCommentFocussed) {
      setBooleanValue(oldOptionValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldOptionValue]);

  // Yes boolean responses must be confirmed - just in case the user tapped instead on scrolled
  const [confirmOpen, setConfirmOpen] = useState(false);

  switch (checklistItem.type) {
    case ChecklistItemType.OPTIONS:
      return (
        <Stack direction="column" sx={{ width: 1 }}>
          <FormControl sx={formControlStyle}>
            <FormLabel id={id} sx={{ fontSize: '14px' }}>
              {/* NOTE: we can alter the sanitize config, but the default is quite secure */}
              <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }}
              ></div>
            </FormLabel>
            <Stack alignSelf={mobileView ? 'end' : 'flex-start'} flexShrink={0}>
              <RadioGroup
                aria-labelledby={id}
                name={id}
                value={booleanValue}
                onChange={(e) => {
                  setBooleanValue(e.target.value);
                  // open the confirm box if comments are not required for this answer
                  setConfirmOpen(
                    !checklistItem.actions.includes(
                      `require.comments:${e.target.value}`
                    ) && !checklistItem.actions.includes('require.comments')
                  );
                }}
                row
                data-testid={'checklist-item-id-' + checklistItem.id}
              >
                {checklistItem.options.map((option) => (
                  <FormControlLabel
                    key={option}
                    disabled={disabled}
                    value={option}
                    control={<Radio />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            </Stack>
          </FormControl>
          {booleanValue &&
            (checklistItem.actions.includes(
              `require.comments:${booleanValue}`
            ) ||
              checklistItem.actions.includes('require.comments')) && (
              <FormField
                disabled={disabled}
                autoFocus={!form.data.comment}
                autoComplete="off"
                onFocus={(e) => {
                  e.target.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
                  setBooleanCommentFocussed(true);
                }}
                onBlur={(e) => {
                  if (e.target.value) {
                    // accept the new response with a comment
                    onValueUpdated({
                      value: booleanValue,
                      comment: e.target.value,
                    });
                  } else {
                    // no comment? revert to the previous answer
                    onValueUpdated({
                      value: oldOptionValue,
                      comment: form.data.comment,
                    });
                    setBooleanValue(oldOptionValue);
                  }
                  setBooleanCommentFocussed(false);
                }}
                form={form}
                id="comment"
                type="text"
                label="Comment"
                required
                data-testid={'checklist-item-id-comments-' + checklistItem.id}
              />
            )}
          {confirmOpen && (
            <ConfirmBooleanAnswer
              responseText={booleanValue}
              checklistItem={checklistItem}
              onSuccess={() => {
                setConfirmOpen(false);
                onValueUpdated({ value: booleanValue });
              }}
              onCancel={() => {
                // revert to the previous answer
                setBooleanValue(oldOptionValue);
                setConfirmOpen(false);
                onValueUpdated({
                  value: oldOptionValue,
                  comment: form.data.comment,
                });
              }}
            />
          )}
        </Stack>
      );
    case ChecklistItemType.INT:
      return (
        <FormField
          onBlur={(e) => onValueUpdated({ value: e.target.value })}
          form={form}
          id={id}
          type="number"
          label={label}
          required={checklistItem.required}
          disabled={disabled}
          data-testid={'checklist-item-id-' + checklistItem.id}
        />
      );
    case ChecklistItemType.DATE:
      return (
        <FormField
          onBlur={(e) => onValueUpdated({ value: e.target.value })}
          form={form}
          id={id}
          type="date"
          label={label}
          required={checklistItem.required}
          InputLabelProps={{ shrink: true }} // display the label on top of the field
          disabled={disabled}
          data-testid={'checklist-item-id-' + checklistItem.id}
        />
      );
    case ChecklistItemType.TIME:
      return (
        <FormField
          onBlur={(e) => onValueUpdated({ value: e.target.value })}
          form={form}
          id={id}
          type="time"
          label={label}
          required={checklistItem.required}
          disabled={disabled}
          data-testid={'checklist-item-id-' + checklistItem.id}
        />
      );
    case ChecklistItemType.DECIMAL:
      return (
        <FormField
          onBlur={(e) => onValueUpdated({ value: e.target.value })}
          form={form}
          id={id}
          type="number"
          label={label}
          required={checklistItem.required}
          InputProps={
            checklistItem.units
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      {checklistItem.units}
                    </InputAdornment>
                  ),
                }
              : undefined
          }
          disabled={disabled}
          validationregex={checklistItem.validationRegEx}
          validationmessage={checklistItem.validationMessage}
          data-testid={'checklist-item-id-' + checklistItem.id}
        />
      );
    case ChecklistItemType.STRING:
    default:
      return (
        <FormField
          onBlur={(e) => onValueUpdated({ value: e.target.value })}
          form={form}
          id={id}
          type="text"
          label={label}
          required={checklistItem.required}
          disabled={
            checklistItem.uniqueRef == 'Transport.ContainerNumber'
              ? true
              : disabled
          }
          data-testid={'checklist-item-id-' + checklistItem.id}
        />
      );
  }
}

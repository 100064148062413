import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, IconButton, Stack, TextField } from '@mui/material';
import {
  CodeEntityQuantity,
  UserDefinedCode,
} from '../../Models/UserDefinedCode';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { onlyAllowMatchingPattern } from '../../Lib/utils';
import UserDefinedCodeService from '../../Services/UserDefinedCodeService';
import useApiForm from '../../Hooks/useApiForm';

export interface BolContainerBillingRowProps {
  entityType: string;
  entityId: number;
  code: UserDefinedCode;
  entityQuantities: CodeEntityQuantity[];
  refresh: () => void;
  onBillingClosed: () => void;
  disabled?: boolean;
}

export default function ({
  entityType,
  entityId,
  code,
  entityQuantities,
  refresh,
  onBillingClosed,
  disabled,
}: BolContainerBillingRowProps) {
  const entityQty = useMemo<CodeEntityQuantity>(() => {
    return (
      entityQuantities.find((q) => q.userDefinedCodeId == code.id) || {
        userDefinedCodeId: code.id,
        quantity: 0,
      }
    );
  }, [entityQuantities, code]);
  const [isBillingCompleted, setIsBillingCompleted] = useState(false);

  const setQtyForm = useApiForm(
    UserDefinedCodeService.setCodeQuantityForEntity,
    {
      entityType: entityType,
      entityId: entityId,
      groupId: code.userDefinedCodeGroupId,
      userDefinedCodeId: code.id,
      quantity: 0,
    },
    {
      onSuccess: refresh,
      onError: (e) => {
        if (e === 'Billing has been closed for this container') {
          onBillingClosed();
          setIsBillingCompleted(true);
          refresh();
        }
      },
    }
  );

  const QUANTITY_MAX_DECIMAL_PLACES = 4;

  /// Handle floating-point issues, e.g. with 3.2 + 0.1 == 3.3000000000000003
  const newQtyAsString = (qty: number): string => {
    return qty
      ? qty
          .toFixed(QUANTITY_MAX_DECIMAL_PLACES)
          .replace(/(\.[0-9]*?)0+$/, '$1')
          .replace(/\.$/, '')
      : '';
  };

  // only submit the new data onBlur or after 1 second debounce
  const timeoutHandle = useRef<NodeJS.Timer | null>(null);
  const [qty, setNewQty] = useState(newQtyAsString(entityQty.quantity));
  const newQty = Number(qty);

  // update newQty when new data from the server, unless we're waiting to send or sending
  useEffect(() => {
    if (
      !timeoutHandle.current &&
      !setQtyForm.processing &&
      entityQty.quantity != newQty
    ) {
      setNewQty(newQtyAsString(entityQty.quantity));
    }
  }, [entityQty, setNewQty]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitQty = useCallback(
    (qty: number) => {
      if (timeoutHandle.current) {
        clearTimeout(timeoutHandle.current);
        timeoutHandle.current = null;
      }
      setQtyForm.setData('quantity', qty);
      setQtyForm.submit();
    },
    [setQtyForm]
  );

  const debounceQty = useCallback(
    (qty: string | number) => {
      const qtyStr = typeof qty === 'string' ? qty : newQtyAsString(qty);
      setNewQty(qtyStr);

      const qtyDecimal = Number(qty);
      if (timeoutHandle.current) {
        clearTimeout(timeoutHandle.current);
      }
      // pass qtyDecimal direct to the submitQty() function rather than using newQty - react can be weird!
      timeoutHandle.current = setTimeout(() => {
        submitQty(qtyDecimal);
      }, 1000);
      return true;
    },
    [setNewQty, submitQty]
  );

  /// Increment/Decrement by the smallest decimal place, e.g. for 1.23 this value should be 0.01
  const numberIncrement = (qty: number) => {
    const decimalPlaces = newQtyAsString(qty).split('.')[1]?.length || 0;
    return 1 / Math.pow(10, decimalPlaces);
  };

  return (
    <Stack direction="row" alignItems="center">
      <Box flexGrow={1} sx={{ paddingTop: '5px' }}>
        {code.name}
      </Box>
      {!disabled && !isBillingCompleted && (
        <IconButton
          disabled={!newQty}
          onClick={() => debounceQty(newQty - numberIncrement(newQty))}
          data-testid={'code-down-' + code.id}
        >
          <RemoveCircleOutline />
        </IconButton>
      )}
      <Box
        minWidth="60px"
        width={36 + newQtyAsString(newQty).length * 8 + 'px'}
        sx={{ marginRight: disabled ? '40px' : '0px' }}
      >
        <TextField
          margin="dense"
          size="small"
          type="number"
          placeholder="Qty"
          disabled={disabled || isBillingCompleted}
          value={qty}
          onChange={(e) => debounceQty(e.target.value)}
          onBlur={() => {
            setNewQty(newQtyAsString(newQty));
            submitQty(newQty);
          }}
          inputProps={{
            'data-testid': 'code-qty-' + code.id,
            inputMode: 'numeric',
            pattern: '[0-9]*\\.?[0-9]{0,4}',
            onKeyDown: onlyAllowMatchingPattern,
            min: 0,
            step: numberIncrement(newQty),
            sx: {
              // hide the up/down arrows in the number input because we've got our own
              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                // Remove -webkit prefix to fix error in the console
                // "Using kebab-case for css properties in objects is not supported"
                appearance: 'none',
              },
            },
          }}
        />
      </Box>
      {!disabled && !isBillingCompleted && (
        <IconButton
          onClick={() => debounceQty(newQty + numberIncrement(newQty))}
          data-testid={'code-up-' + code.id}
        >
          <AddCircleOutline />
        </IconButton>
      )}
    </Stack>
  );
}

import { Warehouse } from '../Models/Warehouse';
import Api, {
  PagedAndSortedRequest,
  PagedResult,
  ServiceGetMethod,
} from './Api';

/**
 * This Service provides api endpoints for interacting with its related entity via the Api Service
 * Note that assigning warehouses to users is done via UserService.CreateOrUpdateUser
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const getWarehouses: ServiceGetMethod<
  PagedAndSortedRequest,
  PagedResult<Warehouse>
> = (options) => Api.get('api/services/app/Warehouse/GetAll', options);

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  getWarehouses,
};

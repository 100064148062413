import { Box, Skeleton } from '@mui/material';
import { BoxProps } from '@mui/system';
import React from 'react';

export default function ({
  count,
  height = 50,
  gap = 1,
  ...boxProps
}: {
  count: number;
  height?: number;
  gap?: number | string;
} & BoxProps) {
  if (!count) {
    /* istanbul ignore next */
    return null;
  }

  return (
    <Box data-testid="skeletons" {...boxProps}>
      {Array.from(Array(count)).map((a, index) => (
        <Skeleton
          variant="rectangular"
          height={height}
          animation="wave"
          key={index}
          sx={{ mb: index < count - 1 ? gap : 0 }}
        />
      ))}
    </Box>
  );
}

import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import FormField from '../Forms/FormField';
import useApiForm from '../../Hooks/useApiForm';
import BillOfLadingService from '../../Services/BillOfLadingService';

/***
 * This modal allows the Admin to fetch a new BOL from Basis
 */

export interface FetchBolProps {
  onClose: () => void;
  onSuccess: () => void;
}

export default function ({ onClose, onSuccess }: FetchBolProps) {
  const form = useApiForm(
    BillOfLadingService.getByRemoteId,
    {
      bolNumber: '',
    },
    {
      // pass the success to the parent event handler which will close the modal and refresh its data
      onSuccess,
      suppressError: true,
      onError: (message) => {
        form.setErrors({ bolNumber: message });
      },
    }
  );

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="xs">
      <Box component="form" onSubmit={form.submit} noValidate>
        <DialogTitle>Fetch Bill of Lading from Basis</DialogTitle>
        <DialogContent>
          <FormField
            form={form}
            id="bolNumber"
            type="text"
            label="BoL Number"
            required
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={form.processing}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={form.processing}>
            Fetch from Basis
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

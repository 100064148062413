import Api, { ServiceGetMethod, ServicePostMethod } from './Api';
import {
  LocationWithPalletsCount,
  ScanLocationRequestDto,
  Stocktake,
} from '../Models/Stocktake';
import {
  StocktakePallet,
  MarkStocktakePalletStatusRequestDto,
} from '../Models/StocktakePallet';

const getAllStocktakes: ServiceGetMethod<void, Stocktake[]> = (options) =>
  Api.get('api/services/app/Stocktake/GetStocktakes', options);

const getLocationWithPalletsCounts: ServiceGetMethod<
  { currentWarehouseId: number | null | undefined; storeId: number },
  LocationWithPalletsCount[]
> = (options) =>
  Api.get('api/services/app/Stocktake/GetLocationWithPalletsCounts', options);

const createStocktake: ServicePostMethod<{ locationIds: number[] }, void> = (
  data,
  options
) => Api.post(`api/services/app/Stocktake/CreateStocktake`, data, options);

const getStocktakesByStore: ServiceGetMethod<
  { warehouseId: number | null | undefined; storeId: string | number | null },
  Stocktake[]
> = (options) =>
  Api.get('api/services/app/Stocktake/GetStocktakesByStore', options);

const scanStocktakePalletLocation: ServicePostMethod<
  ScanLocationRequestDto,
  StocktakePallet[]
> = (data, options) =>
  Api.post(
    'api/services/app/Stocktake/ScanStocktakePalletLocation',
    data,
    options
  );

const markStocktakePalletStatus: ServicePostMethod<
  MarkStocktakePalletStatusRequestDto,
  void
> = (data, options) =>
  Api.put(
    'api/services/app/Stocktake/MarkStocktakePalletStatus',
    data,
    options
  );

const onScanCompleted: ServicePostMethod<
  { stocktakeId: string | undefined },
  void
> = ({ stocktakeId }, options) =>
  Api.post(
    'api/services/app/Stocktake/OnScanCompleted?stocktakeId=' + stocktakeId,
    options
  );

export default {
  getAllStocktakes,
  getLocationWithPalletsCounts,
  createStocktake,
  getStocktakesByStore,
  scanStocktakePalletLocation,
  markStocktakePalletStatus,
  onScanCompleted,
};

import React, { useState } from 'react';
import { XmlTag } from '../../../Lib/xmlUtils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ECertProductProcessingHistory({
  product,
  tagNamePrefix = 'ed:',
}: {
  product: XmlTag;
  tagNamePrefix?: string;
}) {
  const [expandedAccordion, setExpandedAccordion] = useState('');
  const handleAccordionChanged =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : '');
    };
  const histories =
    product.getElementsByTagName?.('Process', tagNamePrefix) || [];

  return (
    <Accordion
      expanded={expandedAccordion === 'panelProductProcessingHistory'}
      onChange={handleAccordionChanged('panelProductProcessingHistory')}
      sx={{ padding: '8px' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="panelProductProcessingHistory-header"
      >
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          Processing History
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Premises</TableCell>
                <TableCell>Process</TableCell>
                <TableCell>Dates</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {histories.map((row, index) => {
                const premiseTag = row.getElementsByTagName?.(
                  'Premise',
                  tagNamePrefix
                )[0];
                const startDate =
                  row.getElementsByTagName?.('StartDate', tagNamePrefix)[0]
                    ?.value || '';
                const endDate =
                  row.getElementsByTagName?.('EndDate', tagNamePrefix)[0]
                    ?.value || '';

                return (
                  <TableRow
                    key={`ecert-product-process-history-${index}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {premiseTag?.getElementsByTagName?.(
                        'ID',
                        tagNamePrefix
                      )[0]?.value || ''}
                    </TableCell>
                    <TableCell>
                      {row.getElementsByTagName?.(
                        'ProcessTypeCode',
                        tagNamePrefix
                      )[0]?.value || ''}
                    </TableCell>
                    <TableCell>{`${startDate} ... ${endDate}`}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

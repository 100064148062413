import { Stack } from '@mui/material';
import { Carton, CartonStatus, CartonStatusChip } from '../../Models/Carton';
import React, { useMemo } from 'react';

interface CartonStatusLegendProps {
  cartons?: Carton[]; // to display the number of cartons in a badge
  status?: CartonStatus | null;
  onClick?: (cartonStatus: CartonStatus) => void;
}

const statuses: CartonStatus[] = [
  CartonStatus.expected,
  CartonStatus.verified,
  CartonStatus.new,
  CartonStatus.lost,
];

export default function ({
  cartons,
  status,
  onClick,
}: CartonStatusLegendProps) {
  const chips = useMemo(() => {
    return statuses.map((status) => ({
      status,
      count: cartons?.filter((carton) => carton.status === status).length || 0,
    }));
  }, [cartons]);

  return (
    <Stack direction="row" spacing={0.65}>
      {chips.map((chip) => (
        <CartonStatusChip
          status={chip.status}
          key={chip.status}
          data-testid={'carton-status-chip-' + chip.status}
          active={chip.status === status}
          badgeContent={chip.count}
          onClick={onClick ? () => onClick(chip.status) : undefined}
        />
      ))}
    </Stack>
  );
}

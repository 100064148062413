import React from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface UserBlobCardProps {
  name: string;
  asnCount: number;
  containerCount: number;
  backgroundColor?: string;
  textColor?: string;
  onNameClick?: () => void;
  onASNClick?: () => void;
  onContainerClick?: () => void;
  isUserSelected?: boolean;
  isASNBlockSelected?: boolean;
  isContainerBlockSelected?: boolean;
  testId?: string;
}

export default function UserBlobCard({
  name,
  asnCount,
  containerCount,
  onNameClick,
  onASNClick,
  onContainerClick,
  backgroundColor = '#fff',
  textColor = '#222',
  isUserSelected = false,
  isASNBlockSelected = false,
  isContainerBlockSelected = false,
  testId,
}: UserBlobCardProps) {
  const BlobCardItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    color: textColor,
    flex: 'auto 1 1',
    backgroundColor: backgroundColor,
    cursor: 'pointer',
  }));
  const wrapperTestId = testId ?? `user-blob-card-${name.replace(' ', '-')}`;
  return (
    <Paper
      data-testid={wrapperTestId}
      sx={{
        minWidth: 190,
        padding: 0.2,
        border: 'solid 2px transparent',
        '&.selected': {
          borderColor: backgroundColor,
        },
      }}
      className={isUserSelected ? 'selected' : ''}
      elevation={2}
    >
      <Stack direction="row" flexWrap="wrap" gap={0.5}>
        <BlobCardItem
          elevation={0}
          onClick={() => (onNameClick ? onNameClick() : undefined)}
          sx={{
            width: '100%',
            backgroundColor: backgroundColor,
          }}
        >
          <Typography noWrap data-testid={`${wrapperTestId}_username`}>
            {name}
          </Typography>
        </BlobCardItem>
        <BlobCardItem
          elevation={0}
          onClick={() => (onASNClick ? onASNClick() : undefined)}
        >
          <SubInfoBlock
            testId={`${wrapperTestId}_asn-info`}
            label={'ASNs'}
            countNumber={asnCount}
            isSelected={isASNBlockSelected}
          />
        </BlobCardItem>
        <BlobCardItem
          elevation={0}
          sx={{ width: '45%' }}
          onClick={() => (onContainerClick ? onContainerClick() : undefined)}
        >
          <SubInfoBlock
            testId={`${wrapperTestId}_container-info`}
            label={'CONTAINERS'}
            countNumber={containerCount}
            isSelected={isContainerBlockSelected}
          />
        </BlobCardItem>
      </Stack>
    </Paper>
  );
}

export function SubInfoBlock({
  label,
  countNumber,
  testId,
  isSelected = false,
}: {
  label: string;
  countNumber: number;
  isSelected?: boolean;
  testId?: string;
}) {
  return (
    <Stack
      direction={'column'}
      data-testid={testId ?? `block-${label.toLowerCase()}`}
    >
      <Typography
        sx={{
          fontSize: '0.85rem',
          '&.selected': {
            textDecoration: 'underline',
            fontWeight: 600,
          },
        }}
        className={isSelected ? 'selected' : ''}
        data-testid={'label-section'}
      >
        {label}
      </Typography>
      <Typography sx={{ fontWeight: 600 }} data-testid={'count-number-section'}>
        {countNumber}
      </Typography>
    </Stack>
  );
}

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import FormField from '../../Components/Forms/FormField';
import useApiForm from '../../Hooks/useApiForm';
import SettingService from '../../Services/SettingService';
import { Setting } from '../../Models/Setting';
import AuditLog from '../../Components/AuditLog/AuditLog';

/***
 * This modal allows the Admin to edit or create a Setting
 */

export interface EditSettingProps {
  setting?: Setting | null;
  onClose: () => void;
  onSuccess: () => void;
}

export default function ({ setting, onClose, onSuccess }: EditSettingProps) {
  const form = useApiForm(
    SettingService.updateSetting,
    {
      name: setting?.name || '',
      value: setting?.value || '',
    },
    {
      // pass the success to the parent event handler which will close the modal and refresh its data
      onSuccess,
    }
  );

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <Box component="form" onSubmit={form.submit} noValidate>
        <DialogTitle>
          {setting ? 'Update Setting' : 'Create Setting'}
        </DialogTitle>
        <DialogContent>
          <FormField
            form={form}
            id="name"
            type="text"
            label="Setting Name"
            required
            inputProps={{ 'data-lpignore': true }}
            disabled
          />
          <FormField
            form={form}
            id="value"
            type="text"
            label="Setting Value"
            autoFocus
            multiline
          />
        </DialogContent>
        <DialogActions>
          <AuditLog
            entityType="Coolpak.WMS.Authorization.Settings.Setting"
            entityId={setting!.name}
            tabIndex={300}
          />
          <Box flexGrow={1} />
          <Button onClick={onClose} disabled={form.processing} tabIndex={2}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={form.processing} tabIndex={1}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

import React, { useContext, useMemo, useState } from 'react';
import { AuthContext } from '../../Providers/AuthProvider';
import { Box, Fab, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import useDateFormatter from '../../Hooks/useDateFormatter';
import StandardDataGrid from '../../Components/StandardDataGrid';
import {
  BOLContainer,
  BOLContainerStatus,
  CONTAINER_LABELS,
  ContainerChip,
} from '../../Models/BOLContainer';
import BolContainerService from '../../Services/BolContainerService';
import CardTitle from '../../Components/CardTitle';
import MobilePage from '../../Components/MobilePage';
import useApiForm from '../../Hooks/useApiForm';
import {
  GridColDef,
  GridRenderCellParams,
  GridSortCellParams,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { toast, toastError } from '../../Components/Toast';
import { CloudSyncTwoTone } from '@mui/icons-material';
import FetchBol from '../../Components/BillOfLading/FetchBol';
import useApiGet from '../../Hooks/useApiGet';
import { ALLOW_EXPORT_LABELS } from '../../Models/BillOfLading';

export default function () {
  const { user } = useContext(AuthContext);
  const formatDate = useDateFormatter();
  const [containers, setContainers] = useState<BOLContainer[] | null>(null);
  const [open, setOpen] = useState(false);
  const canSyncBOL =
    user?.permissions?.find((p) => p === 'Pages.AdminBolLoadList') !==
    undefined;

  const statuses = useMemo(
    () => [
      BOLContainerStatus.open,
      BOLContainerStatus.loading,
      BOLContainerStatus.readyToPick,
      BOLContainerStatus.pickingInProgress,
      BOLContainerStatus.readyToLoad,
    ],
    []
  );

  const closeModal = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    toast(
      'Bill of Lading imported. Pallets and Cartons will sync in the background.'
    );
    closeModal();
    refresh();
  };

  const { loading, refresh } = useApiGet(
    BolContainerService.getContainersByStatuses,
    {
      params: {
        statuses,
        warehouseId: user?.currentWarehouseId,
      },
      onSuccess: (data) => {
        setContainers(data);
      },
    }
  );
  const updateContainerDetailsForm = useApiForm(
    BolContainerService.updateContainerPriority,
    {
      id: 0,
      loadType: '',
      priority: undefined,
      location: '',
    },
    {
      onSuccess: () => {
        refresh();
      },
      onError: (error) => {
        toastError(error);
      },
    }
  );

  const handleRowUpdate = async (
    updatedRow: BOLContainer,
    originalRow: BOLContainer
  ) => {
    if (
      updatedRow.loadType !== originalRow.loadType ||
      updatedRow.priority !== originalRow.priority ||
      updatedRow.location !== originalRow.location
    ) {
      updateContainerDetailsForm.setData('id', updatedRow.id);
      updateContainerDetailsForm.setData('loadType', updatedRow.loadType);
      updateContainerDetailsForm.setData('location', updatedRow.location);

      // Use a temporary variable to handle the conversion
      let priority: number | string | undefined = updatedRow.priority;

      // Convert empty string to undefined
      if (priority?.toString() === '') {
        priority = undefined;
      }

      updateContainerDetailsForm.setData('priority', priority);

      await updateContainerDetailsForm.submit();

      // returning the original row here. When the form is submitted, the grid will re-render
      // using this approach as if there was an error from the server, it's async and we won't be able to revert back to the original
      // row if there was an error, returning the original row ensures if there was an error, the original data is preserved.
      return originalRow;
    }
    return originalRow;
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'bolNumber',
        headerName: 'BOL',
        minWidth: 50,
        maxWidth: 150,
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <Link to={`/bol/${params.row.bolNumber}`}>
            {params.row.bolNumber}
          </Link>
        ),
      },
      {
        field: 'code',
        headerName: 'Container',
        minWidth: 130,
        maxWidth: 200,
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <Link to={`/bol/${params.row.bolNumber}/${params.row.code}`}>
            {params.row.code}
          </Link>
        ),
      },
      {
        field: 'shipDate',
        headerName: 'Ship Date',
        minWidth: 100,
        maxWidth: 200,
        valueGetter: (params: GridRenderCellParams<BOLContainer>) =>
          formatDate(params.row.shipDate || ''),
      },
      {
        field: 'customerName',
        headerName: 'Customer',
        minWidth: 150,
        maxWidth: 230,
      },
      {
        field: 'customerOrderNo',
        headerName: 'Customer Order #',
        minWidth: 140,
        maxWidth: 300,
      },
      {
        field: 'vesselName',
        headerName: 'Vessel',
        minWidth: 140,
        maxWidth: 300,
      },
      {
        field: 'loadType',
        headerName: 'Load Type',
        minWidth: 100,
        maxWidth: 200,
        editable: true,
      },
      {
        field: 'priority',
        headerName: 'Priority',
        minWidth: 100,
        maxWidth: 200,
        editable: true,
        sortComparator: (
          v1: number | null | string,
          v2: number | null | string,
          params: GridSortCellParams
        ): number => {
          const sortModel: GridSortModel = params.api.getSortModel();
          const sortDirection = sortModel.find(
            (model: GridSortItem) => model.field === params.field
          )?.sort;

          // If both values are null or empty strings, they are considered equal
          if ((v1 === null || v1 === '') && (v2 === null || v2 === '')) {
            return 0;
          }

          if (v1 === null || v1 === '') {
            return sortDirection === 'asc' ? 1 : -1;
          }

          if (v2 === null || v2 === '') {
            return sortDirection === 'asc' ? -1 : 1;
          }
          // Both values are numbers, so we can compare them directly
          return (v1 as number) - (v2 as number);
        },
      },
      {
        field: 'location',
        headerName: 'Staging Location',
        minWidth: 150,
        maxWidth: 250,
        editable: true,
      },
      {
        field: 'notes',
        headerName: 'Notes',
        minWidth: 200,
        maxWidth: 380,
        editable: false,
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <Tooltip title={params.row.notes}>
            <span>{params.row.notes?.substring(0, 30)}</span>
          </Tooltip>
        ),
      },
      {
        field: 'canExportBol',
        headerName: 'Export?',
        valueGetter: (params: GridRenderCellParams<BOLContainer>) =>
          ALLOW_EXPORT_LABELS[params.row.canExportBol ? 1 : 0],
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        maxWidth: 250,
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <ContainerChip status={params.row.status} />
        ),
        valueGetter: (params: GridRenderCellParams) =>
          CONTAINER_LABELS[params.row.status],
      },
    ],
    [formatDate]
  );

  const initialSortModel: GridSortModel = [
    {
      field: 'bolNumber',
      sort: 'asc',
    },
  ];

  return (
    <MobilePage sx={{ position: 'relative' }} maxWidth={false}>
      <Box sx={{ height: '82vh' }}>
        <CardTitle title="Load Plan" />
        {canSyncBOL && (
          <Fab
            color="primary"
            size="medium"
            data-testid="loadplan-datagrid-fab-button"
            sx={{
              position: 'absolute',
              right: 24,
              top: 10,
            }}
            onClick={() => setOpen(true)}
          >
            <CloudSyncTwoTone sx={{ top: '50px' }} />
          </Fab>
        )}
        <StandardDataGrid
          testId="load-plan-data-grid"
          loading={loading}
          columns={columns}
          rows={containers || []}
          getRowId={(row) => row.id}
          initialSortModel={initialSortModel}
          disableRowSelectionOnClick={true}
          onRowUpdate={handleRowUpdate}
        />
        {open && <FetchBol onClose={closeModal} onSuccess={onSuccess} />}
      </Box>
    </MobilePage>
  );
}

import React, { useMemo } from 'react';
import SortedTable, { SortedTableColumn } from '../../Components/SortedTable';
import { tryFormatDateStr } from '../../Lib/utils';
import useApiGet from '../../Hooks/useApiGet';
import PalletService from '../../Services/PalletService';
import { PalletMovementHistory } from '../../Models/PalletMovementHistory';

export interface PalletMovementProps {
  palletId: number | null;
}

export default function ({ palletId }: PalletMovementProps) {
  const { data, loading } = useApiGet(
    PalletService.getPalletMovementByPalletId,
    {
      params: {
        palletId: palletId,
      },
    }
  );

  const columnDefs = useMemo<SortedTableColumn<PalletMovementHistory>[]>(() => {
    return [
      {
        id: 'palletCode',
        label: 'Pallet',
        sortable: true,
      },
      {
        id: 'type',
        label: 'Type',
        sortable: true,
      },
      {
        id: 'cartonsQty',
        label: 'Carton Qty',
        sortable: true,
        numeric: true,
      },
      {
        id: 'cartonsWeight',
        label: 'Carton Weight',
        sortable: true,
        numeric: true,
      },
      {
        id: 'previousLocation',
        label: 'From Location',
        sortable: true,
      },
      {
        id: 'newLocation',
        label: 'To Location',
        sortable: true,
      },
      {
        id: 'creationTime',
        label: 'Created Time',
        sortable: true,
        cellRender: (data) =>
          tryFormatDateStr(data.creationTime, 'dd/MM/yyyy HH:mm:ss'),
      },
      {
        id: 'bolNumber',
        label: 'BOL Number',
        sortable: true,
      },
      {
        id: 'containerCode',
        label: 'Container Code',
        sortable: true,
      },
      {
        id: 'userName',
        label: 'User Name',
        sortable: true,
      },
      {
        id: 'notes',
        label: 'Notes',
        sortable: true,
      },
    ];
  }, []);

  return (
    <SortedTable
      columnDefs={columnDefs}
      data={data}
      loading={loading}
      title="Pallet Movement - History"
      rowKey="id"
      smallFont
      smallGap
      searchable
      pageSize="auto"
      exportable
    />
  );
}

import { Factory } from 'fishery';
import { faker } from '@faker-js/faker';

export interface Store {
  id: number;
  description: string;
  xName: string;
  yName: string;
  zName: string;
}

export const storeFactory = Factory.define<Store>(() => ({
  id: faker.datatype.number(),
  description: faker.datatype.string(),
  xName: faker.datatype.string(),
  yName: faker.datatype.string(),
  zName: faker.datatype.string(),
}));

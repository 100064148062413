import { Customer, CustomerSelectOption } from '../Models/Customer';
import Api, {
  PagedAndSortedSearchRequest,
  PagedResult,
  ServiceGetMethod,
  ServicePostMethod,
} from './Api';

/**
 * The CustomerService provides api endpoints for an Admin to operate on Customers.
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const get: ServiceGetMethod<{ id: number }, Customer> = (options) =>
  Api.get('api/services/app/Customer/Get', options);

const getCustomers: ServiceGetMethod<
  PagedAndSortedSearchRequest,
  PagedResult<Customer>
> = (options) => Api.get('api/services/app/Customer/GetAll', options);

const updateCustomer: ServicePostMethod<
  CreateOrUpdateCustomerRequest,
  Customer
> = (data, options) =>
  Api.put('api/services/app/Customer/Update', data, options);

const getAllCustomerNames: ServiceGetMethod<
  { includeSupportedProducts?: boolean },
  CustomerSelectOption[]
> = (options) =>
  Api.get('api/services/app/Customer/GetAllCustomerNames', options);

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  get,
  getCustomers,
  updateCustomer,
  getAllCustomerNames,
};

/**
 *  Models used in this service
 */

export interface CreateOrUpdateCustomerRequest {
  id: number;
  netsuiteId: string;
  billingCycle: string;
  // billingCodeIds: number[];
  adminId?: string;
  hardAssign?: boolean;
  thirdParties?: string[];
}

import Api, {
  PagedAndSortedRequest,
  PagedResult,
  ServiceGetMethod,
} from './Api';
import { PalletStatus } from '../Models/PalletStatus';

/**
 * This Service provides api endpoints for interacting with its related entity via the Api Service
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const getAll: ServiceGetMethod<
  PagedAndSortedRequest,
  PagedResult<PalletStatus>
> = (options) => Api.get('api/services/app/PalletStatus/GetAll', options);

// export a default object so we can use FooService.method in our code - which makes it clear what we're getting
export default {
  getAll,
};

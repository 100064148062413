import React, { useContext, useEffect } from 'react';
import MobilePage from '../../Components/MobilePage';
import { Stack } from '@mui/material';
import BillOfLadingService from '../../Services/BillOfLadingService';
import { AuthContext } from '../../Providers/AuthProvider';
import AdminReview from './AdminReview';

export default function () {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user?.currentWarehouseId) {
      BillOfLadingService.runToleranceCheck({
        warehouseId: user.currentWarehouseId,
      }).then();
    }
  }, [user]);

  return (
    <MobilePage maxWidth={false}>
      <Stack
        direction="row"
        spacing={2}
        flexWrap="wrap"
        justifyContent="space-around"
      >
        <AdminReview />
      </Stack>
    </MobilePage>
  );
}

import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import ECertService from '../../Services/ECertService';
import { ECert, ECertStatus } from '../../Models/BOLContainer';
import StandardDataGrid from '../../Components/StandardDataGrid';
import useApiGet from '../../Hooks/useApiGet';
import { Link, useParams } from 'react-router-dom';

export default function () {
  const { bolNumber: bolNumberParam } = useParams<{ bolNumber: string }>();
  const { data: eCerts, loading } = useApiGet(ECertService.getECertsForBol, {
    params: {
      bolNumber: bolNumberParam!,
    },
    noAutoFetch: false,
  });

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'certificatePublicId',
        headerName: 'ECert',
        flex: 1,
        renderCell: (params: GridRenderCellParams<ECert>) => (
          <Link
            to={`/bol/${bolNumberParam}/ecert/${params.row.certificatePublicId
              .split('/')
              .join('-')}`}
          >
            {params.row.certificatePublicId}
          </Link>
        ),
      },
      {
        field: 'bolContainerCodes',
        headerName: 'Container Numbers',
        flex: 1,
        renderCell: (params: GridRenderCellParams<ECert>) => {
          return params.row.bolContainerCodes?.map((code, index) => (
            <Typography fontSize={'14px'} key={`containers-${index}`}>
              {code}
            </Typography>
          ));
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params: GridRenderCellParams<ECert>) => {
          return params.row.status != null ? (
            <Typography fontSize={'14px'} sx={{ textTransform: 'capitalize' }}>
              {ECertStatus[params.row.status]}
            </Typography>
          ) : (
            ''
          );
        },
      },
    ],
    [bolNumberParam]
  );
  return (
    <StandardDataGrid
      testId={'ecerts-grid'}
      loading={loading}
      columns={columns}
      rows={eCerts || []}
    />
  );
}

import React, { useState, useContext } from 'react';
import { BOLContainer } from '../../../Models/BOLContainer';
import {
  Box,
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { StockOnHandPalletToFulfilBol } from '../../../Models/StockOnHand';
import CloseIcon from '@mui/icons-material/Close';
import AssignContainerList, {
  AssignContainerCallbackProps,
} from './AssignContainerList';
import BolContainerService from '../../../Services/BolContainerService';
import { AssignCartonsToContainerRequest } from '../../../Services/BolContainerService';
import { AuthContext } from '../../../Providers/AuthProvider';

interface AssignContainerPalletsProps {
  containers: BOLContainer[] | undefined;
  selectedPallets: StockOnHandPalletToFulfilBol[];
  bolLine: number;
  onRefresh: () => void;
}
const AssignContainerPallets = ({
  containers,
  selectedPallets,
  onRefresh,
  bolLine,
}: AssignContainerPalletsProps) => {
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [loadingId, setLoadingId] = useState(0);
  const { user } = useContext(AuthContext);

  const hasContainersAndPallets = !!(
    containers?.length && selectedPallets.length
  );

  const closeModalDialog = async ({
    refresh = false,
    containerId,
  }: AssignContainerCallbackProps = {}) => {
    setAssignModalOpen(false);
    if (containerId) {
      setLoadingId(containerId);
      const assignCartonsToContainerRequest: AssignCartonsToContainerRequest = {
        containerId: containerId,
        softAssign: true,
        warehouseId: user?.currentWarehouseId ?? null,
        billOfLadingLineId: bolLine,
        stockOnHandPallet: selectedPallets.map((item) => ({
          PalletId: item.pallet.id,
          ProductCode: item.productId,
          BatchNumber: item.batchNumber,
        })),
      };
      await BolContainerService.assignCartonsToContainer(
        assignCartonsToContainerRequest
      );
    }
    setLoadingId(0);
    if (refresh) {
      onRefresh();
    }
  };

  return (
    <>
      <Button
        data-testid={'AssignContainerButton'}
        onClick={() => setAssignModalOpen(true)}
        variant="contained"
        disabled={!hasContainersAndPallets}
      >
        Assign to Container
      </Button>
      {assignModalOpen && (
        <Dialog
          open
          fullWidth
          maxWidth="md"
          data-testid="modal-dialog"
          scroll="paper"
          onClose={closeModalDialog}
        >
          <DialogTitle data-testid="modal-dialog-title">
            Assign to Container
            <IconButton
              aria-label="close"
              data-testid="modal-close"
              onClick={() => closeModalDialog()}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent data-testid="AssignContainerDialog" dividers>
            <h3>Selected pallets:</h3>
            <Box>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Box sx={{ width: '15%' }}>Total Selected: </Box>
                <Box sx={{ width: '10%', textAlign: 'right' }}>
                  <span data-testid="dialog-total-pallet-count">
                    {selectedPallets.length}
                  </span>
                </Box>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Box sx={{ width: '15%' }}>Total Weight (kg): </Box>
                <Box sx={{ width: '10%', textAlign: 'right' }}>
                  <span data-testid="dialog-total-pallet-kg">
                    {selectedPallets
                      .reduce((totalKg, pallet) => {
                        return totalKg + pallet.weight;
                      }, 0)
                      .toFixed(2)}
                  </span>
                </Box>
              </Stack>
            </Box>
            <h3>Select container row to assign to</h3>
            <AssignContainerList
              containers={containers!}
              onClose={closeModalDialog}
              loadingId={loadingId}
            ></AssignContainerList>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default AssignContainerPallets;

import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import theme from '../../theme';
import Toast from '../../Components/Toast';
import AuthProvider from '../../Providers/AuthProvider';
import ResponsiveProvider from '../../Providers/ResponsiveProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enNZ from 'date-fns/locale/en-NZ';
import ModalDialog from '../../Components/ModalDialog';
import { LicenseInfo } from '@mui/x-license-pro';
import appConfig from '../../appConfig';

export default function () {
  // https://mui.com/x/introduction/licensing/#license-key-security
  LicenseInfo.setLicenseKey(appConfig.MUI_GRID_LICENSE_KEY);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enNZ}>
        <AuthProvider>
          <CssBaseline />
          <Router>
            <ResponsiveProvider>
              <App />
            </ResponsiveProvider>
            <Toast />
            <ModalDialog />
          </Router>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

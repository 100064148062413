import React, { useMemo, useState } from 'react';
import { pagedParams } from '../../Services/Api';
import CustomerService from '../../Services/CustomerService';
import SortedTable, { SortedTableColumn } from '../../Components/SortedTable';
import useApiGet from '../../Hooks/useApiGet';
import { Customer } from '../../Models/Customer';
import { toast } from '../../Components/Toast';
import EditCustomer from './EditCustomer';
import MobilePage from '../../Components/MobilePage';
import UserService from '../../Services/UserService';
import { userSelectItems } from '../../Models/User';
import { CODE_GROUPS } from '../../Models/UserDefinedCode';
import UserDefinedCodeService from '../../Services/UserDefinedCodeService';

/***
 * This is the Admin's list of customers
 */

export default function () {
  const { data, loading, refresh } = useApiGet(CustomerService.getCustomers, {
    params: pagedParams,
  });

  const { data: adminData } = useApiGet(UserService.getUsers, {
    params: {
      permissions: ['Customer.Admin'],
      isActive: true,
      maxResultCount: pagedParams.maxResultCount,
    },
  });
  const admins = useMemo(() => userSelectItems(adminData?.items), [adminData]);

  const billingCodeGroupName = CODE_GROUPS.BILLING_CODES;
  const { data: billingCodeGroup } = useApiGet(
    UserDefinedCodeService.getGroupDetails,
    { params: { name: billingCodeGroupName } }
  );

  const [editCustomer, setEditCustomer] = useState<Customer | null>(null);
  const [open, setOpen] = useState(false);

  const onCustomerEditClick = (customer: Customer) => {
    setEditCustomer(customer);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setEditCustomer(null);
  };

  const onSuccess = () => {
    toast('Customer updated!');
    closeModal();
    refresh();
  };

  const columnDefs = useMemo<SortedTableColumn<Customer>[]>(
    () => [
      {
        id: 'name',
        label: 'Name',
        sortable: true,
      },
      {
        id: 'code',
        label: 'Basis Code',
        sortable: true,
      },
      {
        id: 'billingCycle',
        label: 'Billing Cycle',
        sortable: true,
      },
      {
        id: 'netsuiteId',
        label: 'Netsuite Id',
        sortable: true,
      },
      {
        id: 'hardAssign',
        label: 'Hard Assign',
        sortable: true,
        value: (customer) => (customer.hardAssign ? 'True' : 'False'),
      },
      {
        id: 'adminId',
        label: 'Admin',
        sortable: true,
        value: (customer) =>
          adminData && customer.adminId
            ? adminData.items.find((a) => a.id === customer.adminId)
                ?.fullName ?? 'Archived admin'
            : '',
      },
    ],
    [adminData]
  );

  return (
    <MobilePage sx={{ position: 'relative' }}>
      <SortedTable
        columnDefs={columnDefs}
        data={data?.items}
        rowKey="id"
        sortOpts={{ id: 'name', dir: 'asc' }}
        title="Customers"
        loading={loading}
        localStorageKey="Customers.tableOpts"
        localStorageOptions={{ todayOnly: true, sessionOnly: true }}
        onRowClick={onCustomerEditClick}
        searchable
      />
      {open && editCustomer && billingCodeGroup && admins && (
        <EditCustomer
          customer={editCustomer}
          onClose={closeModal}
          onSuccess={onSuccess}
          admins={admins}
          billingCodeGroup={billingCodeGroup}
        />
      )}
    </MobilePage>
  );
}

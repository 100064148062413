import {
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, {
  ChangeEventHandler,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FileService, {
  CreateForEntityRequest,
} from '../../Services/FileService';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PhotoTaker, { MetaQuestionType } from './PhotoTaker';
import { WMSFile, FileEntityType, FileMetaData } from '../../Models/WMSFile';
import {
  readFile,
  sanitiseFilename,
  scaleAndRotateImageFromDataUrl,
} from '../../Lib/utils';
import { toastError } from '../Toast';
import { Close, Download, FilePresentTwoTone } from '@mui/icons-material';
import { AuthContext } from '../../Providers/AuthProvider';
import Lightbox from './Lightbox';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';
import JSZip from 'jszip';
import { LoadingButton } from '@mui/lab';
import { saveAs } from 'file-saver';
import { axiosHttp } from '../../Services/Api';
import useApiForm from '../../Hooks/useApiForm';
import { alertModalDialog, confirmModalDialog } from '../ModalDialog';
import DOMPurify from 'dompurify';

/**
 * This component renders a "Camera" icon button which when clicked opens the media library with optional upload UI
 */

// extend the WMSFile object so we can store additional state on it
interface LocalFile extends Omit<WMSFile, 'key'> {
  id: number;
  local: true;
  uploading?: boolean;
  dataUrl: string;
}

interface GroupFile {
  label: string;
  allFiles: (LocalFile | WMSFile)[];
}

// type guard to help us determine if a 'file' is local or uploaded
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
export const isUploadedFile = (file: WMSFile | LocalFile): file is WMSFile =>
  'key' in file;

// give local files a temporary -ve id
let nextLocalFileId = -1;

export interface MediaLibraryProps {
  entityTypes: FileEntityType[]; // the type of entity to fetch/upload media for
  entityIds: number[]; // the id(s) of the EntityType
  labels?: string[]; // labels for each entity, if entityIds is a comma separated list
  allowUpload?: boolean; // defaults to false
  allowDelete?: boolean; // defaults to false
  autoOpenCamera?: boolean; // default to false
  metaData?: FileMetaData[];
  questions?: MetaQuestionType[];
  newFilePrefix?: string;
  zipName?: string;
  onUploaded?: () => void;
  required?: boolean;
  combineGroups?: boolean; // default to false
}

export default function ({
  entityTypes,
  entityIds,
  labels,
  allowUpload,
  allowDelete,
  autoOpenCamera,
  metaData,
  questions,
  zipName,
  newFilePrefix = 'photo',
  onUploaded,
  required,
  combineGroups,
}: MediaLibraryProps) {
  const { application } = useContext(AuthContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const form = useApiForm(
    FileService.GetAllForEntities,
    {
      entityTypes: entityTypes.join(','),
      entityIds: entityIds.join(','),
    },
    {
      onSuccess: (files) => {
        setFiles(files);
      },
    }
  );

  const fileInput = useRef<HTMLInputElement>(null);
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);
  const [photoTakerOpen, setPhotoTakerOpen] = useState(false);
  const [pendingFiles, setPendingFiles] = useState<LocalFile[]>([]);
  const [lightboxFile, setLightboxFile] = useState<WMSFile | null>(null);
  const { mobileView } = useContext(ResponsiveContext);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [downloading, setDownloading] = useState(false);
  const [deletedFileIds, setDeletedFileIds] = useState<number[]>([]);
  const allowDownload = !mobileView && !!zipName;
  const [files, setFiles] = useState<WMSFile[]>([]);

  useEffect(() => {
    // each time we open the media library, auto-open the camera if eligible
    if (mediaLibraryOpen) {
      setPhotoTakerOpen(!!allowUpload && !!autoOpenCamera);
    }
  }, [mediaLibraryOpen, setPhotoTakerOpen, allowUpload, autoOpenCamera]);

  const onFileInputChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    e.preventDefault();
    if (!e.target.files) {
      return;
    }
    for (const file of Array.from(e.target.files)) {
      let dataUrl = await readFile(file);
      const type = file.name.split('.').slice(-1)[0]; // get "jpg" from 'filename.jpg'
      let name = `${newFilePrefix}.${file.name}`;
      if (file.type == 'image/jpeg' || file.type == 'image/png') {
        dataUrl = await scaleAndRotateImageFromDataUrl(
          dataUrl,
          application!.photoWidth,
          application!.photoQuality,
          0
        );
        name = `${newFilePrefix}.${new Date().toISOString()}.${type}`;
      }
      uploadFile({
        id: nextLocalFileId--, // decrement the next local file id
        local: true,
        entityType: entityTypes[0],
        entityId: entityIds[0],
        name,
        mimeType: file.type,
        size: file.size,
        metaData: metaData ? JSON.stringify(metaData) : '',
        dataUrl,
        creationTime: new Date().toISOString(),
      });
    }

    // reset the file input so we can select the same file again
    e.target.value = '';
  };

  const onCapturePhoto = (dataUrl: string, metaData: FileMetaData[]) => {
    uploadFile({
      id: nextLocalFileId--, // decrement the next local file id
      local: true,
      entityType: entityTypes[0],
      entityId: entityIds[0],
      name: `${newFilePrefix}.${new Date().toISOString()}.jpg`,
      mimeType: 'image/jpg',
      size: dataUrl.length,
      metaData: metaData ? JSON.stringify(metaData) : '',
      dataUrl,
      creationTime: new Date().toISOString(),
    });
  };

  const uploadFile = async (file: LocalFile) => {
    file.uploading = true;
    pendingFiles.push(file);
    setPendingFiles([...pendingFiles]);

    // send the file to the server without the local data
    const request: CreateForEntityRequest = {
      ...file,
      base64: file.dataUrl.split(',')[1], // remove prefix from "data:image/jpeg;base64,/9j/4AAQSkZJR....."
    };
    delete (request as { dataUrl?: string }).dataUrl;
    delete (request as { id?: number }).id;
    const [result] = await FileService.create(request);
    if (result) {
      file.id = result.id;
      setPendingFiles([...pendingFiles]);
      form.submit();
      onUploaded?.();
    }
  };

  // get the media when first rendering the component
  useEffect(() => {
    form.submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // refresh the media
  useEffect(() => {
    const interval = setInterval(() => {
      form.submit();
    }, (application!.basisSyncPeriodMins || 10) * 60 * 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when the data changes, remove any pending files which were just uploaded
  useEffect(() => {
    const fileIds = (files || []).map((f) => f.id);
    setPendingFiles([
      ...pendingFiles.filter((f) => !f.id || !fileIds.includes(f.id)),
    ]);
  }, [files, setPendingFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  // combine stored files with local files pending upload...
  const allFiles = useMemo(() => {
    // remove any pending and deleted files from the actual files which have been uploaded
    const pendingFileIds = pendingFiles.map((f) => f.id);
    const filteredFiles = (files || []).filter(
      (f) => !deletedFileIds.includes(f.id) && !pendingFileIds.includes(f.id)
    );
    return [...filteredFiles, ...pendingFiles].sort((a, b) =>
      a.creationTime.localeCompare(b.creationTime)
    );
  }, [files, pendingFiles, deletedFileIds]);

  const groups = useMemo(() => {
    if (!labels) {
      return [
        {
          label: '',
          allFiles,
        },
      ];
    }

    const groups: GroupFile[] = labels.map((label, index) => ({
      label,
      allFiles: allFiles.filter(
        (f) =>
          f.entityType == entityTypes[index] && f.entityId == entityIds[index]
      ),
    }));

    // group all files with the same label
    if (combineGroups) {
      return groups
        .reduce((acc: GroupFile[], curr: GroupFile) => {
          const existingItem = acc.find((item) => item.label === curr.label);
          if (existingItem) {
            existingItem.allFiles.push(...curr.allFiles);
          } else {
            acc.push({ ...curr });
          }

          return acc;
        }, [])
        .filter((item) => item.allFiles.length > 0); // filter out items with empty allFiles array
    }

    return groups;
  }, [allFiles, entityTypes, entityIds, labels, combineGroups]);

  const deleteFile = async (file: WMSFile) => {
    if (required && allFiles.length === 1) {
      alertModalDialog({
        title: '',
        content: 'You have to upload another photo before deleting this one.',
      });
      return;
    }
    const yes = await confirmModalDialog({
      title: 'Delete Photo',
      content: 'Are you sure you want to delete this photo?',
    });
    if (yes) {
      // use Optimistic UI - assume this works, then display the file again if it doesn't
      setDeletedFileIds([...deletedFileIds, file.id]);
      const [, error] = await FileService.deleteById(file.id);
      if (error) {
        setDeletedFileIds([...deletedFileIds.filter((f) => f != file.id)]);
      }
    }
  };

  const onDownload = async () => {
    setDownloading(true);
    try {
      const zip = JSZip();
      for (const group of groups) {
        const selectedFiles = group.allFiles.filter((file) =>
          selectedIds.includes(file.id)
        );
        if (!selectedFiles.length) {
          continue;
        }
        const folder =
          labels && group.label
            ? zip.folder(sanitiseFilename(group.label))!
            : zip;
        for (const file of selectedFiles) {
          const data = await axiosHttp({
            url: FileService.makeProxyUrl(file as WMSFile),
            responseType: 'arraybuffer',
          });
          folder.file(sanitiseFilename(file.name), data.data);
        }
      }
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, zipName);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.error(e);
      toastError(e.toString());
    }
    setDownloading(false);
  };

  const openFile = (file: WMSFile | LocalFile) => {
    if (!isUploadedFile(file)) {
      return;
    }
    if (file.mimeType.startsWith('image/')) {
      setLightboxFile(file);
    } else {
      const url = FileService.makeProxyUrl(file);
      saveAs(url, file.name);
    }
  };

  const iconButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <IconButton
        ref={iconButtonRef}
        color="primary"
        data-testid="media-library-open-btn"
        onClick={() => {
          setMediaLibraryOpen(true);
          if (iconButtonRef.current) {
            iconButtonRef.current.blur();
          }
        }}
        tabIndex={-1}
      >
        <Badge badgeContent={allFiles.length} color="info">
          <CameraAltIcon
            color={required && allFiles?.length == 0 ? 'error' : 'inherit'}
          />
        </Badge>
      </IconButton>
      <Dialog
        open={mediaLibraryOpen}
        onClose={() => setMediaLibraryOpen(false)}
        fullWidth
        maxWidth={false}
        fullScreen={fullScreen}
      >
        <DialogTitle display="flex">
          <Box flexGrow={1}>Media Library</Box>
          {allowDownload && selectedIds.length < allFiles.length && (
            <Button
              variant="text"
              onClick={() => setSelectedIds(allFiles.map((f) => f.id))}
            >
              Select All
            </Button>
          )}
          {allowDownload && selectedIds.length > 0 && (
            <Button variant="text" onClick={() => setSelectedIds([])}>
              Select None
            </Button>
          )}
          {allowDownload && (
            <LoadingButton
              variant="contained"
              size="small"
              endIcon={<Download />}
              disabled={!selectedIds.length}
              onClick={onDownload}
              loading={downloading}
            >
              Download
            </LoadingButton>
          )}
        </DialogTitle>
        <DialogContent>
          {form.processing && !files && (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}
          {groups.map((group) => (
            <div key={group.label} data-testid={'media-group-' + group.label}>
              {group.label && (
                <Typography variant="subtitle2" display="flex">
                  <Box flexGrow={1}>{group.label}</Box>
                  {allowDownload &&
                    group.allFiles.some((f) => !selectedIds.includes(f.id)) && (
                      <Button
                        variant="text"
                        onClick={() =>
                          setSelectedIds(
                            Array.from(
                              new Set([
                                ...selectedIds,
                                ...group.allFiles.map((f) => f.id),
                              ])
                            )
                          )
                        }
                      >
                        Select All
                      </Button>
                    )}
                  {allowDownload &&
                    group.allFiles.some((f) => selectedIds.includes(f.id)) && (
                      <Button
                        variant="text"
                        onClick={() =>
                          setSelectedIds(
                            selectedIds.filter((id) =>
                              group.allFiles.every((f) => f.id != id)
                            )
                          )
                        }
                      >
                        Select None
                      </Button>
                    )}
                </Typography>
              )}
              {group.allFiles.length ? (
                <ImageList
                  sx={{
                    width: '100%',
                    my: 0,
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr) !important',
                      sm: 'repeat(2, 1fr) !important',
                      md: 'repeat(3, 1fr) !important',
                      lg: 'repeat(4, 1fr) !important',
                      xl: 'repeat(5, 1fr) !important',
                    },
                  }}
                >
                  {group.allFiles.map((file) => (
                    <ImageListItem
                      key={file.id}
                      onClick={() => openFile(file)}
                      sx={{
                        cursor: isUploadedFile(file) ? 'pointer' : undefined,
                        '.MuiImageListItem-img': {
                          objectFit: 'contain',
                          maxHeight: '200px',
                        },
                      }}
                    >
                      {file.mimeType.startsWith('image/') ? (
                        <img
                          src={
                            isUploadedFile(file)
                              ? FileService.makeProxyUrl(file)
                              : file.dataUrl
                          }
                          alt={file.name}
                          data-testid={'img-' + file.id}
                        />
                      ) : (
                        <div
                          data-testid={'icon-' + file.id}
                          style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <FilePresentTwoTone
                            fontSize="large"
                            sx={{
                              width: '100%',
                              flexGrow: '1',
                              minHeight: '100px',
                            }}
                          />
                          <Typography variant="body2" display="flex">
                            {file.name}
                          </Typography>
                        </div>
                      )}
                      {allowDownload && isUploadedFile(file) && (
                        <Checkbox
                          checked={selectedIds.includes(file.id)}
                          color="success"
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            e.stopPropagation();
                            if (selectedIds.includes(file.id)) {
                              setSelectedIds(
                                selectedIds.filter((id) => id != file.id)
                              );
                            } else {
                              setSelectedIds([...selectedIds, file.id]);
                            }
                          }}
                          data-testid={'select-btn-' + file.id}
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            // apply a white drop-shadow to the icons in case the image behind them is very dark
                            filter:
                              'drop-shadow(2px 2px 2px rgba(255,255,255,0.7))',
                          }}
                        />
                      )}
                      {allowDelete && isUploadedFile(file) && (
                        <IconButton
                          size="small"
                          color="error"
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            // apply a black drop-shadow to the icons in case the image behind them is very light
                            filter: 'drop-shadow(2px 2px 2px black)',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteFile(file);
                          }}
                          data-testid={'delete-btn-' + file.id}
                        >
                          <Close />
                        </IconButton>
                      )}
                      {!isUploadedFile(file) && file.uploading && (
                        <Box
                          sx={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            background: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      )}
                      <ImageListItemBar
                        subtitle={<RenderMeta meta={file.metaData} />}
                        position="below"
                        sx={{
                          '.MuiImageListItemBar-title': {
                            whiteSpace: 'normal',
                            fontSize: '14px',
                          },
                          '& ul': {
                            paddingLeft: '25px',
                          },
                        }}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              ) : (
                <Typography variant="body1" mb={2}>
                  No Photos
                </Typography>
              )}
            </div>
          ))}
          <input
            ref={fileInput}
            type="file"
            onChange={onFileInputChange}
            style={{ display: 'none' }}
            data-testid="file-input"
          />
          {photoTakerOpen && (
            <PhotoTaker
              metaData={metaData}
              questions={questions}
              onPhoto={onCapturePhoto}
              onClose={() => setPhotoTakerOpen(false)}
            />
          )}
          {lightboxFile && (
            <Lightbox
              files={allFiles.filter(isUploadedFile)}
              file={lightboxFile}
              onClose={() => setLightboxFile(null)}
              selectedIds={allowDownload ? selectedIds : undefined}
              setSelectedIds={setSelectedIds}
            />
          )}
        </DialogContent>
        <DialogActions>
          {allowUpload && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => fileInput.current!.click()}
              data-testid="select-btn"
            >
              Select file
            </Button>
          )}
          {allowUpload && (
            <IconButton
              color="primary"
              onClick={() => setPhotoTakerOpen(true)}
              data-testid="take-photo-btn"
            >
              <CameraAltIcon />
            </IconButton>
          )}
          <Box flexGrow={1} />
          <Button onClick={() => setMediaLibraryOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

/**
 * Render each meta data item in a compact table
 */
function RenderMeta({ meta }: { meta: string }) {
  const isHtmlLabel = (label: string) => {
    const doc = new DOMParser().parseFromString(label, 'text/html');
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  };
  const rows = useMemo<FileMetaData[]>(() => {
    if (!meta) {
      return [];
    }
    try {
      return JSON.parse(meta);
    } catch (e) {
      console.error('Error parsing photo meta data', meta, e);
      toastError('Error parsing photo meta data');
    }
    return [];
  }, [meta]);

  if (!rows.length) {
    return null;
  }

  return (
    <table border={0} width="100%">
      <tbody>
        {rows.map((row) => (
          <tr key={row.label}>
            <th
              style={{
                width: '90%',
                textAlign: 'left',
                whiteSpace: 'break-spaces',
              }}
            >
              {/* NOTE: we can alter the sanitize config, but the default is quite secure */}
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(row.label),
                }}
              />
              {/*Only show colons if the label is text*/}
              {!isHtmlLabel(row.label) && ':'}
            </th>
            <td
              style={{
                whiteSpace: 'normal',
                textAlign: 'right',
                verticalAlign: 'text-top',
                paddingRight: '5px',
                paddingTop: isHtmlLabel(row.label) ? '16px' : '0',
              }}
            >
              {row.value}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

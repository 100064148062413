import React, { useMemo } from 'react';
import { Box, Button, darken, Stack } from '@mui/material';

import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import {
  getShortenBarcode,
  STOCKTAKE_PALLET_STATUS_COLORS,
  STOCKTAKE_PALLET_STATUS_LABELS,
  StocktakePallet,
  StocktakePalletStatus,
} from '../../../Models/StocktakePallet';
import PalletStatusLegend from './PalletStatusLegend';

interface StocktakePalletsWithStatusFilterProps {
  stocktakePallets?: StocktakePallet[];
  status?: StocktakePalletStatus | null;
  onStatusClick?: (StocktakePalletStatus: StocktakePalletStatus) => void;
  onCompleteScanning: () => void;
  stocktakePalletColumns: GridColDef[];
  onRowSelectionChange?: (row: GridRowSelectionModel) => void;
  rowSelectionModel?: GridRowSelectionModel;
}

export default function ({
  stocktakePallets,
  status,
  onStatusClick,
  stocktakePalletColumns,
  onCompleteScanning,
  onRowSelectionChange,
  rowSelectionModel,
}: StocktakePalletsWithStatusFilterProps) {
  // we can not use DataGrid's sortModel because we are sorting on the status column that is not visible
  const sortedStocktakePallets = useMemo(() => {
    const stocktakePalletsFilteredByStatus =
      status !== null
        ? stocktakePallets?.filter((c) => c.status === status)
        : stocktakePallets;
    // divide the pallets in two groups : expected pallets and others
    const expectedStocktakePallets = stocktakePalletsFilteredByStatus?.filter(
      (c) => c.status === StocktakePalletStatus.expected
    );

    const otherStocktakePallets = stocktakePalletsFilteredByStatus?.filter(
      (c) => c.status !== StocktakePalletStatus.expected
    );
    // sort each group by the last ten digits of the barcode (we only display ten digits because the barcode is too long)
    const sortByDisplayedDigit = (a: StocktakePallet, b: StocktakePallet) =>
      getShortenBarcode(a.palletBarcode).localeCompare(
        getShortenBarcode(b.palletBarcode)
      );
    const expectedStocktakePalletsSorted =
      expectedStocktakePallets?.sort(sortByDisplayedDigit) ?? [];
    const otherStocktakePalletsSorted =
      otherStocktakePallets?.sort(sortByDisplayedDigit) ?? [];

    return [...expectedStocktakePalletsSorted, ...otherStocktakePalletsSorted];
  }, [stocktakePallets, status]);

  return (
    <Box
      data-testid="list-pallets-with-status-filter"
      sx={{
        width: '100%',
      }}
    >
      <Stack
        direction="column"
        justifyContent={'space-between'}
        spacing={2}
        mb={1}
      >
        <Button
          data-testid="pallet-scanning-complete-button"
          sx={{ width: 'fit-content', alignSelf: 'end' }}
          variant="contained"
          onClick={onCompleteScanning}
          size="small"
        >
          Scanning Complete
        </Button>
        <PalletStatusLegend
          pallets={stocktakePallets}
          onClick={onStatusClick}
          status={status}
        />
      </Stack>
      <DataGridPro
        disableColumnFilter
        disableColumnMenu
        autoHeight
        onRowSelectionModelChange={(newRowSelectionModel) =>
          onRowSelectionChange?.(newRowSelectionModel)
        }
        rowSelectionModel={rowSelectionModel ? rowSelectionModel : undefined}
        data-testid="stocktakePallets-grid"
        hideFooter
        rows={sortedStocktakePallets ?? []}
        columns={stocktakePalletColumns}
        sx={{
          backgroundColor: 'white',
          '& .pallet-status--1': {
            backgroundColor:
              STOCKTAKE_PALLET_STATUS_COLORS[
                STOCKTAKE_PALLET_STATUS_LABELS[StocktakePalletStatus.verified]
              ].bg,
            '&:hover': {
              backgroundColor: darken(
                STOCKTAKE_PALLET_STATUS_COLORS[
                  STOCKTAKE_PALLET_STATUS_LABELS[StocktakePalletStatus.verified]
                ].bg,
                0.1
              ),
            },
          },
          '& .pallet-status--2': {
            backgroundColor:
              STOCKTAKE_PALLET_STATUS_COLORS[
                STOCKTAKE_PALLET_STATUS_LABELS[StocktakePalletStatus.new]
              ].bg,
            '&:hover': {
              backgroundColor: darken(
                STOCKTAKE_PALLET_STATUS_COLORS[
                  STOCKTAKE_PALLET_STATUS_LABELS[StocktakePalletStatus.new]
                ].bg,
                0.1
              ),
            },
          },
          '& .pallet-status--3': {
            backgroundColor:
              STOCKTAKE_PALLET_STATUS_COLORS[
                STOCKTAKE_PALLET_STATUS_LABELS[StocktakePalletStatus.lost]
              ].bg,
            '&:hover': {
              backgroundColor: darken(
                STOCKTAKE_PALLET_STATUS_COLORS[
                  STOCKTAKE_PALLET_STATUS_LABELS[StocktakePalletStatus.lost]
                ].bg,
                0.1
              ),
            },
          },
        }}
        getRowClassName={(params) =>
          `pallet-status--${params.row.StocktakePalletStatus}`
        }
      />
    </Box>
  );
}

import React from 'react';
import MobilePage from '../../Components/MobilePage';

import { MovePallets } from '../../Components/MovePallets';

export default function () {
  return (
    <MobilePage showOnDesktop showTitle title="Move Pallets">
      <MovePallets />
    </MobilePage>
  );
}

import { Box, Typography } from '@mui/material';
import {
  DataGridPremium,
  GridColDef,
  GridFilterModel,
  GridRowModel,
  GridSortModel,
  GridRowSelectionModel,
  GridToolbar,
  GridColumnVisibilityModel,
  GridRowParams,
  useGridApiRef,
  GridRowCount,
  ToolbarPropsOverrides,
} from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import {
  gridExpandedSortedRowIdsSelector,
  GridToolbarProps,
} from '@mui/x-data-grid-pro';

const standardDataGridStyleRules = {
  headerLabelColor: 'white',
  headerIconColor: 'white',
  headerBackgroundColor: 'primary.main',
  headerBorderRadius: 0,
} as const;

/**
 * StandardDataGrid styles.
 * Header row: white text and icons on primary background.
 */
export const standardDataGridStyles = {
  '& .MuiDataGrid-columnHeaderTitle': {
    color: standardDataGridStyleRules.headerLabelColor,
  },
  '& .MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeader': {
    backgroundColor: standardDataGridStyleRules.headerBackgroundColor,
    borderRadius: standardDataGridStyleRules.headerBorderRadius,
  },
  '& .MuiDataGrid-iconButtonContainer *, .MuiDataGrid-menuIcon *': {
    color: standardDataGridStyleRules.headerIconColor,
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent .MuiDataGrid-checkboxInput':
    {
      color: standardDataGridStyleRules.headerIconColor,
    },
} as const;

export const toolbarProps: Partial<GridToolbarProps & ToolbarPropsOverrides> = {
  // TODO: Disable print option for now. Need to check with React MUI team to see if we can export directly to PDF.
  printOptions: {
    disableToolbarButton: true,
  },
  showQuickFilter: true,
  quickFilterProps: {
    debounceMs: 500,
    quickFilterParser: (searchInputText) => {
      return searchInputText
        .split(' ')
        .map((value) => value.trim())
        .filter((value) => value !== '');
    },
  },
};

interface StandardDataGridProps<T> {
  rows: T[];
  columns: GridColDef[];
  initialFilterModel?: GridFilterModel;
  initialSortModel?: GridSortModel;
  columnVisibilityModel?: GridColumnVisibilityModel;
  disableRowSelectionOnClick?: boolean;
  disableColumnMenu?: boolean;
  hideFooter?: boolean;
  hideToolbar?: boolean;
  testId?: string;
  loading?: boolean;
  getRowId?: (row: T) => number | string;
  allowCheckboxSelection?: boolean;
  initialRowSelectionModel?: GridRowSelectionModel;
  onRowSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel
  ) => void;
  clearCheckboxSelection?: boolean;
  onClearCheckboxSelection?: () => void;
  noRowsMessage?: string;
  onRowUpdate?: (updatedRow: T, originalRow: T) => Promise<T>;
  onFilterModelChange?: (filterModel: GridFilterModel) => void;
  isRowSelectable?: (params: GridRowParams) => boolean;
  footerRowCountTemplate?: string;
}

/**
 * Renders a standard data grid using MUI DataGridPremium component.
 * @template T - The type of the grid row model.
 * @param columns - The columns to display in the grid.
 * @param rows - The rows to display in the grid.
 * @param initialFilterModel - The initial filter model to apply to the grid.
 * @param initialSortModel - The initial sort model to apply to the grid.
 * @param columnVisibilityModel - The column visibility model to apply to the grid.
 * @param testId - The test id to apply to the grid.
 * @param loading - The loading state of the grid.
 * @param allowCheckboxSelection - Whether to show the checkbox selection column.
 * @param initialRowSelectionModel - The initial row selection model.
 * @param onRowSelectionModelChange - The function to handle row selection model changes.
 * @param clearCheckboxSelection - Whether to clear the checkbox selection.
 * @param onClearCheckboxSelection - The function to handle clearing the checkbox selection.
 * @param disableRowSelectionOnClick - Whether to disable row selection on click.
 * @param disableColumnMenu - Whether to disable the column menu.
 * @param hideFooter - Whether to hide the footer.
 * @param hideToolbar - Whether to hide the toolbar.
 * @param getRowId - The function to get the row id.
 * @param onRowUpdate - The function to handle row updates.
 * @param onFilterModelChange - The function to handle filter model changes.
 * @param noRowsMessage - The message to display when no rows are found.
 * @param isRowSelectable - The function to handle a row is selectable
 * @param footerRowCountTemplate - Text template for footer row count message. Placeholder for rowCount number is :rowCount. E.g. "Total rows: :rowCount"
 */
export default function StandardDataGrid<T extends GridRowModel>({
  columns,
  rows,
  initialFilterModel,
  initialSortModel,
  columnVisibilityModel,
  testId,
  loading,
  allowCheckboxSelection,
  initialRowSelectionModel,
  onRowSelectionModelChange,
  clearCheckboxSelection,
  onClearCheckboxSelection,
  disableRowSelectionOnClick,
  disableColumnMenu,
  hideFooter,
  hideToolbar,
  noRowsMessage,
  getRowId,
  onRowUpdate,
  onFilterModelChange,
  isRowSelectable,
  footerRowCountTemplate,
}: StandardDataGridProps<T>) {
  const apiRef = useGridApiRef();

  const defaultFilterModel: GridFilterModel = initialFilterModel
    ? initialFilterModel
    : {
        items: [],
      };
  const [filterModel, setFilterModel] =
    useState<GridFilterModel>(defaultFilterModel);

  const handleFilterModelChange = (updatedFilterModel: GridFilterModel) => {
    setFilterModel(updatedFilterModel);
    onFilterModelChange?.(updatedFilterModel);
  };

  const defaultSortModel: GridSortModel =
    initialSortModel || ([] as GridSortModel);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);

  const handleSortModelChange = (updatedSortModel: GridSortModel) => {
    setSortModel(updatedSortModel);
  };

  const defaultRowSelectionModel: GridRowSelectionModel =
    initialRowSelectionModel || ([] as GridRowSelectionModel);

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>(defaultRowSelectionModel);
  const handleRowSelectionModelChange = (
    updatedRowSelectionModel: GridRowSelectionModel
  ) => {
    onRowSelectionModelChange &&
      onRowSelectionModelChange(updatedRowSelectionModel);
    setRowSelectionModel(updatedRowSelectionModel);
  };

  // Effects -------------------------------------------------- //
  useEffect(() => {
    if (clearCheckboxSelection) {
      setRowSelectionModel([]);
      onClearCheckboxSelection?.();
    }
  }, [clearCheckboxSelection, onClearCheckboxSelection]);

  // Reset filter model when initialFilterModel changes
  useEffect(() => {
    setFilterModel(initialFilterModel ? initialFilterModel : { items: [] });
  }, [initialFilterModel]);

  // Reset row selection model when initialRowSelectionModel changes
  useEffect(() => {
    setRowSelectionModel(
      initialRowSelectionModel ? initialRowSelectionModel : []
    );
  }, [initialRowSelectionModel]);

  const customNoRowsOverlay = () => (
    <Box
      textAlign="center"
      mt={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        minHeight: '60px',
      }}
    >
      <Typography variant="h6">
        {!noRowsMessage ? 'No Rows Found' : noRowsMessage}
      </Typography>
    </Box>
  );
  // Customize text for RowCount in the footer
  const customFooterRowCount = () => {
    const rowCount = apiRef.current.getRowsCount();
    // Get visible rows (The rows after applying the sorting rules, the filtering rules, and without the collapsed rows)
    // by using gridExpandedSortedRowIdsSelector
    const visibleRowCount =
      gridExpandedSortedRowIdsSelector(apiRef)?.length ?? 0;

    if (footerRowCountTemplate) {
      const rowCountText =
        visibleRowCount < rowCount
          ? `${visibleRowCount} of ${rowCount}`
          : rowCount.toLocaleString();
      return (
        <Box
          className="MuiDataGrid-rowCount"
          sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}
        >
          <Typography fontSize="14px" fontWeight={400}>
            {footerRowCountTemplate.replace(':rowCount', rowCountText)}
          </Typography>
        </Box>
      );
    }

    return (
      <GridRowCount rowCount={rowCount} visibleRowCount={visibleRowCount} />
    );
  };

  return (
    <DataGridPremium
      apiRef={apiRef}
      data-testid={testId}
      loading={loading}
      columns={columns}
      rows={rows || []}
      getRowId={getRowId}
      processRowUpdate={onRowUpdate}
      columnVisibilityModel={columnVisibilityModel}
      filterModel={filterModel}
      onFilterModelChange={handleFilterModelChange}
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      disableAggregation
      disableRowGrouping
      disableColumnMenu={disableColumnMenu}
      hideFooter={hideFooter}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      checkboxSelection={allowCheckboxSelection}
      isRowSelectable={isRowSelectable}
      slots={{
        toolbar: hideToolbar ? null : GridToolbar,
        noRowsOverlay: customNoRowsOverlay,
        footerRowCount: customFooterRowCount,
      }}
      slotProps={{
        toolbar: toolbarProps,
      }}
      sx={standardDataGridStyles}
    />
  );
}

import { faker } from '@faker-js/faker';
import { Factory } from 'fishery';
import { tryFormatDateStr } from '../Lib/utils';

export interface AuditLog {
  usersName: string;
  reason: string;
  date: string;
  type: 'Created' | 'Updated'; // more??
  changes: AuditLogChange[];
}

export interface AuditLogChange {
  name: string;
  oldValue: string;
  newValue: string;
  type: string;
  _index?: string; // used in the UI for quick searching
}

export type ValueMappings = Record<string, readonly string[]>;

export const mapValue = (
  name: string,
  mappings?: ValueMappings,
  value?: string
) => {
  if (!mappings?.[name] || typeof value != 'string') {
    return value;
  }
  const valueInt = parseInt(value);
  return (!isNaN(valueInt) && mappings[name][valueInt]) || value;
};

export const formattedChangeValues = (change: AuditLogChange) => {
  const isDate = change.type.includes('System.DateTime');
  const formatStr = (valStr = '') =>
    (isDate ? tryFormatDateStr(valStr) : valStr) || '""';
  return {
    ...change,
    oldValue: formatStr(change.oldValue),
    newValue: formatStr(change.newValue),
  };
};
export const changeToString = (
  change: AuditLogChange,
  valueMappings?: ValueMappings
) => {
  // all audit changes have been formatted in bulk within AuditLog.tsx

  const oldValue = mapValue(change.name, valueMappings, change.oldValue);
  const newValue = mapValue(change.name, valueMappings, change.newValue);

  return `${change.name}: ${oldValue} → ${newValue}`;
};

export const auditLogFactory = Factory.define<AuditLog>(() => ({
  usersName: faker.name.fullName(),
  reason: 'User/Update',
  date: faker.datatype.datetime().toISOString(),
  type: 'Updated',
  changes: [],
}));

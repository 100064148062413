import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { AuditLog, mapValue, ValueMappings } from '../../Models/AuditLog';

export interface AuditLogDetailProps {
  item: AuditLog;
  valueMappings?: ValueMappings;
}

export default function ({ item, valueMappings }: AuditLogDetailProps) {
  return (
    <TableContainer
      component={Paper}
      sx={{ width: 'fit-content', margin: 'auto' }}
    >
      <Table size="small" sx={{ width: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell>Property</TableCell>
            <TableCell>Old Value</TableCell>
            <TableCell>New Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {item.changes.map((change) => (
            <TableRow key={change.name}>
              <TableCell>{change.name}</TableCell>
              <TableCell>
                {mapValue(change.name, valueMappings, change.oldValue)}
              </TableCell>
              <TableCell>
                {mapValue(change.name, valueMappings, change.newValue)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React, { useContext, useEffect, useMemo } from 'react';
import { ArrowForwardIos } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CardTitle from '../../Components/CardTitle';
import useApiGet from '../../Hooks/useApiGet';
import { pagedParams } from '../../Services/Api';
import { AuthContext } from '../../Providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import {
  BillOfLading,
  BolStatus,
  BolStatusChip,
} from '../../Models/BillOfLading';
import BillOfLadingService from '../../Services/BillOfLadingService';
import LocationSelector from '../../Components/LocationSelector';
import MobilePage from '../../Components/MobilePage';

/**
 * This page shows a list of BoL to the Operator so they can select a BoL to start loading
 */

export default function () {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { data, loading, refresh } = useApiGet(
    BillOfLadingService.getBillsOfLading,
    {
      params: {
        ...pagedParams,
        warehouseId: user?.currentWarehouseId,
        statuses: [BolStatus.readyToLoad, BolStatus.loading],
      },
      // sync periodically from the server, on the same frequency as the BOLs are synced
      autoRefreshTrueOrSecs: true,
      noAutoFetch: true,
    }
  );

  useEffect(() => {
    if (user?.currentWarehouseId) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.currentWarehouseId]);

  const bolsSortedByStatus = useMemo(() => {
    return data?.items.sort((a, b) => {
      return b.status - a.status || a.bolNumber - b.bolNumber;
    });
  }, [data?.items]);

  const openBOL = (bol: BillOfLading) => {
    navigate(`/bol/${bol.bolNumber}`);
  };

  return (
    <MobilePage showTitle={false}>
      <CardTitle title={`Welcome ${user?.name}!`} />
      <LocationSelector />
      <Stack sx={{ mt: 1 }}>
        {loading && (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )}
        <Stack gap={1}>
          {bolsSortedByStatus?.map((bol) => (
            <Card
              key={bol.bolNumber}
              onClick={() => openBOL(bol)}
              data-testid={'bol-card-' + bol.bolNumber}
            >
              <CardContent>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Stack gap={1} flexGrow={1}>
                    <Stack direction="row" alignItems="center">
                      <CardTitle title={bol.bolNumber} sx={{ flexGrow: 1 }} />
                      <BolStatusChip status={bol.status} />
                    </Stack>
                    <Typography>
                      {bol?.customerName?.toLocaleUpperCase()}
                    </Typography>
                  </Stack>
                  <IconButton size="large" sx={{ p: 0 }}>
                    <ArrowForwardIos />
                  </IconButton>
                </Stack>
              </CardContent>
            </Card>
          ))}
          {data?.items.length === 0 && (
            <Typography align="center">
              No Bills of Lading ready for loading in this Warehouse
            </Typography>
          )}
        </Stack>
      </Stack>
    </MobilePage>
  );
}

import {
  GridColDef,
  GridFilterModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid-premium';
import React from 'react';
import { SelectItem } from '../../../Components/Forms/FormField';
import StandardDataGrid from '../../../Components/StandardDataGrid';
import { StockOnHandCarton } from '../../../Models/Carton';

interface Props {
  cartons: StockOnHandCarton[];
  loading: boolean;
  filterModel: GridFilterModel;
  rowSelectionModel: GridRowSelectionModel;
  selectedCustomer: SelectItem | null;
  onCartonsSelect: (cartons: StockOnHandCarton[]) => void;
}

function CartonsDataGrid({
  cartons,
  loading,
  filterModel,
  rowSelectionModel,
  selectedCustomer,
  onCartonsSelect,
}: Props) {
  const columns: GridColDef[] = [
    {
      field: 'cartonBarcode',
      headerName: 'Carton',
      flex: 1,
    },
    {
      field: 'qty',
      headerName: 'Quantity',
      flex: 1,
    },
    {
      field: 'customerCode',
      headerName: 'Customer',
      flex: 1,
    },
    {
      field: 'asn',
      headerName: 'ASN',
      flex: 1,
    },
    {
      field: 'batchNumber',
      headerName: 'Batch',
      flex: 1,
    },
    {
      field: 'productCode',
      headerName: 'Product Code',
      flex: 1,
    },
    {
      field: 'lot',
      headerName: 'Lot',
      flex: 1,
    },
  ];

  const handleRowSelectionModelChange = (
    selectionModel: GridRowSelectionModel
  ) => {
    const selectedCartons = cartons.filter((carton) =>
      selectionModel.includes(carton.id)
    );
    onCartonsSelect(selectedCartons);
  };

  return (
    <StandardDataGrid
      testId="cartons-data-grid"
      getRowId={(row) => row.id}
      columns={columns}
      rows={cartons}
      loading={loading}
      allowCheckboxSelection={true}
      initialFilterModel={filterModel}
      initialRowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      noRowsMessage={
        selectedCustomer
          ? 'No rows found'
          : 'Please select a customer to view cartons'
      }
    />
  );
}

export default CartonsDataGrid;

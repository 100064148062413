import {
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import LocationSelector from '../../Components/LocationSelector';
import MobilePage from '../../Components/MobilePage';
import CardTitle from '../../Components/CardTitle';
import { AuthContext } from '../../Providers/AuthProvider';
import useApiGet from '../../Hooks/useApiGet';
import StoreService from '../../Services/StoreService';
import StocktakeService from '../../Services/StocktakeService';
import {
  STOCKTAKE_STATUS_COLORS,
  STOCKTAKE_STATUS_LABELS,
  Stocktake,
} from '../../Models/Stocktake';
import { ArrowForwardIos } from '@mui/icons-material';
import StatusChip from '../../Components/StatusChip';
import { useNavigate } from 'react-router-dom';

export default function () {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [storeId, setStoreId] = useState<number | null>(-1);
  const [stocktakeDetails, setStocktakeDetails] = useState<
    Stocktake[] | undefined
  >([]);

  const { data: storeData } = useApiGet(StoreService.getStores, {
    params: { warehouseId: user?.currentWarehouseId },
  });

  useApiGet(StocktakeService.getStocktakesByStore, {
    params: {
      warehouseId: user?.currentWarehouseId,
      storeId: storeId,
    },
    onSuccess: (stocktakes) => {
      setStocktakeDetails(stocktakes);
    },
  });
  useEffect(() => {
    if (user?.currentWarehouseId) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.currentWarehouseId]);
  function RefreshStocktakes(storeId: number): void {
    setStoreId(storeId);
  }

  function openStocktake(stocktakeId: string, location: string) {
    navigate(`/ScanLocationOperator/${stocktakeId}/${location}`);
  }

  return (
    <MobilePage showTitle title="Stocktake">
      <CardTitle title={`Welcome ${user?.name}!`} />
      <LocationSelector />
      <Select
        fullWidth
        onChange={(e) => RefreshStocktakes(parseInt(e.target.value))}
        defaultValue={'-1'}
      >
        <MenuItem value="-1">Store Location</MenuItem>
        {storeData?.items.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.description}
          </MenuItem>
        ))}
      </Select>

      <Stack gap={1}>
        {stocktakeDetails?.map((sd) => (
          <Card
            key={sd.id}
            onClick={() =>
              openStocktake(sd.id.toString(), sd.location.toLocaleUpperCase())
            }
            data-testid={'stocktake-card-' + sd.id}
          >
            <CardContent>
              <Stack direction="row" alignItems="center" gap={1}>
                <Stack gap={1} flexGrow={1}>
                  <Stack direction="row" alignItems="center">
                    <CardTitle title={sd.id} sx={{ flexGrow: 1 }} />
                    <StatusChip
                      label={STOCKTAKE_STATUS_LABELS[sd.stocktakeStatus]}
                      colors={STOCKTAKE_STATUS_COLORS}
                      width="10em"
                      sx={{
                        p: '2px 4px',
                        paddingY: '4px',
                      }}
                    />
                  </Stack>
                  <Typography>{sd?.location?.toLocaleUpperCase()}</Typography>
                </Stack>
                <IconButton size="large" sx={{ p: 0 }}>
                  <ArrowForwardIos />
                </IconButton>
              </Stack>
            </CardContent>
          </Card>
        ))}
        {stocktakeDetails?.length === 0 && (
          <Typography align="center">
            No Stocktakes ready for loading in this Store
          </Typography>
        )}
      </Stack>
    </MobilePage>
  );
}

import Api, { ServiceGetMethod } from './Api';

export interface GetLocationDetailsRequest {
  locationBarcode: string;
}

interface GetLocationDetailsResponse {
  isValidLocation: boolean;
  storeCode: string;
  xCoord: string;
  yCoord: string;
  zCoord: string;
}

const getLocationDetails: ServiceGetMethod<
  GetLocationDetailsRequest,
  GetLocationDetailsResponse
> = (options) =>
  Api.get(`api/services/app/Location/GetLocationDetails`, options);

export default {
  getLocationDetails,
};

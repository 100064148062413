import React from 'react';
import { BillOfLadingWithCounts } from '../../Models/BillOfLading';
import { BOLContainer } from '../../Models/BOLContainer';

/**
 * TODO: This is the 'Unload' tab for an Operator's view of a container
 */

export interface BolContainerUnloadProps {
  bol: BillOfLadingWithCounts;
  container: BOLContainer;
  refresh: () => void;
}

export default function ({ container }: BolContainerUnloadProps) {
  return <>Unload container {container.code}</>;
}

import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-premium';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  BOLContainer,
  BOLContainerStatus,
  ContainerChip,
  ECertStatus,
} from '../../../../Models/BOLContainer';
import StandardDataGrid from '../../../../Components/StandardDataGrid';
import { AuthContext } from '../../../../Providers/AuthProvider';
import BolContainerService from '../../../../Services/BolContainerService';
import useApiForm from '../../../../Hooks/useApiForm';
import { base64toBlob, saveFile } from '../../../../Lib/utils';
import { confirmModalDialog } from '../../../../Components/ModalDialog';
import { Box, Button, Checkbox } from '@mui/material';
import EditContainer from '../../../../Components/Container/EditContainer';
import { Link, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/system';
import ECertService from '../../../../Services/ECertService';

interface ContainerDataGridProps {
  loading: boolean;
  rows: BOLContainer[];
  bolNumber: number;
  refresh?: () => void;
}

const ContainerDataGrid = ({
  loading,
  rows,
  bolNumber,
  refresh,
}: ContainerDataGridProps) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const carterNotePdfFileName = useRef('carter_note');
  const proofOfDeliveryPdfFileName = useRef('proof_of_delivery');

  const [openEditContainerDialog, setOpenEditContainerDialog] = useState(false);
  const containerToEditRef = useRef<BOLContainer>();

  const [selectedContainers, setSelectedContainers] = useState<
    Record<string, BOLContainer>
  >({});

  const generateCartersNotePdfForm = useApiForm(
    BolContainerService.generateCartersNotePdf,
    { bolContainerId: 0 },
    {
      onSuccess: (data: string) => {
        const mimeType = 'application/pdf';
        const blob = base64toBlob(data, mimeType);
        saveFile(blob, carterNotePdfFileName.current, mimeType);
      },
    }
  );

  const generateProofOfDeliveryPdfForm = useApiForm(
    BolContainerService.generateProofOfDeliveryPdf,
    { bolContainerId: 0 },
    {
      onSuccess: (data: string) => {
        const mimeType = 'application/pdf';
        const blob = base64toBlob(data, mimeType);
        saveFile(blob, proofOfDeliveryPdfFileName.current, mimeType);
      },
    }
  );

  const validStatusesCartersNoteProofOfDelivery = useMemo(
    () => [
      BOLContainerStatus.adminReview,
      BOLContainerStatus.managerReview,
      BOLContainerStatus.pendingInvoice,
      BOLContainerStatus.invoiced,
    ],
    []
  );

  const printCartersNoteActionBtn = useCallback(
    (params: GridRowParams<BOLContainer>) => {
      const canPrintCartersNote =
        validStatusesCartersNoteProofOfDelivery.includes(params.row.status) &&
        user?.permissions?.includes('Container.CartersNote');

      if (canPrintCartersNote) {
        return (
          <GridActionsCellItem
            label="Print Carter's Note"
            onClick={() => {
              carterNotePdfFileName.current = `carters_note_${params.row.code}.pdf`;
              generateCartersNotePdfForm.setData(
                'bolContainerId',
                params.row.id
              );
              generateCartersNotePdfForm.submit();
            }}
            showInMenu
          />
        );
      }
    },
    [
      generateCartersNotePdfForm,
      user?.permissions,
      validStatusesCartersNoteProofOfDelivery,
    ]
  );

  const printProofOfDeliveryActionBtn = useCallback(
    (params: GridRowParams<BOLContainer>) => {
      const canPrintProofOfDelivery =
        validStatusesCartersNoteProofOfDelivery.includes(params.row.status) &&
        user?.permissions?.includes('Container.ProofOfDelivery');

      if (canPrintProofOfDelivery) {
        return (
          <GridActionsCellItem
            label="Print Proof of Delivery"
            onClick={() => {
              proofOfDeliveryPdfFileName.current = `proof_of_delivery_${params.row.code}.pdf`;
              generateProofOfDeliveryPdfForm.setData(
                'bolContainerId',
                params.row.id
              );
              generateProofOfDeliveryPdfForm.submit();
            }}
            showInMenu
          />
        );
      }
    },
    [
      generateProofOfDeliveryPdfForm,
      user?.permissions,
      validStatusesCartersNoteProofOfDelivery,
    ]
  );

  const renderActions = useMemo(
    () => (params: GridRowParams<BOLContainer>) => {
      const actions = [];

      const printCartersNoteBtn = printCartersNoteActionBtn(params);
      if (printCartersNoteBtn) actions.push(printCartersNoteBtn);

      const printProofOfDeliveryBtn = printProofOfDeliveryActionBtn(params);
      if (printProofOfDeliveryBtn) actions.push(printProofOfDeliveryBtn);

      const deleteAction = (
        <GridActionsCellItem
          label="Delete"
          onClick={async () => {
            const deletionMessage = `Are you sure you want to delete container '${params.row.code}' from BOL '${params.row.bolNumber}'?`;
            const confirmed = await confirmModalDialog({
              title: '',
              acceptButtonLabel: 'Delete',
              severity: 'warning',
              content: (
                <Box textAlign={'center'}>
                  <h3>{deletionMessage}</h3>
                </Box>
              ),
            });
            if (confirmed) {
              const [, error] = await BolContainerService.deleteContainer(
                params.row.id
              );
              if (!error && refresh) {
                refresh();
              }
            }
          }}
          showInMenu
        />
      );
      if (params.row.isDeleteAble) actions.push(deleteAction);

      const editAction = (
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            containerToEditRef.current = params.row;
            setOpenEditContainerDialog(true);
          }}
          showInMenu
        />
      );
      actions.push(editAction);

      const deleteECertAction = (
        <GridActionsCellItem
          label="Delete ECert"
          onClick={async () => {
            const deletionMessage = `Are you sure you want to delete ECert '${params.row.eCert?.certificatePublicId}'?`;
            const confirmed = await confirmModalDialog({
              title: '',
              acceptButtonLabel: 'Delete',
              content: (
                <Box textAlign={'center'}>
                  <h3>{deletionMessage}</h3>
                </Box>
              ),
            });
            if (confirmed && params.row.eCert) {
              const [, error] = await ECertService.deleteECert(
                params.row.eCert.id
              );
              if (!error && refresh) {
                refresh();
              }
            }
          }}
          showInMenu
        />
      );
      if (params.row.eCert && params.row.eCert.status === ECertStatus.draft) {
        actions.push(deleteECertAction);
      }

      return actions;
    },
    [printCartersNoteActionBtn, printProofOfDeliveryActionBtn, refresh]
  );
  const eCertCheckboxOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const currentRowId = e.target.value;
    const isChecked = e.target.checked;

    const selectedContainer = selectedContainers[currentRowId];
    if (!isChecked && selectedContainer) {
      delete selectedContainers[currentRowId];
      setSelectedContainers({ ...selectedContainers });
    }
    if (isChecked && !selectedContainer) {
      const selectedContainer = rows.find(
        (row) => row.id.toString() === currentRowId
      );
      if (selectedContainer) {
        setSelectedContainers({
          ...selectedContainers,
          [selectedContainer.id]: selectedContainer,
        });
      }
    }
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'checkbox',
        headerName: '',
        type: 'actions',
        flex: 0.2,
        renderCell: (params) => (
          <Checkbox
            onChange={eCertCheckboxOnChange}
            value={params.row.id}
            disabled={params.row.eCert?.certificatePublicId.length > 0}
            checked={!!selectedContainers[params.row.id]}
          />
        ),
      },
      {
        field: 'eCert',
        headerName: 'ECert',
        flex: 1,
        renderCell: (params: GridRenderCellParams<BOLContainer>) =>
          params.row.eCert ? (
            <>
              <Link
                to={`/bol/${
                  params.row.bolNumber
                }/ecert/${params.row.eCert.certificatePublicId
                  .split('/')
                  .join('-')}`}
              >
                {params.row.eCert.certificatePublicId}
              </Link>
            </>
          ) : null,
      },
      {
        field: 'code',
        headerName: 'Container',
        flex: 1,
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <Link to={`/bol/${params.row.bolNumber}/${params.row.code}`}>
            {params.row.code}
          </Link>
        ),
      },
      {
        field: 'pickedPallets',
        headerName: 'Picked',
        flex: 1,
        valueGetter: (params: GridRenderCellParams<BOLContainer>) =>
          `${params.row.palletsInPickedLocation} / ${params.row.totalPallets}`,
      },
      {
        field: 'loadedPallets',
        headerName: 'Loaded',
        flex: 1,
        valueGetter: (params: GridRenderCellParams<BOLContainer>) =>
          `${params.row.loadedPallets} / ${params.row.totalPallets}`,
      },
      {
        field: 'loadedCartons',
        headerName: 'Cartons',
        flex: 1,
        valueGetter: (params: GridRenderCellParams<BOLContainer>) =>
          `${params.row.loadedCartons} / ${params.row.totalCartons}`,
      },
      {
        field: 'loadedWeight',
        headerName: 'Weight',
        flex: 1,
        valueGetter: (params: GridRenderCellParams<BOLContainer>) =>
          `${params.row.loadedWeight} / ${params.row.totalWeight}`,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <ContainerChip status={params.row.status} />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        flex: 1,
        getActions: renderActions,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [renderActions, selectedContainers, rows]
  );

  const handleRowSelection = (selectedRowIds: GridRowSelectionModel) => {
    const uniqueSelectedRowIds = new Set(selectedRowIds);

    const selectedRows = rows.reduce(
      (obj: Record<string, BOLContainer>, row) => {
        if (uniqueSelectedRowIds.has(row.id)) {
          obj[row.id] = row;
        }
        return obj;
      },
      {}
    );

    setSelectedContainers(selectedRows);
  };

  const handleECertBtnClick = () => {
    if (!selectedContainers) {
      return;
    }
    ECertService.createECertForContainers({
      containerIds: Object.keys(selectedContainers).map(Number),
    }).then((data) => {
      if (data[0] && data[0]?.certificatePublicId.length > 0) {
        const certificateId = data[0].certificatePublicId.split('/').join('-');
        navigate(`/bol/${bolNumber}/ecert/${certificateId}`);
      }
    });
  };

  return (
    <>
      <Stack direction="row" alignItems="left">
        <Box alignContent={'flex-start'}>
          <Button
            variant="contained"
            disabled={Object.keys(selectedContainers).length === 0}
            onClick={() => handleECertBtnClick()}
          >
            Create ECERT
          </Button>
        </Box>
      </Stack>

      <StandardDataGrid
        loading={loading}
        columns={columns}
        rows={rows}
        onRowSelectionModelChange={handleRowSelection}
        disableRowSelectionOnClick
        isRowSelectable={(params) => !params.row.eCert}
      />

      {openEditContainerDialog && (
        <EditContainer
          bolContainer={containerToEditRef.current || null}
          open={openEditContainerDialog}
          onClose={() => setOpenEditContainerDialog(false)}
          onSuccess={() => {
            if (refresh) {
              refresh();
            }
            setOpenEditContainerDialog(false);
          }}
        />
      )}
    </>
  );
};

export default ContainerDataGrid;

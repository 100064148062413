import React from 'react';
import { StoreCapacityRow } from '../../../../Models/StoreCapacity';
import StandardDataGrid from '../../../../Components/StandardDataGrid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';

interface StoreCapacityDataGridProps {
  loading: boolean;
  rows: StoreCapacityRow[];
}

const StoreCapacityDataGrid = ({
  loading,
  rows,
}: StoreCapacityDataGridProps) => {
  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: 'Store',
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: 'xCoord',
      headerName: 'Row',
      minWidth: 50,
      maxWidth: 100,
      valueGetter: (params: GridRenderCellParams<StoreCapacityRow>) =>
        params.row.xCoord + params.row.yCoord,
    },
    {
      field: 'zCoord',
      headerName: 'Bay',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'totalCapacity',
      headerName: 'Bay Capacity',
      minWidth: 70,
      maxWidth: 150,
    },
    {
      field: 'productDescription',
      headerName: 'Product Description',
      minWidth: 350,
      maxWidth: 550,
    },
    {
      field: 'totalPallets',
      headerName: 'Pallets',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'totalAvailable',
      headerName: 'Available',
      minWidth: 150,
      maxWidth: 250,
    },
  ];

  return (
    <StandardDataGrid
      testId="store-capacity-data-grid"
      loading={loading}
      rows={rows}
      columns={columns}
      getRowId={(row) => row.rowId}
    />
  );
};

export default StoreCapacityDataGrid;

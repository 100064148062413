import React from 'react';
import { Grid, GridProps, Typography } from '@mui/material';

export default function ECertBlockInfo({
  label,
  displayText,
  ...props
}: { label: string; displayText: string } & GridProps) {
  return (
    <Grid item {...props}>
      <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{label}</Typography>
      <Typography sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
        {displayText}
      </Typography>
    </Grid>
  );
}

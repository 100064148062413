import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent } from '@mui/material';
import { Stack } from '@mui/system';
import { GridColDef, GridFilterModel } from '@mui/x-data-grid-premium';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import CardTitle from '../../Components/CardTitle';
import UserBlobCard from '../../Components/Dashboard/UserBlobCard';
import { confirmModalDialog } from '../../Components/ModalDialog';
import StandardDataGrid from '../../Components/StandardDataGrid';
import useApiForm, { UseApiFormResult } from '../../Hooks/useApiForm';
import useApiGet from '../../Hooks/useApiGet';
import useLocalStorage from '../../Hooks/useLocalStorage';
import {
  AdminReviewEntity,
  AdminReviewEntityType,
} from '../../Models/AdminReviewEntity';
import { ASN } from '../../Models/ASN';
import { BOLContainerStatus } from '../../Models/BOLContainer';
import { User } from '../../Models/User';
import { AuthContext } from '../../Providers/AuthProvider';
import { pagedParams } from '../../Services/Api';
import AsnService from '../../Services/AsnService';
import BolContainerService from '../../Services/BolContainerService';
import DashboardService from '../../Services/DashboardService';
import UserService from '../../Services/UserService';

interface UserWithCounts extends User {
  asnCount: number;
  containerCount: number;
}

const backgroundColours = [
  'rgb(0, 136, 209)',
  'rgb(47, 124, 49)',
  'rgb(156, 39, 176)',
  'rgb(211, 47, 47)',
  'rgb(237, 108, 3)',
  'rgb(133, 68, 66)',
  'rgb(17, 167, 160)',
  'rgb(96, 92, 168)',
  'rgb(254, 138, 113)',
  'rgb(23, 162, 184)',
];
const defaultTextColor = 'white';

export default function () {
  const { user } = useContext(AuthContext);
  const [selectedAdmin, setSelectedAdmin] = useState<UserWithCounts | null>(
    null
  );
  const defaultFilterByAdminModel: GridFilterModel = {
    items: [
      {
        field: 'admin',
        operator: 'equals',
        value: '',
      },
    ],
  };
  const [filterByAdminModel, setFilterByAdminModel] = useState<GridFilterModel>(
    defaultFilterByAdminModel
  );
  const [filterEntityType, setFilterEntityType] = useLocalStorage(
    'AdminReview.filterEntityType',
    '',
    { sessionOnly: true }
  );
  // Generate footer row count message
  const footerRowCountMessage = useCallback(() => {
    const defaultText = 'Showing total :rowCount ';
    if (filterEntityType.length === 0) {
      return `${defaultText} ASNs and Containers`;
    }
    const suffixes = [] as string[];
    if (filterEntityType === AdminReviewEntityType.ASN) suffixes.push('ASNs');
    if (filterEntityType === AdminReviewEntityType.CONTAINER)
      suffixes.push('Containers');
    return `${defaultText} ${suffixes.join(' and ')}`;
  }, [filterEntityType]);
  const isEntityTypeSelected = useCallback(
    (typeName: AdminReviewEntityType, user: UserWithCounts | null = null) => {
      if (typeName.length === 0) return false;
      return (
        ((user == null && selectedAdmin == null) ||
          user?.userName === selectedAdmin?.userName) &&
        typeName === filterEntityType
      );
    },
    [filterEntityType, selectedAdmin]
  );

  const { data: adminData } = useApiGet(UserService.getUsers, {
    params: {
      permissions: ['Container.Reviewer'],
      isActive: true,
      maxResultCount: pagedParams.maxResultCount,
    },
  });

  const { data, refresh } = useApiGet(DashboardService.getAdminReviewData, {
    params: {
      warehouseId: user?.currentWarehouseId,
    },
  });
  const usersWithCounts = useMemo(() => {
    const currentWarehouseId = user?.currentWarehouseId;
    return (
      adminData?.items
        .filter(
          (x) =>
            x.warehouseIds!.length > 0 &&
            (currentWarehouseId == null ||
              x.warehouseIds?.includes(currentWarehouseId!))
        )
        .map((admin) => {
          const dataByAdmin = data?.filter((d) => d.admin?.id == admin.id);
          const containerRowsByAdmin = dataByAdmin?.filter(
            (d) => d.entityType === AdminReviewEntityType.CONTAINER
          );
          const asnRowsByAdmin = dataByAdmin?.filter(
            (d) => d.entityType === AdminReviewEntityType.ASN
          );
          return {
            ...admin,
            containerCount: containerRowsByAdmin?.length ?? 0,
            asnCount: asnRowsByAdmin?.length ?? 0,
          } as UserWithCounts;
        })
        .sort((a, b) => a.fullName.localeCompare(b.fullName)) ?? []
    );
  }, [adminData, data, user?.currentWarehouseId]);

  const asnTotalCount = useMemo(() => {
    return (
      data?.filter((d) => d.entityType === AdminReviewEntityType.ASN)?.length ??
      0
    );
  }, [data]);
  const containerTotalCount = useMemo(() => {
    return (
      data?.filter((d) => d.entityType === AdminReviewEntityType.CONTAINER)
        ?.length ?? 0
    );
  }, [data]);
  const filterByAdminNameAndType = (
    user: UserWithCounts | null,
    entityType = ''
  ) => {
    const removeFilter =
      user?.userName === selectedAdmin?.userName &&
      entityType === filterEntityType;
    if (removeFilter) {
      setSelectedAdmin(null);
      setFilterEntityType('');
      setFilterByAdminModel(defaultFilterByAdminModel);
      return;
    }
    setSelectedAdmin(user);
    setFilterEntityType(entityType);
    setFilterByAdminModel({
      items: [
        {
          field: 'admin',
          operator: 'equals',
          value: user ? user.fullName : '',
        },
      ],
    } as GridFilterModel);
  };
  const updateContainerStatusForm = useApiForm(
    BolContainerService.updateContainerStatus,
    {
      containerId: 0,
      status: BOLContainerStatus.managerReview,
    },
    {
      onError: () => {
        updateContainerStatusForm.setData('containerId', 0);
      },
      onSuccess: () => {
        updateContainerStatusForm.setData('containerId', 0);
        refresh();
      },
    }
  );
  const asnMarkAsReviewedForm = useApiForm(
    AsnService.markAsReviewed,
    {
      asnId: 0,
    },
    {
      onError: () => {
        asnMarkAsReviewedForm.setData('asnId', 0);
      },
      onSuccess: () => {
        asnMarkAsReviewedForm.setData('asnId', 0);
        refresh();
      },
    }
  );

  const columnDefs = useMemo<GridColDef<AdminReviewEntity>[]>(
    () => [
      {
        field: 'entityNumber',
        headerName: 'ASN / BOL',
        sortable: true,
        maxWidth: 120,
        type: 'number',
        renderCell: (params) =>
          params.row.entityType === AdminReviewEntityType.CONTAINER ? (
            <Link to={`bol/${params.row.entityNumber}`}>
              {params.row.entityNumber}
            </Link>
          ) : (
            <Link to={`asn/${params.row.entityNumber}`}>
              {params.row.entityNumber}
            </Link>
          ),
      },
      {
        field: 'code',
        headerName: 'Container',
        sortable: true,
        minWidth: 130,
        maxWidth: 200,
        renderCell: (params) =>
          params.row.entityType === AdminReviewEntityType.CONTAINER ? (
            <Link to={`bol/${params.row.entityNumber}/${params.row.code}`}>
              {params.row.code}
            </Link>
          ) : null,
      },
      {
        field: 'customerName',
        headerName: 'Customer',
        sortable: true,
        flex: 1,
      },
      {
        field: 'consigneeName',
        headerName: 'Deliver To Customer',
        sortable: true,
        flex: 1,
      },
      {
        field: 'customerOrderNumber',
        headerName: 'Customer Order No.',
        sortable: true,
        flex: 1,
      },
      {
        field: 'admin',
        headerName: 'Admin',
        sortable: true,
        minWidth: 140,
        valueGetter: (params) => params.row.admin?.fullName,
      },
      {
        field: 'completedDate',
        headerName: 'Loaded / Received',
        sortable: true,
        type: 'date',
        minWidth: 140,
        valueGetter: (params) => new Date(params.row.completedDate ?? ''),
      },
      {
        field: '',
        headerName: 'Action',
        headerProps: { align: 'center' },
        cellProps: { align: 'center' },
        minWidth: 165,
        renderCell: (params) =>
          params.row.entityType === AdminReviewEntityType.CONTAINER ? (
            <MarkedAsReviewedContainerButton
              entity={params.row}
              form={updateContainerStatusForm}
            />
          ) : (
            <MarkedAsReviewedASNButton
              entity={params.row}
              form={asnMarkAsReviewedForm}
            />
          ),
      },
    ],
    [updateContainerStatusForm, asnMarkAsReviewedForm]
  );
  const filteredData = useMemo(() => {
    let filteredRows = data;
    if (
      filterEntityType.length > 0 &&
      (
        [AdminReviewEntityType.ASN, AdminReviewEntityType.CONTAINER] as string[]
      ).includes(filterEntityType)
    ) {
      filteredRows = filteredRows?.filter(
        (r) => r.entityType === filterEntityType
      );
    }
    return filteredRows;
  }, [filterEntityType, data]);

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <CardTitle title="Admin Review" />
        {data && (
          <Stack
            direction="row"
            spacing={0.5}
            maxWidth={'100%'}
            paddingBottom={2}
            overflow={'auto'}
          >
            <UserBlobCard
              testId={`all-user-count-block`}
              key={'total'}
              name={'ALL'}
              onNameClick={() => filterByAdminNameAndType(null, '')}
              asnCount={asnTotalCount}
              onASNClick={() =>
                filterByAdminNameAndType(null, AdminReviewEntityType.ASN)
              }
              containerCount={containerTotalCount}
              onContainerClick={() =>
                filterByAdminNameAndType(null, AdminReviewEntityType.CONTAINER)
              }
              backgroundColor={backgroundColours[0]}
              textColor={defaultTextColor}
              isUserSelected={!selectedAdmin}
              isASNBlockSelected={isEntityTypeSelected(
                AdminReviewEntityType.ASN
              )}
              isContainerBlockSelected={isEntityTypeSelected(
                AdminReviewEntityType.CONTAINER
              )}
            />
            {usersWithCounts.map((user, index) => (
              <UserBlobCard
                testId={`user-blob-card-${user.userName}`}
                key={user.id}
                name={user.name}
                asnCount={user.asnCount}
                containerCount={user.containerCount}
                onNameClick={() => filterByAdminNameAndType(user, '')}
                onASNClick={() =>
                  filterByAdminNameAndType(user, AdminReviewEntityType.ASN)
                }
                onContainerClick={() =>
                  filterByAdminNameAndType(
                    user,
                    AdminReviewEntityType.CONTAINER
                  )
                }
                backgroundColor={
                  backgroundColours[(index + 1) % backgroundColours.length]
                }
                textColor={defaultTextColor}
                isUserSelected={selectedAdmin?.userName === user.userName}
                isASNBlockSelected={isEntityTypeSelected(
                  AdminReviewEntityType.ASN,
                  user
                )}
                isContainerBlockSelected={isEntityTypeSelected(
                  AdminReviewEntityType.CONTAINER,
                  user
                )}
              />
            ))}
          </Stack>
        )}
        <Box sx={{ height: '65vh', marginTop: 1 }}>
          {' '}
          {/* set fixed height to show the footer message */}
          <StandardDataGrid
            testId="admin-review-grid"
            rows={filteredData ?? ([] as AdminReviewEntity[])}
            columns={columnDefs}
            getRowId={(row) => row.rowKey}
            initialFilterModel={filterByAdminModel}
            onFilterModelChange={(newFilterModel) => {
              // Only highlight blob card when filtering data by 'equals' operator
              setFilterByAdminModel(newFilterModel);
              const adminFilterModel = newFilterModel.items.find(
                (i) => i.field === 'admin'
              );
              if (!adminFilterModel) return;
              const userMatchedSearchValue = usersWithCounts.find(
                (u) =>
                  u.fullName.toLowerCase() ===
                  adminFilterModel.value.toLowerCase()
              );
              if (
                adminFilterModel.operator === 'equals' &&
                userMatchedSearchValue
              ) {
                setSelectedAdmin(userMatchedSearchValue);
              } else {
                // Don't highlight Blob Card when filtering operator is not 'equals'
                setSelectedAdmin(null);
              }
            }}
            footerRowCountTemplate={footerRowCountMessage()}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export function MarkedAsReviewedContainerButton({
  entity,
  form,
}: {
  entity: AdminReviewEntity;
  form: UseApiFormResult<
    { containerId: number; status: BOLContainerStatus },
    void
  >;
}) {
  if (entity.entityType !== AdminReviewEntityType.CONTAINER) return null;

  return (
    <LoadingButton
      type="button"
      size="small"
      variant="outlined"
      data-testid={'mark-reviewed-container-btn-' + entity.rowKey}
      loading={form.data.containerId == entity.entityId}
      onClick={async () => {
        const yes = await confirmModalDialog({
          title: 'Complete review of container',
          content: 'Are you sure you want to mark this container as reviewed?',
        });

        if (yes) {
          form.setData('containerId', entity.entityId);
          form.submit();
        }
      }}
    >
      Mark as reviewed
    </LoadingButton>
  );
}

export function MarkedAsReviewedASNButton({
  entity,
  form,
}: {
  entity: AdminReviewEntity;
  form: UseApiFormResult<{ asnId: number }, ASN>;
}) {
  if (entity.entityType !== AdminReviewEntityType.ASN) return null;

  return (
    <LoadingButton
      type="button"
      size="small"
      variant="outlined"
      data-testid={'mark-reviewed-asn-btn-' + entity.rowKey}
      loading={form.data.asnId == entity.entityId}
      onClick={async () => {
        const yes = await confirmModalDialog({
          title: 'Complete review of ASN',
          content: 'Are you sure you want to mark this ASN as reviewed?',
        });

        if (yes) {
          form.setData('asnId', entity.entityId);
          form.submit();
        }
      }}
    >
      Mark as reviewed
    </LoadingButton>
  );
}

import {
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import React, { useRef, useState } from 'react';
import { confirmModalDialog } from '../../../Components/ModalDialog';
import StandardDataGrid from '../../../Components/StandardDataGrid';
import { StockOnHandCarton } from '../../../Models/Carton';
import { Lot } from '../../../Models/Lot';
import LotService from '../../../Services/LotService';
import EditLot from './EditLot';

interface Props {
  lots: Lot[];
  selectedCartons: StockOnHandCarton[] | null;
  loading: boolean;
  filterModel: GridFilterModel;
  onSuccessfulLinking: () => void;
  onSuccessfulLotEdit: () => void;
}

function LotsDataGrid({
  lots,
  loading,
  filterModel,
  selectedCartons,
  onSuccessfulLinking,
  onSuccessfulLotEdit,
}: Props) {
  const [openEditLotDialog, setOpenEditLotDialog] = useState(false);

  const lotToEditRef = useRef<Lot>();

  const renderActions = (params: GridRowParams<Lot>) => {
    const actions = [];

    const editLotAction = (
      <GridActionsCellItem
        label="Edit"
        onClick={() => {
          setOpenEditLotDialog(true);
          lotToEditRef.current = params.row;
        }}
        showInMenu
      />
    );
    actions.push(editLotAction);

    const linkLotAction = (
      <GridActionsCellItem
        label="Link"
        onClick={() => {
          const selectedCartonIds = selectedCartons?.map((carton) => carton.id);
          const selectedCartonProductCode = selectedCartons?.[0]?.productCode;
          const selectedCartonCustomerCode = selectedCartons?.[0]?.customerCode;
          const selectedCartonBatchNumber = selectedCartons?.[0]?.batchNumber;
          const selectedCartonAsnNumber = selectedCartons?.[0]?.asn;

          const dialogContent = `You have selected ${selectedCartons?.length} carton(s) of 
            Product = ${selectedCartonProductCode}, 
            Customer = ${selectedCartonCustomerCode}, 
            Batch = ${selectedCartonBatchNumber}, 
            ASN = ${selectedCartonAsnNumber}. 
            Lot selected is ${params.row.lotNumber}.`;

          confirmModalDialog({
            title: 'Link Lot',
            content: dialogContent,
          }).then((res) => {
            if (res) {
              LotService.linkCartonsToLot({
                cartonIds: selectedCartonIds ?? [],
                lotId: params.row.id,
              }).then(() => {
                onSuccessfulLinking();
              });
            }
          });
        }}
        showInMenu
      />
    );
    if (selectedCartons && selectedCartons.length > 0)
      actions.push(linkLotAction);

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'lotNumber',
      headerName: 'Lot Number',
      flex: 1,
    },
    {
      field: 'docNumber',
      headerName: 'Doc Number',
      flex: 1,
    },
    {
      field: 'docLine',
      headerName: 'Doc Line',
      flex: 1,
    },
    {
      field: 'customerOrderReference',
      headerName: 'Customer Order Reference',
      flex: 1,
    },
    {
      field: 'productDescription',
      headerName: 'Product Description',
      flex: 1,
    },
    {
      field: 'productCode',
      headerName: 'Product Code',
      flex: 1,
    },
    {
      field: 'productionBatch',
      headerName: 'Production Batch',
      flex: 1,
    },
    {
      field: 'qty',
      headerName: 'Quantity',
      flex: 1,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      flex: 1,
    },
    {
      field: 'brands',
      headerName: 'Brands',
      flex: 1,
    },
    {
      field: 'productRemarks',
      headerName: 'Product Remarks',
      flex: 1,
    },
    {
      field: 'plantCode',
      headerName: 'Plant Code',
      flex: 1,
    },
    {
      field: 'requiredTempState',
      headerName: 'Required Temp State',
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      getActions: renderActions,
    },
  ];

  return (
    <>
      <StandardDataGrid
        testId="lots-data-grid"
        getRowId={(row) => row.id}
        columns={columns}
        rows={lots}
        loading={loading}
        initialFilterModel={filterModel}
      />
      {openEditLotDialog && (
        <EditLot
          lot={lotToEditRef.current || null}
          open={openEditLotDialog}
          onClose={() => setOpenEditLotDialog(false)}
          onSuccess={() => {
            setOpenEditLotDialog(false);
            onSuccessfulLotEdit();
          }}
        />
      )}
    </>
  );
}

export default LotsDataGrid;

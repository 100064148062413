import React, { useCallback, useMemo } from 'react';
import { BOLContainer, isContainerClosed } from '../../../Models/BOLContainer';
import SortedTable, { SortedTableColumn } from '../../SortedTable';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';

export type AssignContainerCallbackProps = {
  refresh?: boolean;
  containerId?: number;
};

interface AssignContainerListProps {
  containers: BOLContainer[];
  onClose: (props?: AssignContainerCallbackProps) => void;
  loadingId: number;
}

const AssignContainerList = ({
  containers,
  onClose,
  loadingId,
}: AssignContainerListProps) => {
  const handleListItemClick = useCallback(
    async (containerId: number) => {
      // if success:
      onClose({ refresh: true, containerId });
    },
    [onClose]
  );

  const columnDefs = useMemo<SortedTableColumn<BOLContainer>[]>(
    () => [
      {
        id: 'code',
        label: 'Code',
      },
      {
        id: 'totalPallets',
        label: 'Total Pallets',
      },
      {
        id: 'totalWeight',
        label: 'Total Weight',
      },
      {
        id: 'id',
        label: '',
        cellProps: { sx: { width: 0 } },
        cellRender: (container) =>
          isContainerClosed(container.status) ? (
            <LoadingButton size="small" disabled={true}>
              Closed
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="contained"
              size="small"
              onClick={() => handleListItemClick(container.id)}
              loading={loadingId == container.id}
              disabled={loadingId != 0}
              data-testid={`assign-container-btn-${container.id}`}
            >
              Assign
            </LoadingButton>
          ),
      },
    ],
    [handleListItemClick, loadingId]
  );

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        '& .container--status--2': {
          backgroundColor: '#808080',
          '&:hover': {
            backgroundColor: '#808080',
          },
        },
        '& .container--status--3': {
          backgroundColor: '#808080',
          '&:hover': {
            backgroundColor: '#808080',
          },
        },
        '& .container--status--4': {
          backgroundColor: '#808080',
          '&:hover': {
            backgroundColor: '#808080',
          },
        },
        '& .container--status--5': {
          backgroundColor: '#808080',
          '&:hover': {
            backgroundColor: '#808080',
          },
        },
        minHeight: 150,
        maxHeight: 300,
      }}
    >
      <SortedTable
        columnDefs={columnDefs}
        data={containers}
        rowKey="id"
        getRowClassName={(params) => `container--status--${params.status}`}
      ></SortedTable>
    </Box>
  );
};

export default AssignContainerList;

import {
  Box,
  BoxProps,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { ReactNode, useMemo } from 'react';
import PalletService from '../Services/PalletService';
import useApiGet from '../Hooks/useApiGet';
import { tryFormatDateStr } from '../Lib/utils';
import { Pallet, PalletDetails } from '../Models/Pallet';

// DetailRow is a component that displays a label and a value for a single row of data
export const DetailRow = ({
  label,
  testId,
  children,
  wrapContent = false,
  ...props
}: {
  label: ReactNode;
  children: ReactNode;
  testId?: string;
  wrapContent?: boolean;
} & BoxProps) => (
  <Box
    {...props}
    sx={{
      display: 'grid',
      gridTemplateColumns: '127px 1fr',
      fontSize: '14px',
      ...(props.sx || {}),
    }}
  >
    <span
      style={{
        fontWeight: 'bold',
      }}
    >
      {label}&nbsp;
    </span>

    <span
      style={{
        textOverflow: wrapContent ? 'initial' : 'ellipsis',
        overflow: wrapContent ? 'initial' : 'hidden',
        whiteSpace: wrapContent ? 'normal' : 'nowrap',
        wordBreak: wrapContent ? 'break-word' : 'normal',
      }}
      data-testid={testId}
    >
      {children}
    </span>
  </Box>
);

// DetailContent is a component that displays the contents of a pallet
const DetailContent = ({ palletDetails }: { palletDetails: PalletDetails }) => {
  // The required temperature is a property of each Carton but hopefully no Pallet should have
  // Cartons with different temp states. However, just in case, display all distinct states
  // and also show the row in red if there is more than one state.
  const requiredTempStates = useMemo(() => {
    return Array.from(
      new Set(palletDetails.contents.map((c) => c.requiredTempState))
    );
  }, [palletDetails.contents]);
  const ecertHold = useMemo(() => {
    return palletDetails.contents.some((c) => c.eCertPending);
  }, [palletDetails.contents]);

  return (
    <>
      <DetailRow label="Pallet #:" testId={`pallet-code`}>
        {palletDetails.code}
      </DetailRow>
      <DetailRow label="Link Barcode:" testId={`linked-barcode`}>
        {palletDetails.linkedBarcode}
      </DetailRow>
      <DetailRow label="Customer Name:" testId={`customer-name`}>
        {palletDetails.customer?.name ?? ''}
      </DetailRow>

      <DetailRow label="BOL:" testId={`bol-number`}>
        {palletDetails.bolNumber ?? palletDetails.plannedBolNumber}
      </DetailRow>

      <DetailRow label="Planned BOL:" testId={`planned-bol-number`}>
        {palletDetails.plannedBolNumber}
      </DetailRow>

      <DetailRow label="Container:" testId={`container-code`}>
        {palletDetails.containerCode}
      </DetailRow>

      <DetailRow label="Planned Container:" testId={`planned-container-code`}>
        {palletDetails.plannedContainerCode}
      </DetailRow>

      <DetailRow label="Location:" testId={`location`}>
        {palletDetails.location}
      </DetailRow>

      <DetailRow label="Located Date:" testId={`located-date`}>
        {tryFormatDateStr(palletDetails.locatedDateTime, 'dd/MM/yyyy hh:mm:ss')}
      </DetailRow>

      <DetailRow label="Loaded Date:" testId={`loaded-date`}>
        {tryFormatDateStr(palletDetails.loadedDateTime, 'dd/MM/yyyy hh:mm:ss')}
      </DetailRow>

      <DetailRow
        label="Required Temp:"
        testId={`required-temp`}
        sx={{ color: requiredTempStates.length > 1 ? 'red' : undefined }}
      >
        {requiredTempStates.join(', ')}
      </DetailRow>

      <DetailRow label="Hold Status:" testId={`hold-status`}>
        {palletDetails.status?.allowLoadOut ? 'YES' : 'NO'}
      </DetailRow>

      <DetailRow label="ECert Hold:" testId={`ecert-hold`}>
        {ecertHold ? 'Hold' : ''}
      </DetailRow>

      <DetailRow label="Lost?:" testId={`lost`}>
        {palletDetails.lost}
      </DetailRow>
    </>
  );
};

const Totals = ({ palletDetails }: { palletDetails: PalletDetails }) => {
  const totals = useMemo(
    () => ({
      qty: palletDetails.contents.reduce(
        (total, item) => total + item.totalQty,
        0
      ),
      weight: palletDetails.contents.reduce(
        (total, item) => total + item.weight,
        0
      ),
    }),
    [palletDetails.contents]
  );

  return (
    <>
      <DetailRow label="No of Cartons:" testId={`total-no-of-cartons`}>
        {totals.qty}
      </DetailRow>

      <DetailRow label="Total Weight:" testId={`total-weight`}>
        {totals.weight.toFixed(2) + ' Kgs'}
      </DetailRow>
    </>
  );
};

const Contents = ({ palletDetails }: { palletDetails: PalletDetails }) => {
  const groupedContent = useMemo(() => {
    const contents = palletDetails.contents;

    const uniqueProductCodes = Array.from(
      new Set(contents.map((c) => c.productCode))
    );

    return uniqueProductCodes.map((productCode) => {
      const filteredContents = contents.filter(
        (c) => c.productCode === productCode
      );

      return {
        ...filteredContents[0],
        totalQty: filteredContents.reduce(
          (total, item) => total + item.totalQty,
          0
        ),
        totalWeight: filteredContents.reduce(
          (total, item) => total + item.weight,
          0
        ),
      };
    });
  }, [palletDetails.contents]);
  return (
    <>
      {groupedContent.map((content, index) => (
        <Stack direction="column" key={index}>
          {index > 0 && <Divider />}

          <DetailRow label="Product Code:" testId={`product-code-${index}`}>
            {content.productCode}
          </DetailRow>

          <DetailRow label="Batch:" testId={`batch-number-${index}`}>
            {content.batchNumber}
          </DetailRow>

          <DetailRow
            label="Description:"
            testId={`product-description-${index}`}
          >
            {content.productDescription}
          </DetailRow>

          <DetailRow label="No of Cartons:" testId={`total-qty-${index}`}>
            {content.totalQty}
          </DetailRow>

          <DetailRow label="Total Weight:" testId={`weight-${index}`}>
            {content.totalWeight.toFixed(2) + ' Kgs'}
          </DetailRow>
        </Stack>
      ))}
    </>
  );
};

// CardLayout is a component that displays a card with a title and children
const CardLayout = ({
  children,
  title,
  backgroundColor,
}: {
  children: ReactNode;
  title: string;
  backgroundColor?: string;
}) => (
  <Card sx={{ backgroundColor: backgroundColor || '' }}>
    <CardHeader title={title} />
    <CardContent>{children}</CardContent>
  </Card>
);

// BoxLayout is a component that displays a box with a title and children
const BoxLayout = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => (
  <Box>
    <Typography variant="h6">{title}</Typography>
    {children}
  </Box>
);

export interface PalletDetailsProps {
  palletId: number;
  backgroundColor?: string;
  onGotPalletInfo?: (pallet: Pallet) => void;
}

export default function ({
  palletId,
  backgroundColor,
  onGotPalletInfo,
}: PalletDetailsProps) {
  const { data: palletDetails } = useApiGet(PalletService.getPalletDetails, {
    params: {
      id: palletId,
    },
    onSuccess: (result) => {
      if (onGotPalletInfo != undefined) {
        onGotPalletInfo!(result);
      }
    },
  });

  // obtain the theme and check if the screen is small
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const Layout = isSmallScreen ? BoxLayout : CardLayout;

  return (
    <Box>
      {palletDetails && (
        <Stack gap={2}>
          <Layout title="Pallet" backgroundColor={backgroundColor}>
            <DetailContent palletDetails={palletDetails} />
          </Layout>

          <Layout title="Pallet Totals" backgroundColor={backgroundColor}>
            <Totals palletDetails={palletDetails} />
          </Layout>

          <Layout title="Contents" backgroundColor={backgroundColor}>
            <Contents palletDetails={palletDetails} />
          </Layout>
        </Stack>
      )}
    </Box>
  );
}

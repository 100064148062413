import { WMSFile } from '../Models/WMSFile';
import Api, { API_URL, ServicePostMethod, ServiceDeleteMethod } from './Api';

/**
 * This Service provides api endpoints for interacting with its related entity via the Api Service
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const GetAllForEntities: ServicePostMethod<
  GetAllForEntitiesRequest,
  WMSFile[]
> = (
  data,
  options
  // ) => Promise.resolve([[], undefined]);
) => Api.post('api/services/app/WMSFile/GetAllForEntities', data, options);

const create: ServicePostMethod<CreateForEntityRequest, WMSFile> = (
  data,
  options
) => Api.post('api/services/app/WMSFile/Create', data, options);

const deleteById: ServiceDeleteMethod = (id, options) =>
  Api.deleteById('api/services/app/WMSFile/DeleteById?id=' + id, options);

// get the proxy url for a given file
// the url is ...Proxy/{guid}/{filename} and file.key is {guid}.{filename} so replace the first '.' with '/'
const MEDIA_PROXY_URL = API_URL + 'api/services/app/WMSFile/Proxy/';
const makeProxyUrl = (file: WMSFile) =>
  MEDIA_PROXY_URL + file.key.replace('.', '/');

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  GetAllForEntities,
  create,
  deleteById,
  makeProxyUrl,
};

export interface GetAllForEntitiesRequest {
  entityTypes: string;
  entityIds: number | string;
}

export interface CreateForEntityRequest extends Omit<WMSFile, 'id' | 'key'> {
  base64: string;
}

import React, { useMemo, useState } from 'react';
import { toast } from '../../Components/Toast';
import SortedTable, { SortedTableColumn } from '../../Components/SortedTable';
import useApiGet from '../../Hooks/useApiGet';
import UserDefinedCodeService from '../../Services/UserDefinedCodeService';
import { UserDefinedCodeGroup } from '../../Models/UserDefinedCode';
import EditGroup from './EditGroup';
import MobilePage from '../../Components/MobilePage';

/***
 * This is the Admin's list of User Defined Code Groups
 */

export default function () {
  const {
    data: groups,
    loading,
    refresh,
  } = useApiGet(UserDefinedCodeService.getAllWithCodes);
  const [editGroup, setEditGroup] = useState<UserDefinedCodeGroup | null>(null);

  const closeModal = () => {
    setEditGroup(null);
  };

  const onSuccess = () => {
    toast('Group updated!');
    closeModal();
    refresh();
  };

  const columnDefs = useMemo<SortedTableColumn<UserDefinedCodeGroup>[]>(
    () => [
      {
        id: 'name',
        label: 'Group Name',
        sortable: true,
      },
      {
        id: 'userDefinedCodes',
        label: 'Codes',
        value: (group) => group.userDefinedCodes?.length || 0,
      },
    ],
    []
  );

  return (
    <MobilePage sx={{ position: 'relative' }}>
      <SortedTable
        columnDefs={columnDefs}
        data={groups}
        rowKey="id"
        sortOpts={{ id: 'name', dir: 'asc' }}
        title="User Defined Code Groups"
        loading={loading}
        onRowClick={(group) => setEditGroup(group)}
        localStorageKey="UserDefinedCodeGroups.tableOpts"
        localStorageOptions={{ todayOnly: true, sessionOnly: true }}
        searchable
      />
      {editGroup && (
        <EditGroup
          group={editGroup}
          onClose={closeModal}
          onSuccess={onSuccess}
          onRefresh={refresh}
        />
      )}
    </MobilePage>
  );
}

import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useContext } from 'react';
import { BOLContainer, ContainerChip } from '../../../Models/BOLContainer';
import { BillOfLadingWithCounts } from '../../../Models/BillOfLading';
import { ResponsiveContext } from '../../../Providers/ResponsiveProvider';
import { useNavigate } from 'react-router-dom';
import ContainerDataGrid from './Components/ContainerDataGrid';

export interface BolOperatorLoadContainerListProps {
  bol: BillOfLadingWithCounts;
  refresh?: () => void;
}

export default function BolLoadContainerList({
  bol,
  refresh,
}: BolOperatorLoadContainerListProps) {
  const navigate = useNavigate();
  const { mobileView } = useContext(ResponsiveContext);

  const onSelectContainer = (
    container: BOLContainer,
    e: React.MouseEvent<
      HTMLTableRowElement | HTMLDivElement | HTMLButtonElement
    >
  ) => {
    // If the target has no 'checked' property, just do navigate.
    if (!Object.prototype.hasOwnProperty.call(e.target, 'checked')) {
      navigate(container.code);
    }
  };

  return (
    <Box width="100%">
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6" data-testid="bol-number">
          {bol.bolNumber}
        </Typography>
      </Stack>
      <Typography variant="subtitle1">{bol.customerName}</Typography>
      <Stack direction="row" gap={1} alignItems="center" height="42px">
        <Typography variant="h6">Containers/Trucks:</Typography>
      </Stack>
      {mobileView ? (
        <Stack direction="column" gap={1}>
          {bol.containers?.map((container) => (
            <Card
              key={container.id}
              data-testid={'container-card-' + container.id}
              onClick={(e) => onSelectContainer(container, e)}
            >
              <CardContent>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Stack flexGrow={1} alignItems="center" gap={1}>
                    <Typography variant="h6">{container.code}</Typography>
                    <ContainerChip
                      status={container.status}
                      data-testid={'status-' + container.id}
                    />
                    <Box>
                      <b>Picking:</b>&nbsp;
                      <span data-testid={'picking-progress-' + container.id}>
                        {container.palletsInPickedLocation} /{' '}
                        {container.totalPallets}
                      </span>
                    </Box>
                    <Box>
                      <b>Pallets:</b>&nbsp;
                      <span data-testid={'pallets-' + container.id}>
                        {container.loadedPallets} / {container.totalPallets}
                      </span>
                    </Box>
                    <Box>
                      <b>Cartons:</b>&nbsp;
                      <span data-testid={'cartons-' + container.id}>
                        {container.loadedCartons} / {container.totalCartons}
                      </span>
                    </Box>
                    <Box>
                      <b>Weight:</b>&nbsp;
                      <span data-testid={'weight-' + container.id}>
                        {container.loadedWeight} / {container.totalWeight}
                      </span>
                    </Box>
                  </Stack>
                  <Stack>
                    <IconButton size="large" sx={{ p: 0 }}>
                      <ArrowForwardIos />
                    </IconButton>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
      ) : (
        <>
          <ContainerDataGrid
            loading={false}
            rows={bol.containers || []}
            bolNumber={bol.bolNumber}
            refresh={refresh}
          />
        </>
      )}
    </Box>
  );
}

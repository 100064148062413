/* eslint-disable @typescript-eslint/naming-convention */
import {
  AltRoute,
  BallotOutlined,
  Battery5Bar,
  Business,
  CallSplitOutlined,
  Code,
  DashboardOutlined,
  Inventory2Outlined,
  Link,
  ListAlt,
  MonetizationOnOutlined,
  MoveToInboxOutlined,
  MoveUp,
  PeopleOutlineOutlined,
  PriorityHigh,
  Rule,
  Search,
  SettingsApplications,
  SettingsOutlined,
  Storage,
  SvgIconComponent,
  TransferWithinAStation,
  Warehouse,
} from '@mui/icons-material';
import { Dispatch, SetStateAction } from 'react';
import ForkliftIcon from './Icons/Forklift';
import { Permission } from './Models/Role';
import ASNDetail from './Pages/ASNs/ASNDetail';
import ASNList from './Pages/ASNs/ASNList';
import ASNListForOperator from './Pages/ASNs/ASNListForOperator';
import AdminDashboard from './Pages/AdminDashboard/AdminDashboard';
import Billing from './Pages/Billing/Billing';
import BolAdminList from './Pages/BillsOfLading/BolAdminList/BolAdminList';
import BolDetails from './Pages/BillsOfLading/BolDetails';
import BolsOperator from './Pages/BillsOfLading/BolsOperator';
import Cartons from './Pages/Cartons/Cartons';
import Customers from './Pages/CustomerAdmin/Customers';
import OutboundOrderDetail from './Pages/CustomerPages/OutboundOrderDetail';
import OutboundOrders from './Pages/CustomerPages/OutboundOrders';
import ECertDetails from './Pages/ECerts/ECertDetails';
import StockOnHand from './Pages/StockOnHand/StockOnHand';
import LinkBarcode from './Pages/LinkBarcode/LinkBarcode';
import LoadPlan from './Pages/LoadPlan/LoadPlan';
import LocationEnquiry from './Pages/LocationEnquiry/LocationEnquiry';
import LotCartonLinking from './Pages/LotCartonLinking/LotCartonLinking';
import MovePallets from './Pages/MovePallets/MovePallets';
import PalletDetails from './Pages/PalletDetails/PalletDetails';
import PalletPickList from './Pages/PalletPickList/PalletPickList';
import PdfTest from './Pages/PdfTest/PdfTest';
import Settings from './Pages/Settings/Settings';
import SplitPallet from './Pages/SplitPallet/SplitPallet';
import ScanLocationOperator from './Pages/Stocktake/ScanLocationOperator';
import Stocktake from './Pages/Stocktake/Stocktake';
import StocktakeListOperator from './Pages/Stocktake/StocktakeListOperator';
import StoreCapacity from './Pages/Stores/StoreCapacity/StoreCapacity';
import TransferCartons from './Pages/TransferCartons/TransferCartons';
import UserDefinedCodeGroups from './Pages/UserDefinedCodes/UserDefinedCodeGroups';
import Users from './Pages/Users/Users';
import PalletEnquiry from './Pages/PalletSearch/PalletEnquiry';

export interface BaseRoute {
  id?: string;
  path: string;
  Component: () => JSX.Element;
  permissions?: Permission[];
  title?: string;
  Icon?: SvgIconComponent;
  group?: string;
  GroupIcon?: SvgIconComponent;
  open?: boolean;
  hideOnMobile?: boolean;
  childRoute?: boolean;
  badge?: number | string;
}

// enforce type checking - if we provide a title, we also need an icon (and vice versa)
export interface MenuRoute extends BaseRoute {
  title: string;
  Icon: SvgIconComponent;
  setBadge?: Dispatch<SetStateAction<string | number>>;
}

export type Route = BaseRoute | MenuRoute;

export interface MenuGroup {
  name: string;
  Icon: SvgIconComponent;
  items: MenuRoute[];
  open: boolean;
  hideOnMobile?: boolean;
}

const routes: Route[] = [
  {
    path: '/',
    title: 'Dashboard',
    Icon: DashboardOutlined,
    Component: AdminDashboard,
    permissions: ['Pages.AdminDashboard'],
  },
  {
    path: '/',
    title: 'Load Out',
    Icon: ForkliftIcon,
    Component: BolsOperator,
    permissions: ['BOL.Load'],
  },
  {
    // CPK127 - this is a duplicate of the '/' route above for an Operator, so they get back here when switching Warehouse
    path: '/bol',
    title: 'Bill of Ladings',
    permissions: ['BOL.Load'],
    Component: BolsOperator,
  },
  {
    id: 'BolAdminList',
    path: '/bol',
    title: 'Bill of Ladings',
    permissions: ['Pages.AdminBolLoadList'],
    Icon: ListAlt,
    Component: BolAdminList,
    badge: '-',
  },
  {
    path: '/loadplan',
    title: 'Load Plan',
    permissions: ['Pages.LoadPlan'],
    Icon: PriorityHigh,
    Component: LoadPlan,
  },
  {
    path: '/bol/:bolNumber/ecert/:eCertId',
    permissions: ['Pages.AdminBolLoadList'],
    Component: ECertDetails,
  },
  {
    path: '/bol/:bolNumber/:containerCode?',
    Component: BolDetails,
  },
  {
    path: '/barcode-search',
    title: 'Pallet Enquiry',
    Icon: Search,
    Component: PalletEnquiry,
    permissions: ['BOL.Pallet.Search'],
  },
  {
    path: '/pallet-pick-list',
    title: 'Pallet Pick List',
    Icon: Search,
    Component: PalletPickList,
    permissions: ['BOL.Pallet.Search', 'BOL.Pallet.UpdateLocation'],
  },
  {
    path: '/barcode-link',
    title: 'Link Barcode',
    Icon: Link,
    Component: LinkBarcode,
    permissions: ['BOL.LinkBarcode'],
  },
  {
    path: '/pallets-move',
    title: 'Move Pallets',
    Icon: MoveUp,
    Component: MovePallets,
    permissions: ['BOL.Pallet.UpdateLocation'],
  },
  {
    path: '/cartons',
    title: 'Carton Manager',
    permissions: ['Pages.Cartons', 'Carton.UpdateHoldStatus'],
    Icon: TransferWithinAStation,
    Component: Cartons,
  },
  {
    path: '/asn',
    title: 'ASN Arrivals',
    Icon: MoveToInboxOutlined,
    Component: ASNListForOperator,
    permissions: ['ASN.Arrivals'],
  },
  {
    path: '/asn',
    title: 'ASN',
    permissions: ['ASN.View'],
    Icon: MoveToInboxOutlined,
    Component: ASNList,
  },
  {
    path: '/lot-carton-linking',
    title: 'ECerts',
    Icon: BallotOutlined,
    Component: LotCartonLinking,
    permissions: ['Pages.Lots'],
  },
  {
    path: '/asn/:asnNumber',
    permissions: ['ASN.View', 'ASN.Arrivals'],
    Component: ASNDetail,
  },
  {
    path: '/LocationEnquiry',
    title: 'Location Enquiry',
    permissions: ['BOL.Pallet.Search'],
    Icon: Storage,
    Component: LocationEnquiry,
  },
  {
    path: '/verify-pallet-details',
    title: 'Verify Pallets',
    Icon: Rule,
    Component: PalletDetails,
    permissions: ['BOL.Pallet.VerifyDetails'],
  },
  {
    path: '/split-pallet',
    title: 'Split Pallet',
    Icon: CallSplitOutlined,
    Component: SplitPallet,
    permissions: ['BOL.Pallet.Split'],
  },
  {
    path: '/transfer-cartons',
    title: 'Transfer Cartons',
    Icon: AltRoute,
    Component: TransferCartons,
    permissions: ['BOL.Pallet.Split'],
  },
  {
    path: '/store-capacity',
    title: 'Store Capacity',
    Icon: Battery5Bar,
    Component: StoreCapacity,
    permissions: ['Store.Capacity.View'],
  },
  {
    path: '/stock',
    title: 'Stock on Hand',
    permissions: ['Pages.StockOnHand'],
    Icon: Inventory2Outlined,
    Component: StockOnHand,
  },
  {
    path: '/billing',
    title: 'Billing',
    permissions: ['Billing.View'],
    Icon: MonetizationOnOutlined,
    Component: Billing,
  },
  {
    path: '/customers',
    title: 'Customers',
    permissions: ['Pages.Customers'],
    Icon: Business,
    Component: Customers,
  },
  {
    path: '/LocationEnquiry/:location',
    Component: LocationEnquiry,
  },
  {
    path: '/Stocktake',
    title: 'Stocktake',
    permissions: ['Stocktake.View'],
    Icon: Warehouse,
    Component: Stocktake,
  },
  {
    path: '/StocktakeListOperator',
    title: 'Stocktake',
    permissions: ['StocktakePallet.View'],
    Icon: Warehouse,
    Component: StocktakeListOperator,
  },
  {
    path: '/ScanLocationOperator/:stocktakeId/:location',
    Component: ScanLocationOperator,
    permissions: ['StocktakePallet.View', 'StocktakePallet.Create'],
  },
  {
    path: '/users',
    title: 'Users',
    permissions: ['Pages.Users'],
    Icon: PeopleOutlineOutlined,
    Component: Users,
    group: 'Settings',
    GroupIcon: SettingsOutlined, // the first item in a group provides the GroupIcon
    open: true,
  },
  {
    path: '/codes',
    title: 'Code Groups',
    permissions: ['Pages.Codes'],
    Icon: Code,
    Component: UserDefinedCodeGroups,
    group: 'Settings',
  },
  {
    path: '/settings',
    title: 'More Settings',
    permissions: ['Admin.Settings.View'],
    Icon: SettingsApplications,
    Component: Settings,
    group: 'Settings',
  },
  {
    path: '/c/outbound',
    title: 'Outbound Orders',
    permissions: ['Customer.User', 'Pages.AdminDashboard'],
    Icon: ListAlt,
    Component: OutboundOrders,
  },
  {
    path: '/c/outbound/:bolNumber',
    title: 'Outbound Order',
    permissions: ['Customer.User', 'Pages.AdminDashboard'],
    Component: OutboundOrderDetail,
    childRoute: true,
  },
  // TODO remove the following route once the pdf module is used
  {
    path: '/pdf-test',
    Component: PdfTest,
  },
];

export default routes;

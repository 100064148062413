import React, { useEffect, useMemo, useState } from 'react';
import FormField, { SelectItem } from '../../Components/Forms/FormField';
import useApiForm, { UseApiFormResult } from '../../Hooks/useApiForm';
import { onlyAllowDigits } from '../../Lib/utils';
import {
  CodeCustomValue,
  CodeGroupCustomField,
  UserDefinedCode,
} from '../../Models/UserDefinedCode';

export interface CustomFieldProps<
  TForm extends { customValues: CodeCustomValue[] },
  TResult
> {
  field: CodeGroupCustomField;
  form: UseApiFormResult<TForm, TResult>;
  code: UserDefinedCode | null;
  codes: UserDefinedCode[];
}

export default function <
  TForm extends { customValues: CodeCustomValue[] },
  TResult
>({ field, form, code, codes }: CustomFieldProps<TForm, TResult>) {
  // if we add a new row to the custom values we need to find the new one
  const [refreshValueCounter, setRefreshValueCounter] = useState(0);

  const value = useMemo(
    () => form.data.customValues.find((v) => v.name == field.name),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field, form.data.customValues, refreshValueCounter]
  );

  // create a dummy form so we can use <FormField> goodness!
  const dummyForm = useApiForm(null, { value: value?.value || '' });

  // when the dummy form is updated, simulate an update to the correct field on the parent
  useEffect(() => {
    if (value && value.value != dummyForm.data.value) {
      value.value = dummyForm.data.value;
      if (!dummyForm.data.value) {
        // if an old value exists but the value has been cleared, delete the custom value
        form.setData(
          'customValues',
          form.data.customValues.filter((v) => v.name != field.name)
        );
        setRefreshValueCounter(refreshValueCounter + 1);
      } else {
        // if the value already exists and is different update it
        form.setData('customValues', form.data.customValues);
      }
    } else if (!value && dummyForm.data.value) {
      // add a new value to the list of custom values
      form.data.customValues.push({
        name: field.name,
        value: dummyForm.data.value,
      });
      form.setData('customValues', form.data.customValues);
      setRefreshValueCounter(refreshValueCounter + 1);
    }
  }, [field, dummyForm.data.value, form, value, refreshValueCounter]);

  const options = useMemo<SelectItem[]>(() => {
    if (field.type == 'select') {
      return (field.options || []).map((id) => ({ id, label: id }));
    }
    if (field.type != 'parent') {
      return [];
    }
    // 'parent' type - don't show self as an option
    // also need to filter any parents which already have a parent themselves
    // TODO make this work with maxLevels > 1, if and when needed?
    const excludeChild = (code: UserDefinedCode) => {
      if (!field.maxLevels) {
        return false;
      }
      const customValueWithParent = code.customValues?.find(
        (c) => c.name == field.name && c.value
      );
      return !!customValueWithParent; // if code has parent, don't show it.
    };
    const parents = codes
      .filter((c) => c.code != code?.code && !excludeChild(c))
      .map((c) => ({ id: c.code, label: c.name }));
    return [{ id: '', label: '' }, ...parents];
  }, [field, code, codes]);

  return field.type == 'select' || field.type == 'parent' ? (
    <FormField
      form={dummyForm}
      id="value"
      type="select"
      options={options}
      label={field.name}
    />
  ) : (
    // type == 'number'
    <FormField
      form={dummyForm}
      id="value"
      type="number"
      label={field.name}
      onKeyDown={onlyAllowDigits}
      inputProps={{
        'data-lpignore': true,
      }}
    />
  );
}

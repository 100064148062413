import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import usePermissions from '../../Hooks/usePermissions';
import MobilePage from '../../Components/MobilePage';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import useApiGet from '../../Hooks/useApiGet';
import BolContainerService from '../../Services/BolContainerService';
import { parseXmlFromString, XmlTag } from '../../Lib/xmlUtils';
import CardTitle from '../../Components/CardTitle';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CodeIcon from '@mui/icons-material/Code';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Skeletons from '../../Components/Skeletons';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';
import XmlDialog from './Components/XmlDialog';
import ECertParties from './Components/ECertParties';
import ECertProducts from './Components/ECertProducts';
import ECertTransport from './Components/ECertTransport';
import ECertService from '../../Services/ECertService';
import ECertRemarks from './Components/ECertRemarks';
import LoadingButton from '@mui/lab/LoadingButton';
import { ECertStatus } from '../../Models/BOLContainer';

export default function ECertDetails() {
  const tagNamePrefix = 'ed:';
  const { eCertId } = useParams<{ eCertId: string }>();
  const [canUpdateContainerStatus] = usePermissions([
    'Container.Status.Update',
  ]);
  const [title, setTitle] = useState('ECERT');
  const [xmlContent, setXmlContent] = useState<XmlTag | null>(null);
  const [showXmlDialog, setShowXmlDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [expandedAccordion, setExpandedAccordion] =
    useState('panelCertificateId');
  const transformECertPublicId = eCertId?.split('/').join('-') || '';
  const { data: eCert, refresh: refreshECert } = useApiGet(
    ECertService.getECertsDetails,
    {
      params: {
        certificatePublicId: transformECertPublicId,
      },
    }
  );
  const { data: xmlString, refresh } = useApiGet(
    BolContainerService.generateECertXML,
    {
      params: {
        certificatePublicId: transformECertPublicId,
      },
      responseType: 'text',
    }
  );
  const [thirdParties, setThirdParties] = useState<XmlTag[]>([]);
  const [products, setProducts] = useState<XmlTag[]>([]);
  const getXmlNodes = (tagName: string) => {
    return (
      (xmlContent?.getElementsByTagName?.(
        tagName,
        tagNamePrefix
      ) as XmlTag[]) || []
    );
  };
  const findXmlNode = (tagName: string) => {
    return getXmlNodes(tagName)[0] as XmlTag;
  };
  const handleAccordionChanged =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : '');
    };

  const submitECert = async () => {
    setSubmitting(true);
    const [eCert] = await ECertService.submitECert(
      { certificatePublicId: transformECertPublicId },
      {
        suppressError: true,
      }
    );
    if (eCert) {
      refreshECert();
      refresh();
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (xmlString) {
      const xmlRootContent = parseXmlFromString(xmlString);
      setXmlContent(xmlRootContent);
    }
  }, [xmlString]);

  useEffect(() => {
    if (xmlContent) {
      const certId = findXmlNode('CertificateID')?.value || '';
      setTitle(`ECERT ${certId}`);
      setThirdParties(findXmlNode('ThirdParty')?.children || []);
      setProducts(getXmlNodes('Product'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xmlContent]);

  return (
    <MobilePage pageTitle={title} showOnDesktop={true}>
      <Paper variant={'outlined'} sx={{ padding: '16px' }}>
        <Stack justifyContent={'space-between'} direction={'row'}>
          <CardTitle title={'Details'}></CardTitle>
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              data-testid="view-xml-button"
              variant="contained"
              endIcon={<CodeIcon fontSize={'small'} />}
              onClick={() => setShowXmlDialog(true)}
              disabled={!canUpdateContainerStatus}
            >
              View XML
            </Button>
            <LoadingButton
              data-testid="submit-ecert-button"
              variant="contained"
              endIcon={<FileUploadIcon fontSize={'small'} />}
              disabled={
                !canUpdateContainerStatus ||
                eCert?.status == ECertStatus.submitted
              }
              loading={submitting}
              onClick={() => submitECert()}
            >
              Submit
            </LoadingButton>
          </Box>
        </Stack>
        {xmlContent ? (
          <>
            <Paper variant={'elevation'} sx={{ marginTop: '16px' }}>
              <Accordion
                expanded={expandedAccordion === 'panelCertificateId'}
                onChange={handleAccordionChanged('panelCertificateId')}
                sx={{ padding: '16px' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="panelCertificateId-header"
                >
                  <Typography
                    sx={{
                      width: '25%',
                      flexShrink: 0,
                      fontSize: '20px',
                      fontWeight: 500,
                    }}
                  >
                    Certificate ID
                  </Typography>
                  <Typography sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                    {findXmlNode('CertificateId')?.value || ''}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DatePicker
                    sx={{ marginBottom: '16px' }}
                    label="Departure date"
                    value={parseISO(
                      findXmlNode('DepartureDate')?.value + 'T00:00:00'
                    )}
                  />
                  {/* Parties Section */}
                  <ECertParties
                    consignorId={findXmlNode('ConsignorID')?.value || ''}
                    consigneeId={findXmlNode('ConsigneeID')?.value || ''}
                    thirdParties={thirdParties}
                  />
                  <Divider variant="fullWidth" />
                  {/* Transport Section */}
                  <ECertTransport
                    eCertXmlContent={xmlContent}
                    tagNamePrefix={tagNamePrefix}
                  />
                  <Divider variant="fullWidth" />
                  {/* Remarks Section */}
                  {eCert && (
                    <ECertRemarks
                      eCert={eCert}
                      refresh={() => {
                        refreshECert({ clear: true });
                        refresh();
                      }}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </Paper>
            <ECertProducts products={products} tagNamePrefix={tagNamePrefix} />
          </>
        ) : (
          <Skeletons count={3} />
        )}
        <XmlDialog
          eCertPublicId={eCert?.certificatePublicId ?? ''}
          onClose={() => setShowXmlDialog(false)}
          isOpen={showXmlDialog}
        />
      </Paper>
    </MobilePage>
  );
}

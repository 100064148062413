import React from 'react';
import Login from './Login';
import { Alert, Typography } from '@mui/material';

export default function () {
  return (
    <Login title="Cooltrak">
      <Typography sx={{ mb: 1 }} variant="body1" data-testid="customerLogin">
        {' '}
        Please login into your account.
      </Typography>

      <Alert icon={false} severity="info">
        <Typography sx={{ mb: 1 }} variant="body2">
          Welcome to the new version of Cooltrak, if you have problems signing
          into your account please{' '}
          <a href="mailto:support@coolpak.co.nz">contact us</a>.
        </Typography>
        <Typography sx={{ mb: 1 }} variant="body2">
          If this is your first time logging in, you will be required to change
          your password on the next screen.
        </Typography>
        <Typography variant="body2">
          We recommend using Cooltrak with browsers Chrome, Safari, FireFox or
          Edge. Older browsers are not supported.
        </Typography>
      </Alert>
    </Login>
  );
}

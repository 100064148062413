import Api, { ServiceGetMethod, ServicePostMethod } from './Api';
import { ChecklistItemResponse } from '../Models/ChecklistItemResponse';

const getAllForEntityTypeAndEntityId: ServiceGetMethod<
  { entityType: string; entityId: number },
  ChecklistItemResponse[]
> = (options) =>
  Api.get(
    'api/services/app/ChecklistItemResponse/GetAllForEntityTypeAndEntityId',
    options
  );

const isChecklistCompletedForEntityTypeAndEntityId: ServiceGetMethod<
  { entityType: string; entityId: number; checklistId: number },
  boolean
> = (options) =>
  Api.get(
    'api/services/app/ChecklistItemResponse/IsChecklistCompletedForEntityTypeAndEntityId',
    options
  );

const createOrUpdateChecklistItemResponse: ServicePostMethod<
  CreateOrUpdateChecklistItemResponseRequest,
  CreateOrUpdateChecklistItemResponseResponse
> = (data, options) =>
  data.id
    ? Api.put(
        'api/services/app/ChecklistItemResponse/UpdateAndCheckChecklistCompleted',
        data,
        options
      )
    : Api.post(
        'api/services/app/ChecklistItemResponse/CreateAndCheckChecklistCompleted',
        data,
        options
      );

export default {
  getAllForEntityTypeAndEntityId,
  createOrUpdateChecklistItemResponse,
  isChecklistCompletedForEntityTypeAndEntityId,
};

export interface CreateOrUpdateChecklistItemResponseRequest {
  id: number;
  response: string;
  entityType: string;
  entityId: number;
  comment: string;
  checklistItemId: number;
}

export interface CreateOrUpdateChecklistItemResponseResponse {
  checklistItemResponse: ChecklistItemResponse;
  isChecklistCompleted: boolean;
}

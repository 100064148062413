/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  ApiOptions,
  getHttpErrorCode,
  getHttpErrorMessage,
  HttpError,
  ServicePostMethod,
} from '../Services/Api';
import { useSearchParams } from 'react-router-dom';

/**
 * useApiForm is a custom React hook to control a form state and then submit the data to a standardised serviceMethod.
 *
 * * The defaultValues parameter and returned objects are strongly typed based on the inferred type of the serviceMethod
 * * The options parameter extends the axios parameters so can take additional url parameters, http headers, etc.
 * * Errors are automatically displayed in a Toast, unless options.suppressError = true
 */

type Errors<TRequest> = Partial<Record<keyof TRequest, string>>;
type SubmitCallback = (e?: { preventDefault: () => void }) => void;

export interface UseApiFormOptions<TResponse> {
  enableFormUpdatesWhenFocussed?: boolean; // defaults to false - form fields will not update with new values from the server when the field is focussed
  useSearchParams?: boolean; // stores the form state in the query params
  onError?: (
    message: string,
    e: HttpError,
    errorCode: number | undefined
  ) => any | void;
  onSuccess?: (r: TResponse) => any;
}

export interface UseApiFormResult<TRequest, TResult> {
  data: TRequest;
  errors: Errors<TRequest>;
  lastData: TRequest;
  processing: boolean;
  httpError: HttpError | undefined;
  httpResponse: TResult | undefined;
  enableFormUpdatesWhenFocussed: boolean;
  setData: <K extends keyof TRequest>(key: K, value: TRequest[K]) => void;
  setErrors: (newErrors?: Errors<TRequest>) => void;
  reset: () => void;
  submit: SubmitCallback;
  cancel: () => void;
  fromSearch: (searchParams: URLSearchParams) => void;
}

// overwrite the default values with the values from the search params
function paramsFromSearch<TRequest extends Record<string, any>>(
  defaultValues: TRequest,
  searchParams: URLSearchParams
) {
  const values = { ...defaultValues };
  Object.keys(defaultValues).forEach((key: keyof TRequest) => {
    const value = searchParams.get(key as string);
    if (value != '' && value != null) {
      if (parseInt(value).toString() == value) {
        values[key] = parseInt(value) as any;
      } else {
        values[key] = value as any;
      }
    }
  });
  return values;
}

export default function <TRequest extends Record<string, any>, TResult>(
  // allow a null serviceMethod so a component can use this to create a handy `form` state for controlling a <FormField>
  serviceMethod: ServicePostMethod<TRequest, TResult> | null,
  defaultValues: TRequest,
  options?: UseApiFormOptions<TResult> & ApiOptions<TRequest>
): UseApiFormResult<TRequest, TResult> {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialValues = useMemo(
    () =>
      options?.useSearchParams
        ? paramsFromSearch(defaultValues, searchParams)
        : { ...defaultValues },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [data, setRawData] = useState<TRequest>(initialValues);
  const [lastData, setLastData] = useState<TRequest>(initialValues);
  const [formErrors, setFormErrors] = useState<Errors<TRequest>>({});
  const [httpResponse, setHttpResponse] = useState<TResult | undefined>(
    undefined
  );
  const [httpError, setHttpError] = useState<HttpError | undefined>(undefined);
  const [processing, setProcessing] = useState(false);
  const abortController = useRef<AbortController>();

  const setData = useCallback<UseApiFormResult<TRequest, TResult>['setData']>(
    (key, value) => {
      data[key] = value;
      const newData = { ...data };
      setRawData(newData);
      if (options?.useSearchParams) {
        // ignore any blank values on the search url, but allow zero values
        const actualValues: Record<string, any> = {};
        Object.keys(newData).forEach((key) => {
          if (newData[key] != '' && newData[key] != null) {
            actualValues[key] = newData[key];
          }
        });
        setSearchParams(actualValues, { replace: true });
      }
    },
    [data, setRawData, setSearchParams, options?.useSearchParams]
  );

  const setErrors = useCallback<
    UseApiFormResult<TRequest, TResult>['setErrors']
  >(
    (newErrors) => {
      setFormErrors(newErrors || {});
    },
    [setFormErrors]
  );

  const submit = useCallback<SubmitCallback>(
    async (e) => {
      e?.preventDefault();
      if (!serviceMethod) {
        return;
      }
      setFormErrors({});
      setProcessing(true);
      setLastData({ ...data });
      abortController.current = new AbortController();
      const [response, error] = await serviceMethod(data, {
        signal: abortController.current.signal,
        ...(options || {}),
      });
      setHttpResponse(response);
      if (error?.message == 'canceled') {
        setHttpError(undefined);
        // return before setting processing to false because by then form will possibly have been resubmitted
        // instead, set processing to false in the cancel callback below
        return;
      }
      setHttpError(error);
      if (error) {
        // parse any server-side validation errors into form errors
        const formKeys = Object.keys(defaultValues || {});
        const validationErrors = error.response?.data?.error?.validationErrors;
        const newFormErrors: Errors<TRequest> = {};
        validationErrors?.forEach((error) => {
          const key = error.members?.find((k) => formKeys.includes(k));
          if (key) {
            // replace "The UserNameOrEmailAddress field..." with "This field..." (note the uppercase U)
            newFormErrors[key as keyof TRequest] = error.message.replace(
              new RegExp('The ' + key, 'i'),
              'This'
            );
          }
        });
        setFormErrors(newFormErrors);
        options?.onError?.(
          getHttpErrorMessage(error),
          error,
          getHttpErrorCode(error)
        );
      } else {
        options?.onSuccess?.(response);
      }
      setProcessing(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, options, setProcessing, setFormErrors]
  );

  const fromSearch = useCallback(
    (params?: URLSearchParams) => {
      const values = paramsFromSearch(defaultValues, params || searchParams);
      Object.keys(values).forEach((key) =>
        setData(key as keyof TRequest, values[key as keyof TRequest] as any)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setData]
  );

  const reset = () => {
    setRawData({ ...defaultValues });
    setLastData({ ...defaultValues });
    setFormErrors({});
    setHttpError(undefined);
  };

  return {
    data,
    setData,
    lastData,
    errors: formErrors,
    setErrors,
    submit,
    reset,
    cancel: () => {
      abortController.current?.abort();
      // set processing to false immediately so the form can be resubmitted
      setProcessing(false);
    },
    processing,
    httpError,
    httpResponse,
    enableFormUpdatesWhenFocussed:
      options?.enableFormUpdatesWhenFocussed || false,
    fromSearch,
  };
}

import React, { useState } from 'react';
import { XmlTag } from '../../../Lib/xmlUtils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import ECertBlockInfo from './ECertBlockInfo';

export default function ECertProductPackaging({
  product,
  tagNamePrefix = 'ed:',
}: {
  product: XmlTag;
  tagNamePrefix?: string;
}) {
  const [expandedAccordion, setExpandedAccordion] = useState('');
  const handleAccordionChanged =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : '');
    };
  const packagingItems =
    product.getElementsByTagName?.('Package', tagNamePrefix) || [];

  return (
    <Accordion
      expanded={expandedAccordion === 'panelProductPackaging'}
      onChange={handleAccordionChanged('panelProductPackaging')}
      sx={{ padding: '8px' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="panelProductPackaging-header"
      >
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          Packaging
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {packagingItems.map((packageItem, index) => {
          const quantity =
            packageItem.getElementsByTagName?.('Quantity', tagNamePrefix)[0]
              ?.value || '';
          const packageType =
            packageItem.getElementsByTagName?.('Type', tagNamePrefix)[0]
              ?.value || '';
          const level =
            packageItem.getElementsByTagName?.('Level', tagNamePrefix)[0]
              ?.value || '';
          const shippingMarkTags =
            packageItem.getElementsByTagName?.(
              'ShippingMarks',
              tagNamePrefix
            ) || [];
          return (
            <Grid
              container
              spacing={2}
              key={`ecert-product-packaging-container-${index}`}
            >
              <ECertBlockInfo
                label="Quantity"
                displayText={quantity}
                xs={5}
                md={4}
              />
              <ECertBlockInfo
                label="Type"
                displayText={packageType}
                xs={5}
                md={4}
              />
              <ECertBlockInfo label="Level" displayText={level} xs={5} md={4} />
              <Grid item xs={5} md={4}>
                <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                  Shipping Marks
                </Typography>
                <Box sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                  {shippingMarkTags.map((shippingMark, idx) => {
                    return (
                      <Typography
                        key={`ecert-product-packaging-remark-name-${index}-${idx}`}
                      >
                        {shippingMark.getElementsByTagName?.(
                          'Name',
                          tagNamePrefix
                        )[0]?.value || ''}
                      </Typography>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}

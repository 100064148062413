import { format, parseISO, isValid } from 'date-fns';
import { useEffect, useState } from 'react';

/*
 * React hook to store data in localStorage
 * Copied from: https://usehooks.com/useLocalStorage/
 * But modified for Typescript and with the todayOnly and sessionOnly options and with event listeners
 * 2022-09-30 chris.morison@webtoolsagri.com
 */

export interface UseLocalStorageOptions {
  todayOnly?: boolean;
  sessionOnly?: boolean;
}

function useLocalStorage<T>(
  key: string | null | undefined,
  initialValue: T,
  { todayOnly, sessionOnly } = {} as UseLocalStorageOptions
) {
  const storage = !key
    ? null
    : sessionOnly
    ? global?.sessionStorage
    : global?.localStorage;
  const today = todayOnly && format(new Date(), 'yyyy-MM-dd');
  const eventName = `useLocalStorage.${key || ''}.changed`;
  const isString = typeof initialValue == 'string';

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (!storage || !key) {
      return initialValue;
    }
    try {
      if (today) {
        // clear the property if it wasn't set today
        const date = storage.getItem(key + '__date');
        if (date && date != today) {
          storage.removeItem(key);
          storage.removeItem(key + '__date');
        }
      }
      // Get from storage by key
      const item = storage.getItem(key);
      // Parse stored json or if none return initialValue
      if (!item) {
        return initialValue;
      } else if (isString) {
        return item;
      } else {
        // Check if the item is a date string and convert it back to a Date object
        const parsedItem = JSON.parse(item);
        if (typeof parsedItem === 'string') {
          const date = parseISO(parsedItem);
          if (isValid(date)) {
            return date;
          }
        }
        return parsedItem;
      }
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(isString ? valueToStore : { ...valueToStore });
      // Save to local storage
      if (storage) {
        // Check if the value is a Date object and convert it to a string
        const valueToStoreString =
          valueToStore instanceof Date
            ? valueToStore.toISOString()
            : valueToStore;
        storage.setItem(
          key!,
          isString ? valueToStoreString : JSON.stringify(valueToStoreString)
        );
        if (today) {
          storage.setItem(key + '__date', today);
        }
      }
      // if multiple components use the same storage, we need to send them a message with the new value
      // note that the native 'storage' event doesn't work on the same page, so use our own event
      window.dispatchEvent(
        new CustomEvent<T>(eventName, { detail: valueToStore })
      );
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  // listen for our own storage change events from other components
  useEffect(() => {
    if (key) {
      const handler = (data: Event) => {
        const newValue = (data as CustomEvent<T>).detail;
        setStoredValue(isString ? newValue : { ...newValue });
      };
      window.addEventListener(eventName, handler);

      // remove the listener when the component is dismounted
      return () => window.removeEventListener(eventName, handler);
    }
  }, [eventName, setStoredValue, key, isString]);

  const reset = () => setValue(initialValue);

  return [storedValue, setValue, reset] as const;
}

export default useLocalStorage;

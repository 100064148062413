import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import useApiForm from '../../Hooks/useApiForm';
import PalletService, {
  PALLET_ERROR_CODES,
} from '../../Services/PalletService';
import CartonService, {
  CARTON_ERROR_CODES,
  ORPHAN_PALLET_STATUS_REASONS,
} from '../../Services/CartonService';
import {
  getHttpErrorMessage,
  HttpError,
  outputError,
} from '../../Services/Api';
import MobilePage from '../../Components/MobilePage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Pallet } from '../../Models/Pallet';
import { GridColDef } from '@mui/x-data-grid-pro';
import { toast, toastError } from '../../Components/Toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  CartonStatus,
  digitsDisplayedForCartonBarcode,
  getShortenBarcode,
} from '../../Models/Carton';
import {
  alertModalDialog,
  confirmModalDialog,
} from '../../Components/ModalDialog';
import BarcodeScannerFormInput from '../../Components/Forms/BarcodeScannerFormInput';
import CartonsWIthStatusFilter from '../../Components/Carton/CartonsWIthStatusFilter';

export default function () {
  const [pallet, setPallet] = useState<Pallet | null>(null);
  const [newPalletBarcode, setNewPalletBarcode] = useState<string>('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState<CartonStatus | null>(null);

  const resetPalletForm = () => {
    getPalletForm.setData('code', '');
    setPallet(null);
    setNewPalletBarcode('');
  };

  const resetCartonToPalletByBarcodeForm = () => {
    addCartonToPalletByBarcodeForm.setData('cartonBarcode', '');
    addCartonToPalletByBarcodeForm.setData('allowNewProduct', false);
  };

  const onAcceptNewProductModal = () => {
    addCartonToPalletByBarcodeForm.setData('allowNewProduct', true);
    addCartonToPalletByBarcodeForm.submit();
  };

  const handleGetPalletError = (
    _: string,
    error: HttpError,
    errorCode: number | undefined
  ) => {
    switch (errorCode) {
      case PALLET_ERROR_CODES.PALLET_NOT_FOUND:
        confirmModalDialog({
          title: '',
          content: `Do you want to create a new pallet with Barcode ${getPalletForm.data.code}?`,
          declineButtonLabel: 'no',
          acceptButtonLabel: 'yes',
          onDecline: resetPalletForm,
        }).then((res) => res && setNewPalletBarcode(getPalletForm.data.code));
        break;
      default:
        outputError(error);
        break;
    }
  };

  const handleAddCartonOnError = (
    _: string,
    error: HttpError,
    errorCode: number | undefined
  ) => {
    switch (errorCode) {
      case CARTON_ERROR_CODES.PRODUCT_NOT_EXISTS_ON_PALLET:
        confirmModalDialog({
          title: 'New product scanned',
          content: getHttpErrorMessage(error),
          onDecline: resetCartonToPalletByBarcodeForm,
          onAccept: onAcceptNewProductModal,
          severity: 'error',
        }).then();
        break;
      case CARTON_ERROR_CODES.CUSTOMER_MISMATCH_PALLET:
        alertModalDialog({
          title: 'Unable to add Carton',
          content: getHttpErrorMessage(error),
          onClose: resetCartonToPalletByBarcodeForm,
          severity: 'error',
          isClosableFromBackdrop: false,
        });
        break;
      default:
        outputError(error);
        break;
    }
  };

  const getPalletForm = useApiForm(
    PalletService.getByCode,
    {
      code: '',
      withCartons: true,
    },
    {
      suppressError: true,
      onError: handleGetPalletError,
      onSuccess: (result) => {
        setPallet(result);
        addCartonToPalletByBarcodeForm.setData('palletId', result!.id);
        addCartonToPalletByBarcodeForm.setData('newPalletBarcode', '');
      },
      useSearchParams: true,
    }
  );

  const addCartonToPalletByBarcodeForm = useApiForm(
    CartonService.addCartonToPalletByBarcode,
    {
      cartonBarcode: '',
      palletId: 0,
      allowNewProduct: false,
      process: ORPHAN_PALLET_STATUS_REASONS.SPLIT_A_PALLET,
      newPalletBarcode: '',
    },
    {
      onSuccess: () => {
        resetCartonToPalletByBarcodeForm();
        // refresh the list of cartons
        getPalletForm.submit();
      },
      suppressError: true,
      onError: handleAddCartonOnError,
    }
  );

  // submit the form if the url contains a query parameter code
  useEffect(() => {
    if (searchParams.get('code')) {
      getPalletForm.submit();
    }
    // should happen only when loading the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPallet = (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    getPalletForm.submit();
    return false;
  };

  const fetchCarton = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    const existingCarton = pallet?.cartons?.some((carton) =>
      carton.cartonBarcode.startsWith(
        addCartonToPalletByBarcodeForm.data.cartonBarcode
      )
    );

    if (existingCarton) {
      toastError('Carton has already been added to the pallet.');
      return;
    }

    if (pallet == null && newPalletBarcode !== '') {
      addCartonToPalletByBarcodeForm.setData('palletId', 0);
      addCartonToPalletByBarcodeForm.setData(
        'newPalletBarcode',
        newPalletBarcode
      );
    }

    addCartonToPalletByBarcodeForm.submit();

    return false;
  };

  const columnDefs = useMemo<GridColDef[]>(
    () => [
      {
        field: 'cartonBarcode',
        renderHeader: () => (
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 450,
              fontSize: '14px',
            }}
          >
            Barcode
            <ContentCopyIcon sx={{ marginLeft: 0.5 }} />
          </Typography>
        ),
        flex: 1,
        renderCell: (params) => (
          <Button
            variant="text"
            onClick={() => {
              navigator.clipboard
                .writeText(params.row.cartonBarcode)
                .then(() => toast('Barcode copied'));
            }}
          >
            {params.row.cartonBarcode.length > digitsDisplayedForCartonBarcode
              ? '...'
              : ''}
            {getShortenBarcode(
              params.row.cartonBarcode,
              digitsDisplayedForCartonBarcode
            )}
          </Button>
        ),
      },
      {
        field: 'productCode',
        headerName: 'Pdt Code',
        flex: 1,
      },
      {
        field: 'qty',
        headerName: 'QTY',
        flex: 0.6,
        renderCell: (params) => params.row.qty,
      },
    ],
    []
  );

  const onCompleteScanning = async () => {
    toast('Scanning completed');
    resetPalletForm();
  };

  const onClickOnStatus = (status: CartonStatus) => {
    setStatusFilter(statusFilter === status ? null : status);
  };

  return (
    <MobilePage
      showOnDesktop
      title="Transfer Cartons"
      onBack={() => {
        if (pallet !== null || newPalletBarcode !== '') {
          resetPalletForm();
        } else {
          navigate(-1);
        }
      }}
    >
      <Stack spacing={1}>
        {pallet == null && newPalletBarcode == '' && (
          <Box component="form" onSubmit={fetchPallet} noValidate>
            <Typography variant="subtitle2" textAlign="center">
              Scan pallet:
            </Typography>
            <BarcodeScannerFormInput
              label={'Pallet Barcode'}
              id="code"
              form={getPalletForm}
              onBarcode={() => fetchPallet()}
            />
          </Box>
        )}
        {(pallet || newPalletBarcode != '') && (
          <>
            <Box component="form" onSubmit={fetchCarton} noValidate>
              <Typography variant="subtitle2" textAlign="center">
                Scan carton:
              </Typography>
              <BarcodeScannerFormInput
                label={'Carton Barcode'}
                id="cartonBarcode"
                form={addCartonToPalletByBarcodeForm}
                onBarcode={() => fetchCarton()}
              />
            </Box>
            <CartonsWIthStatusFilter
              cartons={pallet?.cartons ?? []}
              status={statusFilter}
              onStatusClick={onClickOnStatus}
              cartonColumns={columnDefs}
              onCompleteScanning={onCompleteScanning}
            />
          </>
        )}
      </Stack>
    </MobilePage>
  );
}

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Chip,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { AuditLog, changeToString, ValueMappings } from '../../Models/AuditLog';
import AuditLogDetail from './AuditLogDetail';

export interface AuditLogItemProps {
  item: AuditLog;
  valueMappings?: ValueMappings;
}

export default function ({ item, valueMappings }: AuditLogItemProps) {
  const date = useMemo(
    () => format(new Date(item.date), 'dd/MM/yyyy HH:mm:ss'),
    [item]
  );

  const single = item.changes.length == 1;

  const summary = useMemo(() => {
    if (single) {
      const change = item.changes[0];
      return changeToString(change, valueMappings);
    }
    return `${item.changes.length} Properties`;
  }, [single, item, valueMappings]);

  return (
    <CustomAccordion
      disableGutters
      elevation={0}
      square
      TransitionProps={{ unmountOnExit: true }}
      data-testid={'audit-log-item-' + item.date}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, .03)',
          '.MuiAccordionSummary-content': {
            maxWidth: '100%',
          },
        }}
      >
        <Stack spacing={2} direction="row" width="100%" alignItems="center">
          <Typography variant="body2" whiteSpace="nowrap">
            {date}
          </Typography>
          <Box width="70px">
            <Chip
              label={item.type}
              size="small"
              color={item.type == 'Created' ? 'success' : 'info'}
            />
          </Box>
          {/* <Typography variant="subtitle2">{item.reason}</Typography> */}
          <Typography
            variant="body2"
            flexGrow={1}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {summary}
          </Typography>
          <Typography variant="subtitle2" whiteSpace="nowrap">
            by: {item.usersName}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <AuditLogDetail item={item} valueMappings={valueMappings} />
      </AccordionDetails>
    </CustomAccordion>
  );
}

const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

import { Permission, RoleName } from '../Models/Role';
import { User } from '../Models/User';
import Api, {
  PagedAndSortedSearchRequest,
  PagedResult,
  ServiceGetMethod,
  ServicePostMethod,
} from './Api';

/**
 * The UserService provides api endpoints for an Admin to operate on other Users.
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const getUsers: ServiceGetMethod<PagedUserResultRequest, PagedResult<User>> = (
  options
) => Api.get('api/services/app/User/GetAll', options);

const createOrUpdateUser: ServicePostMethod<CreateOrUpdateUserRequest, User> = (
  data,
  options
) =>
  data.id
    ? Api.put('api/services/app/User/Update', data, options)
    : Api.post('api/services/app/User/Create', data, options);

const activateUser: ServicePostMethod<{ id: number }, void> = (data, options) =>
  Api.post('api/services/app/User/Activate', data, options);

const deActivateUser: ServicePostMethod<{ id: number }, void> = (
  data,
  options
) => Api.post('api/services/app/User/DeActivate', data, options);

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  getUsers,
  createOrUpdateUser,
  activateUser,
  deActivateUser,
};

/**
 *  Models used in this service
 */

export interface CreateOrUpdateUserRequest {
  userName: string;
  name: string;
  surname: string;
  emailAddress: string;
  isActive: boolean;
  roleNames: RoleName[];
  password: string;
  id: number;
  hasBarcode: boolean;
  barcode?: string;
  warehouseIds?: number[];
  customerIds?: number[];
}

export interface PagedUserResultRequest extends PagedAndSortedSearchRequest {
  roles?: RoleName[];
  permissions?: Permission[];
  isActive?: boolean;
}

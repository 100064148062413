import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BolContainerService from '../../../Services/BolContainerService';

interface XmlDialogProps {
  title?: string;
  eCertPublicId: string;
  onClose: () => void;
  isOpen: boolean;
}
export default function XmlDialog({
  title = 'ECERT XML Content',
  eCertPublicId,
  onClose,
  isOpen,
}: XmlDialogProps) {
  const [xmlString, setXmlString] = useState('');
  const transformECertPublicId = eCertPublicId.split('/').join('-');
  useEffect(() => {
    if (isOpen && eCertPublicId.length > 0) {
      BolContainerService.generateECertXML({
        params: {
          certificatePublicId: transformECertPublicId,
        },
        responseType: 'text',
      }).then((response) => {
        const [data] = response;
        setXmlString(data ?? '');
      });
    }
  }, [isOpen, eCertPublicId, transformECertPublicId]);
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="lg"
      data-testid="modal-dialog-show-xml-content"
      scroll="paper"
      onClose={onClose}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          data-testid="modal-close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <textarea
          style={{ width: '100%' }}
          rows={20}
          readOnly={true}
          value={xmlString}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Stack } from '@mui/material';
import React, {
  useContext,
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import MobilePage from '../../Components/MobilePage';
import useApiGet from '../../Hooks/useApiGet';
import FormField, { SelectItem } from '../../Components/Forms/FormField';
import useApiForm from '../../Hooks/useApiForm';
import { AuthContext } from '../../Providers/AuthProvider';
import { useSearchParams } from 'react-router-dom';
import CartonService from '../../Services/CartonService';
import {
  StockOnHandCarton,
  StockOnHandCount,
  StockOnHandCustomerCount,
  cartonEligibilitySelectItems,
} from '../../Models/Carton';
import CustomerService from '../../Services/CustomerService';
import {
  CustomerSelectOption,
  customerSelectItems,
} from '../../Models/Customer';
import ProductService from '../../Services/ProductService';
import { Product, productSelectItems } from '../../Models/Product';
import PalletStatusService from '../../Services/PalletStatusService';
import { palletStatusSelectItems } from '../../Models/PalletStatus';
import UserDefinedCodeService from '../../Services/UserDefinedCodeService';
import { CODE_GROUPS } from '../../Models/UserDefinedCode';
import StockOnHandDataGrid from './StockOnHandDataGrid';
import StockOnHandCartonDataGrid from './StockOnHandCartonDataGrid';

export interface StockOnHandCountWithNames extends StockOnHandCount {
  productCode?: string; // not returned from server - injected from Product response
  productDescription?: string; // not returned from server - injected from Product response
  customerName?: string; // not returned from server - injected from Customer response
}

export interface StockOnHandCustomerCountWithNames
  extends StockOnHandCustomerCount {
  stockOnHandCount: StockOnHandCountWithNames[];
}
const heightOfMainHeader = 64;
const heightOfMenuBar = 48;
const totalMargin = 32; // 24 (margin top) + 8 (margin bottom)
const totalPadding = 30;
export default function () {
  // the Cartons list is displayed on-top of this one so that closing it returns to this page
  const { user } = useContext(AuthContext);
  const searchCriteriaSectionRef = useRef<HTMLDivElement>(null);
  const prevSearch = useRef<string | null>('');
  const [searchParams] = useSearchParams();
  const [heightOfSearchSection, setHeightOfSearchSection] = useState(0);
  const [stockOnHandItems, setStockOnHandItems] = useState<
    StockOnHandCustomerCount[] | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Product[] | undefined>(undefined);
  const [customers, setCustomers] = useState<
    CustomerSelectOption[] | undefined
  >(undefined);

  // while we still have bad data from Basis (cartons with no ProductId), we need to sometimes force the Cartons table to show without a ProductId
  const [forceCartonsTableOpen, setForceCartonsTableOpen] = useState(false);

  // get Products, Customers and PalletStatuses for the autocompletes
  const { data: allCustomers, loading: loadingCustomers } = useApiGet(
    CustomerService.getAllCustomerNames,
    {
      params: { includeSupportedProducts: true },
      onSuccess: (response) => {
        setCustomers(response);
      },
    }
  );
  const customerItems = useMemo(
    () => customerSelectItems(customers || []),
    [customers]
  );
  const { data: allProducts, loading: loadingProducts } = useApiGet(
    ProductService.getAllProductNames,
    {
      params: { includeSupportedCustomers: true },
      onSuccess: (response) => {
        setProducts(response);
      },
    }
  );
  const productItems = useMemo(
    () => productSelectItems(products || []),
    [products]
  );
  const { data: palletStatuses, loading: loadingPalletStatuses } = useApiGet(
    PalletStatusService.getAll,
    {
      params: {
        maxResultCount: 9999,
      },
    }
  );
  const palletStatusItems = useMemo(
    () => palletStatusSelectItems(palletStatuses?.items || []),
    [palletStatuses]
  );
  const { data: userDefinedCodeGroup, loading: loadingEligibility } = useApiGet(
    UserDefinedCodeService.getGroupDetails,
    {
      params: {
        name: CODE_GROUPS.COUNTRY_CODES,
      },
    }
  );
  const eligibilityItems = useMemo(
    () =>
      cartonEligibilitySelectItems(
        userDefinedCodeGroup?.userDefinedCodes || []
      ),
    [userDefinedCodeGroup?.userDefinedCodes]
  );

  // data for the search filters
  const inputForm = useApiForm(
    null,
    {
      customerId: null as number | null,
      productId: null as number | null,
      palletStatusId: null as number | null,
      batchNumber: null as string | null,
      palletId: null as number | null,
      palletCode: null as string | null,
      plantCode: null as string | null,
      plantCodeId: null as string | null, // this needs to be a different parameter name to plantCode
      dateIn: '',
      lowDate: '',
      highDate: '',
      packDateFrom: '',
      packDateTo: '',
      eligibility: '',
      cypher: '',
    },
    { useSearchParams: true }
  );

  // ** Product/Customer table **
  useEffect(() => {
    if (
      (inputForm.data.batchNumber ?? '').length > 0 ||
      inputForm.data.customerId ||
      inputForm.data.productId
    ) {
      setLoading(true);
      CartonService.getStockOnHandCounts({
        params: {
          warehouseId: user?.currentWarehouseId,
          batchNumber: inputForm.data.batchNumber || '',
          customerId: inputForm.data.customerId,
          productId: inputForm.data.productId,
        },
      }).then((response) => {
        const [data] = response;
        setStockOnHandItems(data);
        // if search by batchNumber only, cascading Customers & Products based on results
        if (
          inputForm.data.batchNumber != null &&
          !inputForm.data.productId &&
          !inputForm.data.customerId
        ) {
          const customerIds = data?.map((d) => d.customerId) ?? [];
          if (customerIds && allCustomers) {
            setCustomers(
              allCustomers?.filter((c) => customerIds.includes(c.id))
            );
          }
          let productIds = [] as number[];
          data?.forEach((d) => {
            const pidArray = d.stockOnHandCount.map((s) => s.productId);
            productIds = productIds.concat(pidArray);
          });

          if (productIds && allProducts) {
            setProducts(allProducts?.filter((p) => productIds.includes(p.id)));
          }
        }
        setLoading(false);
      });
    } else {
      setStockOnHandItems(undefined);
      // Reset customers & products
      setCustomers(allCustomers);
      setProducts(allProducts);
    }
  }, [inputForm.data, user?.currentWarehouseId, allCustomers, allProducts]);

  // populate the productCode, productDescription and customerName fields for each row
  const populatedData = useMemo(() => {
    const dataWithNames = (stockOnHandItems ||
      []) as StockOnHandCustomerCountWithNames[];
    for (const row of dataWithNames) {
      for (const soh of row.stockOnHandCount || []) {
        soh.productCode =
          (allProducts?.find((p) => p.id === soh.productId)?.code as string) ||
          '';
        soh.productDescription =
          (allProducts?.find((p) => p.id === soh.productId)
            ?.description as string) || '';
        soh.customerName =
          (allCustomers?.find((c) => c.id === soh.customerId)
            ?.name as string) || '';
      }
    }
    return dataWithNames;
  }, [stockOnHandItems, allCustomers, allProducts]);

  // ** Cartons Table **
  const showCartonsTable = useMemo(
    () =>
      forceCartonsTableOpen ||
      (inputForm.data.customerId && inputForm.data.productId),
    [inputForm.data.customerId, inputForm.data.productId, forceCartonsTableOpen]
  );

  // when the Cartons table is open, we can replace the text filters with auto-completes using the data from the table
  const [palletItems, setPalletItems] = useState<SelectItem[]>([]);
  const [batchItems, setBatchItems] = useState<SelectItem[]>([]);
  const [plantItems, setPlantItems] = useState<SelectItem[]>([]);

  const onGotCartons = useCallback(
    (cartons?: StockOnHandCarton[]) => {
      // Pallet AutoComplete
      const pallets: SelectItem[] = [];
      for (const row of cartons || []) {
        if (row.palletId && !pallets.find((c) => c.id === row.palletId)) {
          pallets.push({
            id: row.palletId,
            label: [row.palletPrefix, row.palletCode]
              .filter((a) => a)
              .join(' - '),
          });
        }
      }
      setPalletItems(pallets.sort((a, b) => a.label.localeCompare(b.label)));

      // auto select a matching PalletCode autocomplete if a Pallet Code string is entered when the Cartons table is opened
      if (inputForm.data.palletCode && !inputForm.data.palletId) {
        const palletCodeLower = inputForm.data.palletCode
          .toString()
          .toLocaleLowerCase();
        const foundPallet =
          (palletCodeLower &&
            pallets.find(
              (p) => p.label.toLocaleLowerCase() == palletCodeLower
            )) ||
          null;
        inputForm.setData('palletId', (foundPallet?.id as number) || null);
      }

      // Batch Code AutoComplete
      const batchNumbers: SelectItem[] = [];
      for (const row of cartons || []) {
        if (
          row.batchNumber &&
          !batchNumbers.find((c) => c.id === row.batchNumber)
        ) {
          batchNumbers.push({
            id: row.batchNumber,
            label: row.batchNumber.toString(),
          });
        }
      }
      setBatchItems(
        batchNumbers.sort((a, b) => a.label.localeCompare(b.label))
      );

      // Plant Code AutoComplete
      const plantCodes: SelectItem[] = [];
      for (const row of cartons || []) {
        if (row.plantCode && !plantCodes.find((c) => c.id === row.plantCode)) {
          plantCodes.push({
            id: row.plantCode,
            label: row.plantCode,
          });
        }
      }
      setPlantItems(plantCodes.sort((a, b) => a.label.localeCompare(b.label)));

      // auto select a matching PlantCode autocomplete if a Plant Code string is entered when the Cartons table is opened
      if (inputForm.data.plantCode && !inputForm.data.plantCodeId) {
        const plantCodeLower = inputForm.data.plantCode
          .toString()
          .toLocaleLowerCase();
        const foundPlant =
          (plantCodeLower &&
            plantCodes?.find(
              (p) => p.label.toString().toLocaleLowerCase() == plantCodeLower
            )) ||
          null;
        inputForm.setData('plantCodeId', (foundPlant?.id as string) || null);
      }
    },
    [setPalletItems, setBatchItems, setPlantItems, inputForm.setData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // ** Opening and closing the Cartons table from the first table **
  const onSelectRow = (row: StockOnHandCount) => {
    // when clicking on a row set the search params and open the cartons page
    // but store the current search params so when the cartons page is closed we can restore the current search
    prevSearch.current = searchParams.toString();
    setForceCartonsTableOpen(true);
    inputForm.setData('customerId', row.customerId || 0);
    inputForm.setData('productId', row.productId || 0);
  };

  const onCloseCartonsTable = () => {
    if (
      showCartonsTable ||
      inputForm.data.customerId ||
      inputForm.data.palletId
    ) {
      setForceCartonsTableOpen(false);
      onGotCartons();
      inputForm.fromSearch(new URLSearchParams(prevSearch.current || ''));
      // reset products & customers & batchItems
      if (
        inputForm.data.customerId == null &&
        inputForm.data.productId == null
      ) {
        setProducts(allProducts);
        setCustomers(allCustomers);
        setBatchItems([]);
      }
    }
  };

  useEffect(
    () => {
      const searchBoxHeight =
        searchCriteriaSectionRef.current?.offsetHeight ?? 0;
      const padding = showCartonsTable ? totalPadding - 8 : totalPadding;
      setHeightOfSearchSection(searchBoxHeight + totalMargin + padding);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setHeightOfSearchSection, searchCriteriaSectionRef, showCartonsTable]
  );
  const setCustomersByProduct = (product: Product | undefined) => {
    if (product) {
      const customersByProduct = allCustomers?.filter(
        (p) =>
          p.supportedProductIDs != null &&
          p.supportedProductIDs?.includes(product.id)
      );
      setCustomers(customersByProduct);
    }
  };
  const setProductsByCustomer = (
    customer: CustomerSelectOption | undefined
  ) => {
    if (customer) {
      const productsByCustomer = allProducts?.filter(
        (p) =>
          p.supportedCustomerIDs != null &&
          p.supportedCustomerIDs?.includes(customer.id)
      );
      setProducts(productsByCustomer);
    }
  };
  const onChangeProduct = (
    newProductItem:
      | string
      | number
      | boolean
      | SelectItem
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | (string | SelectItem)[]
      | null
      | undefined
  ) => {
    if (newProductItem) {
      const selectedItem = newProductItem as SelectItem;
      const selectedProduct = products?.find((c) => c.id == selectedItem.id);
      setCustomersByProduct(selectedProduct);
    } else {
      setCustomers(allCustomers);
      // Need to check current customer, and set Product options by selected customer
      if (inputForm.data.customerId != null) {
        const customer = allCustomers?.find(
          (c) => c.id == inputForm.data.customerId
        );
        setProductsByCustomer(customer);
      }
      // while we still have bad data from Basis (cartons with no ProductId),
      // we need to sometimes force the Cartons table to show without a ProductId
      setForceCartonsTableOpen(inputForm.data.customerId != null);
    }
  };
  const onChangeCustomer = (
    newCustomerItem:
      | string
      | number
      | boolean
      | SelectItem
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | (string | SelectItem)[]
      | null
      | undefined
  ) => {
    if (newCustomerItem) {
      const selectedItem = newCustomerItem as SelectItem;
      const selectedCustomer = customers?.find((c) => c.id == selectedItem.id);
      setProductsByCustomer(selectedCustomer);
    } else {
      setProducts(allProducts);
      // Need to check current product, and set Customer options by selected product
      if (inputForm.data.productId != null) {
        const product = allProducts?.find(
          (c) => c.id == inputForm.data.productId
        );
        setCustomersByProduct(product);
      }
      // When no customer selected, back to SOH Customers/Products
      setForceCartonsTableOpen(false);
    }
  };
  useEffect(() => {
    if (
      !forceCartonsTableOpen &&
      inputForm.data.customerId == null &&
      inputForm.data.productId == null
    ) {
      setProducts(allProducts);
      setCustomers(allCustomers);
      setBatchItems([]);
    }
  }, [
    forceCartonsTableOpen,
    inputForm.data.customerId,
    inputForm.data.productId,
    allCustomers,
    allProducts,
  ]);

  return (
    <MobilePage showOnDesktop maxWidth={false} onBack={onCloseCartonsTable}>
      <Stack spacing={1}>
        <Stack ref={searchCriteriaSectionRef}>
          <Stack direction="row" spacing={1} sx={{ py: 0.5 }}>
            <FormField
              form={inputForm}
              id="productId"
              label="Product"
              size="small"
              type="autocomplete"
              options={productItems}
              loading={loadingProducts}
              data-testid={'product-selector'}
              onChange={(_event, value) => {
                onChangeProduct(value);
              }}
            />
            <FormField
              form={inputForm}
              id="customerId"
              label="Customer"
              size="small"
              type="autocomplete"
              options={customerItems}
              loading={loadingCustomers}
              data-testid={'customer-selector'}
              onChange={(_event, value) => {
                onChangeCustomer(value);
              }}
            />
            <FormField
              form={inputForm}
              id="batchNumber"
              label={`Batch Number${
                batchItems.length ? ` (${batchItems.length})` : ''
              }`}
              size="small"
              type={batchItems.length ? 'autocomplete' : 'text'}
              options={batchItems}
              debounceMs={500}
              clearable
            />
          </Stack>

          {showCartonsTable && (
            <Stack direction="row" spacing={1} sx={{ py: 0.5 }}>
              <FormField
                form={inputForm}
                id={plantItems.length ? 'plantCodeId' : 'plantCode'}
                label={`Plant Code${
                  plantItems.length ? ` (${plantItems.length})` : ''
                }`}
                size="small"
                type={plantItems.length ? 'autocomplete' : 'text'}
                options={plantItems}
                debounceMs={500}
                clearable
                sx={{ marginTop: 0 }}
              />
              <FormField
                form={inputForm}
                id={palletItems.length ? 'palletId' : 'palletCode'}
                label={`Pallet${
                  palletItems.length ? ` (${palletItems.length})` : ''
                }`}
                size="small"
                type={palletItems.length ? 'autocomplete' : 'text'}
                options={palletItems}
                debounceMs={500}
                clearable
              />
              <FormField
                form={inputForm}
                id="palletStatusId"
                label="Pallet Status"
                size="small"
                type="autocomplete"
                options={palletStatusItems}
                loading={loadingPalletStatuses}
              />
            </Stack>
          )}

          {showCartonsTable && (
            <Stack direction="row" spacing={1} sx={{ pt: 1, pb: 0.5 }}>
              <FormField
                form={inputForm}
                id="dateIn"
                label="Date In (From)"
                size="small"
                type="date"
                clearable
                InputLabelProps={{ shrink: true }} // display the label on top of the field
                sx={{ marginTop: 0 }}
              />
              <FormField
                form={inputForm}
                id="lowDate"
                label="Low Date (From)"
                size="small"
                type="date"
                clearable
                InputLabelProps={{ shrink: true }} // display the label on top of the field
              />
              <FormField
                form={inputForm}
                id="highDate"
                label="High Date (To)"
                size="small"
                type="date"
                clearable
                InputLabelProps={{ shrink: true }} // display the label on top of the field
              />
            </Stack>
          )}

          {showCartonsTable && (
            <Stack direction="row" spacing={1} sx={{ py: 0.5 }}>
              <FormField
                form={inputForm}
                id="packDateFrom"
                label="Pack Date (From)"
                size="small"
                type="date"
                clearable
                InputLabelProps={{ shrink: true }} // display the label on top of the field
                sx={{ marginTop: 0 }}
              />
              <FormField
                form={inputForm}
                id="packDateTo"
                label="Pack Date (To)"
                size="small"
                type="date"
                clearable
                InputLabelProps={{ shrink: true }} // display the label on top of the field
              />
              <FormField
                form={inputForm}
                id="eligibility"
                label="Eligibility"
                size="small"
                type="autocomplete"
                clearable
                options={eligibilityItems}
                loading={loadingEligibility}
              />
              <FormField
                form={inputForm}
                id="cypher"
                label="Cypher"
                size="small"
                type="text" // "autocomplete"
                clearable
              />
            </Stack>
          )}
        </Stack>
        {/* calculate height of data grid container dynamically */}
        <Box
          sx={{
            height: `calc(100vh - ${heightOfMainHeader}px - ${heightOfMenuBar}px - ${heightOfSearchSection}px)`,
          }}
        >
          {showCartonsTable ? (
            <StockOnHandCartonDataGrid
              warehouseId={user?.currentWarehouseId}
              palletStatuses={palletStatuses?.items || []}
              onGotCartons={onGotCartons}
              {...inputForm.data}
            />
          ) : (
            <StockOnHandDataGrid
              data={populatedData}
              loading={loading}
              onRowClick={onSelectRow}
              filteredProductId={inputForm.data.productId ?? undefined}
            />
          )}
        </Box>
      </Stack>
    </MobilePage>
  );
}

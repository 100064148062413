import { useRef, useState } from 'react';
import useApiForm from '../../../Hooks/useApiForm';
import PalletService from '../../../Services/PalletService';

const useUnloadSelectedPallets = (
  bolContainerId: number,
  refresh: () => void
) => {
  const [selectedPalletIds, setSelectedPalletIds] = useState<number[]>([]);
  const selectedPalletIdsRef = useRef(selectedPalletIds);

  const unloadForm = useApiForm(
    PalletService.markAsUnLoaded,
    {
      palletIds: [] as number[],
      bolContainerId: bolContainerId,
    },
    {
      onSuccess: () => {
        selectedPalletIdsRef.current = [];
        setSelectedPalletIds([]);
        unloadForm.reset();
        refresh();
      },
    }
  );

  const onUnloadPalletCheckboxChangeHandler = (selectedPalletId: number) => {
    if (unloadForm.data.palletIds?.includes(selectedPalletId)) {
      unloadForm.setData(
        'palletIds',
        unloadForm.data.palletIds?.filter((p) => p !== selectedPalletId)
      );
    } else {
      unloadForm.setData('palletIds', [
        ...(unloadForm.data.palletIds || []),
        selectedPalletId,
      ]);
    }

    setSelectedPalletIds((prevState) => {
      let updatedState;
      if (!prevState?.includes(selectedPalletId)) {
        updatedState = [...(prevState || []), selectedPalletId];
      } else {
        updatedState = prevState?.filter((p) => p !== selectedPalletId);
      }
      selectedPalletIdsRef.current = updatedState;
      return updatedState;
    });
  };

  const onUnloadBtnClickHandler = () => {
    if (unloadForm.data.palletIds?.length > 0) {
      unloadForm.submit();
    }
  };

  return {
    unloadForm,
    selectedPalletIds,
    selectedPalletIdsRef,
    onUnloadBtnClickHandler,
    onUnloadPalletCheckboxChangeHandler,
  };
};

export default useUnloadSelectedPallets;

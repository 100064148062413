import React from 'react';
import useApiForm from '../../../Hooks/useApiForm';
import BillOfLadingService from '../../../Services/BillOfLadingService';
import { LoadingButton } from '@mui/lab';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';

/**
 * Render Mark As Ready to Load as a button or Grid Action
 * @params billOfLadingId - ID of BOL which should be marked as Ready To Load
 * @params textContent - (Optional) Text of button. Default to 'Mark as ready...'
 * @params displayAs - (Optional) Set which style of this component 'Button' or 'GridAction'. Default to 'Button'
 * @params showInMenu - (Optional) Set showInMenu in case displayAs='GridAction'
 * @params visible - (Optional) Should this button be visible. Default to True
 * @params onSuccess - (Optional) Callback when Mark as ready successfully.
 * @params onError - (Optional) Callback when Mark as ready failed.
 * @params testId - (Optional) Set data-testid for this component.
 */
interface MarkAsReadyButtonProps {
  billOfLadingId: number;
  textContent?: string;
  displayAs?: 'Button' | 'GridAction';
  showInMenu?: boolean;
  visible?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
  testId?: string;
}

export default function ({
  billOfLadingId,
  textContent = 'Mark as ready...',
  displayAs = 'Button',
  showInMenu = true,
  visible = true,
  testId,
  onSuccess,
  onError,
}: MarkAsReadyButtonProps) {
  if (!visible) {
    return null;
  }

  const markAsReadyForm = useApiForm(
    BillOfLadingService.markAsReady,
    {
      bolId: 0,
    },
    {
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
      onError: () => {
        resetMarkAsReadyForm();
        if (onError) onError();
      },
    }
  );
  const resetMarkAsReadyForm = () => {
    markAsReadyForm.setData('bolId', 0);
  };

  const handleMarkAsReady = (
    e: React.MouseEvent<HTMLButtonElement> | undefined = undefined
  ) => {
    e?.stopPropagation();
    markAsReadyForm.setData('bolId', billOfLadingId);
    markAsReadyForm.submit();
  };

  return displayAs === 'Button' ? (
    <LoadingButton
      size="small"
      data-testid={testId ?? `mark-ready-btn-${billOfLadingId}`}
      loading={markAsReadyForm.processing}
      onClick={(e) => {
        handleMarkAsReady(e);
      }}
    >
      {textContent}
    </LoadingButton>
  ) : (
    <GridActionsCellItem
      data-testid={testId ?? `mark-ready-action-${billOfLadingId}`}
      label={textContent}
      onClick={() => handleMarkAsReady()}
      // Need to pass showInMenu through props
      // https://stackoverflow.com/questions/75324291/wrapping-material-uis-datagrid-gridactionscellitem-in-a-wrapper-component-cause
      showInMenu={showInMenu}
    />
  );
}

import React, { useState } from 'react';
import { DateCalendar, DateCalendarProps } from '@mui/x-date-pickers';

interface CustomDateCalendarProps extends DateCalendarProps<Date> {
  initialDate: Date | null;
  onDateSelected: (date: Date | null) => void;
}

const CustomDateCalendar: React.FC<CustomDateCalendarProps> = ({
  initialDate,
  onDateSelected,
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(initialDate);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    onDateSelected(date);
  };

  return (
    <DateCalendar value={selectedDate} onChange={handleDateChange} {...rest} />
  );
};

export default CustomDateCalendar;

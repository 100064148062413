import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddIcon from '@mui/icons-material/Add';
import useApiForm from '../../../Hooks/useApiForm';
import ECertService from '../../../Services/ECertService';
import { confirmModalDialog } from '../../../Components/ModalDialog';
import { ECert, ECertRemark, ECertStatus } from '../../../Models/BOLContainer';

interface ECertRemarksProps {
  eCert: ECert;
  refresh: () => void;
}

export default function ECertRemarks({ eCert, refresh }: ECertRemarksProps) {
  const [remarks, setRemarks] = useState<ECertRemark[]>(eCert.remarks ?? []);
  const [selectedRemark, setSelectedRemark] = useState<ECertRemark | null>(
    null
  );
  useEffect(() => {
    if (eCert) {
      setRemarks(eCert.remarks ?? []);
    }
  }, [eCert]);
  const remarkForm = useApiForm(
    ECertService.createOrUpdateRemark,
    {
      eCertPublicId: eCert.certificatePublicId,
      RemarkId: null as number | null,
      RemarkType: '',
      RemarkValue: '',
    },
    {
      onSuccess: () => {
        remarkForm.reset();
        setSelectedRemark(null);
        refresh();
      },
    }
  );
  useEffect(() => {
    if (selectedRemark) {
      remarkForm.setData('RemarkId', selectedRemark.id);
      remarkForm.setData('RemarkType', selectedRemark.remarkType);
      remarkForm.setData('RemarkValue', selectedRemark.remarkValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRemark]);
  const addRemark = () => {
    const remarkItem = {
      id: null as number | null,
      remarkType: '',
      remarkValue: '',
    } as ECertRemark;
    setRemarks((remark) => {
      return [...remark, remarkItem];
    });
  };
  const removeRemark = async (remarkId: number) => {
    const deletionMessage = `Are you sure you want to delete this Remark?`;
    const confirmed = await confirmModalDialog({
      title: '',
      acceptButtonLabel: 'Delete',
      content: (
        <Box textAlign={'left'}>
          <h3>{deletionMessage}</h3>
        </Box>
      ),
    });

    if (confirmed) {
      const [, error] = await ECertService.deleteRemark(remarkId);
      if (!error) {
        refresh();
      }
    }
  };

  const updateRemarkOnBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: 'RemarkType' | 'RemarkValue',
    remarkId: number
  ) => {
    remarkForm.setData('RemarkId', remarkId);
    const remarkField = e.target.value;
    remarkForm.setData(fieldName, remarkField);
    // Only submit if the data has changed
    const shouldSubmit =
      remarkId == 0
        ? remarkForm.data['RemarkType'].length > 0 ||
          remarkForm.data['RemarkValue'].length > 0
        : remarkForm.data['RemarkType'] !== selectedRemark?.remarkType ||
          remarkForm.data['RemarkValue'] !== selectedRemark?.remarkValue;
    if (eCert.status == ECertStatus.draft && shouldSubmit) {
      remarkForm.submit();
    }
  };
  return (
    <Box sx={{ marginY: '16px' }} data-testid="ecert-remarks-section">
      <Typography
        sx={{ fontSize: '20px', fontWeight: 500, marginBottom: '16px' }}
      >
        Remarks
      </Typography>
      <Box sx={{ marginY: '16px' }}>
        {remarks.map((remark, index) => {
          return (
            <Grid
              container
              spacing={2}
              key={`remark-${remark.id}-${index}`}
              sx={{ marginTop: '8px' }}
            >
              <Grid item xs={5}>
                <TextField
                  data-testid={`remark-type-${remark.id}-at-${index}`}
                  label="Remark Type"
                  defaultValue={remark.remarkType}
                  sx={{ width: '100%' }}
                  onFocus={() => setSelectedRemark(remark)}
                  onChange={(e) =>
                    remarkForm.setData('RemarkType', e.target.value)
                  }
                  onBlur={(e) => updateRemarkOnBlur(e, 'RemarkType', remark.id)}
                  disabled={remarkForm.processing}
                  InputProps={{ readOnly: eCert.status !== ECertStatus.draft }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  data-testid={`remark-value-${remark.id}-at-${index}`}
                  multiline
                  rows={4}
                  label="Remark Value"
                  defaultValue={remark.remarkValue}
                  sx={{ width: '100%' }}
                  onFocus={() => setSelectedRemark(remark)}
                  onChange={(e) =>
                    remarkForm.setData('RemarkValue', e.target.value)
                  }
                  onBlur={(e) =>
                    updateRemarkOnBlur(e, 'RemarkValue', remark.id)
                  }
                  disabled={remarkForm.processing}
                  InputProps={{ readOnly: eCert.status !== ECertStatus.draft }}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  data-testid={`remove-remark-button-${remark.id}-at-${index}`}
                  aria-label="remove remark"
                  color="error"
                  onClick={() => removeRemark(remark.id)}
                  disabled={eCert.status !== ECertStatus.draft}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
        <Grid item xs={12} sx={{ marginTop: '16px' }}>
          <Button
            data-testid="add-remark-btn"
            variant="contained"
            endIcon={<AddIcon />}
            onClick={() => addRemark()}
            disabled={eCert.status !== ECertStatus.draft}
          >
            Add Remark
          </Button>
        </Grid>
      </Box>
    </Box>
  );
}

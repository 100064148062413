import React, { useCallback, useState } from 'react';
import MobilePage, { MobilePageTab } from '../../Components/MobilePage';
import { TabPanel } from '@mui/lab';
import { History, Inventory2Outlined, Widgets } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PalletSearch from './PalletSearch';
import PalletMovement from './PalletMovement';
import { Pallet } from '../../Models/Pallet';
import CartonDetails from './CartonDetails';

const tabLists = ['pallet', 'cartons', 'history'];
type Tab = typeof tabLists[number];

export default function () {
  const navigate = useNavigate();

  // if the url contains a #tab we set the default tab to it
  const [tab, setTab] = useState<Tab>('pallet');
  const [pallet, setPallet] = useState<Pallet | null>(null);

  const onTabChange = (tab: Tab) => {
    // add the hash to the url, so we can redirect to the tab if needed
    navigate(`#${tab}`, { replace: true });
    setTab(tab);
  };

  //Start Pallet Tab related codes
  const [selectedBarcode, setSelectedBarcode] = useState<string>('');
  const [selectedPalletId, setSelectedPalletId] = useState<number | null>(null);

  const onGotPalletEnquiryInfo = useCallback((pallet: Pallet) => {
    setSelectedPalletId(pallet.id);
    setSelectedBarcode(pallet.barcode);
    setPallet(pallet);
  }, []);
  // End Pallet Tab related code
  //Start Cartons Tab related code

  //End Carton related code

  const tabs: MobilePageTab<Tab>[] = [
    {
      id: 'pallet',
      icon: Widgets,
    },
    {
      id: 'cartons',
      icon: Inventory2Outlined,
    },
    {
      id: 'history',
      icon: History,
    },
  ];
  return (
    <MobilePage
      maxWidth={false}
      tabs={tabs}
      tab={tab}
      onTabChange={onTabChange}
    >
      <TabPanel value="pallet" sx={{ padding: 0 }}>
        <PalletSearch
          barcode={selectedBarcode}
          onGotPalletEnquiryInfo={onGotPalletEnquiryInfo}
        />
      </TabPanel>
      <TabPanel value="cartons" sx={{ padding: 0 }}>
        <CartonDetails pallet={pallet}></CartonDetails>
      </TabPanel>
      <TabPanel value="history" sx={{ padding: 0 }}>
        <PalletMovement palletId={selectedPalletId} />
      </TabPanel>
    </MobilePage>
  );
}

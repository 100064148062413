import axios, { AxiosRequestConfig } from 'axios';
import { toastError } from '../Components/Toast';
import { AuthenticateResponse } from './AuthService';

/**
 * The Api service uses Axios to communicate with the WMS backend by injecting the authToken header
 *
 * NOTE that ideally this service shouldn't be consumed directly - instead it should be wrapped by a Service for each Domain
 * Each of those services should export methods which extend the ServiceGetMethod<R> or ServicePostMethod<D, R> interfaces
 * so that they themselves can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 *
 * It provides the following default export:
 *  * Api.get<Response>()
 *  * Api.post<Data, Response>()
 *  * Api.put<Data, Response>()
 *
 * Each of these methods will also dispatch a Toast event to display any errors, unless options.suppressError = true
 *
 * It also provides:
 *  * setAccessToken(token)
 *  * getHttpErrorMessage(error)
 */

export const API_URL = process.env.REACT_APP_REMOTE_SERVICE_BASE_URL || '/';

// this is only exported so it can be mocked in tests
export const axiosHttp = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

// Cookie storage is more secure than localStorage, but localStorage has the benefit of other tabs being able to listen
// to storage changed events and then log the user out/in automatically depending on what happened in the other tab.
// So use a hybrid - store the access token securely as a cookie, but also set a localStorage flag to trigger an event
// which is picked up in AuthProvider and used to get the new cookie and sign in, or clear the authState.
const ACCESS_TOKEN_COOKIE_NAME = 'accessToken';
export const HAS_COOKIE_LOCALSTORAGE_KEY = 'hasCookie';
export const setAccessToken = (token?: AuthenticateResponse) => {
  const domain = window.location.host.split(':')[0];
  if (token) {
    document.cookie = `${ACCESS_TOKEN_COOKIE_NAME}=${token.accessToken}; Max-Age=${token.expireInSeconds}; Domain=${domain}`;
    window.localStorage.setItem(HAS_COOKIE_LOCALSTORAGE_KEY, 'true');
  } else {
    document.cookie = `${ACCESS_TOKEN_COOKIE_NAME}=; Max-Age=0;path=/;Domain=${domain}`;
    // if we're logging out need to clear session storage, but not local storage (except for hasCookie which we need to clear)
    window.sessionStorage.clear();
    window.localStorage.removeItem(HAS_COOKIE_LOCALSTORAGE_KEY);
  }
};
// this is only exported for testing
export const getAccessToken = () => {
  const cookies = document.cookie.split('; ');
  const cookie = cookies.find((c) =>
    c.startsWith(ACCESS_TOKEN_COOKIE_NAME + '=')
  );
  return cookie ? cookie.split('=')[1] : '';
};

// wrap the axios http client with our own methods so we can:
//  * strongly type the requests and responses with less boilerplate in the caller
//  * automatically toast any errors
//  * return [result, error] so the error can be strongly typed (catch (error) {} cannot be typed) and the consumer doesn't need try...catch
export type ResponseTupple<TResponse> =
  | [TResponse, undefined]
  | [undefined, HttpError];
export type ApiOptions<TRequest> = HttpOptions &
  Omit<AxiosRequestConfig, 'params'> & {
    params?: TRequest; // & PagedFilterAndSortedSearchRequest;
  };
export type ApiParameters = Record<string, string>;

// enforce each service method to have a consistent type so they can be passed as parameters to useGet or useForm hooks
export type ServiceGetMethod<TRequest, TResponse> = (
  options?: ApiOptions<TRequest>
) => Promise<ResponseTupple<TResponse>>;

export type ServicePostMethod<D, R> = (
  data: D,
  options?: ApiOptions<D>
) => Promise<ResponseTupple<R>>;

export type ServiceDeleteMethod = (
  id: number,
  options?: ApiOptions<void>
) => Promise<ResponseTupple<null>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cachedGetPromises: Record<string, Promise<any>> = {};

async function get<TRequest, TResponse>(
  url: string,
  options?: ApiOptions<TRequest>
): Promise<ResponseTupple<TResponse>> {
  const { suppressError, ...axiosOptions } = options || {};

  if (options?.cache) {
    const cachedPromise = cachedGetPromises[url];
    if (cachedPromise) {
      return cachedPromise;
    }
  }

  // cache the promise, not the result so two calls at the same time share the same promise without hitting the server again
  const promise = axiosHttp
    .get<ApiResponse<TResponse> | TResponse>(url, axiosOptions)
    .then<ResponseTupple<TResponse>>((result) => [
      // For APIs which are set DontWrapResult annotation, result.data.result should be undefined
      (result.data as ApiResponse<TResponse>)?.result ??
        (result.data as TResponse),
      undefined,
    ])
    .catch((e) => handleError(e as HttpError, suppressError));
  if (options?.cache) {
    cachedGetPromises[url] = promise;
  }
  return promise;
}

async function post<TRequest, TResponse>(
  url: string,
  data: TRequest,
  options?: ApiOptions<TRequest>
): Promise<ResponseTupple<TResponse>> {
  const { suppressError, ...axiosOptions } = options || {};
  try {
    const response = await axiosHttp.post<ApiResponse<TResponse>>(
      url,
      data,
      axiosOptions
    );
    return [response.data.result, undefined];
  } catch (e) {
    return handleError(e as HttpError, suppressError);
  }
}

async function put<TRequest, TResponse>(
  url: string,
  data: TRequest,
  options?: ApiOptions<TRequest>
): Promise<ResponseTupple<TResponse>> {
  const { suppressError, ...axiosOptions } = options || {};
  try {
    const response = await axiosHttp.put<ApiResponse<TResponse>>(
      url,
      data,
      axiosOptions
    );
    return [response.data.result, undefined];
  } catch (e) {
    return handleError(e as HttpError, suppressError);
  }
}

async function deleteById(
  url: string,
  options?: ApiOptions<void>
): Promise<ResponseTupple<null>> {
  const { suppressError, ...axiosOptions } = options || {};
  try {
    const response = await axiosHttp.delete<ApiResponse<null>>(
      url,
      axiosOptions
    );
    return [response.data.result, undefined];
  } catch (e) {
    return handleError(e as HttpError, suppressError);
  }
}

export default {
  get,
  post,
  put,
  deleteById,
};

axiosHttp.interceptors.request.use((config) => {
  const accessToken = getAccessToken();
  if (accessToken && !config.headers!['Authorization']) {
    config.headers!['Authorization'] = 'Bearer ' + accessToken;
  }

  // if we were using localisations or multi-tenants...
  // config.headers.common['.AspNetCore.Culture'] = abp.utils.getCookieValue('Abp.Localization.CultureName');
  // config.headers.common['Abp.TenantId'] = abp.multiTenancy.getTenantIdCookie();
  return config;
});

function handleError(
  error: HttpError,
  suppressError?: boolean
): [undefined, HttpError] {
  outputError(error, suppressError);
  return [undefined, error];
}

export function outputError(error: HttpError, suppressError?: boolean) {
  if (process.env.NODE_ENV != 'test') {
    /* istanbul ignore next */
    console.log('POST error', error);
  }
  if (!suppressError && error.message != 'canceled') {
    toastError(getHttpErrorMessage(error));
  }
}

// returns the error message from an http error response
export function getHttpErrorMessage(error?: Partial<HttpError>) {
  if (!error) {
    return '';
  }
  return (
    error.response?.data?.error?.details ||
    error.response?.data?.error?.message ||
    error.response?.statusText ||
    error.message ||
    'Unknown error: ' + JSON.stringify(error)
  );
}

// returns the error code from an http error response, note this is not to be confused with the http status code
export function getHttpErrorCode(
  error?: Partial<HttpError>
): number | undefined {
  return error?.response?.data?.error?.code;
}

export interface ApiResponse<TResponse> {
  error?: null;
  result: TResponse;
  success: boolean;
  targetUrl?: null;
  unAuthorizedRequest?: boolean;
}

export interface HttpOptions {
  suppressError?: boolean;
  cache?: boolean;
}

export interface ValidationError {
  members?: string[];
  message: string;
}

export interface HttpError {
  code: string;
  message: string;
  response?: {
    data?: {
      error?: {
        code: number;
        message: string;
        details: string;
        validationErrors: null | ValidationError[];
      };
      result: null;
      success: false;
    };
    status: number;
    statusText: string;
  };
}

export interface PagedAndSortedRequest {
  maxResultCount?: number;
  skipCount?: number;
  sorting?: string;
}

export interface PagedAndSortedSearchRequest extends PagedAndSortedRequest {
  keyword?: string;
}

export interface PagedResult<T> {
  totalCount: number;
  items: T[];
}

export const pagedParams: PagedAndSortedSearchRequest = {
  maxResultCount: 200,
  skipCount: 0,
  keyword: '',
};

import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import useApiForm from '../../Hooks/useApiForm';
import BolContainerService from '../../Services/BolContainerService';
import FormField, { SelectItem } from '../Forms/FormField';
import { BOLContainer } from '../../Models/BOLContainer';
import { LoadingButton } from '@mui/lab';

interface EditContainerProps {
  bolContainer: BOLContainer | null;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const EditContainer = ({
  bolContainer,
  open,
  onClose,
  onSuccess,
}: EditContainerProps) => {
  const form = useApiForm(
    BolContainerService.updateContainerDetails,
    {
      id: bolContainer?.id || 0,
      code: bolContainer?.code || '',
      location: bolContainer?.location || '',
      type: bolContainer?.type || '',
      notes: bolContainer?.notes || '',
      loadType: bolContainer?.loadType || '',
      priority: bolContainer?.priority,
    },
    {
      onSuccess,
    }
  );
  const handleClose = () => onClose();

  const containerTypeOptions = useMemo(() => {
    return ['10ft', '20ft', '40ft'].map<SelectItem>((type) => ({
      id: type,
      label: type,
    }));
  }, []);

  return (
    <Dialog open={open}>
      <Box
        component="form"
        onSubmit={form.submit}
        data-testid="edit-container-form"
        noValidate
      >
        <DialogTitle>Edit Container</DialogTitle>
        <DialogContent>
          <FormField
            form={form}
            id="code"
            type="text"
            label="Container Code"
            required
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="location"
            type="text"
            label="Container Location"
            required
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="type"
            type="select"
            options={containerTypeOptions}
            label="Container Size"
            required
          />
          <FormField form={form} id="notes" type="text" label="Notes" />
          <FormField
            form={form}
            id="loadType"
            type="text"
            label="Container Load Type"
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="priority"
            type="number"
            label="Container Priority"
            inputProps={{ 'data-lpignore': true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={form.processing} tabIndex={2}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={form.processing} tabIndex={1}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditContainer;

import {
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import React, { useContext, useMemo } from 'react';
import useWarehouses from '../Hooks/useWarehouses';
import { AuthContext } from '../Providers/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Display a location Select box.
 * - The appBar variant is white.
 * - Can allowNull if you want the user to be able to deselect the warehouse
 */
export default function ({
  appBar,
  allowNull,
}: {
  appBar?: boolean;
  allowNull?: boolean;
}) {
  const { user, setCurrentWarehouseId } = useContext(AuthContext);
  const navigate = useNavigate();
  // fetch cached warehouses
  const { warehouses } = useWarehouses();
  const location = useLocation();

  const isOperator = useMemo(
    () => user?.roleNames?.length == 1 && user.roleNames[0] == 'OPERATOR',
    [user]
  );

  // filter the available warehouses by those the user is assigned to
  const assignedWarehouses = useMemo(
    () =>
      // only filter the warehouses for Operators, or if another user has actually been assigned to specific warehouses
      // i.e. Super/Office Admins can see all warehouses by default.
      user?.warehouseIds?.length || isOperator
        ? (warehouses || []).filter((w) => user?.warehouseIds?.includes(w.id))
        : warehouses || [],
    [warehouses, user, isOperator]
  );

  if (!warehouses) {
    return <CircularProgress />;
  }

  return (
    <FormControl
      fullWidth={!appBar}
      size={appBar ? 'small' : undefined}
      margin="normal"
      variant="outlined"
      // className={appBar ? 'select-white' : ''}
      sx={
        appBar
          ? {
              margin: '0 0 0 12px !important',
              '&, svg, label, .MuiSelect-select': {
                color: 'white !important',
              },
              fieldset: {
                borderColor: 'white !important',
              },
              '.MuiSelect-select': {
                minWidth: '10em',
              },
            }
          : undefined
      }
    >
      <InputLabel
        id="location-selector-label"
        sx={{ color: appBar ? 'white' : undefined }}
      >
        Select Warehouse
      </InputLabel>
      <Select
        value={user?.currentWarehouseId || ''}
        data-testid="location-selector"
        labelId="location-selector-label"
        input={<OutlinedInput label="Select Warehouse" />}
        onChange={(e) => {
          setCurrentWarehouseId(+e.target.value > 0 ? +e.target.value : null);
          const path = location.pathname.split('/').filter((p) => p)[0] || '';
          navigate('/' + path);
          // blur the Location Switcher input element
          setTimeout(() => (document.activeElement as HTMLElement)?.blur());
        }}
      >
        {allowNull && !isOperator && (
          <MenuItem
            value=""
            sx={!user?.currentWarehouseId ? { display: 'none' } : undefined}
          >
            All Warehouses
          </MenuItem>
        )}
        {assignedWarehouses.map((warehouse) => (
          <MenuItem key={warehouse.id} value={warehouse.id}>
            {warehouse.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import {
  CodeEntityQuantity,
  UserDefinedCode,
  UserDefinedCodeGroup,
} from '../Models/UserDefinedCode';
import Api, { ServiceGetMethod, ServicePostMethod } from './Api';

/**
 * The RoleService provides api endpoints for interacting with Roles.
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const getAllWithCodes: ServiceGetMethod<void, UserDefinedCodeGroup[]> = (
  options
) => Api.get('api/services/app/UserDefinedCode/GetAllWithCodes', options);

const getGroupDetails: ServiceGetMethod<
  { name: string; relevantTypes?: string[] },
  UserDefinedCodeGroup
> = (options) =>
  Api.get('api/services/app/UserDefinedCode/GetGroupDetails', options);

const updateGroupOrderAndActive: ServicePostMethod<
  UpdateGroupOrderAndActiveRequest,
  UserDefinedCodeGroup
> = (data, options) =>
  Api.put(
    'api/services/app/UserDefinedCode/UpdateGroupOrderAndActive',
    data,
    options
  );

const getCodeQuantitiesForEntity: ServiceGetMethod<
  GetCodesForEntityRequest,
  CodeEntityQuantity[]
> = (options) =>
  Api.get(
    'api/services/app/UserDefinedCode/GetCodeQuantitiesForEntity',
    options
  );

const setCodeQuantitiesForEntity: ServicePostMethod<
  SetCodesForEntityRequest,
  CodeEntityQuantity[]
> = (data, options) =>
  Api.put(
    'api/services/app/UserDefinedCode/SetCodeQuantitiesForEntity',
    data,
    options
  );

const setCodeQuantityForEntity: ServicePostMethod<
  SetCodeForEntityRequest,
  CodeEntityQuantity[]
> = (data, options) =>
  Api.put(
    'api/services/app/UserDefinedCode/SetCodeQuantityForEntity',
    data,
    options
  );

const createOrUpdateCode: ServicePostMethod<
  CreateOrUpdateCodeRequest,
  UserDefinedCode
> = (data, options) =>
  data.id
    ? Api.put('api/services/app/UserDefinedCode/UpdateCode', data, options)
    : Api.post('api/services/app/UserDefinedCode/CreateCode', data, options);

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  getAllWithCodes,
  getGroupDetails,
  updateGroupOrderAndActive,
  createOrUpdateCode,
  getCodeQuantitiesForEntity,
  setCodeQuantitiesForEntity,
  setCodeQuantityForEntity,
};

type UpdateGroupOrderAndActiveRequest = Omit<
  UserDefinedCodeGroup,
  'name' | 'customFields'
>;
export type CreateOrUpdateCodeRequest = Omit<UserDefinedCode, 'order' | 'code'>;

export interface GetCodesForEntityRequest {
  entityType: string;
  entityId: number;
  groupId: number;
}

export interface SetCodesForEntityRequest extends GetCodesForEntityRequest {
  items: CodeEntityQuantity[];
}

export type SetCodeForEntityRequest = GetCodesForEntityRequest &
  CodeEntityQuantity;

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';
import useApiForm from '../../Hooks/useApiForm';
import PrintingService from '../../Services/PrintingService';
import FormField, { SelectItem } from '../Forms/FormField';
import useApiGet from '../../Hooks/useApiGet';
import UserDefinedCodeService from '../../Services/UserDefinedCodeService';
import { BillOfLadingWithCountsAndPalletDetails } from '../../Models/BillOfLading';
import { CODE_GROUPS } from '../../Models/UserDefinedCode';
import { toast } from '../Toast';

interface Props {
  bol: BillOfLadingWithCountsAndPalletDetails;
}

function PrintAddressLabelForm({ bol }: Props) {
  const [open, setOpen] = useState(true);

  const { data: printerGroup } = useApiGet(
    UserDefinedCodeService.getGroupDetails,
    {
      params: {
        name: CODE_GROUPS.PRINTER_CODES,
      },
    }
  );

  const form = useApiForm(
    PrintingService.printAddressLabels,
    {
      printerCode: '',
      qty: 0,
      bolId: bol.id,
    },
    {
      onSuccess: () => {
        toast('Address labels submitted.');
        setOpen(false);
      },
    }
  );

  return (
    <Dialog open={open}>
      <Box
        component="form"
        onSubmit={form.submit}
        data-testid="edit-container-form"
        noValidate
      >
        <DialogTitle>Printer Address Labels</DialogTitle>
        <DialogContent>
          <FormField
            form={form}
            id="printerCode"
            type="select"
            label="Printer"
            required
            options={(printerGroup?.userDefinedCodes || []).map<SelectItem>(
              (p) => {
                return {
                  id: p.code,
                  label: p.name,
                };
              }
            )}
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="qty"
            type="number"
            label="Quantity Needed"
            required
            inputProps={{ 'data-lpignore': true }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            disabled={form.processing}
            tabIndex={2}
          >
            Cancel
          </Button>
          <LoadingButton type="submit" loading={form.processing} tabIndex={1}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default PrintAddressLabelForm;

import React, { useContext, useMemo } from 'react';
import MobilePage from '../../Components/MobilePage';
import useApiGet from '../../Hooks/useApiGet';
import BolContainerService from '../../Services/BolContainerService';
import AsnService from '../../Services/AsnService';
import NetsuiteExportButtons from '../../Components/Billing/NetsuiteExportButtons';
import {
  BOLContainerStatus,
  CONTAINER_ENTITY_NAME,
  CONTAINER_LABELS,
} from '../../Models/BOLContainer';
import UserDefinedCodeService from '../../Services/UserDefinedCodeService';
import { CODE_GROUPS } from '../../Models/UserDefinedCode';
import DataGridBillingTable, {
  ContainerWithCharges,
} from './DataGridBillingTable';
import { AuthContext } from '../../Providers/AuthProvider';
import useApiForm from '../../Hooks/useApiForm';
import { confirmModalDialog } from '../../Components/ModalDialog';
import CardTitle from '../../Components/CardTitle';
import Skeletons from '../../Components/Skeletons';
import { ASN_STATUS_LABELS, ASNStatus } from '../../Models/ASN';

export default function BillingPage() {
  const { user } = useContext(AuthContext);
  const billingCodeGroupName = CODE_GROUPS.BILLING_CODES;

  // load the Billing Code group - so we get the full names, groupId, any custom data, etc
  const { data: billingCodeGroup } = useApiGet(
    UserDefinedCodeService.getGroupDetails,
    { params: { name: billingCodeGroupName } }
  );

  // load the list of containers that are ready for billing including their charges
  const {
    data: containers,
    loading,
    refresh,
  } = useApiGet(BolContainerService.getChargesByContainerStatus, {
    params: {
      warehouseId: user?.currentWarehouseId,
      statuses: [
        BOLContainerStatus.managerReview,
        BOLContainerStatus.invoiced,
        BOLContainerStatus.pendingInvoice,
      ],
      asnStatuses: [
        ASNStatus.managerReview,
        ASNStatus.invoiced,
        ASNStatus.pendingInvoice,
      ],
    },
    autoRefreshTrueOrSecs: true,
  });

  // form to update the quantity of a billing code for a container
  const setQtyForm = useApiForm(
    UserDefinedCodeService.setCodeQuantityForEntity,
    {
      entityType: '',
      entityId: 0,
      groupId: 0,
      userDefinedCodeId: 0,
      quantity: 0,
    },
    {
      onError: () => {
        setQtyForm.setData('entityType', '');
        setQtyForm.setData('entityId', 0);
        refresh();
      },
      onSuccess: () => {
        setQtyForm.setData('entityType', '');
        setQtyForm.setData('entityId', 0);
        refresh();
      },
    }
  );

  // form to update the status of a container to the backend
  const updateContainerStatusForm = useApiForm(
    BolContainerService.updateContainerStatus,
    {
      containerId: 0,
      status: BOLContainerStatus.managerReview,
    },
    {
      onError: () => {
        updateContainerStatusForm.setData('containerId', 0);
      },
      onSuccess: () => {
        updateContainerStatusForm.setData('containerId', 0);
        refresh();
      },
    }
  );

  // form to update the status of an ASN to the backend
  const updateASNStatusForm = useApiForm(
    AsnService.updateASNStatus,
    {
      asnId: 0,
      status: ASNStatus.managerReview,
    },
    {
      onError: () => {
        updateASNStatusForm.setData('asnId', 0);
      },
      onSuccess: () => {
        updateASNStatusForm.setData('asnId', 0);
        refresh();
      },
    }
  );

  // combine the containers and charges into a single array that is flatten to support the data grid
  const containerWithCharges: ContainerWithCharges[] = useMemo(() => {
    if (!containers) return [];

    let rowId = 0;
    return containers.flatMap((container) =>
      container.charges.map((charge) => {
        rowId++;
        return {
          ...container,
          ...charge,
          rowId,
        };
      })
    );
  }, [containers]);

  const handleSetQty = async (updatedRow: ContainerWithCharges) => {
    setQtyForm.setData('entityType', updatedRow.entityType);
    setQtyForm.setData(
      'entityId',
      updatedRow.entityType == CONTAINER_ENTITY_NAME
        ? updatedRow.id || 0
        : updatedRow.entityTypeId || 0
    );
    setQtyForm.setData('groupId', billingCodeGroup!.id);
    setQtyForm.setData('userDefinedCodeId', updatedRow.userDefinedCodeId);
    setQtyForm.setData('quantity', updatedRow.quantity);
    setQtyForm.submit();
    return updatedRow;
  };

  // event handler for when a container is marked as ready for billing or reviewed
  const handleContainerStatusChange = async (
    container: ContainerWithCharges,
    status: BOLContainerStatus
  ) => {
    const yes = await confirmModalDialog({
      title: 'Update Status',
      content: `This will mark the whole container as ${CONTAINER_LABELS[status]}. Are you sure?`,
    });

    if (yes) {
      updateContainerStatusForm.setData('containerId', container.id);
      updateContainerStatusForm.setData('status', status);
      updateContainerStatusForm.submit();
    }
  };

  const handleASNStatusChange = async (
    container: ContainerWithCharges,
    status: ASNStatus
  ) => {
    const yes = await confirmModalDialog({
      title: 'Update Status',
      content: `This will mark the whole ASN as ${ASN_STATUS_LABELS[status]}. Are you sure?`,
    });

    if (yes) {
      updateASNStatusForm.setData('asnId', container.entityTypeId || 0);
      updateASNStatusForm.setData('status', status);
      updateASNStatusForm.submit();
    }
  };

  return (
    <MobilePage maxWidth={false}>
      <CardTitle
        title="Billing"
        rightChild={<NetsuiteExportButtons onFinished={refresh} />}
      />
      {loading ? (
        <Skeletons count={3} />
      ) : (
        <DataGridBillingTable
          data={containerWithCharges}
          loading={loading}
          changeBOLContainerStatus={(
            rowData: ContainerWithCharges,
            status: BOLContainerStatus
          ) => handleContainerStatusChange(rowData, status)}
          changeASNStatus={(rowData: ContainerWithCharges, status: ASNStatus) =>
            handleASNStatusChange(rowData, status)
          }
          onRowUpdate={handleSetQty}
        />
      )}
    </MobilePage>
  );
}

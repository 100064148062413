export type XmlTag = {
  name: string;
  attributes?: { [key: string]: string };
  children?: XmlTag[];
  value: string;
  getElementsByTagName?: (
    this: XmlTag,
    tagName: string,
    prefix?: string
  ) => XmlTag[];
};
const parseFromString = (xmlText: string) => {
  const xmlEncodeText = encodeCDATAValues(xmlText);
  const cleanXmlText = xmlEncodeText
    .replace(/\s{2,}/g, ' ')
    .replace(/\\t\\n\\r/g, '')
    .replace(/>/g, '>\n')
    .replace(/]]/g, ']]\n');
  const rawXmlData: XmlTag[] = [];

  cleanXmlText.split('\n').map((ele) => {
    const element = ele.trim();

    if (!element || element.indexOf('?xml') > -1) {
      return;
    }

    if (element.indexOf('<') == 0 && element.indexOf('CDATA') < 0) {
      const parsedTag = parseTag(element);

      rawXmlData.push(parsedTag);

      if (element.match(/\/\s*>$/)) {
        rawXmlData.push(parseTag('</' + parsedTag.name + '>'));
      }
    } else {
      rawXmlData[rawXmlData.length - 1].value += ` ${parseValue(element)}`;
    }
  });

  return convertTagsArrayToTree(rawXmlData)[0];
};

const encodeCDATAValues = (xmlText: string) => {
  const cdataRegex = new RegExp(/<!\[CDATA\[([^\]]+)]]/gi);
  let xmlTextContent = xmlText;
  let result = cdataRegex.exec(xmlText);
  while (result) {
    if (result.length > 1) {
      xmlTextContent = xmlTextContent.replace(
        result[1],
        encodeURIComponent(result[1])
      );
    }

    result = cdataRegex.exec(xmlTextContent);
  }

  return xmlTextContent;
};

const getElementsByTagName = (
  rootNode: XmlTag,
  tagName: string,
  prefix = ''
): XmlTag[] => {
  let matches: XmlTag[] = [];
  const fullTagName = prefix.length > 0 ? prefix + tagName : tagName;
  if (
    fullTagName == '*' ||
    rootNode.name.toLowerCase() === fullTagName.toLowerCase()
  ) {
    matches.push(rootNode);
  }

  rootNode.children?.map((child) => {
    if (child.getElementsByTagName) {
      matches = matches.concat(child.getElementsByTagName(tagName, prefix));
    }
  });

  return matches;
};

const parseTag = (tagText: string) => {
  const cleanTagText = tagText.match(
    /(\S*)=('([^']*?)'|"([^"]*?)")|([\/?\w\-:]+)/g // eslint-disable-line no-useless-escape
  );

  const tagName = cleanTagText!.shift()!.replace(/\/\s*$/, '');
  const tag: XmlTag = {
    name: tagName,
    attributes: {},
    children: [],
    value: '',
    getElementsByTagName: (tagName, prefix?: string) =>
      getElementsByTagName(tag, tagName, prefix),
  };

  cleanTagText?.map((attribute) => {
    let attributeKeyVal = attribute.split('=');
    if (attributeKeyVal.length < 2) {
      return;
    }

    const attributeKey = attributeKeyVal[0];
    let attributeVal = attributeKeyVal[1];

    if (attributeKeyVal.length > 2) {
      attributeKeyVal = attributeKeyVal.slice(1);
      attributeVal = attributeKeyVal.join('=');
    }

    tag.attributes![attributeKey] = attributeVal
      .replace(/^"/g, '')
      .replace(/^'/g, '')
      .replace(/"$/g, '')
      .replace(/'$/g, '')
      .trim();
  });

  return tag;
};

const parseValue = (tagValue: string) => {
  if (tagValue.indexOf('CDATA') < 0) {
    return tagValue.trim();
  }

  return tagValue.substring(
    tagValue.lastIndexOf('[') + 1,
    tagValue.indexOf(']')
  );
};

const convertTagsArrayToTree = (xml: XmlTag[]) => {
  const xmlTree = [];

  while (xml.length > 0) {
    const tag = xml.shift();
    if (!tag) {
      break;
    }
    if (tag.value.indexOf('</') > -1 || tag.name.match(/\/$/)) {
      tag.name = tag.name.replace(/\/$/, '').trim();
      tag.value = tag.value.substring(0, tag.value!.indexOf('</')).trim();
      xmlTree.push(tag);
      continue;
    }
    if (tag.name.indexOf('/') == 0) {
      break;
    }

    xmlTree.push(tag);
    tag.children = convertTagsArrayToTree(xml);
    tag.value = decodeURIComponent(tag.value.trim());
  }
  return xmlTree;
};

const tagToString = (xml: XmlTag) => {
  let xmlText = convertTagToText(xml);
  if (xml.children && xml.children.length > 0) {
    xml.children.map((child) => {
      xmlText += tagToString(child);
    });

    xmlText += '</' + xml.name + '>';
  }

  return xmlText;
};

const convertTagToText = (tag: XmlTag) => {
  let tagText = '<' + tag.name;

  for (const attribute in tag.attributes) {
    tagText += ' ' + attribute + '="' + tag.attributes[attribute] + '"';
  }

  if (tag.value.length > 0) {
    tagText += '>' + tag.value;
  } else {
    tagText += '>';
  }

  if (tag.children?.length === 0) {
    tagText += '</' + tag.name + '>';
  }

  return tagText;
};

export const parseXmlFromString = (xmlText: string) => {
  return parseFromString(xmlText);
};
export const xmlTagToString = (xml: XmlTag) => {
  return tagToString(xml);
};

export const xmlBeautify = (xml: string, indent = '    ') => {
  const splitOnTags = (str: string) =>
    str.split(/(<\/?[^>]+>)/g).filter((line) => line.trim() !== '');
  const isTag = (str: string) => /<[^>!]+>/.test(str);
  const isXMLDeclaration = (str: string) => /<\?[^?>]+\?>/.test(str);
  const isClosingTag = (str: string) => /<\/+[^>]+>/.test(str);
  const isSelfClosingTag = (str: string) => /<[^>]+\/>/.test(str);
  const isOpeningTag = (str: string) =>
    isTag(str) &&
    !isClosingTag(str) &&
    !isSelfClosingTag(str) &&
    !isXMLDeclaration(str);

  let depth = 0;
  let ignoreMode = false;
  let deferred: string[] = [];
  return splitOnTags(xml)
    .map((item) => {
      if (item.trim().startsWith('<![CDATA[')) {
        ignoreMode = true;
      }
      if (item.trim().endsWith(']]>')) {
        ignoreMode = false;
        deferred.push(item);
        const cdataBlock = deferred.join('');
        deferred = [];
        return cdataBlock;
      }
      if (ignoreMode) {
        deferred.push(item);
        return null;
      }

      // removes any pre-existing whitespace chars at the end or beginning of the item
      const noEndWhitespaceItem = item.replace(/^\s+|\s+$/g, '');
      if (isClosingTag(noEndWhitespaceItem)) {
        depth--;
      }

      const line = indent.repeat(depth) + noEndWhitespaceItem;
      if (isOpeningTag(noEndWhitespaceItem)) {
        depth++;
      }
      return line;
    })
    .filter((c) => c)
    .join('\n');
};

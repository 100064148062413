import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface CacheItem<T> {
  value: T;
  path: string;
}

export let cachedItems: Record<string, CacheItem<unknown>> = {};

/**
 * delete any cached items which do not start with the current path.
 * this function needs to be called by a top level component, e.g.:
 *     const location = useLocation();
 *   useEffect(() =>  setTopLevelPath(location.pathname), [location.pathname]);
 *
 * @param path the current path
 */
export function setTopLevelPath(path: string) {
  Object.keys(cachedItems).forEach((key) => {
    if (!path.startsWith(cachedItems[key].path)) {
      delete cachedItems[key];
    }
  });
}

/**
 * Resets the cache - useful for cleaning up between tests
 */
export function resetTopLevelCache() {
  cachedItems = {};
}

/**
 * This hook provides an easy way to use and store state for the duration of the user's navigation within a defined url prefix.
 * If the user navigates to a different route (not starting with the prefix) then the state will be forgotten.
 *
 * @param key Key for the value name, similar to localStorage keys
 * @param defaultValue default value
 * @param pathOverride Path for which the value will be remembered as long as you stay on the path
 */
function useTopLevelNavCache<T>(
  key: string,
  defaultValue: T,
  pathOverride?: string
) {
  const location = useLocation();
  const [value, setValue] = useState<T>(
    (cachedItems[key]?.value as T) ?? defaultValue
  );

  // use the current path, unless an override is provided
  const [path] = useState(pathOverride || location.pathname);

  const setAndStoreValue = useCallback(
    (newValue: T) => {
      cachedItems[key] = {
        value: newValue,
        path: (path.startsWith('/') ? '' : '/') + path,
      };
      setValue(newValue);
    },
    [setValue, key, path]
  );

  return [value, setAndStoreValue] as const;
}

export default useTopLevelNavCache;

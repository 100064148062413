import { useContext } from 'react';
import { AuthContext } from '../Providers/AuthProvider';
import { Permission } from '../Models/Role';

/**
 * given a an array of permission names...
 * ...return a matching boolean array if the logged in user has each permission
 */

export default function (permissions: Permission[]) {
  const { user } = useContext(AuthContext);
  return permissions.map((p) => user?.permissions?.includes(p));
}

import React, {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  Fragment,
} from 'react';
import { UseApiFormResult } from '../../Hooks/useApiForm';

/**
 * Text fields
 */

type FormTextFieldProps = Omit<TextFieldProps, 'id'> & {
  type: 'text' | 'password' | 'date' | 'time' | 'number';
  validationregex?: string;
  validationmessage?: string;
};

/**
 * Select boxes
 */

type FormSelectProps = Omit<SelectProps, 'id'> & {
  type: 'select';
  // this was "items" but changed to "options" for compatibility with Autocomplete
  options: SelectItem[];
  value?: (string | number)[] | (string | number);
  helperText?: string;
};

export interface SelectItem {
  id: number | string;
  label: string;
}

export function selectLabelsById(items: SelectItem[] | readonly SelectItem[]) {
  const result: Record<number | string, string> = {};
  items.forEach((item) => (result[item.id] = item.label));
  return result;
}

/**
 * Checkbox
 */

type FormCheckBoxProps = Omit<CheckboxProps, 'id' | 'form'> & {
  type: 'checkbox';
  label?: string;
  helperText?: string;
};

/**
 * Autocomplete
 */

type FormAutoCompleteProps = Omit<
  AutocompleteProps<SelectItem, boolean, boolean, boolean>,
  'id' | 'renderInput'
> &
  Pick<SelectProps, 'onChange'> & {
    // use the same onChange callback as the other types of input we accept
    type: 'autocomplete';
    label?: string;
    name?: string;
  };

/**
 * Combined props
 */

interface BaseProps {
  error?: boolean;
  clearable?: boolean;
  debounceMs?: number;
  onClear?: () => void;
}
type FieldProps = BaseProps &
  (
    | FormTextFieldProps
    | FormSelectProps
    | FormCheckBoxProps
    | FormAutoCompleteProps
  );

/**
 * Component
 */

export type FormFieldProps<T, R> = FieldProps & {
  id: Extract<keyof T, string>;
  form: UseApiFormResult<T, R>;
};

export default function <T, R>({
  id,
  form,
  clearable,
  debounceMs,
  onClear,
  ...props
}: FormFieldProps<T, R>) {
  const newValue = form.data[id];
  const [myValue, setMyValue] = useState(newValue);
  props.value = myValue;
  const originalValue = props.value;
  props.name = props.name || id;
  props.error = props.error || !!form.errors[id];
  const onChangeCallback = props.onChange;
  const debounceTimeoutHandle = useRef<NodeJS.Timer | null>(null);
  const lastChangeEventParams = useRef<
    [any, any] | null // eslint-disable-line @typescript-eslint/no-explicit-any
  >(null);

  // send immediately, or after a delay of debounceMs
  const sendWithDebounce = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any, { immediate = false } = {} as { immediate?: boolean }) => {
      if (debounceTimeoutHandle.current) {
        clearTimeout(debounceTimeoutHandle.current);
      }
      if (
        !debounceMs ||
        immediate ||
        !lastChangeEventParams.current?.[0].target.value
      ) {
        form.setData(id, value);
        onChangeCallback?.(...lastChangeEventParams.current!);
        return;
      }
      debounceTimeoutHandle.current = setTimeout(() => {
        debounceTimeoutHandle.current = null;
        form.setData(id, value);
        onChangeCallback?.(...lastChangeEventParams.current!);
        lastChangeEventParams.current = null;
      }, debounceMs);
    },
    [onChangeCallback, debounceMs, lastChangeEventParams, form, id]
  );

  // clear the timeout handle if we get dismounted
  useEffect(() => {
    return () => {
      if (debounceTimeoutHandle.current) {
        clearTimeout(debounceTimeoutHandle.current);
      }
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props.onChange = (e: any, child: any) => {
    // for a Select item when multiple=false but the value is an array, keep the value as an array
    // this is useful for the User.roleName selector where for now we want to limit it to only one role but the form needs an array
    // this also supports where multiple=true and will either get the id from the array of objects or just use the array of strings
    // prettier-ignore
    let errText = '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let value: any = e.target.value;
    if (props.type == 'autocomplete') {
      value = Array.isArray(child)
        ? child.map((c) => (typeof c == 'object' ? c.id : c))
        : child?.id || null;
    } else if (props.type == 'checkbox') {
      value = e.target.checked;
    } else if (Array.isArray(originalValue) && !Array.isArray(e.target.value)) {
      value = [e.target.value];
    } else if (props.type == 'number') {
      const validationRegEx = props.validationregex || /^-?\d*\.?\d*$/;
      const validationMessage = props.validationmessage || 'Invalid number';
      if (!new RegExp(validationRegEx).test(e.target.value)) {
        errText = validationMessage;
      }
    }

    if (errText == '') {
      setMyValue(value);
      lastChangeEventParams.current = [e, child];
      sendWithDebounce(value);
    }
    form.setErrors({ ...form.errors, [id]: errText });
  };

  // if the source data changes, only update our value if we don't have the focus

  // sometimes the onBlur gets called BEFORE the setMyValue() in useEffect() has time to actually update the value
  // therefore we need to know the up-to-date value of myValue to send in the onBlur() callback
  const valueForOnBlur = useRef(myValue);
  const hasFocus = useRef(false);
  const originalOnFocus = props.onFocus;
  const originalOnBlur = props.onBlur!;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props.onFocus = (e: any) => {
    hasFocus.current = true;
    originalOnFocus?.(e);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props.onBlur = (e: any) => {
    hasFocus.current = false;
    originalOnBlur?.(e);
    form.setErrors({ ...form.errors, [id]: '' });
    if (lastChangeEventParams.current && props.type != 'autocomplete') {
      lastChangeEventParams.current[0].target.value = myValue;
      sendWithDebounce(valueForOnBlur.current, { immediate: true });
    }
  };
  useEffect(() => {
    if (!hasFocus.current || form.enableFormUpdatesWhenFocussed) {
      // only update our value if we don't have the focus
      setMyValue(newValue);
    }
    // keep a note of the new value in case we need it within onBlur() before the next render loop
    valueForOnBlur.current = newValue;
  }, [newValue, form.enableFormUpdatesWhenFocussed]);

  const inputProps = clearable
    ? {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={
                myValue
                  ? undefined
                  : {
                      // use visibility:hidden instead of display:none so the input's width doesn't change
                      visibility: 'hidden',
                      // but need to let user events pass through the invisible button
                      userEvent: 'none',
                    }
              }
              onClick={(e) => {
                onClear?.();
                props.onChange!(
                  {
                    ...e,
                    target: {
                      ...e.target,
                      value: '',
                    },
                  } as unknown as ChangeEvent<HTMLInputElement>,
                  false
                );
              }}
              edge="end"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }
    : {};

  const labelsById = useMemo(
    () =>
      'options' in props && props.options
        ? selectLabelsById(props.options)
        : {},
    [(props as any).options] // eslint-disable-line react-hooks/exhaustive-deps, @typescript-eslint/no-explicit-any
  );

  if (
    props.type == 'text' ||
    props.type == 'password' ||
    props.type == 'date' ||
    props.type == 'time' ||
    props.type == 'number'
  ) {
    props.helperText = form.errors[id] || props.helperText;

    const isNumber = props.type == 'number';
    return (
      <TextField
        margin="normal"
        fullWidth
        data-testid={`form-${id}`}
        inputProps={{
          style: { textAlign: isNumber ? 'right' : 'left' },
        }}
        InputProps={inputProps}
        InputLabelProps={{
          shrink: true,
        }}
        onClick={
          isNumber
            ? (e) => {
                // for right aligned numbers, when clicking on the input we want the cursor to be at the end, not the start
                // but setSelectionRange doesn't work for number inputs, so use 'tel' instead (so we still get number keypad)
                const target = e.target as HTMLInputElement;
                if (target.value) {
                  target.setSelectionRange(
                    target.value.length,
                    target.value.length
                  );
                }
              }
            : undefined
        }
        key={props.type + id} // force DOM element to be switched out when type changes
        {...props}
        value={props.value || ''}
        name={props.name + '-LastPass-ignore-hack'}
        autoComplete="off"
        type={isNumber ? 'tel' : props.type}
      />
    );
  }

  if (props.type == 'select') {
    // if props.value is an array, but we don't want to allow multiple options then only pass the first item
    props.value =
      !props.multiple && Array.isArray(originalValue)
        ? originalValue[0] || ''
        : originalValue;
    // sending props.helperText to the Select element causes an error, so need to display the helper text ourselves
    const helperText = form.errors[id] || props.helperText;
    delete props.helperText;

    return (
      <FormControl
        fullWidth
        margin="normal"
        error={props.error}
        key={props.type + id} // force DOM element to be switched out when type changes
      >
        <InputLabel id={`form-${id}-label`}>{props.label}</InputLabel>
        <Select
          labelId={`form-${id}-label`}
          id={`form-${id}`}
          data-testid={`form-${id}`}
          input={<OutlinedInput label={props.label} />}
          renderValue={
            // provide our own renderer even if not an array because for some reason the
            // default renderer is a bit higher than a standard text input
            (selected) =>
              Array.isArray(selected)
                ? selected.map((id) => labelsById[id]).join(', ')
                : labelsById[selected as string]
          }
          {...props}
        >
          {props.options.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              data-testid={`select-item-${id}-${item.id}`}
            >
              {props.multiple && Array.isArray(originalValue) && (
                <Checkbox checked={originalValue!.includes(item.id)} />
              )}
              <ListItemText
                primary={item.label || '<blank>'}
                sx={{ opacity: item.label ? 1 : 0.5 }}
              />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }

  if (props.type == 'checkbox') {
    const error = props.error;
    delete props.error;
    return (
      <FormControl
        fullWidth
        margin="normal"
        error={error}
        // prevent the label being selected if you double-click
        sx={{ userSelect: 'none' }}
        key={props.type + id} // force DOM element to be switched out when type changes
      >
        <FormControlLabel
          control={<Checkbox checked={!!props.value} {...props} />}
          label={props.label}
          sx={{ mr: 0 }}
        />
      </FormControl>
    );
  }

  if (props.type == 'autocomplete') {
    const error = props.error;
    delete props.error;
    return (
      <Autocomplete
        fullWidth
        data-testid={`form-${id}`}
        value={
          props.options && Array.isArray(props.value)
            ? props.options.filter((o) =>
                (props.value as (string | number)[]).includes(o.id)
              )
            : props.value || null
        }
        isOptionEqualToValue={(option, value) =>
          option.id == (value as unknown as number | string)
        }
        getOptionLabel={(option) => {
          return typeof option == 'object'
            ? option.label
            : labelsById[option] || '';
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {props.loading ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
        key={props.type + id} // force DOM element to be switched out when type changes
        {...props}
      />
    );
  }

  return null;
}

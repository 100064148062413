import { KeyboardEventHandler } from 'react';
import { format, getDate } from 'date-fns';

export const EnvironmentTypes = {
  TEST: 'test',
  STAGE: 'stage',
  PRE_PROD: 'preproduction',
  CUSTOMER_TEST: 'customer-test',
  CUSTOMER_STAGE: 'customer-stage',
  CUSTOMER_PREPROD: 'customer-preprod',
  PRODUCTION: 'production',
  CUSTOMER_PRODUCTION: 'customer-prod',
};

export enum AppBarColours {
  testBackgroundColor = '#FFC000',
  preprodBackgroundColor = '#bc2500',
  // TODO: This will change once prod goes live
  stagingBackgroundColor = '#2374ab',
  productionBackgroundColor = '#2374ab',
}

export enum ThemeColorElements {
  appBarBackground,
}

/**
 * Gets color based on the element defined in the function parameter, July 28th, 2024
 *
 * @returns Hexadecimal String
 */
export function getColor(themeColorElements: ThemeColorElements) {
  switch (themeColorElements) {
    case ThemeColorElements.appBarBackground:
      return getAppBarColour();
  }
}

/**
 * Determines the color of AppBar based on environment, July 7th, 2024
 *
 * @returns Hexadecimal String
 */
export function getAppBarColour() {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case EnvironmentTypes.TEST:
      return AppBarColours.testBackgroundColor;
    case EnvironmentTypes.STAGE:
      // TODO: This will change once prod goes live
      return AppBarColours.stagingBackgroundColor;
    case EnvironmentTypes.PRE_PROD:
      return AppBarColours.preprodBackgroundColor;
    case EnvironmentTypes.CUSTOMER_TEST:
      return AppBarColours.testBackgroundColor;
    case EnvironmentTypes.CUSTOMER_STAGE:
      // TODO: This will change once prod goes live
      return AppBarColours.stagingBackgroundColor;
    case EnvironmentTypes.CUSTOMER_PREPROD:
      return AppBarColours.preprodBackgroundColor;
    default:
      return AppBarColours.productionBackgroundColor;
  }
}

/**
 * Format a number with commas and optional digits
 */
export function formatNumber(val: number, numDigits?: number) {
  return val.toLocaleString(undefined, {
    minimumFractionDigits: numDigits,
    maximumFractionDigits: numDigits,
  });
}

/**
 *
 * @param dateStr
 * @param formatStr
 * @returns
 */
export const tryFormatDateStr = (
  dateStr: string | null | undefined,
  formatStr = 'dd/MM/yyyy HH:mm:ss'
) => {
  if (!dateStr) {
    return '';
  }
  try {
    if (!dateStr) {
      return '';
    }
    const str = dateStr.replace(/"/g, '').trim();
    return format(new Date(str), formatStr);
  } catch (e) {
    return 'Invalid Date';
  }
};

/**
 * Format a date so that it's a human-readable string, November 23rd, 2022
 * @param date
 * @returns
 */
export const friendlyDate = (date: Date | null | string): string => {
  if (!date) {
    return '';
  }

  //If the date is a string, convert to a Date object
  let newDate: Date;

  try {
    if (typeof date === 'string') {
      newDate = new Date(date);
    } else {
      newDate = date;
    }

    if (isNaN(Date.parse(newDate.toISOString()))) {
      return '';
    }
  } catch (e) {
    return '';
  }

  // Get the day of the month as a number
  const day = getDate(newDate);
  // Determine the ordinal indicator
  const ordinalIndicator =
    ['th', 'st', 'nd', 'rd'][(day % 100 >> 3) ^ 1 && day % 10] || 'th';
  // Format the date without the day
  const formattedMonth = format(newDate, 'MMMM');
  const formattedYear = format(newDate, 'yyyy');
  // Combine the formatted date with the day and its ordinal indicator
  const formattedDate = `${formattedMonth} ${day}${ordinalIndicator} ${formattedYear}`;

  return formattedDate;
};

/**
 * Reads a file from a file input and converts it to a base64 URL string
 */
export function readFile(file: Blob) {
  return new Promise<string>((resolve, reject) => {
    try {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result as string);
      fileReader.readAsDataURL(file);
    } catch (error) {
      /* istanbul ignore next */
      reject(error);
    }
  });
}

/**
 * Restrict a width and height to a maximum size keeping proportions
 */
export function scaleDimensions(
  width: number,
  height: number,
  maxSize: number
) {
  // prettier-ignore
  return width < maxSize && height < maxSize ? { width, height }
    : width > height ? { width: maxSize, height: (height * maxSize) / width }
    : { width: (width * maxSize) / height, height: maxSize };
}

/**
 * Takes a base64 data URL and scales the image to default size and can also rotate it
 */
/* istanbul ignore next */
export function scaleAndRotateImageFromDataUrl(
  dataUrl: string,
  maxSize: number,
  quality = 0.9,
  degrees = 0
) {
  return new Promise<string>((resolve) => {
    const image = new Image();
    image.onload = () => {
      let { width, height } = scaleDimensions(
        image.width,
        image.height,
        maxSize
      );
      const oWidth = width;
      const oHeight = height;
      if (degrees % 180) {
        width = oHeight;
        height = oWidth;
      }
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d')!;
      ctx.translate(canvas.width / 2, canvas.height / 2); // the origin [0,0] is now center-canvas
      ctx.rotate((degrees * Math.PI) / 180); // rotate the canvas by +90% (==Math.PI/2)
      ctx.drawImage(image, -oWidth / 2, -oHeight / 2, oWidth, oHeight); // since images draw from top-left offset the draw by 1/2 width & height
      ctx.rotate((-degrees * Math.PI) / 180); // un-rotate the canvas by -90% (== -Math.PI/2)
      ctx.translate(-canvas.width / 2, -canvas.height / 2); // un-translate the canvas back to origin==top-left canvas
      const resultDataUrl = canvas.toDataURL('image/jpeg', quality);
      resolve(resultDataUrl);
    };
    image.src = dataUrl;
  });
}

/**
 * Strip illegal characters from a filename
 */
export function sanitiseFilename(filename: string) {
  return filename.replace(/[/\\?%*:|"<>]/g, '');
}

/**
 * Wrap JSON.parse with a typed result
 */
export function typedJsonParse<T>(str: string) {
  return JSON.parse(str) as T;
}

/**
 * Deep clone an object using JSON stringify/parse, maintaining the type of the object
 */
export function jsonClone<T>(obj: T) {
  return typedJsonParse<T>(JSON.stringify(obj));
}

/**
 * Prevent inserting non-numeric characters in input fields
 * (using type="number" still allows +-e), and pattern="[0-9]*" is often ignored
 */
export const onlyAllowDigits: KeyboardEventHandler = (e) => {
  // if the key non-printable, let it through (Enter, Delete, Left, etc)
  if (e.key.length > 1) {
    return;
  }

  // if the key is '-' (minus), allow it at the start and only if the input doesn't have 'min' attribute < 0
  if (e.key == '-') {
    const target = e.target as HTMLInputElement;
    // prevent '-' not at start of number
    if (target.value != '') {
      e.preventDefault();
      return;
    }

    // allow if no minimum, or -ve minimum
    if (!target.min || parseInt(target.min) < 0) {
      return;
    }
  }

  // otherwise must be a digit
  const re = /[0-9]/;
  if (!re.test(e.key)) {
    e.preventDefault();
  }
};

/**
 * Force using the "pattern" of the input for validation
 */
export const onlyAllowMatchingPattern: KeyboardEventHandler = (e) => {
  // if the key non-printable, let it through (Enter, Delete, Left, etc)
  if (e.key.length > 1) {
    return;
  }
  const target = e.target as HTMLInputElement;
  if (target.pattern) {
    const regex = new RegExp('^' + target.pattern + '$');
    if (!regex.test(target.value + e.key)) {
      e.preventDefault();
      return;
    }
  }
};

/**
 * validates the output from a date input field (which returns valid dates for years 0000 to well over 9999)
 * valid dates are 10 characters: 1234-67-90 and start with '2'
 * @param date string output from an input type="date" field
 * @returns a valid date string or an empty string
 */
export const validateQueryDate = (date: string) =>
  date.length == 10 && date[0] == '2' ? date : '';

export const printFile = (blobOrString: Blob | string, mimeType: string) => {
  const blob = new Blob([blobOrString], { type: mimeType });
  const downloadUrl = URL.createObjectURL(blob);

  const newWindow: WindowProxy | null = window.open(downloadUrl, '_blank');

  if (newWindow) {
    newWindow.addEventListener('load', () => {
      newWindow.print();
      URL.revokeObjectURL(downloadUrl);
    });
  } else {
    URL.revokeObjectURL(downloadUrl);
  }
};

export const saveFile = (
  blobOrString: Blob | string,
  filename: string,
  mimeType: string
) => {
  const blob = new Blob([blobOrString], { type: mimeType });
  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = filename;
  a.click();
  setTimeout(() => URL.revokeObjectURL(downloadUrl), 100);
};

export const base64toBlob = (dataURI: string, type: string): Blob => {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);

  Array.from(byteString).forEach((char, i) => {
    int8Array[i] = char.charCodeAt(0);
  });

  return new Blob([int8Array], { type });
};

/**
 * *************** CSV Utils ************************
 */

// defining the parameters like this gives helpful type hints in the code where we're preparing the data
export type CSVRow<T extends readonly string[]> = Partial<
  Record<T[number], string | number | null | undefined>
>;
const lineDelimiter = '\r\n';
const colDelimiter = ',';

export const generateCSV = <T extends readonly string[]>(
  columns: string[],
  rows: { [p: string]: string }[] | undefined
) => {
  // generate a row using the columns array, replacing each header with the matching value from the row object
  const mappedRows = rows?.map((row) =>
    columns.map((c) => row[c as T[number]] ?? '')
  );
  return arraysToCSV([columns as unknown as string[], ...(mappedRows ?? [])]);
};

const arraysToCSV = (rows: (string | number | null | undefined)[][]) => {
  return rows
    .map((row) => row.map(quoteCSVCol).join(colDelimiter))
    .join(lineDelimiter);
};

const quoteCSVCol = (col: string | number | null | undefined) => {
  // if a column contains , or " then it needs to be quoted and " replaced with ""
  const str = col === null || col === undefined ? '' : col.toString();
  return /[,"]/.test(str) ? `"${str.replace(/"/g, '""')}"` : str;
};

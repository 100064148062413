import Api, { ServicePostMethod } from './Api';

/**
 * The CurrentUserService provides api endpoints for the current user to operate on their own profile.
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const setCurrentWarehouse: ServicePostMethod<number | null, boolean> = (
  data,
  options
) =>
  Api.put(
    `api/services/app/CurrentUser/SetCurrentWarehouse?warehouseId=${
      data || ''
    }`,
    null,
    options
  );

const setCurrentCustomer: ServicePostMethod<number | null, boolean> = (
  data,
  options
) =>
  Api.put(
    `api/services/app/CurrentUser/SetCurrentCustomer?customerId=${data || ''}`,
    null,
    options
  );

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  setCurrentWarehouse,
  setCurrentCustomer,
};

import React, { useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { BillOfLading, BolStatus } from '../../../Models/BillOfLading';
import BillOfLadingService from '../../../Services/BillOfLadingService';
import { confirmModalDialog } from '../../ModalDialog';
import { Box } from '@mui/material';

const STATUSES_TO_UNASSIGN = [
  BolStatus.notStarted,
  BolStatus.readyToLoad,
  BolStatus.readyToPick,
  BolStatus.pickingInProgress,
];
// Component to display a button to unassign all soft assigned pallets/cartons from the BOL and it’s containers.
export default function ({
  bol,
  onAfterUnassigned,
}: {
  bol: BillOfLading;
  onAfterUnassigned?: () => void;
}) {
  const [loadingId, setLoadingId] = useState(0);
  const unassignHandler = async () => {
    setLoadingId(bol.id);
    const confirmed = await confirmModalDialog({
      title: 'Unassign all cartons',
      acceptButtonLabel: 'Yes',
      declineButtonLabel: 'No',
      acceptButtonCountdown: 3,
      declineButtonCountdown: 0,
      content: (
        <Box textAlign={'center'}>
          <h3>Are you sure you want to unassign all cartons for this BOL?</h3>
        </Box>
      ),
    });
    if (confirmed) {
      await BillOfLadingService.unassignAllForBol({ bolId: bol.id }).then(
        () => {
          if (onAfterUnassigned) onAfterUnassigned();
          setLoadingId(0);
        }
      );
    }
    setLoadingId(0);
  };
  const hasAssignedCartons = useMemo(() => {
    if (!bol.pallets || bol.pallets.length === 0) return false;

    const cartons = bol.pallets.flatMap((p) =>
      p.cartons?.filter((c) => c != null)
    );
    return cartons.length > 0;
  }, [bol]);
  const allowToUnassign = useMemo(() => {
    return STATUSES_TO_UNASSIGN.includes(bol.status);
  }, [bol]);

  return (
    <LoadingButton
      data-testid={'unassign-all-button'}
      onClick={() => unassignHandler()}
      variant="contained"
      disabled={!allowToUnassign || !hasAssignedCartons}
      loading={loadingId == bol.id}
    >
      Unassign All
    </LoadingButton>
  );
}

import React, { useState } from 'react';
import useApiGet from '../../Hooks/useApiGet';
import ChecklistService from '../../Services/ChecklistService';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import ChecklistItemResponseService from '../../Services/ChecklistItemResponseService';
import ChecklistItemForm, {
  ChecklistItemEntityType,
} from './ChecklistItemForm';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface ChecklistProps {
  entityType: ChecklistItemEntityType;
  entityId: number;
  parentEntityType?: ChecklistItemEntityType;
  parentEntityId?: number;
  autoFill?: Record<string, string | number>;
  onComplete?: (isComplete: boolean) => void;
  canCompleteChecklist?: boolean;
  onMediaUploaded?: () => void;
}

export default function ({
  entityType,
  entityId,
  parentEntityType,
  parentEntityId,
  autoFill,
  onComplete,
  canCompleteChecklist = false,
  onMediaUploaded,
}: ChecklistProps) {
  const [expandedPanel, setExpandedPanel] = useState<number | false>(0);
  const handleChange =
    (panel: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : false);
    };
  const { data: checklist } = useApiGet(ChecklistService.getByEntity, {
    params: {
      entityType,
      entityId,
      parentEntityType,
      parentEntityId,
    },
    onSuccess: (checklist) => {
      setExpandedPanel(checklist.checklistItemGroups[0].id);
    },
  });
  const { data: checklistItemResponses, refresh: refreshResponses } = useApiGet(
    ChecklistItemResponseService.getAllForEntityTypeAndEntityId,
    {
      params: {
        entityType,
        entityId,
      },
      autoRefreshTrueOrSecs: 30,
    }
  );

  return (
    <>
      {(!checklist || !checklistItemResponses) && (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      )}
      {checklist?.checklistItemGroups.map((checklistItemGroup) => (
        <Accordion
          key={checklistItemGroup.id}
          expanded={expandedPanel === checklistItemGroup.id}
          onChange={handleChange(checklistItemGroup.id)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              variant="h6"
              data-testid={`checklist-group-name-${checklistItemGroup.id}`}
            >
              {checklistItemGroup.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {checklistItemGroup.checklistItems.map((checklistItem, index) => (
              <React.Fragment key={checklistItem.id}>
                {index !== 0 && <Divider />}
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <ChecklistItemForm
                    checklistItem={checklistItem}
                    entityId={entityId}
                    entityType={entityType}
                    response={checklistItemResponses?.find(
                      (response) =>
                        response.checklistItemId === checklistItem.id
                    )}
                    onSuccess={refreshResponses}
                    autoFill={autoFill}
                    onChecklistCompleted={onComplete}
                    checklistId={checklist.id}
                    canCompleteChecklist={canCompleteChecklist}
                    checklistItemGroup={checklistItemGroup}
                    onMediaUploaded={onMediaUploaded}
                  />
                </Stack>
              </React.Fragment>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}

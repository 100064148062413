import {
  Box,
  Button,
  Checkbox,
  Fab,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React, {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useLocalStorage, {
  UseLocalStorageOptions,
} from '../Hooks/useLocalStorage';
import CardTitle from './CardTitle';
import Skeletons from './Skeletons';
import useTopLevelNavCache from '../Hooks/useTopLevelNavCache';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import {
  base64toBlob,
  formatNumber,
  generateCSV,
  saveFile,
} from '../Lib/utils';
import SplitButton from './SplitButton';
import useApiForm from '../Hooks/useApiForm';
import PdfGeneratorService from '../Services/PdfGeneratorService';
import { utils, writeFile } from 'xlsx';

/**
 * This is a wrapper for the standard MUI Table Component but not as complex as the DataGrid component
 * It provides sorting and filtering
 * Currently this assumes there is only a single page of data
 * TODO Make it compatible with server-side paging.  Ideally if all the data fits in a single page we can filter and sort locally
 * and only resort to server-side filtering/sorting if there is more than one page, because it's better UX and less server load.
 */

export interface SortedTableSwitchFilter<T> {
  id: keyof T;
  label: string;
  default: boolean;
}

export interface SortedTableColumn<T> {
  id: string;
  label?: string;
  show?: boolean; // defaults to true (will be shown)
  searchable?: boolean; // defaults to true (will be searched)
  sortable?: boolean; // defaults to false
  defaultSortDir?: 'asc' | 'desc'; // defaults to 'asc'
  headerProps?: TableCellProps;
  cellProps?: TableCellProps;
  textAlign?: 'left' | 'right' | 'center';
  numeric?: boolean; // right-aligns the cell and header, and also formats as a number with commas
  opacityIfSameAsPrevious?: number;
  showTotal?: boolean;
  value?: (data: T) => number | string | undefined | null;
  sortValue?: (data: T) => number | string | undefined | null;
  cellRender?: (data: T, index: number) => ReactNode;
  renderTotal?: (data: T[]) => number | string | undefined | null; // can override and display a custom value in the footer column
  exportable?: boolean; // defaults to true
}

export interface SortedTableSortOpts {
  id: string;
  dir: 'asc' | 'desc';
}

export interface SortedTableProps<T> {
  data?: T[];
  rowKey: keyof T;
  columnDefs: SortedTableColumn<T>[];
  sortOpts?: SortedTableSortOpts;
  localStorageKey?: string;
  localStorageOptions?: UseLocalStorageOptions;
  title?: ReactNode;
  loading?: boolean;
  searchable?: boolean;
  smallGap?: boolean; // remove the double padding between cells - defaults to false
  smallFont?: boolean; // reduce the default font size - default to false
  fabIcon?: ReactNode;
  pageSize?: number | 'auto';
  resetPageKey?: number;
  onFabClick?: () => void;
  onRowClick?: (data: T, e: React.MouseEvent<HTMLTableRowElement>) => void;
  switchFilters?: SortedTableSwitchFilter<T>[];
  exportable?: boolean;
  fabStyle?: SxProps;
  testId?: string;
  exportFileName?: string | (() => string);
  onRowCheckboxChanged?: (
    data: T,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void; // event when a row selected by checking to checkbox column.
  enableRowCheckbox?: (data: T) => boolean; // method to set a row checkbox disable or enable.
  selectionMode?: 'single' | 'multiple';

  actionBtnTitle?: string | undefined;
  actionBtnIsDisabled?: boolean | undefined;
  onActionBtnClick?: () => void;
  getRowClassName?: (data: T) => string;
}

// This is used to generate the file name for the export based on looking at either the title OR the exportFileName property
// which can either be a function or string
const getExportFileName = (
  title?: ReactNode,
  exportFileName?: string | (() => string)
) => {
  if (!exportFileName) {
    return typeof title == 'string' ? title : '';
  }

  if (typeof exportFileName === 'string') {
    return exportFileName;
  }

  return exportFileName();
};

export default function <T>({
  data,
  columnDefs,
  rowKey,
  title,
  searchable,
  loading,
  fabIcon,
  pageSize: pageSizeInput,
  resetPageKey,
  switchFilters,
  exportable,
  fabStyle,
  exportFileName,
  onRowCheckboxChanged,
  enableRowCheckbox,
  selectionMode = 'single',

  actionBtnIsDisabled,
  actionBtnTitle,
  onActionBtnClick,
  getRowClassName,
  ...props
}: SortedTableProps<T>) {
  // 'auto' pageSize requires knowing the position of the top of the table
  const [tableTop, setTableTop] = useState(0);
  const [selectedRows, setSelectedRows] = useState<readonly string[]>([]);
  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    rowKey: keyof T,
    row: T
  ) => {
    const id = row[rowKey] as string;
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectionMode === 'single') {
      newSelected = selectedIndex === -1 ? [id] : [];
    } else {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }
    }
    setSelectedRows(newSelected);
    props.onRowClick?.(row, event);
  };

  const isSelected = (rowKey: string) => selectedRows.indexOf(rowKey) !== -1;
  const getElementTopPosition = useCallback((node: HTMLTableSectionElement) => {
    setTableTop(node?.getBoundingClientRect().top);
  }, []);
  const pageSize = useMemo(() => {
    // if we have a totals row then our page size is one less
    const totalsRowAdjustment = columnDefs.some((c) => c.showTotal) ? -1 : 0;
    if (pageSizeInput != 'auto') {
      return pageSizeInput ? pageSizeInput + totalsRowAdjustment : undefined;
    }
    if (!tableTop) {
      return 20;
    }

    const windowHeight = window.innerHeight - 16; // 16 px just for some rounding error
    const rowHeight =
      (props.smallFont ? 19 : 20) +
      2 * (props.smallFont ? 12 : 16) +
      1; /* bottom border */
    return (
      Math.floor((windowHeight - tableTop) / rowHeight) + totalsRowAdjustment
    );
  }, [pageSizeInput, tableTop, props.smallFont, columnDefs]);

  const visibleColumns = useMemo(
    () => columnDefs.filter((c) => typeof c.show == 'undefined' || c.show),
    [columnDefs]
  );

  // By default, all columns are exportable but this gives the option to exclude some
  const exportableColumns = useMemo(
    () =>
      columnDefs.filter(
        (c) => typeof c.exportable == 'undefined' || c.exportable
      ),
    [columnDefs]
  );

  // build the default switch filters value, by looping through the switch filters attributes to get keys and default values
  const defaultSwitchFilters = useMemo(() => {
    return switchFilters
      ? Object.fromEntries(switchFilters.map((s) => [s.id, s.default]))
      : null;
  }, [switchFilters]);

  // Sorting
  const [sortOpts, setSortOpts] = useLocalStorage<SortedTableSortOpts>(
    props.localStorageKey,
    props.sortOpts || {
      id: '',
      dir: 'asc',
    },
    props.localStorageOptions || { todayOnly: true, sessionOnly: true }
  );

  const onSortClick = useCallback(
    (id: string) => {
      const col = columnDefs.find((col) => col.id == id)!;
      setSortOpts({
        id,
        dir:
          id == sortOpts.id
            ? sortOpts.dir == 'asc'
              ? 'desc'
              : 'asc'
            : col.defaultSortDir || 'asc',
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSortOpts, columnDefs]
  );

  // Searching & Pagination - apply after sorting - more efficient (don't need to re-sort on every key press)
  // only remember the search & page while we stay on this route
  const [routeState, setRouteState] = useTopLevelNavCache(
    props.localStorageKey + '.search',
    {
      search: '',
      filters: defaultSwitchFilters ?? {},
      page: 0,
    }
  );

  const sortedData = useMemo(() => {
    const id = sortOpts.id;
    if (!id) {
      return data || [];
    }
    const dir = sortOpts.dir == 'asc' ? 1 : -1;
    const col = columnDefs.find((c) => c.id == id)!;
    const sortedItems = (data || []).sort((a, b) => {
      const valA = col.sortValue?.(a) ?? col.value?.(a) ?? a[id as keyof T];
      const valB = col.sortValue?.(b) ?? col.value?.(b) ?? b[id as keyof T];
      if (!valA && !valB) {
        return 0;
      }
      if (typeof valA == 'number' && typeof valB == 'number') {
        return ((valA as number) - (valB as number)) * dir;
      }
      return (
        (valA || '').toString().localeCompare((valB || '').toString()) * dir
      );
    });
    if (Object.keys(routeState.filters).length === 0) {
      return sortedItems;
    }
    let sortedDataFiltered = sortedItems;
    for (const [key, value] of Object.entries(routeState.filters)) {
      sortedDataFiltered = sortedDataFiltered.filter(
        (row) => row[key as keyof T] === value
      );
    }

    return sortedDataFiltered;
  }, [data, sortOpts, columnDefs, routeState.filters]);

  // the parent can force us to reset the page to zero, e.g. if their search query changes
  // (don't necessarily want to do this when the data or data.length changes, because don't want to reset page on regular refresh)
  // also reset when the in-table search changes
  useEffect(() => {
    setRouteState({ ...routeState, page: 0 });
  }, [resetPageKey, routeState.search]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchableColumns = useMemo(
    () =>
      columnDefs.filter(
        (col) => typeof col.searchable == 'undefined' || col.searchable
      ),
    [columnDefs]
  );

  const rowContainsWord = useMemo(() => {
    // memoize the value of each searchable field
    const cache: Record<string, Record<string, string>> = {};

    searchableColumns.forEach((col) => (cache[col.id as string] = {}));

    return (row: T, word: string) =>
      searchableColumns.some((col) => {
        let value = cache[col.id as string][row[rowKey] as string];
        if (typeof value == 'undefined') {
          // get the rendered value from the value() getter, if there is one, otherwise from the row data
          value = ((col.value?.(row) ?? row[col.id as keyof T]) || '')
            .toString()
            .toLowerCase();
          // put the searchable value in the cache for next time
          cache[col.id as string][row[rowKey] as string] = value;
        }
        return value.includes(word);
      });
    // rely on 'data' even though we don't need it - so we clear the cache when the data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchableColumns, data]);

  const filteredData = useMemo(() => {
    if (!routeState.search && Object.keys(routeState.filters).length === 0) {
      return sortedData;
    }

    let sortedDataFiltered = sortedData;
    // apply search
    if (routeState.search) {
      const wordsLower = routeState.search
        .split(' ')
        .map((w) => w.trim().toLowerCase());
      // match a row if ALL the search words are found in the row - each word could be in a different column
      sortedDataFiltered = sortedData.filter((row) =>
        wordsLower.every((word) => rowContainsWord(row, word))
      );
    }

    return sortedDataFiltered;
  }, [sortedData, routeState, rowContainsWord]);

  const pagedData = useMemo(
    () => {
      return {
        pageCount: pageSize ? Math.ceil(filteredData.length / pageSize) : 1,
        currentPageData:
          pageSize && filteredData.length > pageSize
            ? filteredData.slice(
                routeState.page * pageSize,
                (routeState.page + 1) * pageSize
              )
            : filteredData,
      };
    },
    // need to include sortOpts in the dependency array because it's used in the sort function which sorts in-place so filteredData doesn't actually change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredData, routeState, pageSize, sortOpts]
  );

  useEffect(() => {
    if (routeState.page > pagedData.pageCount) {
      // if the data is cleared then reset to the first page
      setRouteState({
        ...routeState,
        page: Math.max(pagedData.pageCount - 1, 0),
      });
    } else if (routeState.page < 0) {
      setRouteState({ ...routeState, page: 0 });
    }
  }, [routeState.page, pagedData.pageCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const totalsRow = useMemo(() => {
    // will get columns where the showTotal is true or renderTotal has been defined
    const colsWithTotals = columnDefs.filter(
      (c) => c.showTotal || c.renderTotal
    );
    if (!colsWithTotals.length) {
      return null;
    }
    return Object.fromEntries(
      colsWithTotals.map((col) => [
        col.id as string,
        col.renderTotal
          ? col.renderTotal(filteredData) ?? 0
          : filteredData.reduce(
              (sum, row) =>
                sum + ((col.value?.(row) ?? row[col.id as keyof T]) as number),
              0
            ),
      ])
    );
  }, [columnDefs, filteredData]);

  // Used with the export functionality
  const request = useMemo(() => {
    const columns = exportableColumns.map((c) => c.label ?? '');
    const dataFormattedForCsv = filteredData?.map((row) => {
      const objectFormattedForCsv: { [key: string]: string } = {};
      for (const col of exportableColumns) {
        const label = col.label ?? '';
        objectFormattedForCsv[label] = (col.value?.(row) ??
          row[col.id as keyof T]) as string;
      }
      return objectFormattedForCsv;
    });

    return {
      columns: columns,
      rows: dataFormattedForCsv,
      reportHeading: title as string,
      reportFooter: title as string,
    };
  }, [filteredData, title, exportableColumns]);

  const generatePdfForm = useApiForm(
    PdfGeneratorService.generateTablePdf,
    request,
    {
      onSuccess: (data: string) => {
        const mimeType = 'application/pdf';
        const blob = base64toBlob(data, mimeType);
        saveFile(
          blob,
          `${getExportFileName(title, exportFileName)}.pdf`,
          mimeType
        );
      },
    }
  );

  const formControls = (
    <Stack direction="row" sx={{ marginRight: fabIcon ? 8 : 0 }} spacing={2}>
      {switchFilters && (
        <FormGroup>
          {switchFilters.map((s: SortedTableSwitchFilter<T>) => (
            <FormControlLabel
              key={s.label}
              control={
                <Switch defaultChecked={s.default} name={s.id as string} />
              }
              label={s.label}
              onChange={(event: SyntheticEvent, checked) => {
                const key = (event.target as HTMLInputElement).name as keyof T;
                const updatedFilters = {
                  ...routeState.filters,
                  [key]: checked,
                };
                setRouteState({ ...routeState, filters: updatedFilters });
              }}
            />
          ))}
        </FormGroup>
      )}
      {pageSize && pagedData.pageCount > 1 ? (
        <Box sx={{ height: '40px', fontSize: '16px' }}>
          <IconButton
            disabled={routeState.page == 0}
            onClick={() => setRouteState({ ...routeState, page: 0 })}
            sx={{ pr: 0 }}
          >
            <KeyboardDoubleArrowLeft />
          </IconButton>
          <IconButton
            disabled={routeState.page == 0}
            onClick={() =>
              setRouteState({ ...routeState, page: routeState.page - 1 })
            }
            sx={{ pl: 0 }}
          >
            <KeyboardArrowLeft />
          </IconButton>
          {routeState.page + 1} / {pagedData.pageCount}
          <IconButton
            disabled={routeState.page == pagedData.pageCount - 1}
            onClick={() =>
              setRouteState({ ...routeState, page: routeState.page + 1 })
            }
            sx={{ pr: 0 }}
          >
            <KeyboardArrowRight />
          </IconButton>
          <IconButton
            disabled={routeState.page == pagedData.pageCount - 1}
            onClick={() =>
              setRouteState({ ...routeState, page: pagedData.pageCount - 1 })
            }
            sx={{ pl: 0 }}
          >
            <KeyboardDoubleArrowRight />
          </IconButton>
        </Box>
      ) : null}
      {searchable ? (
        <TextField
          label="Search"
          margin="none"
          size="small"
          value={routeState.search}
          onChange={(e) =>
            setRouteState({ ...routeState, search: e.target.value })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={
                    routeState
                      ? undefined
                      : {
                          // use visibility:hidden instead of display:none so the input's width doesn't change
                          visibility: 'hidden',
                          // but need to let user events pass through the invisible button
                          userEvent: 'none',
                        }
                  }
                  onClick={() => setRouteState({ ...routeState, search: '' })}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : null}
      {exportable ? (
        <SplitButton
          loading={generatePdfForm.processing}
          title={'Export'}
          actions={[
            {
              title: 'Download as PDF',
              onClick: () => {
                generatePdfForm.setData('rows', request.rows);
                generatePdfForm.setData('columns', request.columns);
                generatePdfForm.submit();
              },
            },
            {
              title: 'Download as CSV',
              onClick: () => {
                const csv = generateCSV(request.columns, request.rows);
                saveFile(
                  csv,
                  `${getExportFileName(title, exportFileName)}.csv`,
                  'text/csv'
                );
              },
            },
            {
              title: 'Download as Excel',
              onClick: () => {
                // generate worksheet from rows
                const ws = utils.json_to_sheet(request.rows);
                // create workbook and append worksheet
                const wb = utils.book_new();
                // sheet name cannot contain special chars and cannot exceed 31 chars
                utils.book_append_sheet(
                  wb,
                  ws,
                  title
                    ?.toString()
                    .replace(/[^\w ]/g, '')
                    .substring(0, 31)
                );
                // export to XLSX
                writeFile(
                  wb,
                  `${getExportFileName(title, exportFileName)}.xlsx`
                );
              },
            },
          ]}
        />
      ) : null}
      {actionBtnTitle ? (
        <Button
          data-testid={`action-btn-${actionBtnTitle}`}
          variant="contained"
          disabled={actionBtnIsDisabled}
          onClick={onActionBtnClick}
        >
          {actionBtnTitle}
        </Button>
      ) : null}
    </Stack>
  );

  return (
    <>
      {(title || searchable || exportable || actionBtnTitle) && (
        <CardTitle title={title} loading={loading} rightChild={formControls} />
      )}
      {fabIcon && (
        <Fab
          color="primary"
          size="medium"
          data-testid="sorted-table-fab-button"
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            ...fabStyle,
          }}
          onClick={props.onFabClick}
        >
          {fabIcon}
        </Fab>
      )}
      {!data ? (
        <Skeletons count={3} />
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 'auto',
              '.MuiTableCell-root': props.smallFont
                ? {
                    fontSize: '13px',
                    padding: '12px',
                  }
                : undefined,
              '.MuiTableRow-root .MuiTableCell-root:not(:last-child)':
                props.smallGap
                  ? {
                      paddingRight: 0,
                    }
                  : undefined,
            }}
            data-testid={props.testId}
          >
            <TableHead>
              <TableRow>
                {onRowCheckboxChanged && <TableCell></TableCell>}
                {visibleColumns.map((col) => (
                  <TableCell
                    key={`${col.id as string | number}-${col.label}`}
                    {...(col.headerProps || [])}
                    sx={{
                      whiteSpace: 'nowrap',
                      direction: col.numeric ? 'rtl' : undefined, // put the sort arrows on the left of the label
                      textAlign: col.numeric ? 'right' : undefined, // put the label far right
                      ...(col.headerProps?.sx || {}),
                    }}
                  >
                    {col.sortable ? (
                      <TableSortLabel
                        active={sortOpts.id == col.id}
                        data-testid={'sortLabel-' + (col.id as string)}
                        direction={
                          sortOpts.id == col.id
                            ? sortOpts.dir
                            : col.defaultSortDir || 'asc'
                        }
                        onClick={() => onSortClick(col.id)}
                      >
                        {col.label}
                      </TableSortLabel>
                    ) : (
                      col.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody ref={getElementTopPosition}>
              {pagedData.currentPageData.map((row, index) => (
                <TableRow
                  key={row[rowKey] as string | number}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: props.onRowClick ? 'pointer' : 'default',
                    '&:hover': { background: '#eee' },
                  }}
                  className={getRowClassName?.(row)}
                  selected={isSelected(rowKey as string)}
                  onClick={(e) => handleClick(e, rowKey, row)}
                  data-testid={'table-row-' + (row[rowKey] as string | number)}
                >
                  {onRowCheckboxChanged && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        disabled={enableRowCheckbox && !enableRowCheckbox(row)}
                        checked={isSelected(row[rowKey] as string)}
                        inputProps={{
                          'aria-labelledby': `sorted-table-${
                            row[rowKey] as string | number
                          }`,
                        }}
                        onChange={(e) => onRowCheckboxChanged(row, e)}
                      />
                    </TableCell>
                  )}
                  {visibleColumns.map((col) => {
                    const value =
                      col.value?.(row) ??
                      (row[col.id as keyof T] as string | number);
                    let opacity = 1;
                    if (
                      typeof col.opacityIfSameAsPrevious != 'undefined' &&
                      index > 0
                    ) {
                      const prevRow = pagedData.currentPageData[index - 1];
                      const prevValue =
                        col.value?.(prevRow) ??
                        (prevRow[col.id as keyof T] as string | number);
                      if (value == prevValue) {
                        opacity = col.opacityIfSameAsPrevious;
                      }
                    }
                    return (
                      <TableCell
                        // some columns use duplicate ids, so also include the label in the key testid
                        key={`${col.id as string | number}-${col.label}`}
                        data-testid={`${col.id as string}-${col.label}-${
                          row[rowKey]
                        }`}
                        {...(col.cellProps || {})}
                        sx={{
                          textAlign: col.numeric ? 'right' : undefined,
                          opacity,
                          ...col.cellProps?.sx,
                        }}
                      >
                        {col.cellRender?.(row, index) ??
                          (col.numeric
                            ? formatNumber(value as number)
                            : value ?? '')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
              {data && !pagedData.currentPageData.length && (
                <TableRow>
                  <TableCell align="center" colSpan={99}>
                    No data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {totalsRow && (
              <TableFooter>
                <TableRow>
                  {visibleColumns.map((col, index) => {
                    if (!index) {
                      return (
                        <TableCell
                          key={col.id as string | number}
                          sx={{ fontWeight: 'bold' }}
                        >
                          Totals:
                        </TableCell>
                      );
                    }
                    if (!col.showTotal) {
                      return <TableCell key={col.id as string | number} />;
                    }
                    return (
                      <TableCell
                        key={col.id as string | number}
                        sx={{
                          textAlign: col.numeric ? 'right' : undefined,
                          fontWeight: 'bold',
                        }}
                        data-testid={`total-${col.id as string}`}
                      >
                        {col.numeric
                          ? formatNumber(totalsRow[col.id as string] as number)
                          : totalsRow[col.id as string]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      )}
    </>
  );
}

import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BarcodeScannerFormInput from '../../../Components/Forms/BarcodeScannerFormInput';
import {
  alertModalDialog,
  confirmModalDialog,
} from '../../../Components/ModalDialog';
import useApiForm from '../../../Hooks/useApiForm';
import { Pallet } from '../../../Models/Pallet';
import PalletService from '../../../Services/PalletService';

interface Props {
  onSuccess?: (pallet: Pallet) => void;
}

/**
 * This method is not used for now, but it is kept here for future reference
 * Reference: https://backstorytech.atlassian.net/browse/CPK-678?focusedCommentId=30641
 */
// const isSamePlannedBolContainer = (cartons: Carton[] | undefined): boolean => {
//   if (!cartons || cartons.length === 0) {
//     return false;
//   }

//   if (cartons.some((c) => c.plannedBOLContainerId === null)) {
//     return false;
//   }

//   const firstCartonPlannedBolContainerId = cartons[0].plannedBOLContainerId;
//   return cartons.every(
//     (carton) =>
//       carton.plannedBOLContainerId === firstCartonPlannedBolContainerId
//   );
// };

export default function SourcePalletForm({ onSuccess }: Props) {
  const navigate = useNavigate();

  const getPalletForm = useApiForm(
    PalletService.getByCode,
    {
      code: '',
      withCartons: true,
    },
    {
      suppressError: true,
      useSearchParams: true,
      onSuccess: (result) => {
        if (result.cartons?.some((carton) => carton.qty === 1) ?? false) {
          confirmModalDialog({
            severity: 'warning',
            title: '',
            content: `Carton level pallets should be split via ‘Transfer Carton(s)`,
            acceptButtonLabel: 'Go to Transfer Carton(s)',
            declineButtonLabel: 'Continue to Split Pallet',
          }).then((res) => {
            if (res) {
              navigate('/transfer-cartons');
            } else {
              onSuccess?.(result);

              getPalletForm.setData('code', '');
            }
          });
        }
        /**
         * For now, disable this IF check until the recommended split process is in place
         * Reference: https://backstorytech.atlassian.net/browse/CPK-678?focusedCommentId=30641
         */
        // else if (isSamePlannedBolContainer(result.cartons)) {
        //   alertModalDialog({
        //     severity: 'info',
        //     title: '',
        //     content: 'Pallet does not need to be split',
        //     onClose: () => getPalletForm.setData('code', ''),
        //   });
        // }
        else {
          getPalletForm.setData('code', '');

          onSuccess?.(result);
        }
      },
      onError: (message) => {
        alertModalDialog({
          severity: 'error',
          title: '',
          content: message,
          onClose: () => getPalletForm.setData('code', ''),
        });
      },
    }
  );

  const handleOnBarcode = () => {
    // if scanning and the current barcode field has focus we want it to lose focus for the second scan
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    getPalletForm.submit();
  };

  return (
    <Box component="form" noValidate>
      <BarcodeScannerFormInput
        label="Scan source pallet"
        id="code"
        form={getPalletForm}
        onBarcode={handleOnBarcode}
        onClear={() => getPalletForm.setData('code', '')}
      />
    </Box>
  );
}

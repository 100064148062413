import Api, { ServiceGetMethod, ServicePostMethod } from './Api';
import { ASN, ASNStatus, ASNWithCounts } from '../Models/ASN';
import { Pallet } from '../Models/Pallet';

export interface ASNListRequest {
  statuses?: ASNStatus[];
  warehouseId?: number | null;
  customerId?: number;
}
export interface ASNDetailRequest {
  asnNumber: string;
}
export interface FindPalletInASNRequest {
  asnId?: number;
  code: string;
}
export interface VerifyPalletForASNRequest {
  asnId: number;
  palletId: number;
}
const markAsReviewed: ServicePostMethod<{ asnId: number }, ASN> = (
  { asnId },
  options
) => Api.put(`api/services/app/ASN/MarkAsReviewed?asnId=${asnId}`, options);

const markAsArrived: ServicePostMethod<{ asnId: number }, ASN> = (
  { asnId },
  options
) => Api.put(`api/services/app/ASN/MarkAsArrived?asnId=${asnId}`, options);

const verificationCompleteASN: ServicePostMethod<{ asnId: number }, void> = (
  { asnId },
  options
) =>
  Api.put(
    `api/services/app/ASN/VerificationCompleteASN?asnId=${asnId}`,
    options
  );

const verifyPalletForASN: ServicePostMethod<
  VerifyPalletForASNRequest,
  Pallet
> = (data, options) =>
  Api.put('api/services/app/ASN/VerifyPalletForASN', data, options);

const findPalletInASNByCode: ServicePostMethod<
  FindPalletInASNRequest,
  Pallet
> = (data, options) =>
  Api.put('api/services/app/ASN/FindPalletInASNByCode', data, options);

const getAllWithCounts: ServiceGetMethod<ASNListRequest, ASNWithCounts[]> = (
  options
) => Api.get('api/services/app/ASN/GetAllWithCounts', options);

const getASNDetail: ServiceGetMethod<ASNDetailRequest, ASNWithCounts> = (
  options
) => Api.get('api/services/app/ASN/GetASNDetail', options);

const getASNListByPallet: ServiceGetMethod<{ palletId: number }, ASN[]> = (
  options
) => Api.get('api/services/app/ASN/GetASNListByPallet', options);

const updateASNStatus: ServicePostMethod<
  { asnId: number; status: ASNStatus },
  ASN
> = (options) => Api.put('api/services/app/ASN/UpdateASNStatus', options);

const updateASNChecklistCompleted: ServicePostMethod<
  { asnId: number; checklistCompleted: boolean },
  ASN
> = (data, options) =>
  Api.put('api/services/app/ASN/UpdateASNChecklistCompleted', data, options);

export default {
  markAsReviewed,
  markAsArrived,
  getAllWithCounts,
  getASNDetail,
  findPalletInASNByCode,
  verificationCompleteASN,
  verifyPalletForASN,
  getASNListByPallet,
  updateASNStatus,
  updateASNChecklistCompleted,
};

import { Customer } from '../Models/Customer';
import { Permission, RoleName } from '../Models/Role';
import Api, { ServiceGetMethod, ServicePostMethod } from './Api';

/**
 * The AuthService provides api endpoints for getting the current authState and for logging in.
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const getCurrentLoginInformations: ServiceGetMethod<
  void,
  CurrentLoginInformation
> = (options) =>
  Api.get('api/services/app/Session/GetCurrentLoginInformations', options);

// if the Authenticate api is called without a password, it must be a barcode but we need to
// inject a special password to get around form validation and also enable user lookup by barcode

// must be the same as in User.cs
export const fakePasswordForBarcodeAuth = 'fake_barcode_password';

const authenticate: ServicePostMethod<
  AuthenticateRequest,
  AuthenticateResponse
> = (data, options) => {
  // if the user tries to login with a barcode, send the password (the actual barcode)
  // to the username property along with a fake password
  const isBarCodeLogin = !data.userNameOrEmailAddress;
  const newData: AuthenticateRequest = {
    ...data,
    userNameOrEmailAddress: isBarCodeLogin
      ? data.password
      : data.userNameOrEmailAddress,
    password: isBarCodeLogin ? fakePasswordForBarcodeAuth : data.password,
  };
  return Api.post('api/TokenAuth/Authenticate', newData, options);
};

const refreshToken: ServicePostMethod<void, AuthenticateResponse> = () => {
  return Api.post('api/TokenAuth/RefreshToken', {});
};

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  getCurrentLoginInformations,
  authenticate,
  refreshToken,
};

/**
 *  Models used in this service
 */

export interface CurrentLoginInformation {
  application: ApplicationInfo | null;
  user: UserLoginInfo | null;
  tenant: TenantLoginInfo | null;
}

export interface ApplicationInfo {
  version: string;
  releaseDate: Date;
  features: Feature[];
  basisSyncPeriodMins: number;
  photoQuality: number;
  photoWidth: number;
  inactivityTimeoutMins: number;
  maxTimeBetweenMovingPalletsSecs: number;
}

export interface Feature {
  name: string;
  included: boolean;
}

export interface UserLoginInfo {
  id: number;
  name: string;
  surname: string;
  userName: string;
  emailAddress: string;
  roleNames?: RoleName[];
  permissions?: Permission[];
  warehouseName?: string;
  currentWarehouseId: number | null | undefined;
  warehouseIds?: number[];
  currentCustomerId: number | null | undefined;
  customers?: Customer[];
}

export interface TenantLoginInfo {
  tenancyName: string;
  name: string;
}

export interface AuthenticateRequest {
  userNameOrEmailAddress: string;
  password: string;

  // this is only here so it gets logged in AbpAuditLogs
  usedScanner: boolean;
}

export interface AuthenticateResponse {
  accessToken: string;
  encryptedAccessToken: string;
  expireInSeconds: number;
  userId: number;
}

import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { onlyAllowMatchingPattern } from '../../../Lib/utils';
import { Carton } from '../../../Models/Carton';

interface Props {
  carton: Carton;
  isActive: boolean;
  onSplitQtyChange: (carton: Carton, splitQty: number) => void;
}

export default function QtyField({
  carton,
  isActive,
  onSplitQtyChange,
}: Props) {
  const [splitQty, setSplitQty] = useState<number>(0);

  if (carton.qty < 1) {
    return <Typography variant="caption">Not split able.</Typography>;
  }

  return (
    <>
      <IconButton
        disabled={splitQty === 0 || !isActive}
        onClick={() => {
          (document.activeElement as HTMLElement)?.blur();
          setSplitQty((prevState) => (prevState > 0 ? prevState - 1 : 0));
          onSplitQtyChange(carton, splitQty - 1);
        }}
        data-testid={'split-qty-down'}
      >
        <RemoveCircleOutline />
      </IconButton>
      <Box>
        <TextField
          label="Selected QTY"
          margin="dense"
          size="small"
          type="number"
          disabled={!isActive}
          value={splitQty === 0 ? '' : splitQty}
          onChange={(e) => {
            setSplitQty(Number(e.target.value));
          }}
          onBlur={() => {
            onSplitQtyChange(carton, splitQty);
            (document.activeElement as HTMLElement)?.blur();
          }}
          inputProps={{
            inputMode: 'numeric',
            pattern: '\\d*',
            onKeyDown: onlyAllowMatchingPattern,
            min: 0,
            sx: {
              // hide the up/down arrows in the number input because we've got our own
              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
              },
            },
          }}
        />
      </Box>
      <IconButton
        disabled={splitQty === carton.qty || !isActive}
        onClick={() => {
          (document.activeElement as HTMLElement)?.blur();
          setSplitQty((prevState) => prevState + 1);
          onSplitQtyChange(carton, splitQty + 1);
        }}
        data-testid={'split-qty-up'}
      >
        <AddCircleOutline />
      </IconButton>
    </>
  );
}

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
} from '@mui/material';
import React, { ReactNode, useMemo } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CardTitle from './CardTitle';
import Skeletons from './Skeletons';
import { SortedTableColumn } from './SortedTable';

/**
 * This is a wrapper for the standard MUI Table Component but not as complex as the DataGrid component
 * It provides drag'n'drop of the rows
 * Currently this assumes there is only a single page of data so is only intended for small list you want to re-order
 */

export interface DragTableProps<T> {
  data: T[];
  rowKey: keyof T;
  columnDefs: SortedTableColumn<T>[];
  title?: ReactNode;
  loading?: boolean;
  smallGap?: boolean; // remove the double padding between cells - defaults to false
  smallFont?: boolean; // reduce the default font size - default to false
  fabIcon?: ReactNode;
  onFabClick?: () => void;
  onRowClick?: (data: T) => void;
  onUpdate: (data: T[]) => void;
}

export default function <T>({
  data,
  columnDefs,
  rowKey,
  title,
  loading,
  fabIcon,
  ...props
}: DragTableProps<T>) {
  const visibleColumns = useMemo(
    () => columnDefs.filter((c) => typeof c.show == 'undefined' || c.show),
    [columnDefs]
  );

  const reorderRow = (sourceIndex: number, targetIndex: number) => {
    data.splice(targetIndex, 0, data.splice(sourceIndex, 1)[0]);
    props.onUpdate(data);
  };

  return (
    <>
      {title && <CardTitle title={title} loading={loading} />}
      {fabIcon && (
        <Fab
          color="primary"
          size="medium"
          data-testid="sorted-table-fab-button"
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
          }}
          onClick={props.onFabClick}
        >
          {fabIcon}
        </Fab>
      )}
      {!data ? (
        <Skeletons count={3} />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <TableContainer
            component={Paper}
            // set maxHeight on the table to let the sticky header row work
            sx={{
              maxHeight: '80vh',
              // persistent scroll bars to indicate content further down the page
              // https://stackoverflow.com/a/38338092
              '::-webkit-scrollbar': {
                webkitAppearance: 'none',
                width: '8px',
                height: '8px',
              },
              '::-webkit-scrollbar-thumb': {
                borderRadius: '5px',
                backgroundColor: 'rgba(0,0,0,.5)',
              },
            }}
          >
            <Table
              size="small"
              sx={{
                minWidth: 650,
                '.MuiTableCell-root': props.smallFont
                  ? {
                      fontSize: '13px',
                      padding: '12px',
                    }
                  : undefined,
                '.MuiTableRow-root .MuiTableCell-root:not(:last-child)':
                  props.smallGap
                    ? {
                        paddingRight: 0,
                      }
                    : undefined,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    width="30px"
                    sx={{
                      position: 'sticky',
                      top: 0,
                      background: 'white',
                      zIndex: 1,
                    }}
                  ></TableCell>
                  {visibleColumns.map((col) => (
                    <TableCell
                      key={`${col.id as string | number}-${col.label}`}
                      {...(col.headerProps || [])}
                      sx={{
                        whiteSpace: 'nowrap',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        background: 'white',
                        ...(col.headerProps?.sx || {}),
                      }}
                    >
                      {col.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <DraggableRow
                    key={row[rowKey] as string | number}
                    row={row}
                    index={index}
                    columns={visibleColumns}
                    rowKey={rowKey}
                    reorderRow={reorderRow}
                    onRowClick={props.onRowClick}
                  />
                ))}
                {data && !data.length && (
                  <TableRow>
                    <TableCell align="center" colSpan={99}>
                      No data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DndProvider>
      )}
    </>
  );
}

interface DraggableRowProps<T> {
  row: T;
  index: number;
  rowKey: keyof T;
  columns: SortedTableColumn<T>[];
  reorderRow: (sourceIndex: number, targetIndex: number) => void;
  onRowClick?: (data: T) => void;
}

interface IndexedRow<T> {
  row: T;
  index: number;
}

// table row drag'n'drop as per this example: https://react-table-v7.tanstack.com/docs/examples/row-dnd

const DraggableRow = function <T>({
  row,
  index,
  columns,
  rowKey,
  reorderRow,
  onRowClick,
}: DraggableRowProps<T>) {
  const dropRef = React.useRef<HTMLTableRowElement>(null);
  const dragRef = React.useRef<HTMLElement>(null);

  const indexedRow = { row, index };

  /* istanbul ignore next */
  const [, drop] = useDrop<IndexedRow<T>>({
    accept: 'row',
    // event fired when the row is dropped - these are really hard to test!

    drop: (draggedRow) => reorderRow(draggedRow.index, index),
    // event fired when a row is hovered over a different row - use this to re-order the rows as you drag
    hover(item, monitor) {
      if (!dropRef) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Get vertical middle
      const hoverBoundingRect = dropRef.current!.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed more then half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY + 5) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY - 5) {
        return;
      }
      // Time to actually perform the action
      reorderRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => indexedRow,
    type: 'row',
  });

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <TableRow
      ref={dropRef}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        cursor: onRowClick ? 'pointer' : 'default',
        '&:hover': { background: '#eee' },
        opacity: isDragging ? 0 : 1,
      }}
      onClick={() => onRowClick?.(row)}
      data-testid={'table-row-' + (row[rowKey] as string | number)}
    >
      <TableCell
        ref={dragRef}
        sx={{ cursor: isDragging ? 'grabbing' : 'grab' }}
      >
        <div>🟰</div>
      </TableCell>
      {columns.map((col) => (
        <TableCell
          // some columns use duplicate ids, so also include the label in the key testid
          key={`${col.id as string | number}-${col.label}`}
          data-testid={`${col.id as string}-${col.label}-${row[rowKey]}`}
          {...(col.cellProps || [])}
        >
          {col.cellRender?.(row, index) ??
            col.value?.(row) ??
            (row[col.id as keyof T] as string | number)}
        </TableCell>
      ))}
    </TableRow>
  );
};

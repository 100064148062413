import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import CardTitle from '../../../Components/CardTitle';
import MobilePage from '../../../Components/MobilePage';
import { confirmModalDialog } from '../../../Components/ModalDialog';
import useApiForm from '../../../Hooks/useApiForm';
import useApiGet from '../../../Hooks/useApiGet';
import { AuthContext } from '../../../Providers/AuthProvider';
import { pagedParams } from '../../../Services/Api';
import BillOfLadingService from '../../../Services/BillOfLadingService';
import BolDataGrid from './Components/BolDataGrid';
import { CloudSyncTwoTone } from '@mui/icons-material';
import FetchBol from '../../../Components/BillOfLading/FetchBol';
import { toast } from '../../../Components/Toast';
import useAuthorizedRoutes from '../../../Hooks/useAuthorizedRoutes';

/***
 * This is the Admin's view of the Bills of Lading table
 */
const indicatorStyles = {
  width: '120px',
  height: '120px',
  borderRadius: '100%',
  color: '#fff',
  fontSize: '5rem',
  verticalAlign: 'middle',
  textAlign: 'center',
  display: 'inline-block',
  cursor: 'pointer',
  margin: '0 auto',
};

export default function BolAdminList() {
  const { user } = useContext(AuthContext);
  const bolIdRef = useRef(0);
  const [open, setOpen] = useState(false);
  const [filterToleranceFail, setFilterToleranceFail] = useState(false);
  const { routeMenuItems } = useAuthorizedRoutes();

  const closeModal = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    toast(
      'Bill of Lading imported. Pallets and Cartons will sync in the background.'
    );
    closeModal();
    refresh();
  };

  const { data, loading, refresh } = useApiGet(
    BillOfLadingService.getBillsOfLading,
    {
      params: {
        ...pagedParams,
        warehouseId: user?.currentWarehouseId,
      },
      // sync periodically from the server, on the same frequency as the BOLs are synced
      autoRefreshTrueOrSecs: true,
      onSuccess: (data) => {
        const toleranceFailCount = data.items.filter(
          (i) => i.toleranceFail === true
        ).length;
        // Update badge menu again
        const bolAdminItem = routeMenuItems.find((m) => m.id == 'BolAdminList');
        if (bolAdminItem) {
          bolAdminItem.setBadge?.(toleranceFailCount);
        }
      },
    }
  );

  const getAllBOLContainersBeyondCompletedStatusForm = useApiForm(
    BillOfLadingService.getAreAllBOLContainersBeyondCompletedStatus,
    {
      billOfLadingId: 0,
    },
    {
      onSuccess: async () => {
        const confirmed = await confirmModalDialog({
          title: '',
          content: (
            <Box textAlign={'center'}>
              <h3>
                This will mark the BOL as loaded <br></br> Do you want to
                continue?
              </h3>
            </Box>
          ),
        });
        if (confirmed) {
          handleMarkAsLoaded();
        }
      },
    }
  );

  const markAsLoadedForm = useApiForm(
    BillOfLadingService.markAsLoaded,
    {
      billOfLadingId: 0,
    },
    {
      onSuccess: () => {
        refresh();
      },
    }
  );

  const handleCheckContainersStatuses = useCallback(
    (id: number) => {
      bolIdRef.current = id;
      getAllBOLContainersBeyondCompletedStatusForm.setData(
        'billOfLadingId',
        id
      );
      getAllBOLContainersBeyondCompletedStatusForm.submit();
    },
    [getAllBOLContainersBeyondCompletedStatusForm]
  );

  const handleMarkAsLoaded = () => {
    markAsLoadedForm.setData('billOfLadingId', bolIdRef.current);
    markAsLoadedForm.submit();
  };

  const dataRows = useMemo(() => {
    return filterToleranceFail
      ? data?.items.filter((b) => b.toleranceFail === true)
      : data?.items;
  }, [filterToleranceFail, data]);

  return (
    <MobilePage sx={{ position: 'relative' }} maxWidth={false}>
      <Box sx={{ height: '62vh' }}>
        <CardTitle title="Bills of Lading" />
        <Stack direction="row" spacing={3} marginBottom={3}>
          <Stack direction="column" spacing={1} key={'tolerance-failed'}>
            <Typography
              data-testid="tolerance-failed-count"
              onClick={() => setFilterToleranceFail(!filterToleranceFail)}
              sx={{
                ...indicatorStyles,
                backgroundColor: (theme) => theme.palette.error.dark,
              }}
            >
              {loading ? (
                <CircularProgress color="inherit" sx={{ marginBottom: 1 }} />
              ) : (
                data?.items.filter((item) => item.toleranceFail === true)
                  .length || 0
              )}
            </Typography>
            <Typography
              data-testid="tolerance-failed-selected-indicator"
              sx={{
                textAlign: 'center',

                '&.filter-tolerance--active': {
                  borderBottom: '6px solid',
                  borderColor: (theme) => theme.palette.error.dark,
                },
                '&.filter-tolerance--inactive': {
                  borderBottom: '0',
                },
              }}
              className={`filter-tolerance--${
                filterToleranceFail ? 'active' : 'inactive'
              }`}
            >
              Tolerance Failures
            </Typography>
          </Stack>
        </Stack>
        <BolDataGrid
          loading={loading}
          rows={dataRows || []}
          checkContainersStatuses={handleCheckContainersStatuses}
          refreshRows={refresh}
          fabIcon={<CloudSyncTwoTone sx={{ top: '50px' }} />}
          onFabClick={() => setOpen(true)}
          fabStyle={{ top: 10 }}
        />
        {open && <FetchBol onClose={closeModal} onSuccess={onSuccess} />}
      </Box>
    </MobilePage>
  );
}

import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AppProvider from './Layouts/AppLayout/AppProvider';

Sentry.init({
  dsn: 'https://fd537d2096dc4319be519a2bd22b56a6@o4505557899214848.ingest.sentry.io/4505565462528000',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'wms-test.coolpak.co.nz',
        'wms-stage.coolpak.co.nz',
        'wms.coolpak.co.nz',
      ],
    }),
    new Sentry.Replay(),
  ],
  environment: process.env.REACT_APP_ENVIRONMENT,
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE
    ? +process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE
    : 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: process.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE
    ? +process.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE
    : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: process.env.REACT_APP_SENTRY_ERROR_SAMPLE_RATE
    ? +process.env.REACT_APP_SENTRY_ERROR_SAMPLE_RATE
    : 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

export default root.render(<AppProvider />);

import React, { useMemo } from 'react';
import {
  Carton,
  CARTON_STATUS_COLORS,
  CARTON_STATUS_LABELS,
  CartonStatus,
  getShortenBarcode,
} from '../../Models/Carton';
import { Box, Button, darken, Stack } from '@mui/material';
import CartonStatusLegend from './CartonStatusLegend';
import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';

interface CartonsWithStatusFilterProps {
  cartons?: Carton[];
  status?: CartonStatus | null;
  onStatusClick?: (cartonStatus: CartonStatus) => void;
  onCompleteScanning: () => void;
  cartonColumns: GridColDef[];
  onRowSelectionChange?: (row: GridRowSelectionModel) => void;
  rowSelectionModel?: GridRowSelectionModel;
}

export default function ({
  cartons,
  status,
  onStatusClick,
  cartonColumns,
  onCompleteScanning,
  onRowSelectionChange,
  rowSelectionModel,
}: CartonsWithStatusFilterProps) {
  // we can not use DataGrid's sortModel because we are sorting on the status column that is not visible
  const sortedCartons = useMemo(() => {
    const cartonsFilteredByStatus =
      status !== null ? cartons?.filter((c) => c.status === status) : cartons;

    // divide the cartons in two groups : expected cartons and others
    const expectedCartons = cartonsFilteredByStatus?.filter(
      (c) => c.status === CartonStatus.expected
    );

    const otherCartons = cartonsFilteredByStatus?.filter(
      (c) => c.status !== CartonStatus.expected
    );
    // sort each group by the last ten digits of the barcode (we only display ten digits because the barcode is too long)
    const sortByDisplayedDigit = (a: Carton, b: Carton) =>
      getShortenBarcode(a.cartonBarcode).localeCompare(
        getShortenBarcode(b.cartonBarcode)
      );
    const expectedCartonsSorted =
      expectedCartons?.sort(sortByDisplayedDigit) ?? [];
    const otherCartonsSorted = otherCartons?.sort(sortByDisplayedDigit) ?? [];

    return [...expectedCartonsSorted, ...otherCartonsSorted];
  }, [cartons, status]);

  return (
    <Box
      data-testid="list-cartons-with-status-filter"
      sx={{
        width: '100%',
      }}
    >
      <Stack
        direction="column"
        justifyContent={'space-between'}
        spacing={2}
        mb={1}
      >
        <Button
          data-testid="pallet-scanning-complete-button"
          sx={{ width: 'fit-content', alignSelf: 'end' }}
          variant="contained"
          onClick={onCompleteScanning}
          size="small"
        >
          Scanning Complete
        </Button>
        <CartonStatusLegend
          cartons={cartons}
          onClick={onStatusClick}
          status={status}
        />
      </Stack>
      <DataGridPro
        disableColumnFilter
        disableColumnMenu
        autoHeight
        onRowSelectionModelChange={(newRowSelectionModel) =>
          onRowSelectionChange?.(newRowSelectionModel)
        }
        rowSelectionModel={rowSelectionModel ? rowSelectionModel : undefined}
        data-testid="cartons-grid"
        hideFooter
        rows={sortedCartons ?? []}
        columns={cartonColumns}
        sx={{
          backgroundColor: 'white',
          '& .pallet-details--cartons-status--1': {
            backgroundColor:
              CARTON_STATUS_COLORS[CARTON_STATUS_LABELS[CartonStatus.verified]]
                .bg,
            '&:hover': {
              backgroundColor: darken(
                CARTON_STATUS_COLORS[
                  CARTON_STATUS_LABELS[CartonStatus.verified]
                ].bg,
                0.1
              ),
            },
          },
          '& .pallet-details--cartons-status--2': {
            backgroundColor:
              CARTON_STATUS_COLORS[CARTON_STATUS_LABELS[CartonStatus.new]].bg,
            '&:hover': {
              backgroundColor: darken(
                CARTON_STATUS_COLORS[CARTON_STATUS_LABELS[CartonStatus.new]].bg,
                0.1
              ),
            },
          },
          '& .pallet-details--cartons-status--3': {
            backgroundColor:
              CARTON_STATUS_COLORS[CARTON_STATUS_LABELS[CartonStatus.lost]].bg,
            '&:hover': {
              backgroundColor: darken(
                CARTON_STATUS_COLORS[CARTON_STATUS_LABELS[CartonStatus.lost]]
                  .bg,
                0.1
              ),
            },
          },
        }}
        getRowClassName={(params) =>
          `pallet-details--cartons-status--${params.row.status}`
        }
      />
    </Box>
  );
}

import React, { useContext, useEffect } from 'react';
import { Stack } from '@mui/system';
import ChecklistItemFormControl from '../../Components/Checklist/ChecklistItemFormControl';
import ChecklistItemResponseService from '../../Services/ChecklistItemResponseService';
import useApiForm from '../../Hooks/useApiForm';
import {
  ChecklistItemResponse,
  getFileMetadata,
} from '../../Models/ChecklistItemResponse';
import {
  ChecklistItem,
  ChecklistItemGroup,
  ChecklistItemType,
} from '../../Models/Checklist';
import AuditLog from '../AuditLog/AuditLog';
import { Box, IconButton } from '@mui/material';
import usePermissions from '../../Hooks/usePermissions';
import MediaLibrary from '../MediaLibrary/MediaLibrary';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';

export interface ChecklistItemFormProps {
  response?: ChecklistItemResponse | null;
  checklistItem: ChecklistItem;
  entityType: ChecklistItemEntityType;
  entityId: number;
  onSuccess: () => void;
  autoFill?: Record<string, string | number>;
  onChecklistCompleted?: (isComplete: boolean) => void;
  checklistId: number;
  canCompleteChecklist?: boolean;
  checklistItemGroup: ChecklistItemGroup;
  onMediaUploaded?: () => void;
}

// just for typescript hinting and to avoid typos
export type ChecklistItemEntityType =
  | 'BillOfLadings.BillOfLading'
  | 'BOLContainers.BOLContainer'
  | 'ASNs.ASN';

export default function ({
  checklistItem,
  response,
  entityType,
  entityId,
  onSuccess,
  autoFill,
  onChecklistCompleted,
  checklistId,
  canCompleteChecklist = false,
  checklistItemGroup,
  onMediaUploaded,
}: ChecklistItemFormProps) {
  const [canViewAudit] = usePermissions(['Audit.View']);
  const { mobileView } = useContext(ResponsiveContext);

  const form = useApiForm(
    ChecklistItemResponseService.createOrUpdateChecklistItemResponse,
    {
      id: response?.id || 0,
      entityType: response?.entityType || entityType,
      checklistItemId: response?.checklistItemId || checklistItem.id,
      comment: response?.comment || '',
      entityId: response?.entityId || entityId,
      response: response?.response || '',
    },
    {
      onSuccess: (data) => {
        onSuccess();
        const { checklistItemResponse, isChecklistCompleted } = data;
        // just in case an update from the server happened while this request was pending
        form.setData('id', checklistItemResponse.id);
        form.setData('response', checklistItemResponse.response);
        form.setData('comment', checklistItemResponse.comment);
        onChecklistCompleted?.(isChecklistCompleted);
      },
    }
  );

  useEffect(() => {
    if (canCompleteChecklist) {
      // find an autoFill which matches the action
      const regExAutofill = /app.autofill.(\w+)/;
      const action =
        autoFill &&
        !response &&
        checklistItem.actions.find((action) => action.match(regExAutofill));
      const value = action
        ? autoFill[action.match(regExAutofill)![1]]
        : undefined;
      if (value) {
        form.setData('response', value.toString());
        form.submit();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // update form when the response is refreshed from the server, unless we're saving our own new data
  useEffect(() => {
    if (response && !form.processing) {
      form.setData('response', response.response);
      form.setData('comment', response.comment);
    }
  }, [response]); // eslint-disable-line react-hooks/exhaustive-deps

  const onValueUpdated = ({
    value,
    comment,
  }: {
    value: string;
    comment?: string;
  }) => {
    form.setData('response', value);
    form.setData('comment', comment || '');

    // do not submit the form if the response is empty or the same as the current one
    if (
      (response?.comment || '') != form.data.comment ||
      (response?.response || '') != form.data.response
    ) {
      form.submit();
    }
  };

  // When a photo is uploaded against a response, we check if the checklist is completed.
  // We also refresh the BOL to have the pictures available on the BolContainerList and BolContainer Media Libraries
  const onUploaded = () => {
    if (onChecklistCompleted) {
      ChecklistItemResponseService.isChecklistCompletedForEntityTypeAndEntityId(
        {
          params: {
            entityType,
            entityId,
            checklistId,
          },
        }
      ).then((response) => {
        const [isChecklistCompleted] = response;
        if (isChecklistCompleted != undefined) {
          onChecklistCompleted(isChecklistCompleted);
        }
        onMediaUploaded?.();
      });
    }
  };

  // photos are required when the action equals require.photos:value_requiring_photos or require.photos
  const requirePhotos =
    checklistItem.actions.includes('require.photos') ||
    (response &&
      checklistItem.actions.includes(`require.photos:${response.response}`));

  // new functionality to allow users to take photos against a checklist item, but it is not enforced (optional)
  const optionalPhotos = checklistItem.actions.includes('optional.photos');

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        // trigger the save-on-blur
        (document.activeElement as HTMLElement).blur();
      }}
      noValidate
      sx={{ width: 1 }}
    >
      <ChecklistItemFormControl
        disabled={!canCompleteChecklist}
        onValueUpdated={onValueUpdated}
        checklistItem={checklistItem}
        form={form}
      />
      {(requirePhotos || optionalPhotos) && (
        <Box
          alignSelf={
            checklistItem.type === ChecklistItemType.OPTIONS
              ? 'flex-start'
              : 'center'
          }
        >
          {response ? (
            <MediaLibrary
              entityTypes={['ChecklistItemResponses.ChecklistItemResponse']}
              entityIds={[response.id]}
              newFilePrefix={`checklist-${entityType}-${entityId}`}
              allowUpload={canCompleteChecklist}
              allowDelete={canCompleteChecklist}
              autoOpenCamera={mobileView} // only auto open camera when the viewport is mobile form factor
              onUploaded={onUploaded}
              metaData={[
                getFileMetadata(response, checklistItem, checklistItemGroup),
              ]}
              required={optionalPhotos ? false : requirePhotos ?? true}
            />
          ) : (
            <IconButton disabled data-testid="media-library-icon-disabled">
              <CameraAltIcon color="disabled" />
            </IconButton>
          )}
        </Box>
      )}
      {canViewAudit && (
        <Box
          sx={{
            minWidth: '40px',
            marginLeft: 4,
            alignSelf:
              checklistItem.type === ChecklistItemType.OPTIONS
                ? 'start'
                : 'center',
          }}
        >
          {response?.id && (
            <AuditLog
              entityType="Coolpak.WMS.ChecklistItemResponses.ChecklistItemResponse"
              entityId={response.id}
              tabIndex={300}
            />
          )}
        </Box>
      )}
    </Stack>
  );
}

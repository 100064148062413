import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { DetailRow } from '../../../Components/PalletDetails';
import { Carton } from '../../../Models/Carton';
import { Pallet } from '../../../Models/Pallet';
import QtyField from './QtyField';

interface Props {
  title?: string;
  sourcePallet: Pallet;
  selectedCartonId: number;
  selectedCartonQty: number;
  onSplitQtyChange: (carton: Carton, splitQty: number) => void;
}

export default function CartonCards({
  title,
  sourcePallet,
  selectedCartonId,
  selectedCartonQty,
  onSplitQtyChange,
}: Props) {
  return (
    <Box>
      <Typography variant="body2" color="textSecondary" align="center" mb={2}>
        <b>Source Pallet:</b> {sourcePallet.code}
      </Typography>
      <Grid container spacing={1}>
        {sourcePallet.cartons
          ?.filter((carton) => carton.isOnHand)
          ?.map((carton) => (
            <Grid item md={3} xs={12} key={carton.id}>
              <Card role="card">
                <CardContent sx={{ paddingBottom: 0 }}>
                  {title && <Typography variant="h4">{title}</Typography>}

                  <DetailRow
                    label="Barcode: "
                    testId={`carton-barcode`}
                    wrapContent={true}
                  >
                    {carton.cartonBarcode}
                  </DetailRow>

                  <DetailRow label="Product: " testId={`carton-barcode`}>
                    {carton.productCode}
                  </DetailRow>
                  <DetailRow label="Batch: " testId={`carton-barcode`}>
                    {carton.batchNumber}
                  </DetailRow>
                  <DetailRow label="QTY: " testId={`carton-barcode`}>
                    {carton.qty}
                  </DetailRow>

                  <Stack direction={'row'}>
                    <QtyField
                      isActive={
                        selectedCartonId === 0 ||
                        selectedCartonId === carton.id ||
                        selectedCartonQty === 0
                      }
                      carton={carton}
                      onSplitQtyChange={onSplitQtyChange}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

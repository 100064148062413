import React, { useMemo, useState } from 'react';
import PalletService from '../../Services/PalletService';
import MobilePage from '../../Components/MobilePage';
import useApiForm from '../../Hooks/useApiForm';

import BarcodeScannerFormInput from '../../Components/Forms/BarcodeScannerFormInput';
import { PalletPickList } from '../../Components/Pallet/PalletPickList';
import { MovePallets } from '../../Components/MovePallets';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { PalletDetails } from '../../Models/Pallet';
import CloseIcon from '@mui/icons-material/Close';

export default function () {
  const [refreshPalletPickList, setRefreshPalletPickList] = useState(0);
  const [scannedPallet, setScannedPallet] = useState<null | PalletDetails>(
    null
  );
  const [movePalletsActive, setMovePalletsActive] = useState<boolean>(false);

  const resetForms = () => {
    getPalletForm.setData('code', '');
    setScannedPallet(null);
  };

  const getPalletForm = useApiForm(
    PalletService.getByCodeDetailed,
    {
      code: '',
      detailed: true,
    },
    {
      onError: (message) => {
        getPalletForm.setErrors({ code: message });
      },
      onSuccess: (result: PalletDetails) => {
        if (result) setScannedPallet(result);
      },
      useSearchParams: true,
    }
  );

  const onPalletScan = () => {
    // if scanning and the current barcode field has focus we want it to lose focus for the second scan
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    getPalletForm.submit();
    resetForms();
  };

  const checkAfterScanResponse = (res: { err: string } | undefined) => {
    if (res && res.err) return;
    setScannedPallet(null);
    setMovePalletsActive(false);
    // Make the pallet pick list refresh
    setRefreshPalletPickList((prevKey) => prevKey + 1);
  };

  const movePalletDialogue = useMemo(() => {
    if (!scannedPallet) return null;

    setMovePalletsActive(true);

    return (
      <Dialog
        open={true}
        fullScreen
        data-testid="modal-dialog"
        scroll="paper"
        sx={{ margin: 0, padding: 0 }}
      >
        <DialogContent
          data-testid="MovePalletDialogue"
          dividers
          sx={{ margin: 0, padding: 0 }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setScannedPallet(null);
              setMovePalletsActive(false);
            }}
            aria-label="close"
            sx={{ position: 'absolute', left: '5px', top: '3px' }}
          >
            <CloseIcon />
          </IconButton>
          <MovePallets
            scannedPallet={scannedPallet}
            onAfterScan={(res) => checkAfterScanResponse(res)}
          />
        </DialogContent>
      </Dialog>
    );
  }, [scannedPallet]);

  return (
    <MobilePage
      showOnDesktop
      showTitle
      title="Pallet Pick List"
      sx={{
        paddingLeft: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingTop: '34px',
      }}
    >
      <BarcodeScannerFormInput
        label="Pallet Barcode"
        id="code"
        form={getPalletForm}
        onBarcode={onPalletScan}
        onClear={() => resetForms()}
        disabledBackgroundInput={movePalletsActive}
      />
      <PalletPickList refreshKey={refreshPalletPickList} />
      {movePalletDialogue}
    </MobilePage>
  );
}

import {
  GridCellModesModel,
  GridCellParams,
  GridCellModes,
} from '@mui/x-data-grid-pro';
import React from 'react';

export default function () {
  //From https://github.com/mui/mui-x/blob/v6.12.0/docs/data/data-grid/recipes-editing/SingleClickEditing.tsx
  const [cellModesModel, setCellModesModel] =
    React.useState<GridCellModesModel>({});

  const handleEditCellClick = React.useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return;
      }

      // Ignore portal
      if (!event.currentTarget.contains(event.target as Element)) {
        return;
      }

      /* eslint-disable @typescript-eslint/no-explicit-any */
      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce((acc: any, id) => {
            acc[id] = Object.keys(prevModel[id]).reduce((acc2: any, field) => {
              acc2[field] = { mode: GridCellModes.View };
              return acc2;
            }, {});
            return acc;
          }, {}),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc: any, field) => {
                acc[field] = { mode: GridCellModes.View };
                return acc;
              },
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
      /* eslint-enable @typescript-eslint/no-explicit-any */
    },
    []
  );

  const handleEditCellModesModelChange = React.useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    []
  );

  return {
    cellModesModel,
    setCellModesModel,
    handleEditCellClick,
    handleEditCellModesModelChange,
  };
}

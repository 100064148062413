export interface Warehouse {
  id: number;
  name: string;
  code: string;
}

// these are just for unit tests - in real life they come from the API...
export const FAKE_WAREHOUSES: Warehouse[] = [
  {
    id: 1,
    name: 'Rolleston',
    code: 'S2316',
  },
  {
    id: 2,
    name: 'Meadows Road',
    code: '66',
  },
  {
    id: 3,
    name: 'Old North Road',
    code: 'S114',
  },
  {
    id: 4,
    name: 'Fraser Street',
    code: 'S202',
  },
  {
    id: 5,
    name: 'King Street',
    code: 'S61',
  },
];

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import FormField from '../../Components/Forms/FormField';
import { toast } from '../../Components/Toast';
import useApiForm from '../../Hooks/useApiForm';
import { jsonClone } from '../../Lib/utils';
import {
  UserDefinedCode,
  UserDefinedCodeGroup,
} from '../../Models/UserDefinedCode';
import UserDefinedCodeService from '../../Services/UserDefinedCodeService';
import CustomField from './CustomField';

export interface EditCodeProps {
  group: UserDefinedCodeGroup;
  code: UserDefinedCode | null;
  onClose: () => void;
  onSuccess: (code?: UserDefinedCode) => void;
}

export default function ({ group, code, onClose, onSuccess }: EditCodeProps) {
  const form = useApiForm(
    UserDefinedCodeService.createOrUpdateCode,
    {
      id: code?.id || 0,
      name: code?.name || '',
      description: code?.description || '',
      value: code?.value ?? '',
      active: code?.active ?? true,
      // deep clone the custom values so we don't overwrite the originals if the use cancels this modal
      customValues: jsonClone(code?.customValues || []),
      userDefinedCodeGroupId: group.id,
    },
    {
      // pass the success to the parent event handler which will close the modal and refresh its data
      onSuccess: (newCode) => {
        toast(`Code "${newCode.name}" ${code ? 'updated' : 'created'}`);
        onSuccess(newCode);
      },
    }
  );

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm" scroll="paper">
      <Box component="form" onSubmit={form.submit} noValidate>
        <DialogTitle>{code ? 'Update Code' : 'Create Code'}</DialogTitle>
        <DialogContent>
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 xs>
              <FormField
                form={form}
                id="name"
                type="text"
                label="Code"
                required
                autoFocus={!code}
              />
            </Grid2>
            <Grid2 xs="auto">
              <FormField
                form={form}
                id="active"
                type="checkbox"
                label="Active"
              />
            </Grid2>
          </Grid2>
          <FormField form={form} id="value" type="text" label="Value" />
          <FormField
            form={form}
            id="description"
            type="text"
            label="Description"
          />
          {group.customFields?.map((field) => (
            <CustomField
              field={field}
              form={form}
              code={code}
              codes={group.userDefinedCodes || []}
              key={field.name}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            disabled={form.processing}
            data-testid="close-code-btn"
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            loading={form.processing}
            disabled={!form.data.name}
            data-testid="save-code-btn"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

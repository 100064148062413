import React, { useContext } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { drawerWidth } from './AppDrawer';
import { IconButton, useScrollTrigger } from '@mui/material';
import usePwaButton from '../../Hooks/usePwaButton';
import LocationSelector from '../../Components/LocationSelector';
import CustomerUserSelector from '../../Components/CustomerUserSelector';
import { AuthContext } from '../../Providers/AuthProvider';

export default function () {
  const pwa = usePwaButton();
  const { user } = useContext(AuthContext);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <MuiAppBar
      position="fixed"
      elevation={trigger ? 4 : 0}
      data-testid="desktop-appbar"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}
      >
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
          data-testid="page-title"
        >
          COOLTRAK
        </Typography>
        {pwa.show && (
          <IconButton color="inherit" onClick={pwa.onClick}>
            <pwa.Icon />
          </IconButton>
        )}
        <CustomerUserSelector appBar />

        {user && !user.roleNames?.some((r) => r == 'CUSTOMER USER') && (
          <LocationSelector appBar allowNull />
        )}
      </Toolbar>
    </MuiAppBar>
  );
}

import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { XmlTag } from '../../../Lib/xmlUtils';
import { Stack } from '@mui/system';

export default function ECertProductClassifications({
  product,
  tagNamePrefix = 'ed:',
}: {
  product: XmlTag;
  tagNamePrefix?: string;
}) {
  const [expandedAccordion, setExpandedAccordion] = useState('');
  const handleAccordionChanged =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : '');
    };
  const classifications =
    product.getElementsByTagName?.('Classification', tagNamePrefix) || [];

  return (
    <Accordion
      expanded={expandedAccordion === 'panelProductClassifications'}
      onChange={handleAccordionChanged('panelProductClassifications')}
      sx={{ padding: '8px' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="panelProductClassifications-header"
      >
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          Classifications
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              Classification Type
            </Typography>
            <Stack direction={'column'} spacing={4} sx={{ marginTop: '16px' }}>
              {classifications.map((classification, index) => {
                const classificationType =
                  classification.getElementsByTagName?.(
                    'ClassificationType',
                    tagNamePrefix
                  )[0]?.value || '';
                return (
                  <Typography
                    sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                    key={`ecert-product-classification-type-${index}`}
                  >
                    {classificationType}
                  </Typography>
                );
              })}
            </Stack>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              Classification Value
            </Typography>
            <Stack direction={'column'} spacing={4} sx={{ marginTop: '16px' }}>
              {classifications.map((classification, index) => {
                const classificationValue =
                  classification.getElementsByTagName?.(
                    'ClassificationValue',
                    tagNamePrefix
                  )[0]?.value || '';
                return (
                  <Typography
                    sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                    key={`ecert-product-classification-value-${index}`}
                  >
                    {classificationValue}
                  </Typography>
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

import React from 'react';
import { Badge, emphasize, Paper, PaperProps } from '@mui/material';

/**
 * This renders a consistent StatusChip based on the label passed and the provides label/colour map
 */

export type StatusChipColors<T extends string | number> = Record<
  T,
  { bg: string; fg?: string }
>;

export interface StatusChipProps<T extends string | number> extends PaperProps {
  label: T;
  colors: StatusChipColors<T>;
  width?: string | number;
  badgeContent?: React.ReactNode;
  active?: boolean; // will render the chip with bold font
}
export default function StatusChip<T extends string | number>({
  label,
  colors,
  width,
  badgeContent,
  active,
  onClick,
  ...props
}: StatusChipProps<T>) {
  const getForegroundColor = () => {
    return colors[label]?.fg;
  };

  const getBackgroundColor = () => {
    return colors[label]?.bg || '#FF2E63'; // bg color is required therefore provide a fallback bg color.
  };

  return (
    <Badge
      badgeContent={badgeContent}
      sx={{
        '.MuiBadge-badge': {
          color: getForegroundColor(),
          backgroundColor: emphasize(getBackgroundColor(), 0.1),
        },
        cursor: onClick ? 'pointer' : 'auto',
      }}
      onClick={onClick}
    >
      <Paper
        {...props}
        sx={{
          p: '4px 8px',
          fontSize: '12px',
          paddingY: '8px',
          fontWeight: active ? 'bold' : undefined,
          color: getForegroundColor(),
          backgroundColor: getBackgroundColor(),
          width,
          textAlign: 'center',
          alignSelf: 'baseline',
          textTransform: 'uppercase',
          margin: 'auto',
          wordWrap: 'break-word',
          whiteSpace: 'normal',
          ...(props.sx || {}),
        }}
        variant="outlined"
      >
        {label || 'Unknown Status'}
      </Paper>
    </Badge>
  );
}

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import FormField, { SelectItem } from '../../Components/Forms/FormField';
import useApiForm from '../../Hooks/useApiForm';
import UserService from '../../Services/UserService';
import ClearIcon from '@mui/icons-material/Clear';
import useWarehouses from '../../Hooks/useWarehouses';
import { User } from '../../Models/User';
import AuditLog from '../../Components/AuditLog/AuditLog';
import UserActivationForm from '../../Components/User/UserActivationForm';
import useApiGet from '../../Hooks/useApiGet';
import CustomerService from '../../Services/CustomerService';
import { customerSelectItems } from '../../Models/Customer';

/***
 * This modal allows the Admin to edit or create a user
 */

export interface EditUserProps {
  user?: User | null;
  roles: SelectItem[];
  onClose: () => void;
  onSuccess: () => void;
  onToggleUserIsActive: () => void;
}

export default function ({
  user,
  roles,
  onClose,
  onSuccess,
  onToggleUserIsActive,
}: EditUserProps) {
  const form = useApiForm(
    UserService.createOrUpdateUser,
    {
      id: user?.id || 0,
      userName: user?.userName || '',
      name: user?.name || '',
      surname: user?.surname || '',
      emailAddress: user?.emailAddress || '',
      roleNames: user?.roleNames || ['OPERATOR'],
      password: '',
      isActive: user?.isActive ?? true,
      barcode: '',
      hasBarcode: user?.hasBarcode || false,
      warehouseIds: user?.warehouseIds || [],
      customerIds: user?.customerIds || [],
    },
    {
      // pass the success to the parent event handler which will close the modal and refresh its data
      onSuccess,
    }
  );

  // fetch cached warehouses
  const { warehouseSelectItems } = useWarehouses();

  // fetch customers
  const { data: customers } = useApiGet(CustomerService.getAllCustomerNames);
  const customerItems = useMemo(
    () => customerSelectItems(customers || []),
    [customers]
  );

  // if the user has a barcode, don't actually show it.  Just show a dummy password field
  // instead and let the user clear the password to enter a new barcode
  const [showHiddenBarcode, setShowHiddenBarcode] = useState(
    user?.hasBarcode || false
  );
  const onClearBarcode = () => {
    setShowHiddenBarcode(false);
    form.setData('hasBarcode', false);
  };

  // pretend we know the user's password, don't actually show it.  Just show a dummy password field
  // instead and let the user clear the password to enter a new one
  const [showHiddenPassword, setShowHiddenPassword] = useState(!!user);

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <Box component="form" onSubmit={form.submit} noValidate>
        <DialogTitle>{user ? 'Update User' : 'Create User'}</DialogTitle>
        <DialogContent>
          <FormField
            form={form}
            id="userName"
            type="text"
            label="Username"
            required
            inputProps={{ 'data-lpignore': true }}
            autoFocus
          />
          <FormField
            form={form}
            id="name"
            type="text"
            label="First names"
            required
          />
          <FormField
            form={form}
            id="surname"
            type="text"
            label="Surname"
            required
          />
          <FormField
            form={form}
            id="emailAddress"
            type="text"
            label="Email Address"
            inputProps={{ 'data-lpignore': true }}
            required
          />
          <FormField
            form={form}
            id="roleNames"
            label="Role"
            type="select"
            options={roles}
          />
          <FormField
            form={form}
            id="warehouseIds"
            label="Warehouses"
            type="select"
            options={warehouseSelectItems}
            multiple
          />
          <FormField
            form={form}
            id="customerIds"
            label="Customers"
            type="autocomplete"
            options={customerItems}
            multiple
          />

          {showHiddenPassword ? (
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel htmlFor="fake-password">Password</InputLabel>
              <OutlinedInput
                id="fake-password"
                type="password"
                value="justForShow"
                label="Password"
                disabled
                inputProps={{ 'data-lpignore': true }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      data-testid="clear-password-btn"
                      onClick={() => setShowHiddenPassword(false)}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          ) : (
            <FormField
              form={form}
              id="password"
              label="Password"
              type="text"
              inputProps={{ 'data-lpignore': true }}
            />
          )}
          {showHiddenBarcode ? (
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel htmlFor="fake-barcode">Barcode</InputLabel>
              <OutlinedInput
                id="fake-barcode"
                type="password"
                value="justForShow"
                label="Barcode"
                inputProps={{ 'data-lpignore': true }}
                disabled
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle barcode visibility"
                      data-testid="clear-barcode-btn"
                      onClick={onClearBarcode}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          ) : (
            <FormField
              form={form}
              id="barcode"
              label="Barcode"
              type="text"
              inputProps={{ 'data-lpignore': true }}
              onChange={(e) => form.setData('hasBarcode', !!e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          {user?.id && (
            <>
              <AuditLog
                entityType="Coolpak.WMS.Authorization.Users.User"
                entityId={user.id}
                tabIndex={300}
              />
              <UserActivationForm
                user={user}
                onSuccess={onToggleUserIsActive}
              />
            </>
          )}
          <Box flexGrow={1} />
          <Button onClick={onClose} disabled={form.processing} tabIndex={2}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={form.processing} tabIndex={1}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

import { faker } from '@faker-js/faker';
import { Factory } from 'fishery';
import { StatusChipColors } from '../Components/StatusChip';

export interface Stocktake {
  id: number;
  creationTime: Date;
  location: string;
  createdBy: string;
  lastScannedTime: Date | null;
  expected: number;
  verified: number;
  lost: number;
  new: number;
  stocktakeStatus: StocktakeStatus;
}

export enum StocktakeStatus {
  OPEN,
  INPROGRESS,
  COMPLETED,
}

export interface LocationWithPalletsCount {
  locationId: number;
  location: string;
  palletCount: number;
}

class CreateStocktakeDTO {
  locationIds!: number[];
}

export interface ScanLocationRequestDto {
  stocktakeId: string | undefined;
  location: string;
}

const createStocktakeDTO = new CreateStocktakeDTO();

export default createStocktakeDTO;

export const STOCKTAKE_STATUS_LABELS = ['Open', 'In Progress', 'Completed'];

export type StocktakeStatusLabel = typeof STOCKTAKE_STATUS_LABELS[number];

export const STOCKTAKE_STATUS_COLORS: StatusChipColors<StocktakeStatusLabel> = {
  Open: { bg: '#81D4FA' }, // blue/200
  'In Progress': { bg: '#31cb41' }, // green/500
  Completed: { bg: '#B9F6CA' }, // green/A100
};

export const stocktakeFactory = Factory.define<Stocktake>(() => {
  return {
    id: faker.datatype.number(),
    creationTime: faker.datatype.datetime(),
    location: faker.datatype.string(),
    createdBy: faker.datatype.string(),
    lastScannedTime: faker.datatype.datetime(),
    expected: faker.datatype.number(),
    verified: faker.datatype.number(),
    lost: faker.datatype.number(),
    new: faker.datatype.number(),
    stocktakeStatus: StocktakeStatus.OPEN,
  };
});

export const locationWithPalletCountsFactory =
  Factory.define<LocationWithPalletsCount>(() => {
    return {
      locationId: faker.datatype.number(),
      location: faker.datatype.string(),
      palletCount: faker.datatype.number(),
    };
  });

import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material';
import React, { FormEventHandler, useEffect, useMemo } from 'react';
import FormField, { SelectItem } from '../../Components/Forms/FormField';
import useApiForm from '../../Hooks/useApiForm';
import CustomerService from '../../Services/CustomerService';
import {
  billingCycleSelectItems,
  Customer,
  CUSTOMER_ENTITY_NAME,
} from '../../Models/Customer';
import useApiGet from '../../Hooks/useApiGet';
import UserDefinedCodeService from '../../Services/UserDefinedCodeService';
import Skeletons from '../../Components/Skeletons';
import EditCustomerBillingCodes from './EditCustomerBillingCodes';
import AuditLog from '../../Components/AuditLog/AuditLog';
import {
  CODE_GROUPS,
  UserDefinedCodeGroup,
} from '../../Models/UserDefinedCode';

/***
 * This modal allows the Admin to edit a customer
 */

export interface EditCustomerProps {
  customer: Customer;
  onClose: () => void;
  onSuccess: () => void;
  admins: SelectItem[];
  billingCodeGroup: UserDefinedCodeGroup;
}

let nPendingSubmissions = 0;

export default function ({
  customer,
  onClose,
  onSuccess,
  admins,
  billingCodeGroup,
}: EditCustomerProps) {
  const billingCodeGroupName = CODE_GROUPS.BILLING_CODES;
  const { data: selectedCodes } = useApiGet(
    UserDefinedCodeService.getCodeQuantitiesForEntity,
    {
      params: {
        entityType: CUSTOMER_ENTITY_NAME,
        entityId: customer.id,
        groupId: billingCodeGroup.id,
      },
    }
  );

  const onFormSuccess = () => {
    // only close the modal when all pending posts are finished
    if (!--nPendingSubmissions) {
      onSuccess();
    }
  };

  const form = useApiForm(
    CustomerService.updateCustomer,
    {
      id: customer.id,
      netsuiteId: customer.netsuiteId || '',
      billingCycle: customer.billingCycle?.toString() ?? '',
      adminId: customer.adminId?.toString() ?? '',
      hardAssign: customer.hardAssign,
      thirdParties: customer.thirdParties,
    },
    {
      onSuccess: onFormSuccess,
    }
  );

  const billingCodeForm = useApiForm(
    UserDefinedCodeService.setCodeQuantitiesForEntity,
    {
      entityType: CUSTOMER_ENTITY_NAME,
      entityId: customer.id,
      groupId: billingCodeGroup?.id || 0,
      items: selectedCodes || [],
    },
    {
      onSuccess: onFormSuccess,
    }
  );

  // submit both forms together
  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    nPendingSubmissions = 1;
    form.submit();
    // test if any items have changed before sending the form?
    if (billingCodeGroup && selectedCodes) {
      const sameCodes =
        billingCodeIdForm.data.billingCodeIds.length == selectedCodes.length &&
        selectedCodes.every((code) =>
          billingCodeIdForm.data.billingCodeIds.includes(code.userDefinedCodeId)
        );
      if (!sameCodes) {
        billingCodeForm.setData('groupId', billingCodeGroup.id);
        billingCodeForm.setData(
          'items',
          billingCodeIdForm.data.billingCodeIds.map((id) => ({
            userDefinedCodeId: id,
            quantity: 1,
          }))
        );
        nPendingSubmissions++;
        billingCodeForm.submit();
      }
    }
  };
  const processing = form.processing || billingCodeForm.processing;

  // this is a dummy form used just for rendering the Billing Code select box
  const billingCodeIdForm = useApiForm(null, {
    billingCodeIds: selectedCodes?.map((c) => c.userDefinedCodeId) || [],
  });

  // update the billingCodeIdForm when we get the selected BillingCodes from the server
  useEffect(() => {
    if (selectedCodes) {
      billingCodeIdForm.setData(
        'billingCodeIds',
        selectedCodes.map((c) => c.userDefinedCodeId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCodes]);

  const billingCycleItems = billingCycleSelectItems();

  const billingCodeSelectItems = useMemo(
    () =>
      (billingCodeGroup?.userDefinedCodes || []).map<SelectItem>((code) => ({
        id: code.id,
        label: code.name,
      })),
    [billingCodeGroup]
  );
  const [selectOpen, setSelectOpen] = React.useState(false);

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
      <Box component="form" onSubmit={onSubmit} noValidate>
        <DialogTitle>
          Update Customer:{' '}
          <span style={{ fontWeight: 'normal', fontSize: 'smaller' }}>
            {customer.name}
          </span>
        </DialogTitle>
        <DialogContent>
          <FormField
            form={form}
            id="netsuiteId"
            type="text"
            label="Netsuite Id"
          />
          <FormField
            form={form}
            id="billingCycle"
            label="Billing Cycle"
            type="select"
            options={billingCycleItems}
          />
          {!billingCodeSelectItems || !selectedCodes ? (
            <Skeletons count={1} height={56} my="14px" />
          ) : (
            <FormField
              form={billingCodeIdForm}
              id="billingCodeIds"
              label={billingCodeGroup?.name}
              type="select"
              options={billingCodeSelectItems}
              multiple
              renderValue={(selected) =>
                `${(selected as number[]).length} / ${
                  billingCodeSelectItems.length
                } Selected`
              }
              open={false}
              onOpen={() => setSelectOpen(true)}
              onClose={() => setSelectOpen(false)}
              endAdornment={
                <Button size="small" onClick={() => setSelectOpen(true)}>
                  Edit
                </Button>
              }
            />
          )}
          {selectOpen && (
            <EditCustomerBillingCodes
              customerName={customer.name}
              groupName={billingCodeGroupName}
              selectedIds={billingCodeIdForm.data.billingCodeIds}
              items={billingCodeSelectItems}
              onClose={() => setSelectOpen(false)}
              onSuccess={(newIds) => {
                billingCodeIdForm.setData('billingCodeIds', newIds);
                setSelectOpen(false);
              }}
            />
          )}
          {!admins ? (
            <Skeletons count={1} height={56} my="14px" />
          ) : (
            <FormField
              form={form}
              id="adminId"
              label="Admin"
              type="select"
              options={admins}
            />
          )}
          <FormField
            form={form}
            id="hardAssign"
            label="Hard Assign"
            type="select"
            options={[
              { id: 'false', label: 'False' },
              { id: 'true', label: 'True' },
            ]}
            renderValue={(selected) =>
              selected == false || selected == 'false' ? 'False' : 'True'
            }
          />
          <FormControl fullWidth sx={{ marginY: 2 }}>
            <Autocomplete
              multiple
              id="third-parties-input"
              options={[]}
              defaultValue={customer.thirdParties ?? []}
              freeSolo
              renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="ECert Third Party IDs"
                  placeholder="ECert Third Party IDs"
                />
              )}
              onChange={(e, val: string[]) => form.setData('thirdParties', val)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <AuditLog
            entityType="Coolpak.WMS.Customers.Customer"
            entityId={customer.id}
            tabIndex={300}
          />
          <Box flexGrow={1} />
          <Button onClick={onClose} disabled={processing} tabIndex={2}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={processing} tabIndex={1}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import React from 'react';
import { ChecklistItem } from '../../Models/Checklist';
import DOMPurify from 'dompurify';

/***
 * This modal allows the operators to confirm their choice when they answer yes to a checklist question.
 */

export interface ConfirmOptionAnswerProps {
  onCancel: () => void;
  onSuccess: () => void;
  checklistItem: ChecklistItem;
  responseText: string;
}

export default function ({
  onCancel,
  onSuccess,
  checklistItem,
  responseText,
}: ConfirmOptionAnswerProps) {
  return (
    <Dialog open onClose={onCancel} fullWidth maxWidth="xs">
      <Box>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <Typography
            variant="subtitle2"
            // NOTE: we can alter the sanitize config, but the default is quite secure
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(checklistItem.text),
            }}
          />
          <Typography variant="subtitle1" mt={1}>
            <strong>Response:</strong> {responseText}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <LoadingButton type="submit" onClick={onSuccess}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
